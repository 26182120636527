<section class="container-space">
  <mat-card class="mt-16 employee-signup-form">
    <mat-progress-bar [value]="(stepper.selectedIndex / (stepper.steps.length - 1)) * 100"></mat-progress-bar>
    <mat-stepper animationDuration="1" matVerticalStepperScroller #stepper [linear]="true">
      <mat-step [stepControl]="profileInfo">
        <form [formGroup]='profileInfo' class='text-center flex flex-col'>
          <ng-template matStepLabel>Basic Details</ng-template>
          <h2 class="mb-3">Create Your Profile</h2>
          <p class="mb-1">This information will be shared with employers when you apply for jobs. </p>
          <p class="mt-2 mb-6">You can change these details later in your profile page.</p>

          <div class="dropzoneContainer">
            <ngx-dropzone (change)="onSelect($event)" accept="image/png, image/jpeg, image/jpg, image/gif"
                          [maxFileSize]="3145728" [multiple]="false" class="pp-dropzone">
              <ngx-dropzone-label>
                <mat-icon>upload</mat-icon>
              </ngx-dropzone-label>
              <ngx-dropzone-image-preview *ngIf="profilePictureFile"
                                          (removed)="onRemoveProfilePicture(profilePictureFile)"
                                          [file]="profilePictureFile"
                                          [removable]="true" ngProjectAs="ngx-dropzone-preview">
              </ngx-dropzone-image-preview>
              <img *ngIf="!profilePictureFile" alt="placeholder profile" src="assets/profile-picture-placeholder.jpg">
            </ngx-dropzone>
          </div>
          <div class="flex justify-center mb-5 font-semibold text-primary mt-3 text-sm">Upload Profile Picture</div>

          <!--        <div class="flex justify-center mb-3 font-semibold text-primary mt-3 text-sm">Remove Photo</div>-->

          <mat-form-field appearance="outline">
            <mat-label>What Best Describes You?</mat-label>
            <mat-select formControlName="jobCategory">
              <mat-option *ngFor="let workerType of workerTypes" [value]="workerType">{{ workerType }}</mat-option>
            </mat-select>
            <mat-error *ngIf="profileInfo.get('jobCategory').hasError('required')">
              Please select a job category
            </mat-error>
          </mat-form-field>

          <!--        <mat-form-field appearance="outline">-->
          <!--          <mat-label>Visa Type</mat-label>-->
          <!--          <mat-select formControlName="nationality">-->
          <!--            <mat-option  *ngFor="let visa of visaTypes" [value]="visa">{{visa}}</mat-option>-->
          <!--          </mat-select>-->
          <!--          <mat-error>-->
          <!--            Please select a country-->
          <!--          </mat-error>-->
          <!--        </mat-form-field>-->

          <mat-form-field appearance="outline">
            <mat-label>Where Are You From?</mat-label>
            <mat-select formControlName="nationality">
              <mat-option *ngFor="let country of countries" [value]="country.name">{{ country.name }}</mat-option>
            </mat-select>
            <mat-error>
              Please select a country
            </mat-error>
          </mat-form-field>
          <mat-form-field appearance="outline">
            <mat-label>Where Are You Looking?</mat-label>
            <mat-select (selectionChange)="stateChanges($event)" multiple formControlName="workLocation">
              <mat-option value="Anywhere In Australia">Anywhere In Australia</mat-option>
              <mat-option [ngStyle]="isAnywhereSelected ? {'opacity':'0.4'} : ''" *ngFor="let state of states"
                          [value]="state">{{ state }}
              </mat-option>
            </mat-select>
            <mat-error>
              Please select a country
            </mat-error>
          </mat-form-field>

          <mat-form-field class="mb-2" appearance="outline" subscriptSizing="dynamic">
            <mat-label>Available To Work?</mat-label>
            <input [matDatepicker]="picker" formControlName="workDate" matInput>
            <mat-hint>DD/MM/YYYY</mat-hint>
            <mat-datepicker-toggle [for]="picker" matIconSuffix></mat-datepicker-toggle>
            <mat-datepicker #picker></mat-datepicker>
            <mat-error>
              Please tell employers when you are available to work
            </mat-error>
          </mat-form-field>

          <mat-form-field class="mb-2" appearance="outline" subscriptSizing="dynamic">
            <mat-label>Contact Number</mat-label>
            <input formControlName="contactNumber" matInput>
            <mat-hint>Shared with employers only for contact purposes</mat-hint>
          </mat-form-field>

          <mat-form-field appearance="outline">
            <mat-label>LinkedIn Profile</mat-label>
            <input formControlName="linkedinProfile" matInput>
          </mat-form-field>

          <mat-form-field appearance="outline">
            <mat-label>Website/Portfolio</mat-label>
            <input formControlName="websiteProfile" matInput>
          </mat-form-field>


          <button class="lightBtn text-xl !p-6 mt-5 self-end w-full sm:w-1/3" mat-flat-button matStepperNext>Continue
          </button>
        </form>
      </mat-step>
      <mat-step [stepControl]="employmentDetails">
        <h2 class="text-center mb-3">Portfolio Documents</h2>
        <p class="text-center">Stand out from the crowd by showing off your skills! </p>
        <form [formGroup]='employmentDetails' class='flex flex-col'>
          <ng-template matStepLabel>Portfolio Builder</ng-template>
          <h3>Upload resume</h3>
          <div class="fileUploadContainer mb-3 mt-2">
            <ngx-dropzone (change)="onSelectResume($event)" [maxFileSize]="3145728" [multiple]="false"
                          accept="application/pdf, application/msword, application/vnd.openxmlformats-officedocument.wordprocessingml.document"
                          class="resume-dropzone">
              <ngx-dropzone-label>Upload Resume</ngx-dropzone-label>
              <div *ngIf="resumeFile" class="success-overlay">
                <mat-icon>check_circle</mat-icon>
                <div>Resume uploaded successfully</div>
                <button mat-flat-button (click)="onRemoveResume(resumeFileUploaded)">Remove</button>
              </div>
            </ngx-dropzone>
          </div>

          <!--        <mat-form-field-->
          <!--          appearance='outline'-->
          <!--          class='form-field mt-5'-->
          <!--        >-->
          <h3 [ngStyle]="{'color': coverLetterEmpty ? 'red' : 'inherit'}">A Little About Yourself *</h3>
          <p>This is a <strong>cover letter</strong> for your profile - generic for all employers. You can edit this on
            the application forms to make it unique to the employer.</p>
          <div class="mt-2">
            <editor [init]="tinymceConfig" apiKey="52gvcmwqj90cb0ungtd1bw4anx2a37qoixz8qjnd7v8b3atl"
                    formControlName="coverLetter"></editor>
            <mat-error *ngIf="coverLetterEmpty">
              A cover letter is required for your profile
            </mat-error>
          </div>


          <h3 class="mt-4">Your Work Experience </h3>
          <p>This work experience is for your profile - so write about all experience that could be relevant. When
            applying to individual jobs you can change this to be more specific.</p>
          <div class="mt-2">
            <editor [init]="tinymceConfig2" apiKey="52gvcmwqj90cb0ungtd1bw4anx2a37qoixz8qjnd7v8b3atl"
                    formControlName="workExperience"></editor>
          </div>

          <!--          -->
          <!--          <textarea #coverLetter [maxLength]="1000" formControlName='coverLetter' matInput-->
          <!--                    style="height: 250px"> </textarea>-->
          <!--          <mat-hint align="end">{{ coverLetter.value ? coverLetter.value.length : 0 }} / 250</mat-hint>-->
          <!--        </mat-form-field>-->


          <div class="flex flex-row justify-between flex-wrap">
            <button class="lightBtn text-xl !p-6 mt-5 self-end w-full sm:w-1/3" mat-flat-button matStepperPrevious>
              Previous
            </button>
            <!--          <button [disabled]="!profileInfo.valid || !employmentDetails.valid"-->
            <!--                  class="lightBtn text-xl !p-6 mt-5 self-end w-full sm:w-1/3" mat-stroked-button>Save & Finish-->
            <!--          </button>-->

            <button
              class="lightBtn text-xl !p-6 mt-5 self-end w-full sm:w-1/3" (click)="finalStepCalled()"
              mat-stroked-button matStepperNext>Continue
            </button>
          </div>


        </form>
      </mat-step>

      <mat-step [stepControl]="advertiseYourself">
        <ng-template matStepLabel>Complete Profile</ng-template>
        <h2 class="text-center mb-3"><span class="underline-dec">Get Hired</span> Without Applying To Jobs</h2>
        <p class="text-center">Feature Your Backpacker Profile On The <a class="underline-dec-blue font-semibold text-blueTheme" href="/search-backpackers" target="_blank"> Search Backpackers</a> Page & <strong>Let Top Employers Hire You!</strong></p>
        <p class="text-center">Take Control & Join The Revolutionary Way To Get Hired!</p>

        <div class="pricingModels">
          <mat-card class="priceCardContainer hvr-float">
            <h2>Standard Account</h2>
            <h3 class="paymentPrice my-4">$0 AUD</h3>
            <p>Find and apply to jobs manually. Your profile will be hidden from the listings, and you cannot share your
              public profile.</p>
            <button (click)="selectTier('standard')"
                    [ngClass]="{'selected': accountTier === 'standard', 'inverseManageBtn': accountTier === 'standard'}"
                    class="manageBtn w-full !text-base mb-2">
              {{ buttonTexts['standard'] }}
            </button>
            <mat-list>
              <mat-list-item class="icon-list">
                <mat-icon matListItemIcon>check</mat-icon>
                <div matListItemTitle>Our Standard Account Type</div>
              </mat-list-item>

              <!--              <mat-list-item class="icon-list">-->
              <!--                <mat-icon matListItemIcon>check</mat-icon>-->
              <!--                <div matListItemTitle>Apply To 3 Jobs Per Day</div>-->
              <!--              </mat-list-item>-->

              <mat-list-item class="icon-list">
                <mat-icon matListItemIcon style="color: red">close</mat-icon>
                <div matListItemTitle>Hidden Profile (Not Listed)</div>
              </mat-list-item>

              <mat-list-item class="icon-list">
                <mat-icon matListItemIcon style="color: red">close</mat-icon>
                <div matListItemTitle>No Access To Profile Share</div>
              </mat-list-item>
            </mat-list>
          </mat-card>

          <mat-card
            class="priceCardContainer recommendedCard hvr-float">
            <div class="recommendedBanner">
              Recommended Choice
            </div>
            <h2>Certified Backpacker</h2>
            <h3 class="paymentPrice mt-4 mb-2">$6.99 AUD <span class="opacity-40 text-xs">Monthly</span></h3>
            <div class="smallHandwriting">The Price of a Coffee!</div>
            <p><strong>Instantly advertise</strong> your backpacker profile to all Australian employers. <strong>Allow
              Employers To Find You!</strong></p>
            <button (click)="selectTier('certified')"
                    [ngClass]="{'selected': accountTier === 'certified', 'inverseManageBtn': accountTier === 'certified'}"
                    class="manageBtn w-full !text-base">
              {{ buttonTexts['certified'] }}
            </button>
            <mat-list>
              <mat-list-item class="icon-list">
                <mat-icon matListItemIcon>check</mat-icon>
                <div matListItemTitle>Most Popular Choice</div>
              </mat-list-item>

              <mat-list-item class="icon-list">
                <mat-icon matListItemIcon>check</mat-icon>
                <div matListItemTitle>Pinned To Top Of Applications
                  <mat-icon class="tooltip-icon"
                            matTooltipClass="tooltip-md"
                            matTooltip="Your profile will appear above the other backpackers on the free plan, giving you more of a chance to stand out."
                            matTooltipPosition="below">info
                  </mat-icon>
                </div>
              </mat-list-item>

              <mat-list-item class="icon-list">
                <mat-icon matListItemIcon>check</mat-icon>
                <div matListItemTitle>Unlimited Job Applications</div>
              </mat-list-item>

              <mat-list-item class="icon-list">
                <mat-icon matListItemIcon>check</mat-icon>
                <div matListItemTitle>New Job Notifications
                  <mat-icon class="tooltip-icon"
                            matTooltipClass="tooltip-md"
                            matTooltip="Receive email notifications about newly listed jobs, increasing your chances of getting to the top of the list and finding a job quickly."
                            matTooltipPosition="below">info
                  </mat-icon>
                </div>
              </mat-list-item>

              <mat-list-item class="icon-list">
                <mat-icon matListItemIcon>check</mat-icon>
                <div matListItemTitle>Profile Featured To Employers
                  <mat-icon class="tooltip-icon"
                            matTooltipClass="tooltip-md"
                            matTooltip="Your profile will be listed on our backpacker search page, allowing employers to find and directly contact you without even applying to jobs!"
                            matTooltipPosition="below">info
                  </mat-icon>
                </div>
              </mat-list-item>

              <mat-list-item class="icon-list">
                <mat-icon matListItemIcon>check</mat-icon>
                <div matListItemTitle>Social Media Profile Share Tool
                  <mat-icon class="tooltip-icon"
                            matTooltipClass="tooltip-md"
                            matTooltip="Create an engaging link to your backpacker profile that is perfect for sharing on social media platforms and messaging apps."
                            matTooltipPosition="below">info
                  </mat-icon>
                </div>
              </mat-list-item>

              <mat-list-item class="icon-list">
                <mat-icon matListItemIcon>check</mat-icon>
                <div matListItemTitle class="!font-semibold">6x More Likely To Find Work</div>
              </mat-list-item>
            </mat-list>

          </mat-card>

          <mat-card class="priceCardContainer hvr-float">
            <h2>Backpacker Pro</h2>
            <h3 class="paymentPrice mt-4 mb-2">$9.99 AUD <span class="opacity-40 text-xs">Monthly</span></h3>
            <div class="smallHandwriting">The Price of a Slightly Larger Coffee!</div>
            <p>All benefits of the others, but get your backpacker profile pinned to the front page for as long as it
              takes you to find a job!</p>
            <button (click)="selectTier('pro')"
                    [ngClass]="{'selected': accountTier === 'pro', 'inverseManageBtn': accountTier === 'pro'}"
                    class="manageBtn w-full !text-base">
              {{ buttonTexts['pro'] }}
            </button>
            <mat-list>
              <mat-list-item class="icon-list">
                <mat-icon matListItemIcon>check</mat-icon>
                <div matListItemTitle>Pinned To Top Of Profile Listings
                  <mat-icon class="tooltip-icon"
                            matTooltipClass="tooltip-md"
                            matTooltip="Perfect when you need a job asap. Your profile will remain pinned to the top of the listings whilst your membership exists."
                            matTooltipPosition="below">info
                  </mat-icon>
                </div>
              </mat-list-item>

              <mat-list-item class="icon-list">
                <mat-icon matListItemIcon>check</mat-icon>
                <div matListItemTitle>Pinned To Top Of Applications
                  <mat-icon class="tooltip-icon"
                            matTooltipClass="tooltip-md"
                            matTooltip="Your profile will appear above the other backpackers on the free plan, giving you more of a chance to stand out."
                            matTooltipPosition="below">info
                  </mat-icon>
                </div>
              </mat-list-item>

              <mat-list-item class="icon-list">
                <mat-icon matListItemIcon>check</mat-icon>
                <div matListItemTitle>'Top Hire' Ribbon Attached To Listing</div>
              </mat-list-item>

              <mat-list-item class="icon-list">
                <mat-icon matListItemIcon>check</mat-icon>
                <div matListItemTitle>Unlimited Job Applications</div>
              </mat-list-item>

              <mat-list-item class="icon-list">
                <mat-icon matListItemIcon>check</mat-icon>
                <div matListItemTitle>New Job Notifications
                  <mat-icon class="tooltip-icon"
                            matTooltipClass="tooltip-md"
                            matTooltip="Receive email notifications about newly listed jobs, increasing your chances of getting to the top of the list and finding a job quickly."
                            matTooltipPosition="below">info
                  </mat-icon>
                </div>
              </mat-list-item>

              <mat-list-item class="icon-list">
                <mat-icon matListItemIcon>check</mat-icon>
                <div matListItemTitle>Profile Featured To Employers
                  <mat-icon class="tooltip-icon"
                            matTooltipClass="tooltip-md"
                            matTooltip="Your profile will be listed on our backpacker search page, allowing employers to find and directly contact you without even applying to jobs!"
                            matTooltipPosition="below">info
                  </mat-icon>
                </div>
              </mat-list-item>

              <mat-list-item class="icon-list">
                <mat-icon matListItemIcon>check</mat-icon>
                <div matListItemTitle>Social Media Profile Share Tool
                  <mat-icon class="tooltip-icon"
                            matTooltipClass="tooltip-md"
                            matTooltip="Create an engaging link to your backpacker profile that is perfect for sharing on social media platforms and messaging apps."
                            matTooltipPosition="below">info
                  </mat-icon>
                </div>
              </mat-list-item>

              <mat-list-item class="icon-list">
                <mat-icon matListItemIcon>check</mat-icon>
                <div matListItemTitle class="!font-semibold">10x More Likely To Find Work</div>
              </mat-list-item>

            </mat-list>
          </mat-card>
        </div>


        <app-public-profile-work-fields *ngIf="collectedData"
                                        (jobFieldsChange)="workFieldSelectionChange($event)"
                                        [joinTodayLink]=false
                                        [accountTier]="accountTier"
                                        [fullName]="sharedService.username"
                                        [workLocations]="workLocationString"
                                        [hasResume]="hasResume"
                                        [hasWorkExperience]="hasExperience"
                                        [unuploadedProfilePicture]="profilePictureFile"
                                        [fromCreateProfile]=true>
        </app-public-profile-work-fields>

        <div *ngIf="errorMsg" class="errorMsg">
          <mat-icon>error_outline</mat-icon>
          <p>{{ this.errorMsg }}</p>
        </div>


        <div class="flex flex-row justify-between flex-wrap">
          <button class="lightBtn text-xl !p-6 mt-5 self-end w-full sm:w-1/3" mat-flat-button matStepperPrevious>
            Previous
          </button>

          <button
            (click)="createProfile()"
            class="primaryBtn text-xl !p-6 mt-5 self-end w-full sm:w-1/3" mat-stroked-button matStepperNext>Complete
            Profile
          </button>
        </div>

      </mat-step>

    </mat-stepper>

    <div class="flex mt-4 justify-end" style="width:95%">
      <a (click)="skipCreateProfile()" class="text-sm font-medium underline cursor-pointer text-primary mb-3">Skip This
        For
        Now...</a>
    </div>
  </mat-card>
</section>
