import {Component, OnInit} from '@angular/core';
import {BlogService} from "../../../services/blog.service";
import {BreadcrumbService} from "xng-breadcrumb";
import {MetaService} from "../../../services/meta.service";
import {Router} from "@angular/router";

@Component({
  selector: 'app-blog',
  templateUrl: './blog.component.html',
  styleUrls: ['./blog.component.scss']
})
export class BlogComponent implements OnInit {
  posts: any[] = [];
  public loading = true;

  constructor(private router: Router, private meta: MetaService, private blog: BlogService, private breadcrumbService: BreadcrumbService,
  ) {
    this.meta.setupPageTitle("Backpacker Information & Blog | Backpacker Jobs Now - Australia\'s Official Backpacker Job Board ");
    this.meta.setupMetaTags(
      "Backpacker Information & Blog | Backpacker Jobs Now - Australia\'s Official Backpacker Job Board ",
      "Explore the Backpacker Jobs Now blog for essential tips, guides, and advice on finding and securing the best job opportunities for backpackers in Australia. Learn your rights, discover high-paying roles, and make your working holiday a success!",
      'blog, backpacker info, info, working holiday visa, backpacker jobs info, guides, backpacker visa info, visa help, farm work, backpacker jobs now',
      this.router.url
    );
  }

  ngOnInit() {
    this.blog.getBlogPosts().then(data => {
      this.posts = data;

      this.loading = false;
    });
  }


}
