<div class="flex flex-row justify-end m-2">
  <button (click)="update()" mat-icon-button>
    <mat-icon class="scale-150">close</mat-icon>
  </button>
</div>
<section class="container-space">
  <form [formGroup]='basicInfo' class='flex flex-col'>
    <h2 class="mb-3">Edit Basic Information</h2>
    <p class="mb-6">This information will be shared when you apply for jobs, as well as shown on your public profile (if
      you are a member)</p>

    <mat-form-field appearance="outline">
      <mat-label>Full Name</mat-label>
      <input formControlName="full_name" matInput>
    </mat-form-field>

    <mat-form-field appearance="outline">
      <mat-label>Email Address</mat-label>
      <input formControlName="email" matInput>
    </mat-form-field>

    <mat-form-field appearance="outline">
      <mat-label>Contact Number</mat-label>
      <input formControlName="contact_number" matInput>
    </mat-form-field>


    <mat-form-field appearance="outline">
      <mat-label>Where Are You From?</mat-label>
      <mat-select formControlName="nationality">
        <mat-option *ngFor="let country of countries" [value]="country.name">{{country.name}}</mat-option>
      </mat-select>
    </mat-form-field>


    <mat-checkbox [checked]="contact_number_hidden"
                  (change)="setPrivateField('contactNumberHidden', $event.checked ? 1 : 0)" class="mt-1 mb-3"> Hide
      Number From Public Profile <span style="font-size: 11px">(Only Employers Accounts Can Ever See This)</span>
    </mat-checkbox>
    <mat-form-field appearance="outline">
      <mat-label>What Best Describes You?</mat-label>
      <mat-select formControlName="job_category">
        <mat-option *ngFor="let workerType of workerTypes" [value]="workerType">{{workerType}}</mat-option>
      </mat-select>
    </mat-form-field>

    <mat-form-field appearance="outline">
      <mat-label>Where Are You Looking For Work?</mat-label>
      <mat-select (selectionChange)="stateChanges($event)" multiple formControlName="work_location">
        <mat-option value="Anywhere In Australia">Anywhere In Australia</mat-option>
        <mat-option [ngStyle]="isAnywhereSelected ? {'opacity':'0.4'} : ''" *ngFor="let state of states"
                    [value]="state">{{ state }}
        </mat-option>
      </mat-select>
      <mat-error>
        Please select a country
      </mat-error>
    </mat-form-field>

    <button (click)="update()" color="primary" mat-stroked-button>Update Profile</button>

  </form>
</section>
