import {Component, OnInit} from '@angular/core';
import {UntypedFormBuilder, UntypedFormGroup, Validators} from "@angular/forms";
import {Accounts} from "../../../services/accounts.service";
import {ActivatedRoute, Router} from "@angular/router";
import {SharedService} from "../../../services/shared.service";

@Component({
  selector: 'app-reset-password',
  templateUrl: './reset-password.component.html',
  styleUrls: ['./reset-password.component.scss', '../../login-page/login-page.component.scss']
})
export class ResetPasswordComponent implements OnInit {
  resetPasswordForm: UntypedFormGroup;
  resetToken: string;
  errorMsg: string;

  constructor(private formBuilder: UntypedFormBuilder,
              private apiService: Accounts,
              private router: Router,
              private route: ActivatedRoute) {
  }


  ngOnInit(): void {
    this.resetToken = this.route.snapshot.queryParamMap.get('token');

    if (this.resetToken) {
      this.apiService.verifyToken(this.resetToken).subscribe(
        (response) => {
          this.resetPasswordForm = this.formBuilder.group({
            password: ['', [Validators.required, Validators.minLength(5)]],
            confirm_password: ['', Validators.required],
          });
        },
        (error) => {
          // this.router.navigate(['/']);
        });
    } else {
      this.router.navigate(['/']);
    }


  }

  submitPasswordReset() {
    const formData = {
      password: this.resetPasswordForm.value.password,
      confirm_password: this.resetPasswordForm.value.confirm_password,
      token: this.resetToken
    };

    this.apiService.passwordReset(formData).subscribe(
      (response) => {
        this.router.navigate(['/login']);
      },
      (error) => {
        if (error.error.returnMsg) {
          this.errorMsg = error.error.returnMsg;
          return;
        } else {
          this.errorMsg = "There was a problem resetting your password. Please try again."
        }
      });
  }
}
