import {Component, Input} from '@angular/core';
import {navItems} from "../navigation-items/navigation-items";
import {loginNavItems} from "../navigation-items/navigation-items";
import {slideInOut} from "../../../animation/slide-in-out.animation";
import {SharedService} from "../../../services/shared.service";
import {Router} from "@angular/router";
import {Accounts} from "../../../services/accounts.service";

@Component({
  selector: 'app-mobile-side-nav',
  templateUrl: './mobile-side-nav.component.html',
  styleUrls: ['./mobile-side-nav.component.scss'],
  animations: [slideInOut]
})
export class MobileSideNavComponent {
  @Input() mobNavToggle: boolean;
  public navItems = navItems;
  public loginNavItems = loginNavItems;

  constructor(public sharedService: SharedService, public router: Router, public accounts: Accounts) {

  }

  logout() {
    this.accounts.logout().subscribe((result: any) => {
    });
  }
}
