<section class="container-space mt-8">
  <section class="banner">
    <div class="flex justify-center text-center flex-col">
      <h1 class="uppercase">Join The <span class="underline-dec">New Way</span> To Employ Backpackers</h1>

      <h2 class="mt-12 text-lg text-center">Streamline Your Hiring Process: Post Jobs, filter by experienced backpackers, view resumes and cover letters - <span class="break-words whitespace-nowrap underline-dec">All For Free</span></h2>

<!--      <h3 class="mt-12">Help us make Australia <span class="underline-dec">safe for everyone</span>. Completely for-->
<!--        free.-->
<!--      </h3>-->
    </div>
    <div class="w-full flex justify-center">
      <button class="mt-10 !rounded-xl !py-6 w-full max-w-xl text-lg my-auto" color="primary"
              mat-flat-button routerLink="/employer-signup">Post Job
      </button>
    </div>
  </section>

  <section class="why-employers-choose-us">
    <h3 class="text-2xl font-bold">Why Employers Choose Us</h3>
    <p>Post a job with BackpackerJobsNow and reach thousands of experienced and hardworking backpackers. Our platform is
      designed with you in mind, making the process of finding the perfect candidate as simple and straightforward as it
      should be.
    </p>

    <h3 class="mt-8 mb-3 text-lg font-semibold">Effortless Job Posting</h3>
    <ul class="ml-6">
      <li>
        Say goodbye to social media groups and the chaos of posting jobs on Facebook. With BackpackerJobsNow, you can post a job
        in under a minute, directly reaching thousands of experienced and hardworking backpackers.
      </li>
    </ul>
    <h3 class="mt-8 mb-3 text-lg font-semibold">Find the Perfect Fit</h3>
    <ul class="ml-6">
      <li>
        With BackpackerJobsNow, reach thousands of backpackers instantly and streamline your hiring process. View, filter, and
        choose from candidate profiles, complete with resumes and cover letters, all in one place. Finding the right
        person for the job has never been so quick, easy, and hassle-free.
      </li>
    </ul>
    <h3 class="mt-8 mb-3 text-lg font-semibold">A Platform Built For You</h3>
    <ul class="ml-6">
      <li>
        We've collaborated closely with farmers and regional employers to create an interface that's not just
        user-friendly, but also tailored to your specific needs. As a dedicated platform for employers, BackpackerJobsNow removes
        the complexity from hiring, putting everything you need right at your fingertips.
      </li>
    </ul>


  </section>
</section>



