import {Component, OnInit} from '@angular/core';
import {UntypedFormBuilder, UntypedFormGroup, Validators} from "@angular/forms";
import {Accounts} from "../../services/accounts.service";
import {Router} from "@angular/router";
import {SharedService} from "../../services/shared.service";
import {MetaService} from "../../services/meta.service";

@Component({
  selector: 'app-login-page',
  templateUrl: './login-page.component.html',
  styleUrls: ['./login-page.component.scss']
})
export class LoginPageComponent implements OnInit{
  signinForm: UntypedFormGroup;
  errorMsg: string;
  public metaTitle: string = 'Welcome Back | Backpacker Jobs Now - Australia\'s Official Backpacker Job Board';
  public metaDescription: string = 'Sign in to Backpacker Jobs Now to find, apply, and save jobs or post, hire, and manage listings. Whether you\'re a backpacker seeking farm work or an employer looking to hire, we\'ve got you covered. Get your Australian working holiday visa today.';
  public metaKeywords: string = 'sign in,backpacker jobs,apply jobs,save jobs,post jobs,hire,manage listings,farm work,employer,Australian working holiday visa';


  constructor(
    private router: Router,
    private meta: MetaService,
    private formBuilder: UntypedFormBuilder,
    private apiService: Accounts,
    private route: Router,
    private sharedService: SharedService) {
  }

  ngOnInit(): void {
    this.meta.setupPageTitle(this.metaTitle);
    this.meta.setupMetaTags(
      this.metaTitle,
      this.metaDescription,
      this.metaKeywords,

      this.router.url
    );
    this.signinForm = this.formBuilder.group({
      email: ['', Validators.email],
      password: ['', Validators.required],
    });
  }

  submitSignin() {
    const formData = {email: this.signinForm.value.email, password: this.signinForm.value.password}
    this.apiService.login(formData).subscribe(
      (response) => {
        this.setWebsiteProfile(response);
        this.route.navigate(['/']);
      },
      (error) => {
        if (error.error.errorType == 'email') {
          this.signinForm.controls['email'].setErrors({'incorrect': true});
        } else {
          this.signinForm.controls['password'].setErrors({'incorrect': true});
        }
        this.errorMsg = error.error.returnMsg;
      }
    );
  }

  private setWebsiteProfile(response) {
    // if returned account is employee, set employee login to true
    if (response.account_type == 'employee') {
      localStorage.setItem('account_type', 'employee')
      this.sharedService.employeeLogin = true;
      this.sharedService.employerLogin = false;

      // else if returned account is employee, set employee login to true
    } else if (response.account_type == 'employer') {
      localStorage.setItem('account_type', 'employer')
      this.sharedService.employeeLogin = false;
      this.sharedService.employerLogin = true;
    }

    // save username into local storage for use in navbar
    this.sharedService.username = response.username;
    localStorage.setItem('username', response.username);

    // save isVerified into local storage for use in the app
    this.sharedService.is_verified = response.is_verified;
    localStorage.setItem('is_verified', response.is_verified)


    // save email into local storage for use in the verification messages etc.
    this.sharedService.account_email = response.account_email;
    localStorage.setItem('account_email', response.account_email);

  }
}
