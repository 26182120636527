import { Injectable } from '@angular/core';
import {Observable} from "rxjs";
import {HttpClient} from "@angular/common/http";
import {SharedService} from "./shared.service";
import {Router} from "@angular/router";

@Injectable({
  providedIn: 'root'
})
export class ReviewsService {

  constructor(private http: HttpClient, private settings: SharedService, private router: Router) {

  }
  submitReview(formData: any): Observable<any> {
    return this.http.post<any>(this.settings.apiURL + 'reviews/SubmitReview', formData, {withCredentials: true});
  }

  fetchReviews(): Observable<any> {
    return this.http.get<any>(this.settings.apiURL + 'reviews/FetchReviews', {withCredentials: true});
  }

}
