import { Pipe, PipeTransform } from '@angular/core';
import { DomSanitizer } from '@angular/platform-browser';

@Pipe({
  name: 'unicode',
})
export class Unicode implements PipeTransform {
  constructor(private sanitized: DomSanitizer) {}
  transform(value: string) {
	const tempElement = document.createElement('div');
	tempElement.innerHTML = value;
	return tempElement.innerText;
  }
}
