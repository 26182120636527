<section class="container-space mt-8 md:mt-20">
  <div class="flex">
    <div (click)="copyPublicProfileToClipboard()" class="hover:underline cursor-pointer flex items-center mb-3 mt-3 text-sm">
      <fa-icon class="mr-2" [icon]="faShare"></fa-icon>
      Share This Profile </div>
  </div>

  <div class="flex md:flex-row flex-col ">

    <mat-card class="flex flex-col p-8 w-full md:w-1/3 mb-4 md:mb-0">
      <div class="pp-container relative">
        <ngx-skeleton-loader [theme]="{height: '10rem', width: '10rem'}" *ngIf="isLoadingProfile; else profilePicture"
                             appearance="circle"></ngx-skeleton-loader>
        <ng-template #profilePicture>

          <img *ngIf="backpackerProfile?.profile_picture != ''" alt="profile picture" class="prof-img" src="{{backpackerProfile?.profile_picture}}">
          <img *ngIf="backpackerProfile?.profile_picture == ''" alt="profile picture"  class="prof-img" src="assets/profile-picture-placeholder.jpg">


        </ng-template>
        <h2 *ngIf="isMobile" class="text-center flex-grow mt-2">{{ backpackerProfile?.full_name }}</h2>

      </div>

      <button *ngIf="backpackerProfile?.resume_link" class="generalBtn lightBtn mt-6 w-full" (click)="navigateButtonBasedOnViewerType('resume')" mat-flat-button>
        View Resume
      </button>


      <button (click)="navigateButtonBasedOnViewerType('contact')"
              class="generalBtn mt-4 mb-4" color="primary" mat-raised-button>
        Contact Now
      </button>


      <div class="flex flex-row justify-between items-center">
        <h3 class="font-semibold text-2xl mb-3 mt-5">Basic Information</h3>
      </div>

      <div class="info-item">
        <h3 class="backpacker-info">Nationality:</h3>
        <h4>{{backpackerProfile?.nationality}}</h4>
      </div>

      <div class="info-item">
        <h3 class="backpacker-info">Looking For Work In:</h3>
        <h4>{{backpackerProfile?.work_location}}</h4>
      </div>

      <div class="info-item">
        <h3 class="backpacker-info">Worker Type:</h3>
        <h4>{{backpackerProfile?.job_category}}</h4>
      </div>

      <div class="flex flex-row justify-between items-center">
        <h3 class="font-semibold text-xl mb-3 mt-5">Additional Information</h3>
      </div>

      <div class="info-item">
        <h3 class="backpacker-info">Available From:</h3>
        <h4>{{backpackerProfile?.work_date | date: 'mediumDate'}}</h4>
      </div>

      <div *ngIf="backpackerProfile?.website" class="info-item">
        <h3 class="backpacker-info">Website/Profile Link:</h3>
        <a target="_blank">{{backpackerProfile?.website}}</a>
      </div>


      <div *ngIf="backpackerProfile?.linked_in" class="info-item">
        <h3 class="backpacker-info">LinkedIn Page</h3>
        <a target="_blank">{{backpackerProfile?.linked_in}}</a>
      </div>
    </mat-card>


    <!-- Backpacker Details Card (right) descriptions etc. -->
    <mat-card class="flex flex-col p-8 w-full md:w-2/3 md:ml-4">



      <h2 *ngIf="!isMobile" class="text-center">{{backpackerProfile?.full_name}}</h2>

      <ng-container *ngIf="backpackerProfile?.account_tier != 'standard'">
        <h3 class="mt-6">Looking For Work In...</h3>
        <div class="highlights w-full text-center">
        <span *ngFor="let workField of backpackerProfile?.work_fields" class="highlight">
          {{workField}}
        </span>
        </div>
      </ng-container>

      <div class="mt-6">
        <h3>A Little About Myself...</h3>
        <div *ngIf="!showMoreCoverLetter"
             [innerHTML]="shared.truncateString(backpackerProfile?.cover_letter, readMoreLength, readMoreLength)">

        </div>
        <div *ngIf="showMoreCoverLetter" [innerHTML]="backpackerProfile?.cover_letter"></div>
        <div *ngIf="backpackerProfile?.cover_letter.split(' ').length > readMoreLength"
             (click)="showMoreCoverLetter = !showMoreCoverLetter"
             class="cursor-pointer flex flex-row align-center font-semibold mt-2 opacity-60">
          {{ showMoreCoverLetter ? 'Show Less' : 'Show More' }}
          <mat-icon [ngClass]="{'spin-icon': showMoreCoverLetter}">expand_more</mat-icon>
        </div>
      </div>

      <div class="mt-6" *ngIf="backpackerProfile?.work_experience">
        <h3>Work Experience</h3>
        <div *ngIf="!showMoreWorkExperience"
             [innerHTML]="shared.truncateString(backpackerProfile?.work_experience, readMoreLength, readMoreLength)">

        </div>
        <div *ngIf="showMoreWorkExperience" [innerHTML]="backpackerProfile?.work_experience"></div>
        <div *ngIf="backpackerProfile?.work_experience.split(' ').length > readMoreLength"
             (click)="showMoreWorkExperience = !showMoreWorkExperience"
             class="cursor-pointer flex flex-row align-center font-semibold mt-2 opacity-60">
          {{ showMoreWorkExperience ? 'Show Less' : 'Show More' }}
          <mat-icon [ngClass]="{'spin-icon': showMoreWorkExperience}">expand_more</mat-icon>
        </div>
      </div>


      <!--  Self Advertise Information    -->
      <ng-container *ngIf="false">


        <h3 class="mt-4 mb-4">Key Information</h3>
        <div class="check-item">
          <mat-icon>check_box</mat-icon>
          <h4>Owns Car</h4>
        </div>
        <div class="check-item">
          <mat-icon>check_box</mat-icon>
          <h4>Drives License</h4>
        </div>
        <div class="check-item">
          <mat-icon>check_box</mat-icon>
          <h4>Blue Card</h4>
        </div>
        <div class="check-item">
          <mat-icon>check_box</mat-icon>
          <h4>White Card</h4>
        </div>
      </ng-container>
    </mat-card>
  </div>
</section>

