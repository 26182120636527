import {Component, OnInit} from '@angular/core';
import {JobsService} from "../../services/jobs.service";
import {BackpackerProfile} from "../../interfaces/backpacker-profile";
import {ActivatedRoute, Router} from '@angular/router';
import {SharedService} from "../../services/shared.service";
import {Candidate, CandidateStats} from "../../interfaces/candidate";
import {MetaService} from "../../services/meta.service";

@Component({
  selector: 'app-candidates',
  templateUrl: './candidates.component.html',
  styleUrls: ['./candidates.component.scss', '../latest-jobs/list-jobs.component.scss']
})
export class CandidatesComponent implements OnInit {
  public metaTitle: string = 'View Candidates | Backpacker Jobs Now - Australia\'s Official Backpacker Job Board';
  public metaDescription: string = 'View all job applicants, shortlist candidates, and view profiles. Simplify your hiring process and find the ideal backpacker quickly with Backpacker Jobs Now';
  public metaKeywords: string = 'view,candidates,job applicants,shortlist,profiles,hiring process,ideal,backpacker,quickly,Backpacker Jobs Now';


  public candidates: Candidate[];
  public candidateStats: CandidateStats;
  public jobTitle: string;
  public jobRef: string;
  candidatesGroupedByJobTitle: { [key: string]: any[] } = {};

  constructor(
      private meta: MetaService,
      private router: Router,
      private jobs: JobsService,
      private route: ActivatedRoute,
      private sharedService: SharedService) {
  }

  ngOnInit() {
    this.meta.setupPageTitle(this.metaTitle);
    this.meta.setupMetaTags(
        this.metaTitle,
        this.metaDescription,
        this.metaKeywords,

        this.router.url
    );

    this.jobRef = this.route.snapshot.queryParamMap.get('ref');
    this.getCandidates(this.jobRef);
  }

  getCandidates(jobRef?: string) {
    this.jobs.getCandidates(jobRef).subscribe(
      (response) => {
        this.candidates = response.data;
        this.candidateStats = response.candidateStats;
        this.groupCandidatesByJobTitle();
        this.jobTitle = response.data[0].job_title;
      });
  }

  loadCandidateProfile(ref, candidateId) {
    const route = `candidate-view`;
    const queryParams = {ref: ref, id: candidateId};

    // Navigate to the desired route with query parameters
    this.router.navigate([route], {queryParams: queryParams});

  }

  groupCandidatesByJobTitle() {
    this.candidatesGroupedByJobTitle = this.candidates.reduce((groups, candidate) => {
      const jobTitle = candidate.job_title;
      if (!groups[jobTitle]) {
        groups[jobTitle] = [];
      }
      groups[jobTitle].push(candidate);
      return groups;
    }, {});
  }

  objectKeys(obj) {
    return Object.keys(obj);
  }

  filterCandidates(tabType: string) {
    const filtered = {};
    for (const jobTitle of this.objectKeys(this.candidatesGroupedByJobTitle)) {
      const candidates = this.candidatesGroupedByJobTitle[jobTitle].filter(candidate => {
        if (tabType === 'pending') return !candidate.is_shortlisted && !candidate.is_approved && !candidate.is_rejected;
        if (tabType === 'shortlisted') return candidate.is_shortlisted;
        if (tabType === 'approved') return candidate.is_approved;
        if (tabType === 'contacted') return candidate.is_contacted && !candidate.is_approved;
        if (tabType === 'archived') return candidate.archived;
        return false;
      });
      if (candidates.length > 0) {
        filtered[jobTitle] = candidates;
      }
    }
    return filtered;
  }

  onRefreshCandidates() {
    this.getCandidates(this.jobRef);
  }


}
