import { trigger, state, style, animate, transition } from '@angular/animations';

export const slideInOut = trigger('slideInOut', [
  state('false', style({
    transform: 'translateX(100%)'
  })),
  state('true', style({
    transform: 'translateX(0%)'
  })),
  transition('true <=> false', animate('300ms ease-in-out'))
]);
