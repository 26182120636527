import {Component, Inject, OnInit, PLATFORM_ID, Renderer2} from '@angular/core';
import {trigger, state, style, animate, transition} from '@angular/animations';
import {Accounts} from "../../services/accounts.service";
import {WHAT_WE_DO} from "./homepage-copy.constants";
import {ActivatedRoute} from "@angular/router";
import {SharedService} from "../../services/shared.service";
import {isPlatformBrowser, isPlatformServer} from "@angular/common";
import {ReviewsService} from "../../services/reviews.service";
import {CustomerReview} from "../../interfaces/reviews";

@Component({
  selector: 'app-homepage',
  templateUrl: './homepage.component.html',
  styleUrls: ['./homepage.component.scss'],
  animations: [
    trigger('scroll', [
      state('first', style({transform: 'translateY(0)', opacity: 1})),
      state('second', style({transform: 'translateY(-100%)', opacity: 0})),
      state('third', style({transform: 'translateY(-200%)', opacity: 0})),
      transition('* => *', animate('0.5s ease-in-out')),
      transition('first => second', animate('0.5s ease-in-out')),
      transition('second => third', animate('0.5s ease-in-out')),
      transition('third => first', animate('0.5s ease-in-out'))
    ])

  ]
})
export class HomepageComponent implements OnInit {

  headerAnimationText: string[] = ['88 days', 'Farm Work', 'Residency'];
  currentHeadingIndex = 0;
  currentHeading: string = this.headerAnimationText[this.currentHeadingIndex];
  animateState = 'shown';
  reviewCount = 0;
  customerReviews: CustomerReview[] = [];

  constructor(
    @Inject(PLATFORM_ID) private platformId: any,
    private sharedService: SharedService,
    private Accounts: Accounts,
    private route: ActivatedRoute,
    private reviews: ReviewsService,
    private renderer: Renderer2,
  ) {
  }

  ngOnInit(): void {

    this.checkForVerificationToken();

    if (isPlatformBrowser(this.platformId)) {

      setInterval(() => {
        switch (this.currentHeadingIndex) {
          case 0:
            this.currentHeadingIndex = 1;
            this.animateState = 'first => second';
            break;
          case 1:
            this.currentHeadingIndex = 2;
            this.animateState = 'second => third';
            break;
          case 2:
            this.currentHeadingIndex = 0;
            this.animateState = 'third => first';
            break;
          default:
            break;
        }
        this.currentHeading = this.headerAnimationText[this.currentHeadingIndex];
      }, 5000);
    }
    this.fetchReviews();
  }

  checkForVerificationToken() {
    this.route.queryParams.subscribe(params => {
      const verificationToken = params['verificationToken'];
      if (verificationToken) {
        this.Accounts.VerifyVerificationEmailToken(verificationToken).subscribe(
          (response) => {
            if (response.status === 'success') {
              this.sharedService.triggerSnackbar('Great News! Your email has been successfully verified.');
              localStorage.setItem('is_verified', 'true');
              this.sharedService.is_verified = true;
            } else {
              this.sharedService.triggerSnackbar('Oh No! There was a problem verifying your email... Please try resending.');
            }
          })
      }

    });
  }

  fetchReviews() {
    this.reviews.fetchReviews().subscribe((response: any) => {
      this.reviewCount = response.reviewCount;
      this.customerReviews = response.reviews;
      // this.addJsonLdSchema();

    });
  }

  addJsonLdSchema() {
    if (isPlatformBrowser(this.platformId)) {
      const script = this.renderer.createElement('script');
      script.type = 'application/ld+json';
      const jsonLd = {
        "@context": "https://schema.org",
        "@type": "Organization",
        "name": "Backpacker Jobs Now",
        "url": "https://backpackerjobsnow.com",
        "aggregateRating": {
          "@type": "AggregateRating",
          "ratingValue": 4.9,
          "reviewCount": this.reviewCount
        },
      };
      script.text = JSON.stringify(jsonLd);
      this.renderer.appendChild(document.head, script);
    }
  }

  protected readonly WHAT_WE_DO = WHAT_WE_DO;
}

