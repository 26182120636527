import {Component, OnInit} from '@angular/core';
import {UntypedFormBuilder, UntypedFormGroup, Validators} from "@angular/forms";
import {Accounts} from "../../../services/accounts.service";
import {SharedService} from "../../../services/shared.service";
import {Router} from "@angular/router";
import {EmployeeProfileService} from "../../profile-page/employee-profile/employee-profile.service";
import {DatePipe} from "@angular/common";

@Component({
  selector: 'app-profile-basic',
  templateUrl: './profile-additional.component.html',
  styleUrls: ['./profile-additional.component.scss']
})
export class ProfileAdditionalComponent implements OnInit{
  additionalInfo: UntypedFormGroup;

  constructor(private datePipe: DatePipe, private formBuilder: UntypedFormBuilder, private apiService: Accounts, private sharedService: SharedService, private router: Router, private profileService: EmployeeProfileService) { }

  ngOnInit() {
    this.additionalInfo = this.formBuilder.group({
      work_date: ['', Validators.required],
      website: [''],
      linked_in: [''],
    });

    this.getUserProfileData();
  }

  update() {

    const date = this.additionalInfo.value['work_date'];
    this.additionalInfo.value['work_date'] = this.datePipe.transform(date, 'yyyy-MM-dd'); // this will also give you '2001-12-26'

    this.apiService.updateAdditionalBackpackerProfile(this.additionalInfo.value).subscribe((res) => {
      this.router.navigate(['/profile']);
    });
  }

  getUserProfileData() {
    this.profileService.currentProfile.subscribe(profile => {
      if (profile) {
        this.additionalInfo.patchValue({
          work_date: profile.work_date,
          website: profile.website,
          linked_in: profile.linked_in,
        });
      }
    });
  }
}
