import {NgModule} from '@angular/core';
import {RouterModule, Routes} from '@angular/router';
import {HomepageComponent} from './components/homepage/homepage.component';
import {JobsMainComponent} from "./components/jobs-main/jobs-main.component";
import {EmployerLandingPageSignupComponent} from "./components/employer-signup/employer-landing-page-signup.component";
import {JobPostComponent} from "./components/job-post/job-post.component";
import {JobViewComponent} from "./components/job-view/job-view.component";
import {EmployeeSignupWindow} from "./components/employee-signup/signup-modal/employee-signup-window.component";
import {
  EmployeeCreateProfileSignup
} from "./components/employee-create-profile-signup/employee-create-profile-signup.component";
import {LoginPageComponent} from "./components/login-page/login-page.component";
import {EmployerSignupPageComponent} from "./components/employer-signup/signup-page/employer-signup-page.component";
import {EmployeeProfilePageComponent} from "./components/profile-page/employee-profile/employee-profile-page.component";
import {ProfileBasicComponent} from "./components/forms/profile-basic/profile-basic.component";
import {ProfileAdditionalComponent} from "./components/forms/profile-additional/profile-additional.component";
import {ProfileDocumentsComponent} from "./components/forms/profile-documents/profile-documents.component";
import {EmployerProfilePageComponent} from "./components/profile-page/employer-profile/employer-profile-page.component";
import {BlogComponent} from "./components/blog/blog-list/blog.component";
import {ArticleViewComponent} from "./components/blog/article-view/article-view.component";
import {CandidatesComponent} from "./components/candidates/candidates.component";
import {JobListingsComponent} from "./components/job-listings/job-listings.component";
import {CandidatesProfileComponent} from "./components/candidates/candidates-profile/candidates-profile.component";
import {ProfileEmployerInfoComponent} from "./components/forms/profile-employer-info/profile-employer-info.component";
import {JobApplicationsComponent} from "./components/job-applications/job-applications.component";
import {SavedJobsComponent} from "./components/saved-jobs/saved-jobs.component";
import {ForgotPasswordPageComponent} from "./components/forgot-password-page/forgot-password-page.component";
import {ResetPasswordComponent} from "./components/forgot-password-page/reset-password/reset-password.component";
import {BackpackerAuthGuard} from "./guards/backpacker-auth.guard";
import {EmployerAuthGuard} from "./guards/employer-auth.guard";
import {TermsConditionsComponent} from "./components/terms-conditions/terms-conditions.component";
import {VerifyEmailComponent} from "./components/verify-email/verify-email.component";
import {BackpackerSearchComponent} from "./components/search-backpackers/backpacker-search.component";
import {
  BackpackerProfileComponent
} from "./components/search-backpackers/backpacker-profile/backpacker-profile.component";
import * as path from "path";
import {
  UpgradeAccountBackpackerComponent
} from "./components/upgrade-account-backpacker/upgrade-account-backpacker.component";
import {CancelConfirmationComponent} from "./components/cancel-confirmation/cancel-confirmation.component";
import {UpdateConfirmationComponent} from "./components/update-confirmation/update-confirmation.component";

const routes: Routes = [
  {path: '', component: HomepageComponent, data: {breadcrumb: 'Home'}},

  {
    path: 'backpacker-information',
    data: {breadcrumb: 'Backpacker Information'},
    children: [
      {path: '', component: BlogComponent},
      {
        path: 'article/:articleSlug',
        component: ArticleViewComponent,
        data: {breadcrumb: {alias: 'Child1'}},
      },
    ],
  },

  {
    path: 'search-jobs',
    component: JobsMainComponent,
    children: [{path: '', component: JobsMainComponent, data: {breadcrumb: 'Search Jobs'}}],
  },
  {
    path: 'search-jobs/:term',
    component: JobsMainComponent,
    children: [{path: '', component: JobsMainComponent}],
  },
  {
    path: 'post-job',
    component: JobPostComponent,
    canActivate: [EmployerAuthGuard],
    data: {requiresVerification: true},
    children: [{path: '', component: JobPostComponent}],
  },
  {
    path: 'edit-listing/:reference/:urlSlug',
    component: JobPostComponent,
    canActivate: [EmployerAuthGuard],
    children: [{path: '', component: JobPostComponent}],
  },
  {
    path: 'job/:reference/:URLSlug',
    component: JobViewComponent,
    children: [{path: '', component: JobViewComponent}],
  },
  {
    path: 'login',
    component: LoginPageComponent,
    children: [{path: '', component: LoginPageComponent}],
  },
  {
    path: 'forgotten-password',
    component: ForgotPasswordPageComponent,
    children: [{path: '', component: ForgotPasswordPageComponent}],
  },
  {
    path: 'reset-password',
    component: ResetPasswordComponent,
    children: [{path: '', component: ResetPasswordComponent}],
  },
  {
    path: 'employer-join',
    component: EmployerLandingPageSignupComponent,
    children: [{path: '', component: EmployerLandingPageSignupComponent}],
  },
  {
    path: 'employer-signup',
    component: EmployerSignupPageComponent,
    children: [{path: '', component: EmployerSignupPageComponent}],
  },
  {
    path: 'employee-signup',
    component: EmployeeSignupWindow,
    children: [{path: '', component: EmployeeSignupWindow}],
  },
  {
    path: 'create-profile',
    component: EmployeeCreateProfileSignup,
    canActivate: [BackpackerAuthGuard],
    children: [{path: '', component: EmployeeCreateProfileSignup}],

  },
  {
    path: 'profile',
    component: EmployeeProfilePageComponent,
    canActivate: [BackpackerAuthGuard],
    children: [{path: '', component: EmployeeProfilePageComponent}],
  },
  {
    path: 'employer-profile',
    component: EmployerProfilePageComponent,
    canActivate: [EmployerAuthGuard],
    children: [{path: '', component: EmployerProfilePageComponent}],
  },
  {
    path: 'profile-editor',
    children: [
      {path: 'basic', component: ProfileBasicComponent},
      {path: 'additional', component: ProfileAdditionalComponent},
      {path: 'upload-documents', component: ProfileDocumentsComponent},
      {path: 'employerInfo', component: ProfileEmployerInfoComponent},
    ]
  },

  {
    path: 'candidates',
    component: CandidatesComponent,
    canActivate: [EmployerAuthGuard],
    children: [{path: '', component: CandidatesComponent}],
  },
  {
    path: 'candidate-view',
    component: CandidatesProfileComponent,
    canActivate: [EmployerAuthGuard],
    children: [{path: '', component: CandidatesProfileComponent}],
  },
  {
    path: 'job-listings',
    component: JobListingsComponent,
    canActivate: [EmployerAuthGuard],
    children: [{path: '', component: JobListingsComponent}],
  },

  {
    path: 'job-applications',
    component: JobApplicationsComponent,
    canActivate: [BackpackerAuthGuard],
    children: [{path: '', component: JobApplicationsComponent}],
  },

  {
    path: 'saved-jobs',
    component: SavedJobsComponent,
    canActivate: [BackpackerAuthGuard],
    children: [{path: '', component: SavedJobsComponent}],
  },

  {
    path: 'terms-and-conditions',
    component: TermsConditionsComponent,
    children: [{path: '', component: TermsConditionsComponent}],
  },

  {
    path: 'verify-email',
    component: VerifyEmailComponent,
    children: [{path: '', component: VerifyEmailComponent}],
  },

  {
    path: 'search-backpackers',
    component: BackpackerSearchComponent,
    children: [{path: '', component: BackpackerSearchComponent}],
  },
  {
    path: 'search-backpackers/:term',
    component: BackpackerSearchComponent,
    children: [{path: '', component: JobsMainComponent}],
  },
  {
    path: 'backpacker-profile/:backpackerID',
    component: BackpackerProfileComponent,
    children: [{path: '', component: BackpackerProfileComponent}],
  },

  {
    path: 'upgrade-account',
    component: UpgradeAccountBackpackerComponent,
    canActivate: [BackpackerAuthGuard],
    children: [{path: '', component: UpgradeAccountBackpackerComponent}],
  },

  {
    path: 'cancel-membership',
    canActivate: [BackpackerAuthGuard],
    component: CancelConfirmationComponent,
    children: [{path: '', component: CancelConfirmationComponent}],
  },

  {
    path: 'update-membership-checkout',
    canActivate: [BackpackerAuthGuard],
    component: UpdateConfirmationComponent,
    children: [{path: '', component: UpdateConfirmationComponent}],
  },

  {
    path : '**',
    component: HomepageComponent,
    data: {breadcrumb: 'Home'}
  }
];

@NgModule({
  imports: [
    RouterModule.forRoot(routes, {
      scrollPositionRestoration: 'enabled',
      // initialNavigation: 'enabled',
      anchorScrolling: 'enabled',
      initialNavigation: 'enabledBlocking'
    }),
  ],
  exports: [RouterModule],
})
export class AppRoutingModule {
}
