import {Injectable} from "@angular/core";

@Injectable()
export class Settings {
  jobCategories = [
    {
      name: "Agriculture & Farm Work",
      urlSlug: "agriculture-jobs",
      seoUrls: ["farm-jobs", 'agriculture-jobs', 'harvesting-jobs'],
      categoryImage: "assets/job_categories/agriculture-job-hero-image.jpg"
    },
    {
      name: "Au Pair & Childcare",
      urlSlug: "au-pair-jobs",
      seoUrls: ["au-pair-jobs", 'childcare-jobs'],
      categoryImage: "assets/job_categories/childcare-job-hero-image.jpg"
    },
    {
      name: "Construction and Labouring",
      urlSlug: "construction-labouring-jobs",
      seoUrls: ["construction-jobs", "labouring-jobs"],
      categoryImage: "assets/job_categories/construction-job-hero-image.jpg"
    },
    {
      name: "Conservation & Environment",
      urlSlug: "conservation-jobs",
      seoUrls: ["environmental-jobs", 'conservation-jobs'],
      categoryImage: "assets/job_categories/conservation-job-hero-image.jpg"
    },
    {
      name: "Disaster Relief & Humanitarian Aid",
      urlSlug: "disaster-relief-jobs",
      seoUrls: ["disaster-relief-jobs"],
      categoryImage: "assets/job_categories/disaster-relief-job-hero-image.jpg"
    },
    {
      name: "Education & Teaching",
      urlSlug: "education-teaching-jobs",
      seoUrls: ["education-jobs", 'teaching-jobs'],
      categoryImage: "assets/job_categories/teaching-job-hero-image.jpg"
    },
    {
      name: "Energy & Utilities",
      urlSlug: "energy-jobs",
      seoUrls: ["energy-jobs", "utilities-jobs"],
      categoryImage: "assets/job_categories/energy-job-hero-image.jpg"
    },
    {
      name: "Events & Promotions",
      urlSlug: "events-promotions-jobs",
      seoUrls: ["events-jobs", 'promotions-jobs'],
      categoryImage: "assets/job_categories/events-planner-job-hero-image.jpg"
    },
    {
      name: "Fishing & Aquaculture",
      urlSlug: "fishing-jobs",
      seoUrls: ["fishing-jobs", 'aquaculture-jobs'],
      categoryImage: "assets/job_categories/fishing-job-hero-image.jpg"
    },
    {
      name: "Food & Hospitality",
      urlSlug: "hospitality-jobs",
      seoUrls: ["food-jobs", 'hospitality-jobs', "bar-jobs", "restaurant-jobs"],
      categoryImage: "assets/job_categories/hospitality-job-hero-image.jpg"
    },
    {
      name: "Fruit Picking & Packaging",
      urlSlug: "fruit-picking-packaging-jobs",
      seoUrls: ["fruit-picking-jobs", 'fruit-packing-jobs'],
      categoryImage: "assets/job_categories/fruit-picking-job-hero-image.jpg"
    },
    {
      name: "Housekeeping & Cleaning",
      urlSlug: "housekeeping-cleaning-jobs",
      seoUrls: ["cleaning-jobs", 'cleaning-jobs'],
      categoryImage: "assets/job_categories/cleaning-job-hero-image.jpg"
    },
    {
      name: "IT & Technology",
      urlSlug: "technology-jobs",
      seoUrls: ["it-jobs", 'technology-jobs', "computer-jobs", "programming-jobs"],
      categoryImage: "assets/job_categories/technology-job-hero-image.jpg"
    },
    {
      name: "Landscaping & Gardening",
      urlSlug: "landscaping-jobs",
      seoUrls: ["landscaping-jobs", 'gardening-jobs'],
      categoryImage: "assets/job_categories/gardening-job-hero-image.jpg"
    },
    {
      name: "Mining & Resources",
      urlSlug: "mining-jobs",
      seoUrls: ["mining-jobs", "resources-jobs"],
      categoryImage: "assets/job_categories/mining-job-hero-image.jpg"
    },
    {
      name: "Photography & Videography",
      urlSlug: "photography-videography-jobs",
      seoUrls: ["photography-jobs", 'videography-jobs'],
      categoryImage: "assets/job_categories/photography-job-hero-image.jpg"
    },
    {
      name: "Retail & Sales",
      urlSlug: "retail-sales-jobs",
      seoUrls: ["retail-jobs", 'sales-jobs', "sales-assistant-jobs"],
      categoryImage: "assets/job_categories/sales-job-hero-image.jpg"
    },
    {
      name: "Sports & Fitness",
      urlSlug: "sports-fitness-jobs",
      seoUrls: ["sports-jobs", 'fitness-jobs', "personal-trainer-jobs"],
      categoryImage: "assets/job_categories/fitness-job-hero-image.jpg"
    },
    {
      name: "Trades & Services",
      urlSlug: "trade-jobs",
      seoUrls: ["trade-jobs", "tradie-jobs", "sparky-jobs", "plumber-jobs", "carpenter-jobs"],
      categoryImage: "assets/job_categories/trade-job-hero-image.jpg"
    },
    {
      name: "Transport & Logistics",
      urlSlug: "transport-logistics-jobs",
      seoUrls: ["transport-jobs", 'logistics-jobs'],
      categoryImage: "assets/job_categories/logistics-job-hero-image.jpg"
    },
    {
      name: "Travel & Tourism",
      urlSlug: "travel-tourism-jobs",
      seoUrls: ["travel-jobs", 'tourism-jobs'],
      categoryImage: "assets/job_categories/tourism-job-hero-image.jpg"
    },
    {
      name: "Volunteer & Community Service",
      urlSlug: "volunteering-jobs",
      seoUrls: ["volunteering-jobs", 'community-service-jobs'],
      categoryImage: "assets/job_categories/volunteering-job-hero-image.jpg"
    }
  ];


  states = [
    {name: "Australian Capital Territory", urlSlug: "australian-capital-territory", abbreviation: "ACT"},
    {name: "New South Wales", urlSlug: "new-south-wales", abbreviation: "NSW"},
    {name: "Northern Territory", urlSlug: "northern-territory", abbreviation: "NT"},
    {name: "Queensland", urlSlug: "queensland", abbreviation: "QLD"},
    {name: "South Australia", urlSlug: "south-australia", abbreviation: "SA"},
    {name: "Tasmania", urlSlug: "tasmania", abbreviation: "TAS"},
    {name: "Victoria", urlSlug: "victoria", abbreviation: "VIC"},
    {name: "Western Australia", urlSlug: "western-australia", abbreviation: "WA"}
  ]


}
