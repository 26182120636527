<div class="py-3">
  <h1 class="text-center !text-2xl" mat-dialog-title>Contact {{backpackerProfile.full_name}} </h1>
  <div mat-dialog-content>
    <div class="flex flex-col">
      <a class="" href="tel:{{backpackerProfile.contact_number}}">
        <button color="primary" class="w-full my-2 generalBtn w-40" mat-flat-button>
          <mat-icon class="mr-3">phone</mat-icon>
          {{backpackerProfile.contact_number}}
        </button>
      </a>
      <div class="my-2 flex items-center w-full">
        <div class="flex-grow border-t border-gray-300"></div>
        <span class="px-4 text-sm text-gray-600">OR</span>
        <div class="flex-grow border-t border-gray-300"></div>
      </div>
      <a class="" href="mailto:{{backpackerProfile.email}}">
        <button color="primary" class="w-full  my-2 flex-start generalBtn w-40" mat-flat-button>
          <mat-icon class="mr-3">mail</mat-icon>
          {{backpackerProfile.email}}
        </button>
      </a>
    </div>
  </div>

  <div class="!justify-center" mat-dialog-actions>
    <button (click)="closeDialog()" class="lightBtn" mat-flat-button>Close</button>
  </div>
</div>
