import {Component, OnInit, ViewChild} from '@angular/core';
import {MetaService} from "../../services/meta.service";
import {Accounts} from "../../services/accounts.service";
import {ActivatedRoute, Router} from "@angular/router";
import {MatStepper} from "@angular/material/stepper";

@Component({
  selector: 'app-update-confirmation',
  templateUrl: './update-confirmation.component.html',
  styleUrls: ['./update-confirmation.component.scss']
})
export class UpdateConfirmationComponent implements OnInit {
  public accountTier: string;
  public productText: string = "Certified Backpacker";
  public priceText: string = "$4.99 AUD";
  public portalURL: string;
  public existingMembership: string;
  updateResponse = false;
  updateSuccess = false;
  renewalDate: Date;
  public metaKeywords: string = "backpacker pro, update membership, manage membership, profile advertising, backpacker jobs, job board, Australia, career advancement, backpacker visibility, get hired, Backpacker Jobs Now";
  public metaTitle = 'Update Your Membership | Backpacker Jobs Now - Australia\'s Official Backpacker Job Board';
  public metaDescription = "Australia's number 1 backpacking job board built exclusively for backpackers. Find rural, farm work, fruit picking and working holiday visa jobs with ease. Get your working holiday visa completed today with Backpacker Jobs Now.";
  @ViewChild('stepper') stepper: MatStepper;

  constructor(private meta: MetaService, private apiService: Accounts, private route: ActivatedRoute, private router: Router) {
  }

  ngOnInit(): void {
    this.route.queryParams.subscribe(params => {
      this.accountTier = params['accountType'];
      if (this.accountTier != 'pro' && this.accountTier != 'certified') {
        this.router.navigate(['/']);
      } else {
        if (this.accountTier == 'certified') {
          this.productText = "Certified Backpacker";
          this.priceText = "$4.99 AUD";
        } else {
          this.productText = "Backpacker Pro";
          this.priceText = "$9.99 AUD";
        }
        this.renewalDate = new Date(new Date().setMonth(new Date().getMonth() + 1))

        this.getUpdateMembershipData();

      }
    });

    this.meta.setupPageTitle(this.metaTitle);
    this.meta.setupMetaTags(
      this.metaTitle,
      this.metaDescription,
      this.metaKeywords,
      this.router.url
    );
  }

  updateMembership() {
    this.apiService.backpackerMembershipUpdate(this.accountTier).subscribe((response: any) => {
        this.updateResponse = true;

        if (response.status == 'success') {
          this.updateSuccess = true;
        } else {
          this.updateSuccess = false;
        }
        this.stepper.next();
      },
      (error) => {
        this.updateResponse = true;
        this.updateSuccess = false;
        console.error('Error updating membership:', error);
        this.stepper.next();
      }
    );
  }

  //
  //
  //   this.apiService.updateBackpackerWorkFields(profilePayload).subscribe(
  //   response => {
  //     if (this.accountTier != 'standard' && this.existingMembership != this.accountTier) {
  //       this.apiService.backpackerMembershipUpdate(this.accountTier).subscribe((session: any) => {
  //           if (session.sessionId) {
  //             this.stripe.redirectToCheckout({
  //               sessionId: session.sessionId
  //             }).then((result) => {
  //             });
  //           } else if(session.url) {
  //             window.location.href = session.url;
  //           }
  //         }
  //       );
  //     } else {
  //       this.route.navigate(['/profile']);
  //     }
  //   },
  //   (error) => {
  //     this.errorMsg = error.error.returnMsg;
  //   }
  // );
  // }

  getUpdateMembershipData() {
    this.apiService.getUpdateMembershipData().subscribe((response: any) => {
      if (response.status == 'success') {
        this.portalURL = response['billingURL'];
        this.existingMembership = response['existingType'];
      }

      if (this.existingMembership == this.accountTier) {
        this.router.navigate(['/']);
      }

    });
  }


  protected readonly Date = Date;
}
