import imageUrlBuilder from '@sanity/image-url';
import {ImageUrlBuilder} from '@sanity/image-url/lib/types/builder';
import {SanityImageSource} from '@sanity/image-url/lib/types/types';
import {PortableTextMarkComponentOptions, toHTML} from '@portabletext/to-html';
import {Injectable} from '@angular/core';
import {createClient} from "@sanity/client";

// import blocksToHtml from '@sanity/block-content-to-html';

@Injectable({
  providedIn: 'root'
})
export class BlogService {
  private client = createClient({
    projectId: 'y7xkulk6',
    dataset: 'production',
  });

  private imageUrlBuilder: ImageUrlBuilder;
  private faqsHTML: any;

  constructor() {
    this.imageUrlBuilder = imageUrlBuilder(this.client);
  }

  urlFor(source: SanityImageSource) {
    return this.imageUrlBuilder.image(source);
  }

  getBlogPosts() {
    const query = `*[_type == "post"]{
    slug,
    title,
    briefDesc,
    publishedAt,
    "imageUrl": mainImage.asset->url,
    "authorName": author->name
  } | order(publishedAt desc)`;
    return this.client.fetch(query);
  }


  // In your BlogService
  getArticleBySlug(slug: string): Promise<any> {
    const query = `*[_type == "post" && slug.current == $slug]{
  slug,
  title,
  briefDesc,
  body,
  tableContents,
 "faqs": faqs[]{
      question,
      "answer": answer[]{
        ...,
        children[]{
          ...,
        }
      }
    },
  publishedAt,
  "imageUrl": mainImage.asset->url,
  "authorName": author->name
  }[0]`;
    const params = {slug};

    return this.client.fetch(query, params).then(article => {
      const myPortableTextComponents = {
        types: {
          embeddedContent: ({value}) => value.html,
          image: ({value}) => `<img class="${value.cssClass}" src="${this.urlFor(value.asset).url()}" alt="${value.alt}" />`
        },
        marks: {
          link: (options: any): string => {  // Using 'any' for now until we are sure of the structure
            const {value, children} = options;
            const {href} = value;

            if (href) {
              return `<a href="${href}" target="_blank" rel="noopener noreferrer">${children}</a>`;
            }
            return children; // or some other default value
          }
        }


      };

      // Convert the main article body to HTML
      let bodyHTML = toHTML(article.body, {components: myPortableTextComponents});
      let tableOfContents = [];


      if (article['tableContents']) {
        // Regular expression to match <h2> tags
        const h2Regex = /<h2>(.*?)<\/h2>/g;
        bodyHTML = bodyHTML.replace(h2Regex, (match, text) => {
          const id = text.toLowerCase().replace(/\s+/g, '-').replace(/[^a-z0-9-]/g, '');
          return `<h2 id="${id}">${text}</h2>`;
        });

        tableOfContents = article.body
          .filter(block => block._type === 'block' && block.style === 'h2')
          .map(header => {
            const text = header.children?.find(child => child._type === 'span')?.text || '';
            const id = text.toLowerCase().replace(/\s+/g, '-').replace(/[^a-z0-9-]/g, '');
            return {id, text};
          });
      }

      // Convert each FAQ answer to HTML

      if (article.faqs && article.faqs.length > 0) {
        this.faqsHTML = article.faqs.map(faq => ({
          question: faq.question,
          answer: toHTML(faq.answer, {components: myPortableTextComponents})
        }));
      }


      return {
        ...article,
        body: bodyHTML,
        faqs: this.faqsHTML,
        tableOfContents: tableOfContents
      };
    });
  }

}
