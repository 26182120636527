import {Component, Inject} from '@angular/core';
import {JobsService} from "../../../../services/jobs.service";
import {UntypedFormBuilder} from "@angular/forms";
import {SharedService} from "../../../../services/shared.service";
import {MAT_DIALOG_DATA, MatDialogRef} from "@angular/material/dialog";
import {BackpackerProfile} from "../../../../interfaces/backpacker-profile";

@Component({
  selector: 'app-contact-backpacker',
  templateUrl: './contact-backpacker.component.html',
  styleUrls: ['./contact-backpacker.component.scss']
})
export class ContactBackpackerComponent {
  backpackerProfile: BackpackerProfile;
  constructor(
    private jobService: JobsService,
    private formBuilder: UntypedFormBuilder,
    public sharedService: SharedService,
    public dialogRef: MatDialogRef<ContactBackpackerComponent>,
    @Inject(MAT_DIALOG_DATA) public data: any) {

    this.backpackerProfile = data.backpackerData;

    dialogRef.backdropClick().subscribe(() => {
      this.closeDialog();
    });

  }

  closeDialog() {
    this.dialogRef.close()
  }

}
