import {Component, OnInit} from '@angular/core';
import {SharedService} from "../../services/shared.service";
import {Settings} from "../../globals/settings.model";
import {MatChipSelectionChange} from "@angular/material/chips";
import {Accounts} from "../../services/accounts.service";
import {Stripe} from "@stripe/stripe-js";
import {Router} from "@angular/router";
import {BackpackerProfile} from "../../interfaces/backpacker-profile";
import {MetaService} from "../../services/meta.service";
import {environment} from "../../../environments/environment.prod";

declare var Stripe: any;

@Component({
  selector: 'app-upgrade-account-backpacker',
  templateUrl: './upgrade-account-backpacker.component.html',
  styleUrls: ['./upgrade-account-backpacker.component.scss', '../latest-jobs/list-jobs.component.scss', '../login-page/login-page.component.scss', '../search-backpackers/backpacker-search.component.scss']
})
export class UpgradeAccountBackpackerComponent implements OnInit {
  private stripe: Stripe;
  errorMsg: string;
  accountTier = 'certified';
  existingMembership: string;
  selectedJobs = [];
  loaded: boolean = false;
  profileData: BackpackerProfile;
  buttonTexts: { [key: string]: string } = {};
  loading = true;

  public metaKeywords: string = "backpacker pro, upgrade membership, manage membership, profile advertising, backpacker jobs, job board, Australia, career advancement, backpacker visibility, get hired, Backpacker Jobs Now";
  public metaTitle = 'Manage Your Membership & Upgrade To Backpacker Pro | Backpacker Jobs Now - Australia\'s Official Backpacker Job Board';
  public metaDescription = "Elevate your visibility and increase your chances of getting hired by upgrading to Backpacker Pro on Backpacker Jobs Now. Gain exclusive features like profile advertising to stand out to employers across Australia. Manage your membership today and take the next step in your backpacking career.";

  constructor(private meta: MetaService, public sharedService: SharedService, public globals: Settings, private apiService: Accounts, private route: Router) {
  }


  ngOnInit(): void {
    this.meta.setupPageTitle(this.metaTitle);
    this.meta.setupMetaTags(
      this.metaTitle,
      this.metaDescription,
      this.metaKeywords,
      this.route.url
    );
    this.getManageMembershipData();
    this.stripe = Stripe(environment.stripeProdKey);
  }

  selectTier(tierSelection) {
    this.accountTier = tierSelection;
    this.computeButtonTexts();
  }

  computeButtonTexts() {
    this.buttonTexts['standard'] = this.getButtonTextTier('standard');
    this.buttonTexts['certified'] = this.getButtonTextTier('certified');
    this.buttonTexts['pro'] = this.getButtonTextTier('pro');
  }


  getButtonTextTier(tier: string): string {
    // Check if the current plan is the one being viewed
    if (this.existingMembership === tier) {
      return 'Current Plan';
    }

    // Handle selected state
    if (this.accountTier === tier) {
      if (tier === 'certified' && !this.profileData.trial_end) {
        return 'Free 30 Day Trial Selected';
      }
      return 'Selected';
    }

    // Special handling for non-standard memberships
    if (this.existingMembership === 'pro' || this.existingMembership === 'certified') {
      if (tier === 'standard') {
        return 'Select'; // Standard should still be selectable
      } else if (tier === 'certified' && this.existingMembership === 'pro') {
        return 'Downgrade Account'; // Option to downgrade from 'pro' to 'certified'
      } else if (tier === 'pro' && this.existingMembership === 'certified') {
        return 'Upgrade Account'; // Option to upgrade from 'certified' to 'pro'
      } else {
        return 'Change Plan'; // General message for changing within 'pro' and 'certified'
      }
    }

    // Default handling for selection
    if (tier === 'certified' && !this.profileData.trial_end) {
      return 'Try Free For 30 Days';
    }
    return 'Select';
  }


  workFieldSelectionChange(workFieldArray: any) {
    this.selectedJobs = workFieldArray;
  }


  upgradeAccount() {
    const profilePayload = {
      workFields: this.selectedJobs,
    }
    this.apiService.updateBackpackerWorkFields(profilePayload).subscribe(
      response => {

        if (this.existingMembership != this.accountTier) {

          if (this.existingMembership == 'standard') {
            this.apiService.backpackerProfileCheckout(this.accountTier).subscribe((session: any) => {
                this.stripe.redirectToCheckout({
                  sessionId: session.sessionId
                }).then((result) => {
                  // Handle result.error here if the redirect fails
                });
              }
            );
          } else {
            this.route.navigate(['/update-membership-checkout'], {
              queryParams: {accountType: this.accountTier}
            });
          }
        } else {
          this.route.navigate(['/profile']);
        }
      }, (error) => {
        this.errorMsg = error.error.returnMsg;
      }
    );

  }

  getManageMembershipData() {
    this.apiService.checkProfileCreated().subscribe(
      (profileCreated) => {
        if (profileCreated.profileFound) {
          this.apiService.getManageMembershipData().subscribe((response: any) => {
            if (response.data) {
              this.profileData = response.data;
              this.existingMembership = response.data.account_tier;
              this.selectedJobs = response.data.work_fields;
              this.loading = false;
              if ((!this.profileData.trial_end && this.existingMembership === 'standard')) {
                this.accountTier = 'certified';
              } else {
                this.accountTier = this.existingMembership;
              }
              this.computeButtonTexts();

            }
            this.loaded = true;
          });
        } else {
          this.route.navigate(['/create-profile'])
        }
      });


  }


}
