<app-loading-screen *ngIf="isLoading"></app-loading-screen>
<section *ngIf="!isLoading" class="container-space mt-8">
  <section class="banner">
    <div class="flex justify-center text-center flex-col">
      <ng-container *ngIf="catTitle != '' && locationTitle != ''">
        <h1 class="uppercase"><span class="underline-dec">HIRE</span> EXPERIENCED BACKPACKERS IN <span
          class="underline-dec"> {{ locationTitle | uppercase }} </span> FOR <span
          class="underline-dec">{{ catTitle | uppercase }} </span></h1>
      </ng-container>

      <ng-container *ngIf="catTitle != '' && locationTitle == ''">
        <h1 class="uppercase"><span class="underline-dec">HIRE</span> EXPERIENCED BACKPACKERS FOR <span
          class="underline-dec"> {{ catTitle | uppercase }} </span> In Australia</h1>
      </ng-container>

      <ng-container *ngIf="catTitle == '' && locationTitle != ''">
        <h1 class="uppercase"><span class="underline-dec">HIRE</span> EXPERIENCED BACKPACKERS In <span
          class="underline-dec"> {{ locationTitle | uppercase }} </span></h1>
      </ng-container>


      <ng-container *ngIf="catTitle == '' && locationTitle == ''">
        <h1 class="uppercase"><span class="underline-dec"> HIRE </span> EXPERIENCED Backpackers IN AUSTRALIA</h1>
      </ng-container>


      <h2 class="text-base font-normal mt-3">Search and <span
        class="font-semibold"> Hire Experienced Backpackers Now </span>. Australia's official backpacker hiring platform
        allows you to
        recruit skilled labour for fruit picking, farm jobs and rural work.
        Search experienced backpackers looking for work now. </h2>

    </div>
  </section>

  <section class="job-search-container flex flex-col mt-10">
    <form [formGroup]="backpackerSearchForm" class="job-search-form">

      <div class="flex flex-col sm:flex-row mt-5">
        <mat-form-field appearance="outline" class="sm:mt-0 md:mr-3 w-full my-2" subscriptSizing="dynamic">
          <mat-label>What Job Are You Hiring For?</mat-label>
          <mat-select formControlName="workTypeFilter" multiple>
            <mat-option *ngFor="let category of globals.jobCategories"
                        [value]="category">{{ category.name }}
            </mat-option>
          </mat-select>
        </mat-form-field>

        <mat-form-field appearance="outline" class="w-full sm:mt-0 md:mr-3 my-2" subscriptSizing="dynamic">
          <mat-label>Backpackers Looking Anywhere In Australia</mat-label>
          <mat-select multiple formControlName="workLocationFilter">
            <mat-option *ngFor="let state of globals.states" [value]="state">{{ state.name }}</mat-option>
          </mat-select>

        </mat-form-field>

        <button (click)="searchBackpackers()" class=" !rounded-xl !py-6 w-full max-w-lg text-base" color="primary"
                mat-flat-button>
          SEARCH BACKPACKERS
        </button>
      </div>

      <mat-checkbox class="mt-2" formControlName="showExperiencedOnly">Show Experienced Backpackers Only</mat-checkbox>


    </form>

  </section>

  <div class="my-8" *ngIf="backpackerProfiles?.length < 1">
    <h3 class="text-center font-semibold">
      Oh No! We couldn't find any backpackers matching your search filters.
    </h3>
    <div class="flex justify-center">
      <button *ngIf="sharedService.employeeLogin" [routerLink]="['/upgrade-account']" class="mt-4 lightBtn generalBtn" mat-flat-button>Post Your Profile Here Now!</button>
      <button *ngIf="!sharedService.employeeLogin" routerLink="/post-job" class="mt-4 lightBtn generalBtn" mat-flat-button>Why not post a job advert instead?</button>
    </div>
  </div>

  <div *ngIf="sharedService.employerLogin && recommendedBackpackers && recommendedBackpackers.length > 4" [ngStyle]="{'display': isLoadingSwiper ? 'none' : 'block'}">
    <h3 class="mt-10 text-xl font-bold">Hire Backpackers Recommended For You</h3>
    <h4 class="tracking-wide text-base mt-1">Recommended based on your industry & job listings.</h4>
    <swiper-container #swiper class="featured-swiper mt-6">
      <ng-container *ngFor="let backpackerProfile of recommendedBackpackers" class="backpacker-profile-container">
        <swiper-slide>
          <a class="backpacker-profile-container" href="/backpacker-profile/{{backpackerProfile?.account_id}}"
             target="_blank">

            <!-- Pay Extra To Select Tag  -->
            <div *ngIf="backpackerProfile.account_tier =='pro'" class="ribbon ribbon-top-right"><span>Top Hire</span>
            </div>

            <div class="flex flex-col mt-3 attachments-container">
              <div *ngIf="backpackerProfile.has_resume" class="attachment">
                <mat-icon>attach_file</mat-icon>
                Resume
              </div>
              <div *ngIf="backpackerProfile.has_work_experience" class="attachment">
                <mat-icon>star</mat-icon>
                Experienced
              </div>
            </div>


            <div class="backpacker-profile-image profile-image-smaller  relative">
              <div *ngIf="showLastOnlineStatus(backpackerProfile)"
                   class="online-recently flex items-center absolute right-3 bottom-1">
                <div class="onlineDot"></div>
              </div>
              <img *ngIf="backpackerProfile?.profile_picture != ''" alt="profile picture"
                   src="{{backpackerProfile?.profile_picture}}">
              <img *ngIf="backpackerProfile?.profile_picture == ''" alt="profile picture"
                   src="assets/profile-picture-placeholder.jpg">
            </div>

            <!--      Privacy so last name initial-->
            <div class="backpacker-profile-name">
              {{ backpackerProfile?.full_name }}
            </div>

            <div *ngIf="backpackerProfile.work_location" class="flex justify-center w-full">
              <div class="work-location text-icon-wrapper">
                <mat-icon>location_on</mat-icon>
                Looking In {{ backpackerProfile?.work_location }}
              </div>
            </div>

            <div class="jobCategories mt-3">
              <h4 class="profile-view-title">Looking To Work In:</h4>
              <div class="flex flex-wrap justify-center mt-3 mb-2">
                <div *ngFor="let field of backpackerProfile?.work_fields"
                     class="highlight jobFieldHighlight-sm">{{ field }}
                </div>
              </div>
            </div>

            <button [routerLink]="['/backpacker-profile',backpackerProfile?.account_id]" class="generalBtn"
                    color="primary"
                    mat-raised-button>
              View Profile
            </button>

          </a>
        </swiper-slide>
      </ng-container>

    </swiper-container>
    <div class="swiper-button-next"></div>
    <div class="swiper-button-prev"></div>
  </div>

  <ng-container *ngIf="backpackerProfiles?.length > 0 && backpackerProfiles && !isLoadingSwiper">

    <h3 class="mt-10 text-xl font-bold">Hire Experienced Backpackers Looking For Work Now</h3>
    <h4 class="tracking-wide text-base mt-1">View, contact, hire... simple as that.</h4>

    <div class="grid grid-cols-1 sm:grid-cols-2 lg:grid-cols-3 gap-4 mt-6">

      <a *ngIf="sharedService.employeeLogin && loggedInBackpackerUserDetails?.account_tier == 'standard'"
         href="/upgrade-account"
         target="_blank" class="backpacker-profile-container !border-dashed !border-black">
        <div class="add-profile-icon">
          <mat-icon>add_circle</mat-icon>
        </div>
        <div class="handwriting z-10 text-2xl text-center">You Could Appear Here!</div>
        <div class="opacity-70 flex flex-col items-center">

          <div class="backpacker-profile-image relative">
            <div class="online-recently flex items-center absolute right-3 bottom-1">
              <div class="onlineDot"></div>
            </div>
            <img *ngIf="loggedInBackpackerUserDetails?.profile_picture != ''" alt="profile picture"
                 src="{{loggedInBackpackerUserDetails?.profile_picture}}">
            <img *ngIf="loggedInBackpackerUserDetails?.profile_picture == ''" alt="profile picture"
                 src="assets/profile-picture-placeholder.jpg">
          </div>
          <div class="backpacker-profile-name">
            {{ loggedInBackpackerUserDetails?.full_name }}
          </div>
          <div class="flex justify-center w-full">
            <div class="work-location text-icon-wrapper">
              <mat-icon>location_on</mat-icon>
              Looking In {{ loggedInBackpackerUserDetails?.work_location }}
            </div>
          </div>
          <div class="flex flex-row mt-3 pinned-highlights">
            <div class="highlight">
              <mat-icon>attach_file</mat-icon>
              Resume Attached
            </div>
            <div class="highlight">
              <mat-icon>star</mat-icon>
              Experienced
            </div>
          </div>
          <div class="jobCategories mt-3">
            <h4 class="profile-view-title">Looking To Work In:</h4>
            <div *ngIf="loggedInBackpackerUserDetails?.work_fields > 0; else defaultFields"
                 class="flex flex-wrap justify-center mt-3 mb-2">
              <div *ngFor="let field of loggedInBackpackerUserDetails?.work_fields"
                   class="highlight jobFieldHighlight">{{ field }}
              </div>
            </div>

            <ng-template #defaultFields>
              <div class="flex flex-wrap justify-center mt-3 mb-2">
                <div class="highlight jobFieldHighlight">Agriculture & Farm Work</div>
                <div class="highlight jobFieldHighlight">Food & Hospitality</div>
              </div>
            </ng-template>

          </div>
        </div>

        <button class="generalBtn !w-40 items-center" color="primary" mat-raised-button>
          Show Me How
        </button>
      </a>


      <a href="/backpacker-profile/{{backpackerProfile?.account_id}}" target="_blank"
         *ngFor="let backpackerProfile of backpackerProfiles" class="backpacker-profile-container">

        <!-- Pay Extra To Select Tag  -->
        <div *ngIf="backpackerProfile.account_tier =='pro'" class="ribbon ribbon-top-right"><span>Top Hire</span></div>


        <div class="backpacker-profile-image relative">
          <div *ngIf="showLastOnlineStatus(backpackerProfile)"
               class="online-recently flex items-center absolute right-3 bottom-1">
            <div class="onlineDot"></div>
          </div>
          <img *ngIf="backpackerProfile?.profile_picture != ''" alt="profile picture"
               src="{{backpackerProfile?.profile_picture}}">
          <img *ngIf="backpackerProfile?.profile_picture == ''" alt="profile picture"
               src="assets/profile-picture-placeholder.jpg">
        </div>

        <!--      Privacy so last name initial-->
        <div class="backpacker-profile-name">
          {{ backpackerProfile?.full_name }}
        </div>

        <div class="flex justify-center w-full">
          <div class="work-location text-icon-wrapper">
            <mat-icon>location_on</mat-icon>
            Looking In {{ backpackerProfile?.work_location }}
          </div>
        </div>

        <div class="flex flex-row mt-3 pinned-highlights">
          <div *ngIf="backpackerProfile.has_resume" class="highlight">
            <mat-icon>attach_file</mat-icon>
            Resume Attached
          </div>
          <div *ngIf="backpackerProfile.has_work_experience" class="highlight">
            <mat-icon>star</mat-icon>
            Experienced
          </div>
        </div>

        <div class="jobCategories mt-3">
          <h4 class="profile-view-title">Looking To Work In:</h4>
          <div class="flex flex-wrap justify-center mt-3 mb-2">
            <div *ngFor="let field of backpackerProfile?.work_fields" class="highlight jobFieldHighlight">{{ field }}
            </div>
          </div>
        </div>

        <button class="generalBtn" color="primary" mat-raised-button>
          View Profile
        </button>

      </a>

    </div>
  </ng-container>

  <div *ngIf="backpackerProfiles?.length > 0" class="flex justify-center mt-4">
    <button (click)="onPageChange(currentPage - 1)" *ngIf="currentPage > 1" class="controlBtns rotate-180 leading-3">
      <mat-icon>navigate_next</mat-icon>
    </button>

    <ng-container *ngFor="let page of [].constructor(totalPages); let i = index">
      <button (click)="onPageChange(i + 1)" [class.active]="i + 1 === currentPage" class="pageBtn">{{ i + 1 }}</button>
    </ng-container>

    <button (click)="onPageChange(currentPage + 1)" *ngIf="currentPage < totalPages" class="controlBtns leading-3">
      <mat-icon>navigate_next</mat-icon>
    </button>
  </div>

</section>
