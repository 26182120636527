<section class="container-space mt-8 md:mt-20">
  <section class="banner">
    <div class="flex justify-center text-center flex-col">
      <h1 class="leading-relaxed uppercase">The <span class="underline-dec">Official</span> Australian Backpacker Job
        Board</h1>
      <h2 class="text-base mt-6">Complete your Working Holiday Visa today and explore a wide range of rural job
        opportunities in Australia.</h2>
      <h3 class="mt-6 leading-10">Get your <span class="border-dec overflow-hidden font-semibold"><span
        [@scroll]="animateState" class="inline-block">{{ currentHeading }}</span></span> completed today.
        <!--        With over 1,242 backpacker safe jobs in Australia.-->
      </h3>
    </div>
  </section>

  <section class="latest-jobs">
    <app-list-jobs [pageRef]="'home'"></app-list-jobs>
  </section>

  <section>

  </section>
  <h2 class="mb-2">Why Choose Backpacker Jobs Now?</h2>
  <p>
    <strong> Welcome to the new backpacker job board.</strong> A completely fresh and simple solution for backpackers in
    Australia seeking
    employment.
    We worked together with backpackers and rural employers to develop the perfect, hassle-free backpacker job board for
    you.
  </p>

  <h3 class="mt-8 mb-3 font-semibold">Completely Free</h3>

  <ul>
    <li>Our commitment is to provide exceptional value without any hidden costs.
      Access a wide range of
      job opportunities on our completely free-to-use platform. No card details, no memberships – just straightforward
      job
      hunting.
    </li>

    <h3 class="mt-8 mb-3 font-semibold">Diverse Job Listings</h3>
    <li>Whether it's fruit picking to fulfill your 2nd year visa requirements,
      engaging in hospitality roles, or exploring au-pair opportunities, our job board is diverse. We cater to various
      employment needs, including
      full-time, part-time, and temporary positions.
    </li>

    <h3 class="mt-8 mb-3 font-semibold">Ease of Use</h3>
    <li>
      Designed with simplicity in mind, our platform allows you to effortlessly browse
      through job
      vacancies and apply online. The process is straightforward, saving you time and hassle.
    </li>
    <h3 class="mt-8 mb-3 font-semibold"> Your Personal Dashboard</h3>

    <li>
      Revolutionize the way you manage your job applications with 'Your
      Dashboard'. Update resumes, cover letters, track saved listings, and oversee your applications all in one
      convenient place. It's the future of job posting, offering unparalleled control and organization.
    </li>

  </ul>

  <section>
    <h2 class="mb-2">What We Do Differently</h2>
    <p>
      <strong class="text-primary">G’day Backpackers!</strong>
      We're Backpacker Jobs Now, and we're revolutionizing the way backpackers find jobs in Australia.
      Whether you're looking to complete your 88 days, find hospitality jobs, or explore residency options, we've got
      you covered.
    </p>

    <h3 class="mt-8 mb-3 font-semibold">Verified Employers and Fair Opportunities</h3>
    <ul class="ml-6">
      <li>
        We carefully vet employers to make sure they align with our commitment to fairness and integrity.
        No more stress over what's meant to be an exciting chapter in your life; we're here to ensure your hard work is
        rewarded.
      </li>
    </ul>

    <h3 class="mt-8 mb-3 font-semibold">Built by Backpackers, for Backpackers</h3>
    <ul class="ml-6">
      <li>
        We've picked the fruit, mustered the sheep, and been in your dusty boots.
        We know exactly what you need because we've lived the backpacker life ourselves.
        Our site is handmade by us, for you.
      </li>
    </ul>

    <h3 class="mt-8 mb-3 font-semibold">No More Scouring Facebook Groups</h3>
    <ul class="ml-6">
      <li>
        Isn't it odd that there's been no dedicated platform for backpacker jobs, forcing you to sift through social
        media
        groups for opportunities?
        We've changed that, making it easy to find the right job without the social media maze.
      </li>
    </ul>

    <!--    <h3 class="mt-8 text-lg font-semibold">Find Backpacker Jobs Today</h3>-->

    <!--    <p>-->
    <!--      Finding the right backpacking job in Australia shouldn't be as hard as wrestling a crocodile, and with BackpackerJobsNow,-->
    <!--      it isn't.-->
    <!--      Our site is completely free. No hidden fees, no wild goose chases, just a straightforward path to the-->
    <!--      opportunities you want.-->
    <!--      Dive into our job listings and make your Australian adventure a reality.-->
    <!--    </p>-->

    <a href="jobs">
      <button class="!rounded-3xl mt-8 w-full !max-w-sm text-lg !py-6" color="primary" mat-raised-button
              routerLink="jobs">
        Browse Backpacker Jobs
        <mat-icon iconPositionEnd>double_arrow</mat-icon>
      </button>
    </a>

  </section>

  <section>
    <h3 class="mb-4 font-semibold">Find Local Backpacking Jobs</h3>
    <div class="jobRegions">
      <div class=" sm:w-1/2 my-1.5 mx-6 sm:mx-0 ">
        <a class="text-link" href="/search-jobs/queensland"><h4>Find Jobs in Queensland</h4></a>
      </div>
      <div class=" sm:w-1/2 my-1.5 mx-6 sm:mx-0 ">
        <a class="text-link" href="/search-jobs/new-south-wales"><h4>Find Jobs in New South Wales</h4></a>
      </div>
      <div class=" sm:w-1/2 my-1.5 mx-6 sm:mx-0 ">
        <a class="text-link" href="/search-jobs/victoria"><h4>Find Jobs in Victoria</h4></a>
      </div>
      <div class=" sm:w-1/2 my-1.5 mx-6 sm:mx-0 ">
        <a class="text-link" href="/search-jobs/south-australia"><h4>Find Jobs in South Australia</h4></a>
      </div>
      <div class=" sm:w-1/2 my-1.5 mx-6 sm:mx-0 ">
        <a class="text-link" href="/search-jobs/western-australia"><h4>Find Jobs in Western Australia</h4></a>
      </div>
      <div class=" sm:w-1/2 my-1.5 mx-6 sm:mx-0 ">
        <a class="text-link" href="/search-jobs/tasmania"><h4>Find Jobs in Tasmania</h4></a>
      </div>
      <div class=" sm:w-1/2 my-1.5 mx-6 sm:mx-0 ">
        <a class="text-link" href="/search-jobs/northern-territory"><h4>Find Jobs in Northern Territory</h4></a>
      </div>
      <div class=" sm:w-1/2 my-1.5 mx-6 sm:mx-0 ">
        <a class="text-link" href="/search-jobs/australian-capital-territory"><h4>Find Jobs in Australian Capital
          Territory</h4></a>
      </div>
    </div>
  </section>

  <section>
    <h3 class="mb-4 font-semibold"> Popular Jobs</h3>
    <div class="jobRegions">
      <div class=" sm:w-1/2 my-1.5 mx-6 sm:mx-0 ">
        <a class="text-link" href="/search-jobs/fruit-picking-jobs"><h4>Fruit Picking Jobs</h4></a>
      </div>
      <div class=" sm:w-1/2 my-1.5 mx-6 sm:mx-0 ">
        <a class="text-link" href="/search-jobs/agriculture-jobs"><h4>Agricultural Work</h4></a>
      </div>
      <div class=" sm:w-1/2 my-1.5 mx-6 sm:mx-0 ">
        <a class="text-link" href="/search-jobs/au-pair-jobs"><h4>Au Pair Jobs</h4></a>
      </div>
      <div class=" sm:w-1/2 my-1.5 mx-6 sm:mx-0 ">
        <a class="text-link" href="/search-jobs/hospitality-jobs"><h4>Hospitality Work</h4></a>
      </div>
      <div class=" sm:w-1/2 my-1.5 mx-6 sm:mx-0 ">
        <a class="text-link" href="/search-jobs/disaster-relief-jobs"><h4>Disaster Relief</h4></a>
      </div>
      <div class=" sm:w-1/2 my-1.5 mx-6 sm:mx-0 ">
        <a class="text-link" href="/search-jobs/education-teaching-jobs"><h4>Teaching Jobs</h4></a>
      </div>
      <div class=" sm:w-1/2 my-1.5 mx-6 sm:mx-0  ">
        <a class="text-link" href="/search-jobs/cleaning-jobs"><h4>Cleaning Jobs</h4></a>
      </div>
      <div class=" sm:w-1/2 my-1.5 mx-6 sm:mx-0 ">
        <a class="text-link" href="/search-jobs/technology-jobs"><h4>Technology/IT Jobs</h4></a>
      </div>
      <div class=" sm:w-1/2 my-1.5 mx-6 sm:mx-0 ">
        <a class="text-link" href="/search-jobs/retail-sales-jobs"><h4>Retail Jobs</h4></a>
      </div>
      <div class=" sm:w-1/2 my-1.5 mx-6 sm:mx-0 ">
        <a class="text-link" href="/search-jobs/travel-tourism-jobs"><h4>Travel & Tourism Jobs</h4></a>
      </div>

    </div>
  </section>

  <section class="reviewsSection">
    <h3 class="mb-4 font-semibold">What Backpackers Say About Us</h3>
    <div class="flex flex-row flex-wrap">
      <div *ngFor="let review of customerReviews" class="review-box flex flex-col items-center ">
        <div class="flex flex-col items-center mb-3">
          <div class="star-rating ">
            <mat-icon class="star">star</mat-icon>
            <mat-icon class="star">star</mat-icon>
            <mat-icon class="star">star</mat-icon>
            <mat-icon class="star">star</mat-icon>
            <mat-icon class="star">star</mat-icon>
          </div>
          <div class="reviewer-name mt-2">
            {{ review.name }}
          </div>
        </div>
        <div *ngIf="review.feedback" class="review-feedback">
          {{ review.feedback }}
        </div>
      </div>
    </div>
  </section>

</section>



