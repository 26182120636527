<ng-container *ngIf="smallWidth; else fullWidthLayout">


  <div class="flex flex-col">
    <ng-container *ngIf="jobListings; else empty">
      <ng-container *ngFor="let job of jobListings">
        <div class="job-card small-job-card" mat-stroked-button>
          <div class="flex flex-col items-center w-full">
            <img *ngIf="job.jobLogo" alt="job image" class="w-full h-full mb-2" [src]="job.jobLogo"/>
            <h3 class="text-primary font-bold text-center mb-2 text-lg sm:text-xl">{{ job.jobTitle }}</h3>
            <div class="flex flex-row justify-between">
              <h4 class="text-blueTheme mx-4 font-bold text-center flex items-center text-xs sm:text-sm">
                <mat-icon class="mr-2">people</mat-icon>
                {{ job.appliedCount }} Candidates
              </h4>
              <h4 class="text-blueTheme mx-4 font-bold text-center flex items-center text-xs sm:text-sm">
                <mat-icon class="mr-2">favorite</mat-icon>
                {{ job.savedCount }} Saves
              </h4>
            </div>
            <p class="font-light text-center mb-4">{{ sharedService.truncateString(job.jobBriefDesc, 15, 40) }}</p>

            <div class="grid sm:grid-cols-4 grid-cols-2 gap-2 w-full mb-4">
              <a class="w-full" href="candidates?ref={{ job.reference }}" target="_blank">
                <div class="manageBtn">
                  <mat-icon>people</mat-icon>
                  View
                </div>
              </a>
              <a class="w-full" [routerLink]="['/edit-listing', job.reference, job.urlSlug]">
                <div class="manageBtn">
                  <mat-icon>edit_note</mat-icon>
                  Edit
                </div>
              </a>
              <a class="w-full" (click)="pauseListing(job)">
                <div [ngClass]="job.pause_status ? 'inverseManageBtn' : 'manageBtn'">
                  <mat-icon>pause</mat-icon>
                  {{ job.pause_status ? 'Unpause' : 'Pause' }}
                </div>
              </a>
              <a class="w-full" (click)="deleteListing(job.reference)">
                <div class="manageBtn deleteBtn">
                  <mat-icon>delete</mat-icon>
                  Delete
                </div>
              </a>
            </div>
            <div class="flex w-full">
              <a class="w-full" href="job/{{ job.reference }}/{{ job.urlSlug }}" target="_blank">
                <div class="manageBtn viewBtn">
                  <mat-icon>description</mat-icon>
                  View Job
                </div>
              </a>


            </div>


          </div>
        </div>
      </ng-container>
    </ng-container>
    <ng-template #empty>
      <p>You have no current job listings.</p>
    </ng-template>
  </div>
</ng-container>

<ng-template #fullWidthLayout>
  <div class="flex flex-col">
    <ng-container *ngIf="jobListings; else empty">
      <ng-container *ngFor="let job of jobListings">
        <!--      <a href="job/{{job.reference}}/{{job.urlSlug}}" target="_blank">-->
        <div class="job-card" mat-stroked-button>
          <div class="flex flex-row items-center justify-between w-full flex-wrap lg:flex-nowrap">
            <div class="lg:w-[48%] w-full flex flex-col lg:flex-row items-center ">
              <img *ngIf="job.jobLogo" alt="job image" class="w-full h-full mr-4 mt-2" [src]="job.jobLogo"/>
              <div class=" flex flex-col lg:items-start items-center lg:text-left text-center">
                <h3 class="text-primary font-bold text-center lg:text-left">{{ job.jobTitle }}</h3>
                <p class="font-light">{{ sharedService.truncateString(job.jobBriefDesc, 15, 40) }}</p>
              </div>
            </div>
            <div class="flex-none grid grid-cols-2 gap-2 w-full lg:w-[48%]">
              <h4 class="font-bold text-center flex flex-wrap justify-center items-center text-sm sm:text-base">
                <mat-icon class="mr-3">people</mat-icon>
                {{ job.appliedCount }} Candidates
              </h4>
              <h4 class="font-bold text-center flex flex-wrap justify-center items-center text-sm sm:text-base">
                <mat-icon class="mr-3">favorite</mat-icon>
                {{ job.savedCount }} Saves
              </h4>
              <a href="candidates?ref={{ job.reference }}" target="_blank">
                <div class="manageBtn">
                  <mat-icon>people</mat-icon>
                  View Candidates
                </div>
              </a>
              <a [routerLink]="['/edit-listing', job.reference, job.urlSlug]">
                <div class="manageBtn">
                  <mat-icon>edit_note</mat-icon>
                  Edit Listing
                </div>
              </a>
              <a (click)="pauseListing(job)">
                <div [ngClass]="job.pause_status ? 'inverseManageBtn' : 'manageBtn'">
                  <mat-icon>pause</mat-icon>
                  {{ job.pause_status ? 'Unpause Listing' : 'Pause Listing' }}
                </div>
              </a>
              <a (click)="deleteListing(job.reference)">
                <div class="manageBtn deleteBtn">
                  <mat-icon>delete</mat-icon>
                  Delete
                </div>
              </a>
              <a class="col-span-2" href="job/{{ job.reference }}/{{ job.urlSlug }}" target="_blank">
                <div class="manageBtn viewBtn">
                  <mat-icon>description</mat-icon>
                  View Job
                </div>
              </a>
            </div>
          </div>


        </div>
      </ng-container>
    </ng-container>
    <ng-template #empty>
      <p>You have no current job listings.</p>
    </ng-template>
  </div>
</ng-template>
