import {Component, OnInit} from '@angular/core';
import {Accounts} from "../../../services/accounts.service";

@Component({
  selector: 'app-upsell-backpacker',
  templateUrl: './upsell-backpacker.component.html',
  styleUrls: ['./upsell-backpacker.component.scss']
})
export class UpsellBackpackerComponent implements OnInit {
  existingCustomer = false

  constructor(private accounts: Accounts) {
  }

  ngOnInit(): void {
    this.accounts.checkForExistingMembership().subscribe((response: any) => {
      if (response.status == 'success' && response.existingCustomer) {
        this.existingCustomer = true
      }
    })
  }
}
