import {Component, Input, OnInit} from '@angular/core';
import {JobsService} from "../../services/jobs.service";
import {Job} from '../../interfaces/job';
import {SharedService} from "../../services/shared.service";
import {Router} from "@angular/router";
import {MetaService} from "../../services/meta.service";

@Component({
    selector: 'app-employer-job-list',
    templateUrl: './employer-job-list.component.html',
    styleUrls: ['./employer-job-list.component.scss']
})
export class EmployerJobListComponent implements OnInit {
    public jobListings: Job[];
    @Input() smallWidth: boolean = false;
    constructor(
        private jobs: JobsService,
        public sharedService: SharedService,
        private router: Router) {
    }

    ngOnInit() {
        this.getEmployerJobListings();
    }

    getEmployerJobListings() {
        this.jobs.getEmployerJobListings().subscribe(
            (response) => {
                this.jobListings = response.data;
            });
    }

    public deleteListing(job_reference: string) {
        if (!confirm('Are you sure you want to delete this listing?\nThis action cannot be undone. ')) {
            return;
        } else {
            this.jobs.deleteJobByReference(job_reference).subscribe((result: any) => {
                this.router.navigate(['/employer-profile']);
            });
        }
    }

    public pauseListing(job) {
        let pause_status = !job.pause_status;
        this.jobs.pauseJobListing(job.id, pause_status).subscribe((result: any) => {
            job.pause_status = !job.pause_status;  // Update the pause_status locally
        });
    }

}
