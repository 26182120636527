<h2 class="text-center" mat-dialog-title>Cover Letter For {{data.jobTitle}}</h2>
<div mat-dialog-content class="flex flex-col h-full">
  <button mat-icon-button class="!absolute top-0 right-0" [mat-dialog-close]="true">
    <mat-icon>close</mat-icon>
  </button>
  <div [innerHTML]="data.coverLetter" class="flex-grow"></div>
<!--  <div class="mt-auto flex justify-center" mat-dialog-actions>-->
<!--    <button [mat-dialog-close]="true" mat-button>Close</button>-->
<!--  </div>-->
</div>
