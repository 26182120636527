import {Component, Inject, OnInit} from '@angular/core';
import {MAT_DIALOG_DATA, MatDialogRef} from "@angular/material/dialog";
import {EmployeeProfileService} from "../profile-page/employee-profile/employee-profile.service";
import {Accounts} from "../../services/accounts.service";
import {BackpackerProfile} from "../../interfaces/backpacker-profile";
import {UntypedFormBuilder, UntypedFormGroup} from "@angular/forms";
import {JobsService} from "../../services/jobs.service";

@Component({
  selector: 'app-apply-now',
  templateUrl: './apply-now.component.html',
  styleUrls: ['./apply-now.component.scss']
})
export class ApplyNowComponent implements OnInit {
  jobApplicationForm: UntypedFormGroup;

  backpackerProfile: BackpackerProfile;
  isLoadingProfileData = true;
  isTinyMCELoaded = false;
  isExperienced = false;

  public tinymceConfig = {
    height: 400,
    menubar: '',
    plugins: 'lists wordcount',
    placeholder: "Write a cover letter, this will be sent to employers when you apply for a job.",
    toolbar: 'undo redo | bullist numlist | italic underline ',
    apiKey: '52gvcmwqj90cb0ungtd1bw4anx2a37qoixz8qjnd7v8b3atl',
    branding: false,
  };

  public experienceEditorConfig = {
    height: 400,
    menubar: '',
    plugins: 'lists wordcount',
    placeholder: "Talk about how your previous experience will help in this job.",
    toolbar: 'undo redo | bullist numlist | italic underline ',
    apiKey: '52gvcmwqj90cb0ungtd1bw4anx2a37qoixz8qjnd7v8b3atl',
    branding: false,
  };

  constructor(
    private formBuilder: UntypedFormBuilder,
    public dialogRef: MatDialogRef<ApplyNowComponent>,
    @Inject(MAT_DIALOG_DATA) public data: any,
    private accounts: Accounts,
    private jobs: JobsService,
  ) {
  }

  ngOnInit() {
    this.getUserProfileData();
    this.jobApplicationForm = this.formBuilder.group({
      availDate: [''],
      coverLetter: [''],
      isExperienced: [false],
      workExperience: [''],
    });
  }

  onApplyClick(): void {
    const dataToSend = {
      ...this.jobApplicationForm.value,
      jobId: this.data.jobId,
    };

    this.jobs.applyForJob(dataToSend).subscribe(
      (response) => {
        this.dialogRef.close(response);
      });
  }


  onCancelClick(): void {
    this.dialogRef.close();
  }

  getUserProfileData() {
    this.accounts.loadUserProfile().subscribe(
      (response) => {
        this.backpackerProfile = response.profile;
        this.isLoadingProfileData = false;
        this.jobApplicationForm.patchValue({
          availDate: this.backpackerProfile.work_date,
          coverLetter: this.backpackerProfile.cover_letter,
        });
      });
  }
}
