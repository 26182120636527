import { Component } from '@angular/core';
import {UntypedFormBuilder, UntypedFormGroup, Validators} from "@angular/forms";
import {Accounts} from "../../../services/accounts.service";
import {SharedService} from "../../../services/shared.service";
import {Router} from "@angular/router";
import {EmployerProfileService} from "../../profile-page/employer-profile/employer-profile.service";

@Component({
  selector: 'app-profile-employer-info',
  templateUrl: './profile-employer-info.component.html',
  styleUrls: ['./profile-employer-info.component.scss']
})
export class ProfileEmployerInfoComponent {
  profileInfo: UntypedFormGroup;

  constructor(private formBuilder: UntypedFormBuilder, private apiService: Accounts, private sharedService: SharedService, private router: Router, private profileService: EmployerProfileService) { }

  ngOnInit() {
    this.profileInfo = this.formBuilder.group({
      company_name: ['', Validators.required],
      email: ['', Validators.email],
      company_logo: [''],
    });

    this.profileInfo.get('email').disable();
    this.getUserProfileData();
  }

  update() {
    this.apiService.updateEmployerInfo(this.profileInfo.value).subscribe((res) => {
      this.router.navigate(['/employer-profile']);
    });
  }

  getUserProfileData() {
    this.profileService.currentProfile.subscribe(profile => {
      if (profile) {
        this.profileInfo.patchValue({
          company_name: profile.company_name,
          email: profile.email,
          company_logo: profile.company_logo,
        });
      }
    });
  }
}
