import {BrowserModule} from '@angular/platform-browser';
import {CUSTOM_ELEMENTS_SCHEMA, APP_INITIALIZER, NgModule} from '@angular/core';
import {AppRoutingModule} from './app-routing.module';
import {AppComponent} from './app.component';
import {HomepageComponent} from './components/homepage/homepage.component';
import {NavigationComponent} from './components/navigation/navigation.component';
import {MatIconModule} from '@angular/material/icon';
import {BrowserAnimationsModule} from '@angular/platform-browser/animations';
import {MobileSideNavComponent} from './components/navigation/mobile-side-nav/mobile-side-nav.component';
import {MatButtonModule} from "@angular/material/button";
import {ListJobsComponent} from './components/latest-jobs/list-jobs.component';
import {JobsMainComponent} from './components/jobs-main/jobs-main.component';
import {MatFormFieldModule} from "@angular/material/form-field";
import {MatInputModule} from '@angular/material/input';
import {MatChipsModule} from "@angular/material/chips";
import {MatSelectModule} from "@angular/material/select";
import {MatDialogModule} from "@angular/material/dialog";
import {ReactiveFormsModule} from "@angular/forms";
import {EmployerLandingPageSignupComponent} from './components/employer-signup/employer-landing-page-signup.component';
import {MatCheckboxModule} from "@angular/material/checkbox";
import {HTTP_INTERCEPTORS, HttpClientModule} from "@angular/common/http";
import {MatIconRegistry} from "@angular/material/icon";
import {JobPostComponent} from './components/job-post/job-post.component';
import {MatStepperModule} from "@angular/material/stepper";
import {MatRadioModule} from "@angular/material/radio";
import {MatBadgeModule} from "@angular/material/badge";
import {EditorModule} from "@tinymce/tinymce-angular";
import {NgxDropzoneModule} from "ngx-dropzone";
import {JobViewComponent} from './components/job-view/job-view.component';
import {EmployeeSignupWindow} from './components/employee-signup/signup-modal/employee-signup-window.component';
import {
  EmployeeCreateProfileSignup
} from "./components/employee-create-profile-signup/employee-create-profile-signup.component";
import {MatDatepickerModule} from "@angular/material/datepicker";
import {MatNativeDateModule} from '@angular/material/core';
import {MAT_DATE_LOCALE} from '@angular/material/core';
import {MatAutocompleteModule} from '@angular/material/autocomplete';
import {LoginPageComponent} from './components/login-page/login-page.component';
import {EmployerSignupPageComponent} from './components/employer-signup/signup-page/employer-signup-page.component';

import {EmployeeProfilePageComponent} from './components/profile-page/employee-profile/employee-profile-page.component';
import {EmployerProfilePageComponent} from "./components/profile-page/employer-profile/employer-profile-page.component";

import {FontAwesomeModule} from "@fortawesome/angular-fontawesome";
import {ProfileBasicComponent} from './components/forms/profile-basic/profile-basic.component';
import {ProfileAdditionalComponent} from './components/forms/profile-additional/profile-additional.component';
import {ProfileDocumentsComponent} from "./components/forms/profile-documents/profile-documents.component";
import {BlogComponent} from './components/blog/blog-list/blog.component';
import {ArticleViewComponent} from "./components/blog/article-view/article-view.component";
import {EmployerJobListComponent} from './components/employer-job-list/employer-job-list.component';
import {JobApplicationsComponent} from './components/job-applications/job-applications.component';
import {CandidatesComponent} from './components/candidates/candidates.component';
import {JobListingsComponent} from './components/job-listings/job-listings.component';
import {CandidatesProfileComponent} from './components/candidates/candidates-profile/candidates-profile.component';
import {MatProgressBarModule} from "@angular/material/progress-bar";
import {MatTooltipModule} from "@angular/material/tooltip";
import {NgxSkeletonLoaderModule} from "ngx-skeleton-loader";
import {ProfileEmployerInfoComponent} from './components/forms/profile-employer-info/profile-employer-info.component';
import {ApplyNowComponent} from './components/apply-now/apply-now.component';
import {ViewCoverLetterDialogComponent} from './components/view-cover-letter-dialog/view-cover-letter-dialog.component';
import {Settings} from './globals/settings.model';
import {SavedJobsComponent} from './components/saved-jobs/saved-jobs.component';
import {ErrorDialogComponent} from './components/error-dialog/error-dialog.component';
import {ContactNowComponent} from "./components/candidates/candidates-profile/hire-now/contact-now.component";
import {FooterComponent} from './components/footer/footer.component';
import {ForgotPasswordPageComponent} from "./components/forgot-password-page/forgot-password-page.component";
import {DatePipe} from '@angular/common';
import {ResetPasswordComponent} from './components/forgot-password-page/reset-password/reset-password.component';
import {SafeHtmlPipe} from './globals/safe-html.pipe';
import {MatCardModule} from "@angular/material/card";
import {MatTabsModule} from "@angular/material/tabs";
import {CandidatesListComponent} from './components/candidates/candidates-list/candidates-list.component';
import {BackpackerAuthGuard} from "./guards/backpacker-auth.guard";
import {EmployerAuthGuard} from "./guards/employer-auth.guard";
import {VerificationMessageComponent} from './components/verification-message/verification-message.component';
import {UniversalInterceptor} from "./interceptors/UniversalInterceptor";
import {TermsConditionsComponent} from './components/terms-conditions/terms-conditions.component';
import {VerifyEmailComponent} from './components/verify-email/verify-email.component';
import {SnackbarComponent} from './components/snackbar/snackbar.component';
import {MatSnackBarModule} from "@angular/material/snack-bar";
import {BreadcrumbModule} from "xng-breadcrumb";
import {BreadcrumbService} from 'xng-breadcrumb';
import {BackpackerSearchComponent} from './components/search-backpackers/backpacker-search.component';
import {
  BackpackerProfileComponent
} from './components/search-backpackers/backpacker-profile/backpacker-profile.component';
import {MatListModule} from "@angular/material/list";
import {
  ContactBackpackerComponent
} from './components/search-backpackers/backpacker-profile/contact-backpacker/contact-backpacker.component';
import {UpsellBackpackerComponent} from './components/reusables/upsell-backpacker/upsell-backpacker.component';
import {
  UpgradeAccountBackpackerComponent
} from './components/upgrade-account-backpacker/upgrade-account-backpacker.component';
import {MatMenuModule} from "@angular/material/menu";
import {
  PublicProfileWorkFieldsComponent
} from './components/reusables/public-profile-work-fields/public-profile-work-fields.component';
import {register} from 'swiper/element/bundle';
import {MatSlideToggleModule} from "@angular/material/slide-toggle";
import {LoadingScreenComponent} from './components/reusables/loading-screen/loading-screen.component';
import {CancelConfirmationComponent} from './components/cancel-confirmation/cancel-confirmation.component';
import {UpdateConfirmationComponent} from './components/update-confirmation/update-confirmation.component';
import {SafeHtml} from "./pipes/safeHTML.pipe";
import {Unicode} from "./pipes/unicode.pipe";
import {StripHtmlPipe} from "./pipes/strip-html.pipe";
import {MatStepperAutoScrollDirective} from "./directives/mat-stepper-auto-scroll.directive";
import { LeaveReviewComponent } from './components/reusables/leave-review/leave-review.component';

register()

@NgModule({
  declarations: [
    AppComponent,
    NavigationComponent,
    HomepageComponent,
    MobileSideNavComponent,
    ListJobsComponent,
    JobsMainComponent,
    EmployerLandingPageSignupComponent,
    EmployeeSignupWindow,
    JobPostComponent,
    JobViewComponent,
    EmployeeCreateProfileSignup,
    LoginPageComponent,
    EmployerSignupPageComponent,
    EmployeeProfilePageComponent,
    ProfileBasicComponent,
    ProfileAdditionalComponent,
    ProfileDocumentsComponent,
    EmployerProfilePageComponent,
    BlogComponent,
    ArticleViewComponent,
    EmployerJobListComponent,
    JobApplicationsComponent,
    CandidatesComponent,
    JobListingsComponent,
    CandidatesProfileComponent,
    ProfileEmployerInfoComponent,
    ApplyNowComponent,
    ViewCoverLetterDialogComponent,
    SavedJobsComponent,
    ErrorDialogComponent,
    ContactNowComponent,
    FooterComponent,
    ForgotPasswordPageComponent,
    ResetPasswordComponent,
    SafeHtmlPipe,
    CandidatesListComponent,
    VerificationMessageComponent,
    TermsConditionsComponent,
    VerifyEmailComponent,
    SnackbarComponent,
    BackpackerSearchComponent,
    BackpackerProfileComponent,
    ContactBackpackerComponent,
    UpsellBackpackerComponent,
    UpgradeAccountBackpackerComponent,
    PublicProfileWorkFieldsComponent,
    LoadingScreenComponent,
    CancelConfirmationComponent,
    UpdateConfirmationComponent,
    SafeHtml,
    Unicode,
    StripHtmlPipe,
    MatStepperAutoScrollDirective,
    LeaveReviewComponent
  ],
  imports: [
    BrowserModule.withServerTransition({appId: 'serverApp'}),
    AppRoutingModule,
    MatIconModule,
    BrowserAnimationsModule,
    MatButtonModule,
    MatFormFieldModule,
    MatInputModule,
    MatChipsModule,
    MatSelectModule,
    ReactiveFormsModule,
    MatDialogModule,
    MatCheckboxModule,
    HttpClientModule,
    MatStepperModule,
    MatRadioModule,
    EditorModule,
    NgxDropzoneModule,
    MatDatepickerModule,
    MatNativeDateModule,
    MatAutocompleteModule,
    FontAwesomeModule,
    MatProgressBarModule,
    MatTooltipModule,
    NgxSkeletonLoaderModule,
    MatCardModule,
    MatTabsModule,
    MatBadgeModule,
    MatSnackBarModule,
    BreadcrumbModule,
    MatListModule,
    MatMenuModule,
    MatSlideToggleModule
  ],
  providers: [BackpackerAuthGuard, EmployerAuthGuard, DatePipe, MatIconRegistry, BreadcrumbService, StripHtmlPipe, SafeHtml, Unicode, {
    provide: MAT_DATE_LOCALE,
    useValue: 'en-GB'
  }, Settings,
    {
      provide: HTTP_INTERCEPTORS,
      useClass: UniversalInterceptor,
      multi: true
    }
  ],
  bootstrap: [AppComponent],
  schemas: [CUSTOM_ELEMENTS_SCHEMA]

})
export class AppModule {
}
