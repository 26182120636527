import {ChangeDetectorRef, Component, HostListener, Inject, OnInit, PLATFORM_ID} from '@angular/core';
import {loginNavItems, navItems} from "./navigation-items/navigation-items";
import {Router} from "@angular/router";
import {SharedService} from "../../services/shared.service";
import {Accounts} from "../../services/accounts.service";
import {isPlatformBrowser} from "@angular/common";

@Component({
  selector: 'app-navigation',
  templateUrl: './navigation.component.html',
  styleUrls: ['./navigation.component.scss']
})
export class NavigationComponent implements OnInit {
  public isMobile = false;
  public navItems = navItems;
  public isMobNavToggled = false;
  public isDropdownVisible = false;

  constructor(
    @Inject(PLATFORM_ID) private platformId: any,
    private cdr: ChangeDetectorRef,
    public router: Router,
    public sharedService: SharedService,
    private accounts: Accounts) {
  }


  ngOnInit(): void {
    if (isPlatformBrowser(this.platformId)) {
      this.isMobile = window.innerWidth < 1000;
      this.cdr.detectChanges();
    }
  }

  toggleMobNav() {
    if (isPlatformBrowser(this.platformId)) {
      const navIcon = document.getElementById('navigation-icon');
      navIcon.classList.toggle('open');
      this.isMobNavToggled = !this.isMobNavToggled;
    }
  }


  @HostListener('window:resize', ['$event'])
  onResize(event) {
    if (isPlatformBrowser(this.platformId)) {
      this.isMobile = event.target.innerWidth < 1000;
      this.cdr.detectChanges();
    }
  }

  toggleDropdown() {
    this.isDropdownVisible = !this.isDropdownVisible;
  }

  logout() {
    this.accounts.logout().subscribe((result: any) => {
    });
  }

  protected readonly loginNavItems = loginNavItems;


}
