<h1 class="text-center !text-xl" mat-dialog-title>Contact {{ data.candidateData.full_name }} </h1>
<div mat-dialog-content>
  <a class="relative flex flex-col w-full appliedJobContainer" href="job/{{data.jobRef}}/{{data.jobData.url_slug}}"
     target="_blank">
    <div class="bg-white px-2 absolute -top-3 text-xs hiringFor">Hiring For</div>
    <div class="flex">
      <img *ngIf="data.jobData.job_logo" class="w-12 h-12" src="{{data.jobData.job_logo}}">
      <div class="ml-4 flex flex-col">
        <h3>{{ data.jobData.job_title }}</h3>
        <p>{{ data.jobData.company_display_name }} - {{ data.jobData.job_type }}</p>
      </div>
    </div>
  </a>

  <div class="flex flex-col">
    <a class="" href="tel:{{data.candidateData.contact_number}}">
      <button color="primary" class="w-full my-2 lightBtn generalBtn" mat-flat-button>
        <mat-icon class="mr-3">phone</mat-icon>
        {{ data.candidateData.contact_number }}
      </button>
      <div class="my-2 flex items-center w-full">
        <div class="flex-grow border-t border-gray-300"></div>
        <span class="px-4 text-sm text-gray-600">OR</span>
        <div class="flex-grow border-t border-gray-300"></div>
      </div>

    </a>
    <a class="" href="mailto:{{data.candidateData.email}}">
      <button color="primary" class="w-full  my-2 flex-start lightBtn generalBtn" mat-flat-button>
        <mat-icon class="mr-3">mail</mat-icon>
        {{ data.candidateData.email }}
      </button>
    </a>
  </div>
  <p class="font-semibold text-center">If you intend to hire this candidate, don't forget to <span class="underline">mark them as
    approved</span>. They will then be automatically categorized for you.</p>
</div>

<div class="!justify-center" mat-dialog-actions>
  <button [mat-dialog-close]="true" class="mr-auto" mat-button>Close</button>
  <button (click)="markAsApproved()" class="apply-button !h-8 text-base" color="primary" mat-raised-button>Approved
    Candidate
  </button>
</div>
