import {Component, Inject, OnInit, PLATFORM_ID} from '@angular/core';
import {faPen} from '@fortawesome/free-solid-svg-icons';
import {Router} from "@angular/router";
import {Accounts} from "../../../services/accounts.service";
import {EmployerProfile} from "../../../interfaces/employer-profile";
import {EmployerProfileService} from "./employer-profile.service";
import {UploadFileService} from "../../../services/upload-file.service";
import {MetaService} from "../../../services/meta.service";
import {SharedService} from "../../../services/shared.service";
import {isPlatformBrowser} from "@angular/common";

@Component({
  selector: 'app-profile-page',
  templateUrl: './employer-profile-page.component.html',
  styleUrls: ['./employer-profile-page.component.scss', "../employee-profile/employee-profile-page.component.scss"]
})
export class EmployerProfilePageComponent implements OnInit {
  public metaTitle: string = 'My Employer Profile | Backpacker Jobs Now - Australia\'s Official Backpacker Job Board';
  public metaDescription: string = 'Manage job listings, update account settings, and track applications. Take control of your hiring process and find the best backpacker talent in Australia with Backpacker Jobs Now.';
  public metaKeywords: string = 'employer profile,manage,job listings,update,account settings,track,applications,hiring process,best,backpacker talent,Australia,Backpacker Jobs Now';

  employerProfile: EmployerProfile;
  isEditingProfilePicture = false;
  profilePictureUploaded = false;
  profilePictureFile: File | null = null;
  isLoadingProfile = true;
  faPen = faPen;
  isMobile = false;

  constructor(
    @Inject(PLATFORM_ID) private platformId: any,
    public shared: SharedService,
    private meta: MetaService,
    private router: Router,
    private accounts: Accounts,
    private profileService: EmployerProfileService,
    private uploadService: UploadFileService) {
  }

  ngOnInit() {
    if (isPlatformBrowser(this.platformId)) {
      if (window.innerWidth < 768) {
        this.isMobile = true;
      }
    }

    this.meta.setupPageTitle(this.metaTitle);
    this.meta.setupMetaTags(
      this.metaTitle,
      this.metaDescription,
      this.metaKeywords,

      this.router.url
    );
    this.getUserProfileData();
  }

  openEditor(editorPath: any) {
    this.router.navigate(['profile-editor', editorPath]);
  }

  getUserProfileData() {
    this.accounts.loadUserProfile().subscribe(
      (response) => {
        this.employerProfile = response.profile;
        this.profileService.changeProfile(this.employerProfile);
        this.isLoadingProfile = false;
      });
  }


  editProfilePicture() {
    this.isEditingProfilePicture = true;
  }

  onSelectProfilePicture(event) {
    this.profilePictureFile = event.addedFiles[0]; // Only accept the first file
    this.profilePictureUploaded = true;
  }

  closeUploader() {
    this.profilePictureFile = null;
    this.isEditingProfilePicture = false;
    this.profilePictureUploaded = false;
  }

  onRemove(event) {
    this.profilePictureFile = null;
  }

  async uploadProfilePicture() {
    if (!this.profilePictureFile) {
      return Promise.resolve([]);
    }

    const oldProfilePicture = this.employerProfile?.company_logo;

    let filePath = 'profile-pictures/employers/' + this.employerProfile?.company_name + Math.random() * 10000000000 + '_' + this.profilePictureFile.name;

    this.uploadService.uploadFile(this.profilePictureFile, filePath).subscribe(
      (response) => {
        if (response.status == "success" && response['fileURL']) {
          let S3Url = response['fileURL'];
          this.uploadService.updateEmployerLogo(S3Url).subscribe(
            (response: any) => {
              if (response.status == "success") {
                this.employerProfile.company_logo = S3Url;
                this.profileService.changeProfile(this.employerProfile);

                if (S3Url && oldProfilePicture) {
                  try {
                    this.uploadService.deleteOverwrittenFile(oldProfilePicture).subscribe();
                  } catch (error) {
                    console.error("An error occurred during deletion:", error);
                  }
                }

                this.closeUploader();
              }
            });
        }
      });


  }


}
