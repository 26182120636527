<section class="footerSection ">
  <div class="navigation-container-space">
    <div class="main-footer">
      <div class="flex flex-col sm:flex-row flex-wrap sm:justify-between ">
        <div class="linksSection items-center sm:items-start sm:mr-20 ">
          <h3>Useful Links</h3>
          <a class="text-link" href="/">Home</a>
          <a class="text-link" href="/search-jobs">Find a Job</a>
          <a class="text-link" href="/login">Sign In</a>
          <a class="text-link" href="/terms-and-conditions">Terms and Conditions</a>
          <!--    <p>How it Works</p>-->
          <a class="text-link" href="/backpacker-information">Backpacker Information</a>
        </div>

        <div class="linksSection items-center sm:items-start sm:mr-20">
          <h3>Backpackers</h3>
          <a class="text-link" href="/jobs">Search Jobs</a>
          <a class="text-link" href="/employee-signup">Sign Up</a>
          <a class="text-link" href="/upgrade-account">List Your Profile</a>
        </div>


        <div class="linksSection items-center sm:items-start sm:mr-20">
          <h3>Employers</h3>
          <a class="text-link" href="/search-backpackers">Search Backpackers</a>
          <a class="text-link" href="/post-job">Post Job</a>
          <a class="text-link" href="/employer-signup">Sign Up</a>
        </div>

        <div class="copyrightInfo">
          <div class="websiteLogo">
            BackpackerJobs
            <div>now</div>
          </div>
          <p class="text-xs mt-4">
            <mat-icon>copyright</mat-icon>
            Copyright {{ currentDate }} BackpackerJobsNow | All Rights Reserved
          </p>
        </div>
      </div>

      <div class="flex mt-4">
        <p class="w-full text-center sm:w-3/4 text-sm sm:text-left ">Welcome to Backpacker Jobs Now, Australia's trusted
          backpacker job board designed specifically
          for working holiday makers and backpackers. Our platform offers a massive range of job opportunities,
          including farm
          work, rural work, and positions that help fulfill your 88 days requirement for the Working Holiday Visa (WHV).
          We connect backpackers with verified employers across Australia, ensuring trusted, fair and
          rewarding employment experiences. Whether you're seeking fruit picking jobs, farm work, or rural employment,
          our user-friendly platform makes it easy to find and apply for positions that suit your needs. Built by
          backpackers for backpackers, our mission is to simplify your job search, providing a hassle-free and efficient
          way to secure employment during your Australian adventure.</p>
      </div>
    </div>

    <div class="jobCategories">
      <div class="mt-10">
        <h4>Find Backpacking Jobs By Location</h4>
        <div class="flex flex-row flex-wrap mt-3">

          <a class="text-link jobCategory" href="/search-jobs/queensland"><h4> Backpacker Jobs in Queensland</h4></a>
          <a class="text-link jobCategory" href="/search-jobs/new-south-wales"><h4> Backpacker Jobs in New South
            Wales</h4></a>
          <a class="text-link jobCategory" href="/search-jobs/victoria"><h4> Backpacker Jobs in Victoria</h4></a>
          <a class="text-link jobCategory" href="/search-jobs/south-australia"><h4> Backpacker Jobs in South
            Australia</h4></a>
          <a class="text-link jobCategory" href="/search-jobs/western-australia"><h4> Backpacker Jobs in Western
            Australia</h4>
          </a>
          <a class="text-link jobCategory" href="/search-jobs/tasmania"><h4> Backpacker Jobs in Tasmania</h4></a>
          <a class="text-link jobCategory" href="/search-jobs/northern-territory"><h4> Backpacker Jobs in Northern
            Territory</h4>
          </a>
          <a class="text-link jobCategory" href="/search-jobs/australian-capital-territory"><h4> Backpacker Jobs in
            Australian
            Capital
            Territory</h4></a>
        </div>
      </div>

      <div class="mt-10">
        <h4>Find Backpacker Jobs By Category</h4>
        <div class="flex flex-row flex-wrap mt-3">

          <a class="text-link jobCategory" href="/search-jobs/fruit-picking-jobs"><h4>Fruit Picking Jobs</h4></a>
          <a class="text-link jobCategory" href="/search-jobs/agriculture-jobs"><h4>Agricultural Work</h4></a>
          <a class="text-link jobCategory" href="/search-jobs/energy-jobs"><h4>Solar Work</h4></a>
          <a class="text-link jobCategory" href="/search-jobs/au-pair-jobs"><h4>Au Pair Jobs</h4></a>
          <a class="text-link jobCategory" href="/search-jobs/hospitality-jobs"><h4>Hospitality Work</h4></a>
          <a class="text-link jobCategory" href="/search-jobs/disaster-relief-jobs"><h4>Disaster Relief</h4></a>
          <a class="text-link jobCategory" href="/search-jobs/education-teaching-jobs"><h4>Teaching Jobs</h4></a>
          <a class="text-link jobCategory" href="/search-jobs/cleaning-jobs"><h4>Cleaning Jobs</h4></a>
          <a class="text-link jobCategory" href="/search-jobs/technology-jobs"><h4>Technology/IT Jobs</h4></a>
          <a class="text-link jobCategory" href="/search-jobs/retail-sales-jobs"><h4>Retail Jobs</h4></a>
          <a class="text-link jobCategory" href="/search-jobs/travel-tourism-jobs"><h4>Travel & Tourism Jobs</h4></a>
          <a class="text-link jobCategory" href="/search-jobs/landscaping-jobs"><h4>Landscaping Jobs</h4></a>
          <a class="text-link jobCategory" href="/search-jobs/construction-jobs"><h4>Construction Jobs</h4></a>
          <a class="text-link jobCategory" href="/search-jobs/mining-jobs"><h4>Mining Jobs</h4></a>
          <a class="text-link jobCategory" href="/search-jobs/trade-jobs"><h4>Tradie Jobs</h4></a>

        </div>
      </div>

      <div class="mt-10">
        <h4>Backpackers For Hire By Location</h4>
        <div class="flex flex-row flex-wrap mt-3">
          <a class="text-link jobCategory" href="/search-backpackers/australian-capital-territory"><h4>Hire Backpackers
            in Australian Capital Territory</h4></a>
          <a class="text-link jobCategory" href="/search-backpackers/new-south-wales"><h4>Hire Backpackers in New South
            Wales</h4></a>
          <a class="text-link jobCategory" href="/search-backpackers/northern-territory"><h4>Hire Backpackers in
            Northern Territory</h4></a>
          <a class="text-link jobCategory" href="/search-backpackers/queensland"><h4>Hire Backpackers in Queensland</h4>
          </a>
          <a class="text-link jobCategory" href="/search-backpackers/south-australia"><h4>Hire Backpackers in South
            Australia</h4></a>
          <a class="text-link jobCategory" href="/search-backpackers/tasmania"><h4>Hire Backpackers in Tasmania</h4></a>
          <a class="text-link jobCategory" href="/search-backpackers/victoria"><h4>Hire Backpackers in Victoria</h4></a>
          <a class="text-link jobCategory" href="/search-backpackers/western-australia"><h4>Hire Backpackers in Western
            Australia</h4></a>

        </div>
      </div>


      <div class="mt-10">
        <h4>Backpackers For Hire By Category</h4>
        <div class="flex flex-row flex-wrap mt-3">

          <a class="text-link jobCategory" href="/search-backpackers/agriculture-jobs"><h4>Hire Backpackers for Farm
            Jobs</h4></a>
          <a class="text-link jobCategory" href="/search-backpackers/au-pair-jobs"><h4>Hire Backpackers for Au Pair
            Roles</h4></a>
          <a class="text-link jobCategory" href="/search-backpackers/construction-jobs"><h4>Hire Backpackers for
            Construction Work</h4></a>
          <a class="text-link jobCategory" href="/search-backpackers/conservation-jobs"><h4>Hire Backpackers for
            Environmental & Conservation Roles</h4></a>
          <a class="text-link jobCategory" href="/search-backpackers/disaster-relief-jobs"><h4>Hire Backpackers for
            Humanitarian Aid</h4></a>
          <a class="text-link jobCategory" href="/search-backpackers/education-teaching-jobs"><h4>Hire Backpackers for
            Teaching Jobs</h4></a>
          <a class="text-link jobCategory" href="/search-backpackers/energy-jobs"><h4>Hire Backpackers for Energy Sector
            Jobs</h4></a>
          <a class="text-link jobCategory" href="/search-backpackers/events-jobs"><h4>Hire Backpackers for Events &
            Promotion Roles</h4></a>
          <a class="text-link jobCategory" href="/search-backpackers/fishing-jobs"><h4>Hire Backpackers for Fishing
            Jobs</h4></a>
          <a class="text-link jobCategory" href="/search-backpackers/hospitality-jobs"><h4>Hire Backpackers for
            Hospitality Jobs</h4></a>
          <a class="text-link jobCategory" href="/search-backpackers/fruit-picking-jobs"><h4>Hire Backpackers for Fruit
            Picking & Packing</h4></a>
          <a class="text-link jobCategory" href="/search-backpackers/housekeeping-cleaning-jobs"><h4>Hire Backpackers
            for Cleaning Jobs</h4></a>
          <a class="text-link jobCategory" href="/search-backpackers/technology-jobs"><h4>Hire Backpackers for Tech
            Jobs</h4></a>
          <a class="text-link jobCategory" href="/search-backpackers/landscaping-jobs"><h4>Hire Backpackers for
            Gardening Jobs</h4></a>
          <a class="text-link jobCategory" href="/search-backpackers/mining-jobs"><h4>Hire Backpackers for Mining
            Jobs</h4></a>
          <a class="text-link jobCategory" href="/search-backpackers/photography-videography-jobs"><h4>Hire Backpackers
            for Photography</h4></a>
          <a class="text-link jobCategory" href="/search-backpackers/retail-sales-jobs"><h4>Hire Backpackers for Retail
            Positions</h4></a>
          <a class="text-link jobCategory" href="/search-backpackers/sports-fitness-jobs"><h4>Hire Backpackers for
            Fitness & Sports Jobs</h4></a>
          <a class="text-link jobCategory" href="/search-backpackers/trade-jobs"><h4>Hire Backpackers for Tradie
            Work</h4></a>
          <a class="text-link jobCategory" href="/search-backpackers/transport-logistics-jobs"><h4>Hire Backpackers for
            Transport & Logistics</h4></a>
          <a class="text-link jobCategory" href="/search-backpackers/travel-tourism-jobs"><h4>Hire Backpackers for
            Travel & Tourism</h4></a>
          <a class="text-link jobCategory" href="/search-backpackers/volunteering-jobs"><h4>Hire Backpackers for
            Volunteering</h4></a>

        </div>
      </div>
    </div>

  </div>

</section>
