<section class="container-space termsSection">
  <div class="mt-16">
    <h1 class="text-center">Terms and Conditions</h1>
    <h2>Welcome to Backpacker Jobs Now</h2>
    <p>
      Thank you for choosing to be part of our community at Backpacker Jobs Now ("we", "us", "our"). We are committed to
      protecting your personal information and your right to privacy. If you have any questions or concerns about these
      terms, or our practices with regards to your personal information, please contact us at
      enquiries@backpackerjobsnow.com.
    </p>

    <h2>Agreement to Terms</h2>
    <p>
      By accessing or using our website and services, you agree to comply with and be bound by these Terms and
      Conditions
      ("Terms").
    </p>

    <h2>Accounts and Features</h2>

    <h3>General</h3>

    <p>If you create an account on Backpacker Jobs Now, either through email or third-party sign-up, you affirm that the
      information you provide is accurate and authorized. You are solely responsible for all activities related to your
      account, including job postings, applications, and purchases. You are also responsible for maintaining the
      confidentiality of your account; please notify us immediately of any unauthorized or suspicious activity. We
      reserve
      the right to terminate or suspend your account or remove your content for any violation of these Terms or
      applicable
      laws.</p>


    <h3>Job Seekers</h3>
    <p>As a Job Seeker, you may apply for jobs listed on Backpacker Jobs Now. You will be required to provide your name,
      resume, and other relevant information, which will be shared with the respective Employers. Please ensure that all
      information you provide is accurate and complete. Note that once you submit an application, you won't be able to
      view
      or manage it afterward.
    </p>

    <h3>Employers</h3>
    <p>Employers can list job postings on Backpacker Jobs Now. All job postings must comply with these Terms and all
      applicable laws. Job postings may be reviewed and/or removed for violations of these Terms.
      Employers agree to use Job Seeker information solely for the purpose of filling job vacancies and
      to comply with all data privacy and security laws.
    </p>

    <h3>Job Posts & Listings</h3>
    <p>Backpacker Jobs Now allows you to create, use, and publicise "job posts" or "listings". You are
      solely responsible for all activities related to your job posts, including any actions taken as a listing owner or
      collaborator.
    </p>

    <h2>Prohibited Activities</h2>
    <h3>General</h3>
    <ol>
      <li>Copy, distribute, or disclose any part of the service.</li>
      <li>Use automated systems like "robots" or "scrapers" to access the service in a way that overloads our servers.
      </li>
      <li>Send spam or unsolicited emails.</li>
      <li>Interfere with the system's integrity or security.</li>
      <li>Upload harmful data like viruses.</li>
      <li>Harvest personal information from the service.</li>
      <li>Use the service for unauthorized commercial purposes.</li>
      <li>Impersonate another person or misrepresent your affiliation.</li>
      <li>Interfere with the proper functioning of the service.</li>
      <li>Bypass any measures to restrict access to the service.</li>
      <li>Create or use an account or content without proper authorization.</li>
      <li>Provide false or misleading information.</li>
      <li>Violate these Terms or any applicable laws.</li>
      <li>Use the service for any purpose that infringes third-party rights or constitutes fraud or harassment.</li>
      <li>Monitor the service for competitive commercial purposes.</li>
    </ol>

    <h3>Job Seekers</h3>
    <p>Job Seekers must submit applications using accurate information and comply with these Terms and all applicable
      laws.
      By applying for a job, you consent to your application being shared with interested third parties.</p>

    <h3>Employers</h3>
    <p>Employers must comply with all requirements for job postings as outlined in these Terms and all applicable laws.
      We
      reserve the right to remove any job ad that violates these terms or any applicable legislation.</p>


    <h3>External Links</h3>
    <p>You may include links to your official website and social profiles within your employer/company profile. All
      other
      external links require our prior consent</p>


    <h3>Job Ads</h3>
    <p>We will not approve job ads if they are duplicates, irrelevant to backpackers, not for a
      genuine vacancy, vague, inappropriate, sexual or explicit, promoting illegal activity or require Job Seekers to
      pay
      a
      fee. Employers may only post job ads that are
      current and for which they are actively recruiting. You may not include external application services in your job
      ad,
      this includes promoting email applications or applying to an external website. We reserve the unilateral right to
      remove or discontinue any job listings that do not align with our terms, guidelines, or any applicable laws, at
      our
      sole discretion and without prior notice.</p>



    <h4>Examples of prohibited Job Ads include:</h4>
    <ul>
      <li>Listings that do not represent a current, genuine job opening;</li>
      <li>Listings that violate any labor, employment, anti-discrimination, equal opportunity, data privacy, or
        intellectual property laws;
      </li>
      <li>Opportunities requiring upfront payment for consideration;</li>
      <li>Multi-level marketing schemes or pyramid structures;</li>
      <li>Advertisements unrelated to the job posting;</li>
      <li>Content that infringes upon rights or that you are not authorized to share;</li>
      <li>Requirements for individuals to disclose sensitive personal information;</li>
      <li>Listings targeting or collecting information from minors;</li>
      <li>Opportunities in countries subject to economic sanctions by the U.S.;</li>
      <li>Content or links containing false, misleading, or unlawful information;</li>
      <li>Content that is defamatory, threatening, exploitative, obscene, or objectionable;</li>
      <li>Duplicate job ads</li>
      <li>Job ads that are not for a genuine vacancy;</li>
      <li>Job ads with misleading or non-descriptive titles;</li>
      <li>Job ads related to the sex industry or containing explicit content;</li>
      <li>Spam job ads;</li>
      <li>Job ads requiring Job Seekers to pay a fee;</li>
      <li>Job ads from competitor job boards.</li>
    </ul>

    <h2>Termination</h2>
    <p>We reserve the right to terminate or suspend your access to Backpacker Jobs Now immediately, without prior notice
      or liability, at our sole discretion, for any reason, including but not limited to a breach of these Terms or
      violation of any applicable law. We may also remove any content, including job postings or applications, for
      failure
      to comply with these Terms or for any fraudulent or abusive activities.</p>

    <p>You may terminate your use of Backpacker Jobs Now at any time by contacting us on enquiries@backpackerjobsnow.com. However,
      any outstanding fees owed will still be due upon termination.</p>

    <p>Provisions of these Terms that by their nature should survive termination will continue in effect, including but
      not limited to dispute resolution and arbitration terms, ownership and intellectual property terms (except we may
      terminate your license to access and use Backpacker Jobs Now), warranty disclaimers, indemnity clauses,
      miscellaneous terms, and limitations of liability.</p>

    <h2>Billing & Refunds </h2>
    <p>Employers posting a job on Backpacker Jobs Now agree to pay the entire amount due at checkout, along with any
      applicable taxes (minus any deductions for coupons, which have no cash value). In the event of a conflict between
      any specific billing or other job posting terms at checkout and these Terms, the terms at checkout will prevail.
      Payments are processed through Stripe, and by proceeding with the payment, you consent to your payment method
      being charged for all outstanding amounts.</p>

    <p>You agree to provide accurate and complete billing and payment information and represent that you are authorized
      to use the payment method for your purchase. You also agree that Backpacker Jobs Now is entitled to interest at
      the highest rate permitted by law, in addition to fees and costs incurred in collecting any overdue payments.</p>

    <p>Refunds are not provided, except in exceptional cases at the sole discretion of Backpacker Jobs Now. There will
      be no refunds under any other
      circumstances, including if Employers close job postings early or if your account or posting is terminated for
      cause.</p>

    <p>You further acknowledge that your purchase may be subject to currency or exchange fees, depending on your
      location.</p>


    <h2>Limitation of Liability</h2>
    <p>IN NO EVENT SHALL BACKPACKER JOBS NOW, NOR ITS MANAGERS, EMPLOYEES, PARTNERS, AGENTS, SUPPLIERS, LICENSORS, OR
      AFFILIATES, BE LIABLE FOR ANY INDIRECT, INCIDENTAL, SPECIAL, CONSEQUENTIAL, OR PUNITIVE DAMAGES, INCLUDING WITHOUT
      LIMITATION, LOSS OF PROFITS, DATA, USE, GOODWILL, OR OTHER INTANGIBLE LOSSES, RESULTING FROM (I) YOUR ACCESS TO OR
      USE OF OR INABILITY TO ACCESS OR USE, OR JOB OR HIRING-RELATED RESULTS FROM, THE SERVICE, INCLUDING ANY ACCOUNT,
      CONTENT, JOB SEEKER APPLICATION, EMPLOYER JOB POST, OR LISTINGS; (II) ANY JOB POSTING OR JOB THAT YOU MAY POST OR
      OBTAIN IN CONNECTION WITH THE SERVICE; (III) ANY CONDUCT OR CONTENT OF ANY THIRD PARTY, INCLUDING JOB SEEKERS AND
      EMPLOYERS, ON THE SERVICE; (IV) ANY CONTENT OBTAINED FROM THE SERVICE RELATING TO A THIRD-PARTY JOB POSTING; AND
      (V) UNAUTHORIZED ACCESS TO, USE OF, OR ALTERATION OF YOUR TRANSMISSIONS OR CONTENT, WHETHER BASED ON WARRANTY,
      CONTRACT, TORT (INCLUDING NEGLIGENCE), OR ANY OTHER LEGAL THEORY, WHETHER OR NOT WE HAVE BEEN INFORMED OF THE
      POSSIBILITY OF SUCH DAMAGE, AND EVEN IF A REMEDY SET FORTH HEREIN IS FOUND TO HAVE FAILED ITS ESSENTIAL
      PURPOSE.</p>

    <p>BACKPACKER JOBS NOW SHALL HAVE NO LIABILITY FOR RESPONSES TO JOB POSTINGS OR APPLICATIONS, INCLUDING THE QUALITY
      OR NUMBER OF APPLICANTS, OFFERS, VIEWS, CLICKS, OR THE ORDER IN WHICH YOUR POSTING OR APPLICATION IS DISPLAYED OR
      DISTRIBUTED.</p>

    <p>TO THE MAXIMUM EXTENT PERMITTED BY APPLICABLE LAW, BACKPACKER JOBS NOW ASSUMES NO LIABILITY OR RESPONSIBILITY FOR
      (I) ANY ERRORS, MISTAKES, OR INACCURACIES OF CONTENT; (II) ANY PERSONAL INJURY OR PROPERTY DAMAGE, OF ANY NATURE
      WHATSOEVER, RESULTING FROM YOUR ACCESS TO OR USE OF OUR SERVICE; (III) ANY UNAUTHORIZED ACCESS TO OR USE OF OUR
      SERVERS OR YOUR ACCOUNT AND/OR ANY AND ALL PERSONAL INFORMATION STORED THEREIN; (IV) ANY INTERRUPTION OR CESSATION
      OF TRANSMISSION TO OR FROM THE SERVICE; (V) ANY BUGS, VIRUSES, TROJAN HORSES, OR THE LIKE THAT MAY BE TRANSMITTED
      TO OR THROUGH OUR SERVICE BY ANY THIRD PARTY; (VI) ANY ERRORS OR OMISSIONS IN ANY CONTENT OR FOR ANY LOSS OR
      DAMAGE INCURRED AS A RESULT OF THE USE OF ANY CONTENT POSTED, EMAILED, TRANSMITTED, OR OTHERWISE MADE AVAILABLE
      THROUGH THE SERVICE; AND/OR (VII) ANY USER OR OTHER THIRD-PARTY CONTENT OR THE DEFAMATORY, OFFENSIVE, OR ILLEGAL
      CONDUCT OF ANY USER OR THIRD PARTY. IN NO EVENT SHALL BACKPACKER JOBS NOW, ITS MANAGERS, EMPLOYEES, PARTNERS,
      AGENTS, SUPPLIERS, LICENSORS, OR AFFILIATES BE LIABLE TO YOU FOR ANY CLAIMS, PROCEEDINGS, LIABILITIES,
      OBLIGATIONS, DAMAGES, LOSSES, OR COSTS.</p>

    <p>THIS LIMITATION OF LIABILITY SECTION APPLIES WHETHER THE ALLEGED LIABILITY IS BASED ON CONTRACT, TORT,
      NEGLIGENCE, STRICT LIABILITY, OR ANY OTHER BASIS, EVEN IF BACKPACKER JOBS NOW HAS BEEN ADVISED OF THE POSSIBILITY
      OF SUCH DAMAGE. THE FOREGOING LIMITATIONS OF LIABILITY SHALL APPLY TO THE FULLEST EXTENT PERMITTED BY LAW IN THE
      APPLICABLE JURISDICTION.</p>

    <h2>Changes to Terms and Conditions</h2>
    <p>We reserve the right, at our sole discretion, to modify or replace these Terms at any time. Such changes will be
      communicated via the Service or through other means such as email. Your continued use of the Service after any
      such changes take effect constitutes your acceptance of the new Terms. If you do not agree with the modified
      Terms, you are no longer authorized to use the Service and should discontinue its use immediately. In such cases,
      you will still be bound by the Terms that were in effect at the time of your use.</p>

    <p>We also reserve the right to terminate, suspend, limit, or modify any aspect or feature of the Service at any
      time, without prior notice or liability. Such changes may be made under our sole discretion, for any reason, and
      without limitation.</p>

    <h2>Contact Us</h2>
    <p>If you have any questions about these Terms or the Service, please contact us at
      enquiries@backpackerjobsnow.com.</p>

  </div>

</section>
