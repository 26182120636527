import {Component} from '@angular/core';
import {SharedService} from "../../services/shared.service";
import {Accounts} from "../../services/accounts.service";

@Component({
  selector: 'app-verify-email',
  templateUrl: './verify-email.component.html',
  styleUrls: ['./verify-email.component.scss']
})
export class VerifyEmailComponent {
  public verificationLinkText = 'Resend Verification Link';
  public resentLink = false;

  constructor(public shared: SharedService, private accounts: Accounts) {
  }

  sendEmailVerification() {
    if (!this.resentLink) {
      this.verificationLinkText = 'Sending Link...';
      this.accounts.sendEmailVerification(this.shared.account_email).subscribe(
        (response: any) => {
          if (response.status === 'success') {
            this.resentLink = true;
            this.verificationLinkText = 'Verification Link Sent';
          }
        });
    }
  }

}
