

<section class="container-space">
  <mat-card class="rounded-2xl my-16 px-3 md:px-10 py-5">
    <h1 class="text-center mb-2 text-2xl" mat-dialog-title>Forgot Your Password</h1>
    <h2 class="text-center mb-2 text-sm">No stress! Enter your email address below and we will send you a link to reset your password.</h2>
    <div class="flex flex-col">
      <form [formGroup]="forgotPasswordForm" class="mt-10 text-center">

        <mat-form-field appearance="fill" class="w-full">
          <mat-label>Email</mat-label>
          <input formControlName="email" matInput placeholder="john.smith@gmail.com">
          <mat-icon matSuffix>email</mat-icon>
        </mat-form-field>

        <div *ngIf="submittedReset" class="resetText">
          <mat-icon>check_circle_outline</mat-icon>
          <p>If this email is registered with us, we've sent you instructions on how to reset your password.</p>
        </div>
        <div class="text-right text-xs font-medium text-primary">
          <a href="/login">Remembered Your Password?</a>
        </div>
        <button (click)="submitForgottenPassword()" class="mt-6 rounded-3xl text-lg w-full sm:max-w-xs !py-5" color="primary" mat-raised-button>
          Reset Password
        </button>
        <div>
          <p class="mt-6 text-base font-medium text-primary">
            Don't have an account yet?
          </p>

          <div class="mb-1 mt-2"><a class="text-primary text-sm font-semibold" routerLink="/employee-signup">Sign up as
            a backpacker</a></div>
          <div><a class="text-primary text-sm font-semibold" routerLink="/employer-signup">Sign up as an employer</a>
          </div>
        </div>
      </form>
    </div>
  </mat-card>
</section>
