import {Component, OnInit, ViewChild} from '@angular/core';
import {MatStepper} from '@angular/material/stepper';
import {Accounts} from "../../services/accounts.service";
import {Router} from "@angular/router";
import {MetaService} from "../../services/meta.service";

@Component({
  selector: 'app-cancel-confirmation',
  templateUrl: './cancel-confirmation.component.html',
  styleUrls: ['./cancel-confirmation.component.scss']
})
export class CancelConfirmationComponent implements OnInit  {
  cancelSuccess = false;
  cancelResponse = false;

  public metaKeywords: string = "backpacker pro, cancel membership, manage membership, profile advertising, backpacker jobs, job board, Australia, career advancement, backpacker visibility, get hired, Backpacker Jobs Now";
  public metaTitle = 'Cancel Your Membership | Backpacker Jobs Now - Australia\'s Official Backpacker Job Board';
  public metaDescription = "Australia's number 1 backpacking job board built exclusively for backpackers. Find rural, farm work, fruit picking and working holiday visa jobs with ease. Get your working holiday visa completed today with Backpacker Jobs Now.";

  @ViewChild('stepper') stepper: MatStepper;

  constructor(private meta: MetaService, private apiService: Accounts, private route: Router) {
  }

  ngOnInit(): void {
    this.meta.setupPageTitle(this.metaTitle);
    this.meta.setupMetaTags(
      this.metaTitle,
      this.metaDescription,
      this.metaKeywords,
      this.route.url
    );
  }

  cancelMembership() {
    this.apiService.cancelMembership().subscribe(
      (response: any) => {
        this.cancelResponse = true;
        if (response.status == 'success') {
          this.cancelSuccess = true;
        } else {
          this.cancelSuccess = false;
        }
        // Move to the next step after receiving the response
        this.stepper.next();
      },
      (error) => {
        this.cancelResponse = true;
        this.cancelSuccess = false;
        console.error('Error cancelling membership:', error);
        // Move to the next step to show the error message
        this.stepper.next();
      }
    );
  }


  protected readonly Date = Date;
}
