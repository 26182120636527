import {Component, EventEmitter, Input, OnInit, Output} from '@angular/core';
import {Router} from "@angular/router";
import {SharedService} from "../../../services/shared.service";
import {JobsService} from "../../../services/jobs.service";

@Component({
  selector: 'candidates-list',
  templateUrl: './candidates-list.component.html',
  styleUrls: ['./candidates-list.component.scss', '../../latest-jobs/list-jobs.component.scss']

})
export class CandidatesListComponent implements OnInit {
  @Input() candidates = [];
  @Output() refreshCandidates = new EventEmitter<void>();
  showExperiencedOnly = false;

  constructor(private router: Router, public sharedService: SharedService, private jobs: JobsService) {
  }


  ngOnInit() {
    // any initialization code if required
  }

  objectKeys(obj) {
    return Object.keys(obj);
  }

  loadCandidateProfile(ref, candidateId) {
    const route = `candidate-view`;
    const queryParams = {ref: ref, id: candidateId};

    // Navigate to the desired route with query parameters
    this.router.navigate([route], {queryParams: queryParams});
  }

  shortlistCandidate(jobId, candidateId) {
    this.jobs.setApplicationShortlisted(jobId, candidateId).subscribe(
      (response) => {
        this.refreshCandidates.emit(); // still inform the parent in case it needs to do anything
      },
      (error) => {
      });
  }


  rejectCandidate(jobId, candidateId) {
    this.jobs.setApplicationRejected(jobId, candidateId).subscribe(
      (response) => {
        this.refreshCandidates.emit();
      },
      (error) => {
      });
  }

  showExperiencedOnlyToggle() {
    this.showExperiencedOnly = !this.showExperiencedOnly;
  }

}
