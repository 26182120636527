import {Component, Inject, OnInit} from '@angular/core';
import {Accounts} from "../../services/accounts.service";
import {JobsService} from "../../services/jobs.service";
import {JobApplication} from "./job-application";
import {MatDialog, MatDialogConfig, MatDialogRef} from "@angular/material/dialog";
import {ViewCoverLetterDialogComponent} from "../view-cover-letter-dialog/view-cover-letter-dialog.component";
import {Router} from "@angular/router";
import {MetaService} from "../../services/meta.service";

@Component({
    selector: 'app-job-applications',
    templateUrl: './job-applications.component.html',
    styleUrls: ['./job-applications.component.scss']
})
export class JobApplicationsComponent implements OnInit {
    public metaTitle: string = 'Manage Job Applications | Backpacker Jobs Now - Australia\'s Official Backpacker Job Board';
    public metaDescription: string = 'View your job applications in real-time, from WHV and farm work to hospitality and casual jobs. Track your application status, and manage your job applications all in one place.';
    public metaKeywords: string = 'manage,job applications,real-time,WHV,farm work,hospitality,casual jobs,track,Backpacker Jobs Now';


    jobApplication: JobApplication[] = [];

    constructor(
        private meta: MetaService,
        private router: Router,
        private accounts: Accounts,
        private jobs: JobsService,
        public dialog: MatDialog
    ) {
    }

    ngOnInit() {
        this.meta.setupPageTitle(this.metaTitle);
        this.meta.setupMetaTags(
            this.metaTitle,
            this.metaDescription,
            this.metaKeywords,

            this.router.url
        );
        this.getAllJobApplications();
    }

    getAllJobApplications() {
        this.accounts.checkProfileCreated().subscribe(
            (profileCreated) => {
                if (profileCreated.profileFound) {
                    this.accounts.getAllJobApplications().subscribe(
                        (response) => {
                            if (response.status == 'success') {
                                this.jobApplication = response.data;
                            }
                        });
                } else {
                    this.router.navigate(['/create-profile'])
                }
            });
    }

    openCoverLetter(selectedJob) {
        const dialogConfig = new MatDialogConfig();
        dialogConfig.maxWidth = '650px'; // Set the width of the dialog
        dialogConfig.minHeight = '400px'; // Set the height of the dialog
        dialogConfig.data = {
            coverLetter: selectedJob['cover_letter'],
            jobTitle: selectedJob['jobTitle'],
        };

        const dialogRef = this.dialog.open(ViewCoverLetterDialogComponent, dialogConfig);

        dialogRef.afterClosed().subscribe((result) => {
            // Handle the result when the dialog is closed
            if (result) {
                // Process the form data here
            }
        });
    }
}


