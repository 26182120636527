<section class="container-space">
  <div class="mt-16">
    <h1 class="text-center">Candidates</h1>
    <h2 *ngIf="jobRef" class="text-center text-xl">{{jobTitle}}</h2>
    <h2 *ngIf="!jobRef" class="text-center text-xl">All Candidates</h2>


    <mat-tab-group class="mt-6" fitInkBarToContent>
      <mat-tab>
        <ng-template mat-tab-label>
          <span [matBadgeHidden]="candidateStats.pendingCount <= 0"
                [matBadge]="candidateStats.pendingCount"
                matBadgeOverlap="false"
                matBadgeSize="medium">
            Pending
          </span>
        </ng-template>
        <!--        label="Pending" matBadge="{{candidateStats.pendingCount}}">-->
        <candidates-list (refreshCandidates)="onRefreshCandidates()" [candidates]="filterCandidates('pending')"></candidates-list>
      </mat-tab>

      <mat-tab>
        <ng-template mat-tab-label>
          <span [matBadgeHidden]="candidateStats.shortlistedCount <= 0"
                [matBadge]="candidateStats.shortlistedCount"
                matBadgeOverlap="false"
                matBadgeSize="medium">
            Shortlisted
          </span>
        </ng-template>

        <candidates-list (refreshCandidates)="onRefreshCandidates()" [candidates]="filterCandidates('shortlisted')"></candidates-list>
      </mat-tab>
      <mat-tab>
        <ng-template mat-tab-label>
          <span [matBadgeHidden]="candidateStats.contactedCount <= 0"
                [matBadge]="candidateStats.contactedCount"
                matBadgeOverlap="false"
                matBadgeSize="medium">
            Contacted
          </span>
        </ng-template>
        <candidates-list (refreshCandidates)="onRefreshCandidates()" [candidates]="filterCandidates('contacted')"></candidates-list>
      </mat-tab>
      <mat-tab>
        <ng-template mat-tab-label>
          <span [matBadgeHidden]="candidateStats.approvedCount <= 0"
                [matBadge]="candidateStats.approvedCount"
                matBadgeOverlap="false"
                matBadgeSize="medium">
            Approved
          </span>
        </ng-template>
        <candidates-list (refreshCandidates)="onRefreshCandidates()" [candidates]="filterCandidates('approved')"></candidates-list>
      </mat-tab>

    </mat-tab-group>


  </div>

</section>
