<section class="cancellation-stepper container-space">
  <mat-horizontal-stepper linear #stepper>
    <mat-step>
      <section class="mb-10">
        <div class="flex justify-center text-center flex-col">
          <h1 class="leading-relaxed uppercase">Are You Sure You Want To Cancel?</h1>
          <h2 class="text-base mt-6">With Backpacker Jobs Now you can find casual work all over Australia! If you
            cancel,
            your public profile listing will be removed, you'll lose access to profile share, the resume builder and all
            membership benefits. </h2>

          <h3 class="text-base mt-7">Your membership will be cancelled immediately
            effective {{ Date() | date: 'dd/MM/yyyy' }}</h3>
        </div>
      </section>

      <div class="flex justify-center w-full">
        <div class="flex flex-col w-[700px]">
          <button (click)="cancelMembership()" mat-flat-button class="lightBtn generalBtn mb-4 !h-10">
            Cancel Anyway
          </button>
          <button [routerLink]="['/profile']" mat-raised-button class="generalBtn !h-12" color="primary">Keep My
            Benefits
          </button>
          <p class="text-center text-sm">For <span class="font-medium"> only $4.99</span>, your support goes directly
            towards maintaining and running our servers, ensuring the platform remains available for backpackers seeking
            work opportunities.</p>
        </div>
      </div>
    </mat-step>

    <mat-step>
      <ng-container *ngIf="cancelResponse">
        <ng-container *ngIf="cancelSuccess; else cancelError">
          <section class="mb-6">
            <div class="flex justify-center text-center flex-col">
              <h1 class="leading-relaxed uppercase">Your Subscription Has Been Cancelled</h1>
              <h2 class="text-base mt-6">We're sorry to see you go. We will continue to improve our
                platform to make it even better for you in the future! <br>We welcome you back to the community anytime.
              </h2>
              <h3 class="text-base mt-7">Thank you so much for trying Backpacker Jobs Now <br> <span class="text-sm mt-2">Kind Regards, Josh <3 </span>
              </h3>
            </div>
          </section>

          <div class="flex justify-center w-full">
            <div class="flex flex-col w-[700px]">
              <button [routerLink]="['/profile']" mat-raised-button class="generalBtn !h-12" color="primary">Take Me
                Home
              </button>
              <p class="text-center text-sm">For <span class="font-medium"> only $4.99</span>, your support goes directly
                towards maintaining and running our servers, ensuring the platform remains available for backpackers
                seeking work opportunities.</p>
            </div>
          </div>
        </ng-container>

        <ng-template #cancelError>
          <section class="mb-6">
            <div class="flex justify-center text-center flex-col">
              <h1 class="leading-relaxed uppercase">An Error Has Occurred</h1>
              <h2 class="text-base mt-6">Well.. This Is Awkward. I promise this was not the plan!
              </h2>
              <h3 class="text-base mt-7">Please reach out directly to me at <a class="font-semibold" href="mailto:Josh@backpackerjobsnow.com">Josh@backpackerjobsnow.com</a> and I can assist you directly.</h3>
            </div>
          </section>

          <div class="flex justify-center w-full">
            <div class="flex flex-col w-[700px]">
              <button [routerLink]="['/profile']" mat-raised-button class="generalBtn !h-12" color="primary">Take Me
                Home
              </button>
              <p class="text-center text-sm">For <span class="font-medium"> only $4.99</span>, your support goes directly
                towards maintaining and running our servers, ensuring the platform remains available for backpackers
                seeking work opportunities.</p>
            </div>
          </div>
        </ng-template>
      </ng-container>
    </mat-step>
  </mat-horizontal-stepper>
</section>
