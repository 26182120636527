<section class="container-space h-full">
  <mat-card class="rounded-2xl my-16 px-3 md:px-10 h-full justify-center ">
    <h1 class="text-center mb-2 text-2xl" mat-dialog-title>Verify Email To Start Posting</h1>
    <h2 class="text-center mb-2 text-sm">To ensure your email has been setup correctly, you must verify it before posting jobs</h2>
    <div class="flex flex-col items-center">
      <strong class="mt-2">Already verified? Sorry about that, try logging out, then logging back in!</strong>
      <p class="text-base text-center">Please verify your account by clicking on the confirmation link sent
        to {{shared.account_email}}</p>

      <button *ngIf="!resentLink" mat-stroked-button (click)="sendEmailVerification()"
              class="text-sm max-w-xl mt-5 lightBtn generalBtn">{{verificationLinkText}}
      </button>

      <ng-container *ngIf="resentLink">
        <button class="flex items-center mt-5 text-sm generalBtn" color="primary" mat-flat-button>Verification Link Sent
          <mat-icon iconPositionEnd>check_circle</mat-icon>
        </button>
        <p class="font-semibold">This may take a couple of minutes to arrive in your inbox</p>

        <p class="my-2">(If our email's playing hard to get, try the spam folder!)</p>
      </ng-container>
    </div>
  </mat-card>
</section>
