import {Component} from '@angular/core';
import {UntypedFormBuilder, UntypedFormGroup, Validators} from "@angular/forms";
import {Accounts} from "../../services/accounts.service";
import {Router} from "@angular/router";
import {SharedService} from "../../services/shared.service";

@Component({
  selector: 'app-login-page',
  templateUrl: './forgot-password-page.component.html',
  styleUrls: ['./forgot-password-page.component.scss']
})
export class ForgotPasswordPageComponent {
  forgotPasswordForm: UntypedFormGroup;
  errorMsg: string;
  error: boolean = false;
  submittedReset: boolean = false;

  constructor(private formBuilder: UntypedFormBuilder, private apiService: Accounts, private route: Router, private sharedService: SharedService) {
  }

  ngOnInit(): void {
    this.forgotPasswordForm = this.formBuilder.group({
      email: ['', Validators.email],
    });
  }

  submitForgottenPassword() {
    this.submittedReset = true;
    this.error = false;
    this.apiService.forgotPassword(this.forgotPasswordForm.value.email).subscribe(
      (response) => {
      });
  }
}
