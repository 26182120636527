<h2 class="text-center !text-xl !mt-3" mat-dialog-title>Application Sent: How Easy Was That?! </h2>
<div class="close-button" (click)="onClose()">
  <mat-icon>close</mat-icon>
</div>
<form class="leave-review-container" mat-dialog-content [formGroup]="reviewForm">
  <h5 class="text-center">Please tell us about your experience with our platform. <br> <span class="text-xs">Your feedback will help us improve!</span></h5>
  <div class="flex flex-col items-center">
    <div class="star-rating">
      <ng-container *ngFor="let star of stars; let i = index">
        <mat-icon class="star" (click)="rate(i + 1)" [ngClass]="{'filled': i < rating, 'outlined': i >= rating}">
          {{ i < rating ? 'star' : 'star_border' }}
        </mat-icon>
      </ng-container>
    </div>
      <mat-form-field class="w-full mt-4" appearance="outline">
        <mat-label>Write your feedback</mat-label>
        <textarea [rows]="3" matInput formControlName="feedback"></textarea>
      </mat-form-field>
  </div>
</form>

<div class="!justify-center" mat-dialog-actions>
  <button (click)="onSubmit()" class="submit-review" color="primary" mat-raised-button>Submit & Close</button>
</div>
