import {Component, Inject, OnInit, PLATFORM_ID} from '@angular/core';
import {BackpackerProfile} from "../../../interfaces/backpacker-profile";
import {SharedService} from "../../../services/shared.service";
import {MetaService} from "../../../services/meta.service";
import {ActivatedRoute, Router} from "@angular/router";
import {Accounts} from "../../../services/accounts.service";
import {EmployeeProfileService} from "../../profile-page/employee-profile/employee-profile.service";
import {UploadFileService} from "../../../services/upload-file.service";
import {isPlatformBrowser} from "@angular/common";
import {MatDialog, MatDialogConfig} from "@angular/material/dialog";
import {ContactNowComponent} from "../../candidates/candidates-profile/hire-now/contact-now.component";
import {ContactBackpackerComponent} from "./contact-backpacker/contact-backpacker.component";
import {faShareSquare} from "@fortawesome/free-solid-svg-icons";
import {Meta} from "@angular/platform-browser";
import {StripHtmlPipe} from "../../../pipes/strip-html.pipe";
import {decode} from "html-entities";

@Component({
  selector: 'app-backpacker-profile',
  templateUrl: './backpacker-profile.component.html',
  styleUrls: ['./backpacker-profile.component.scss', '../../profile-page/employee-profile/employee-profile-page.component.scss', '../../latest-jobs/list-jobs.component.scss']
})
export class BackpackerProfileComponent implements OnInit {
  isMobile = false;

  public metaKeywords: string = "hire, search backpackers, how to hire backpackers, hire backpackers, backpacking job board, backpacker job board, Australia, backpackers, rural jobs, farm work, fruit picking, working holiday visa, WHV jobs, job applications, Backpacker Jobs Now";

  public metaTitle = 'Search & Hire Experienced Backpackers | Backpacker Jobs Now - Australia\'s Official Backpacker Job Board';

  public metaDescription = "Search and hire experienced backpackers for free with Backpacker Jobs Now, Australia's leading job board for backpacking work. Find skilled backpackers for farm work, fruit picking, and more, or explore opportunities on a working holiday visa. Start your search today to connect with backpackers ready for rural and agricultural jobs without even needing to post an advert.";


  showMoreCoverLetter = false;
  showMoreWorkExperience = false;
  readMoreLength = 100;
  backpackerID: string;
  backpackerProfile: BackpackerProfile;
  isLoadingProfile = true;
  viewerType = 'public'


  constructor(
    @Inject(PLATFORM_ID) private platformId: any,
    public striphtml: StripHtmlPipe,
    private metaService: Meta,
    public shared: SharedService,
    private meta: MetaService,
    private router: Router,
    private accounts: Accounts,
    private dialog: MatDialog,
    private profileService: EmployeeProfileService,
    private route: ActivatedRoute) {

  }

  ngOnInit() {
    if (isPlatformBrowser(this.platformId)) {
      if (window.innerWidth < 768) {
        this.isMobile = true;
        this.readMoreLength = 40;
      }
    }

    this.route.paramMap.subscribe(params => {
      this.backpackerID = params.get('backpackerID');
      this.getBackpackerProfileByID();
    });

  }

  getBackpackerProfileByID() {
    this.accounts.getPublicBackpackerProfileByID(this.backpackerID).subscribe(response => {
      if (response.status == 'success') {
        this.backpackerProfile = response.data;
        this.viewerType = response.viewerType
        this.isLoadingProfile = false;

        let coverLetterMetaDesc = this.shared.truncateString(this.backpackerProfile?.cover_letter, 200, 200)
        this.metaTitle = "Contact & Hire " + this.backpackerProfile?.full_name + " | Backpacker Jobs Now - Australia\'s Official Backpacker Job Board ";
        this.metaDescription = decode(this.striphtml.transform(coverLetterMetaDesc));
        this.meta.setupPageTitle(this.metaTitle);
        this.meta.setupMetaTags(
          this.metaTitle,
          this.metaDescription,
          this.metaKeywords,
          this.router.url
        );

        this.metaService.updateTag(
          {
            name: 'twitter:label1',
            content: `${this.backpackerProfile?.full_name} Is Looking To Work In:`,
          });

        this.metaService.updateTag(
          {
            name: 'twitter:data1',
            content: this.backpackerProfile?.work_fields.join(', '),
          });

      } else{
        this.router.navigate(['/']);
      }
    });
  }

  navigateButtonBasedOnViewerType(buttonType: string) {
    if (this.viewerType === 'public') {
      this.router.navigate(['/employer-signup']);
    } else {
      if (buttonType == 'resume') {
        window.open(this.backpackerProfile?.resume_link, "_blank");
      } else if (buttonType == 'contact') {
        const dialogConfig = new MatDialogConfig();
        dialogConfig.width = '550px';
        dialogConfig.data = {
          backpackerData: this.backpackerProfile,
        };

        const dialogRef = this.dialog.open(ContactBackpackerComponent, dialogConfig);

        dialogRef.afterClosed().subscribe((result) => {
        });
      }
    }
  }

  protected readonly faShare = faShareSquare;

  copyPublicProfileToClipboard() {
    if (isPlatformBrowser(this.platformId)) {
      navigator.clipboard.writeText(window.location.href);
      this.shared.triggerSnackbar('Profile link copied to clipboard');
    }
  }
}

