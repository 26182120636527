import {Component} from '@angular/core';
import {Job} from "../../interfaces/job";
import {JobsService} from "../../services/jobs.service";
import {MetaService} from "../../services/meta.service";
import {Router} from "@angular/router";

@Component({
    selector: 'app-saved-jobs',
    templateUrl: './saved-jobs.component.html',
    styleUrls: ['./saved-jobs.component.scss', '../job-applications/job-applications.component.scss']
})
export class SavedJobsComponent {
    public metaTitle: string = 'Saved Job Listings | Backpacker Jobs Now - Australia\'s Official Backpacker Job Board';
    public metaDescription: string = 'Browse your saved job listings, from farm work to hospitality roles, and take the next step in your backpacking adventure in Australia. Keep track of opportunities and apply to your favorite job.';
    public metaKeywords: string = 'saved listings,browse,farm work,hospitality,backpacking adventure,Australia,track,opportunities,apply,favorite job,Backpacker Jobs Now';

    public savedJobs: Job[];

    constructor(
        private router: Router,
        private jobs: JobsService,
        private meta: MetaService,
    ) {
    }

    ngOnInit() {
        this.meta.setupPageTitle(this.metaTitle);
        this.meta.setupMetaTags(
            this.metaTitle,
            this.metaDescription,
            this.metaKeywords,

            this.router.url
        );
        this.getAllSavedJobsForUser();
    }

    getAllSavedJobsForUser() {
        this.jobs.getAllSavedJobsForUser().subscribe(
            (response) => {
                this.savedJobs = response.data;
            });
    }

}
