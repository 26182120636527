import { Injectable } from '@angular/core';
import {SharedService} from "./shared.service";
import {HttpClient} from "@angular/common/http";

@Injectable({
  providedIn: 'root'
})
export class UploadFileService {

  constructor(private http: HttpClient, private settings: SharedService) { }

  uploadFile(file: File, filePath: string) {
    const formData = new FormData();
    formData.append('file', file, file.name);
    formData.append('filePath', filePath);

    return this.http.post<any>(this.settings.apiURL + 'uploads/UploadFile', formData, {
      withCredentials: true
    });
  }

  updateEmployerLogo(image_url: string) {
    const formData = {image_url: image_url}
    return this.http.post<any>(this.settings.apiURL + 'uploads/UpdateEmployerLogo', formData, {
      withCredentials: true
    });
  }

  deleteOverwrittenFile(filePath: string) {
    const formData = new FormData();
    formData.append('filePath', filePath);
    return this.http.post<any>(this.settings.apiURL + 'uploads/DeleteFile', formData, {
      withCredentials: true
    });
  }



}
