import {Component, Inject, OnInit, PLATFORM_ID, ViewChild} from '@angular/core';
import {faPen, faEdit, faShareSquare} from '@fortawesome/free-solid-svg-icons';
import {ActivatedRoute, Router} from "@angular/router";
import {Accounts} from "../../../services/accounts.service";
import {BackpackerProfile} from "../../../interfaces/backpacker-profile";
import {EmployeeProfileService} from "./employee-profile.service";
import {UploadFileService} from "../../../services/upload-file.service";
import {MetaService} from "../../../services/meta.service";
import {SharedService} from "../../../services/shared.service";
import {isPlatformBrowser} from "@angular/common";
import {MatMenuTrigger} from "@angular/material/menu";

@Component({
  selector: 'app-profile-page',
  templateUrl: './employee-profile-page.component.html',
  styleUrls: ['./employee-profile-page.component.scss', '../../latest-jobs/list-jobs.component.scss']
})
export class EmployeeProfilePageComponent implements OnInit {
  @ViewChild('menuTrigger') menuTrigger: MatMenuTrigger;

  public metaTitle: string = 'My Backpacker Profile | Backpacker Jobs Now - Australia\'s Official Backpacker Job Board';
  public metaDescription: string = ' Update your cover letter, CV, and personal information to stand out to employers. Make your backpacker profile the best it can be and get hired faster.';
  public metaKeywords: string = 'backpacker profile,update,cover letter,CV,personal information,stand out,employers,get hired,faster,Backpacker Jobs Now';

  showMoreCoverLetter = false;
  showMoreWorkExperience = false;
  readMoreLength = 100;
  backpackerProfile: BackpackerProfile;
  isEditingProfilePicture = false;
  profilePictureUploaded = false;
  profilePictureFile: File | null = null;
  isLoadingProfile = true;
  redirectionPage: string;
  paidProfile = false;
  isMobile = false;
  currentPlanText = 'Free Plan';

  faPen = faPen;
  faShare = faShareSquare;
  faEdit = faEdit;

  constructor(
    @Inject(PLATFORM_ID) private platformId: any,
    public shared: SharedService,
    private meta: MetaService,
    private router: Router,
    private accounts: Accounts,
    private profileService: EmployeeProfileService,
    private route: ActivatedRoute,
    private uploadService: UploadFileService) {

  }

  ngOnInit() {
    if (isPlatformBrowser(this.platformId)) {
      if (window.innerWidth < 768) {
        this.isMobile = true;
        this.readMoreLength = 40;
      }
    }

    this.redirectionPage = this.route.snapshot.queryParamMap.get('redirect');

    // Backpacker cancelled the checkout, handle follow up etc.
    if (this.redirectionPage === 'cancelledPayment') {
      let customerID = this.route.snapshot.queryParamMap.get('id');
      if (customerID) {
        this.accounts.backpackerCheckoutCancelled(customerID).subscribe((response) => {
        });
      }
    }


    this.meta.setupPageTitle(this.metaTitle);
    this.meta.setupMetaTags(
      this.metaTitle,
      this.metaDescription,
      this.metaKeywords,

      this.router.url
    );
    this.getUserProfileData();
  }

  editProfilePicture() {
    this.isEditingProfilePicture = true;
  }

  onSelectProfilePicture(event) {
    this.profilePictureFile = event.addedFiles[0]; // Only accept the first file
    this.profilePictureUploaded = true;
  }

  closeUploader() {
    this.profilePictureFile = null;
    this.isEditingProfilePicture = false;
    this.profilePictureUploaded = false;
  }

  onRemove(event) {
    this.profilePictureFile = null;
  }

  async uploadProfilePicture(): Promise<string> {
    let profileImageURL = "";

    const oldProfilePicture = this.backpackerProfile.profile_picture;
    if (!this.profilePictureFile) {
      return Promise.resolve("");
    }

    let filePath = 'profile-pictures/backpackers/' + Math.random() * 10000000000 + "_" + this.profilePictureFile.name;

    try {
      const response = await this.uploadService.uploadFile(this.profilePictureFile, filePath).toPromise();
      if (response.status === "success" && response['fileURL']) {
        profileImageURL = response['fileURL'];
        const formData = {profile_picture: profileImageURL};
        this.accounts.UpdateBackpackerProfilePicture(formData).subscribe(
          (response) => {
            this.getUserProfileData();
            if (profileImageURL && oldProfilePicture) {
              try {
                this.uploadService.deleteOverwrittenFile(oldProfilePicture).subscribe();
              } catch (error) {
                console.error("An error occurred during deletion:", error);
              }
            }


            this.closeUploader();
          });
      }
    } catch (error) {
      console.error("An error occurred during upload:", error);
      // Handle error appropriately
    }
  }


  openEditor(editorPath: any) {
    this.router.navigate(['profile-editor', editorPath]);
  }

  getUserProfileData() {
    this.accounts.checkProfileCreated().subscribe(
      (profileCreated) => {
        if (profileCreated.profileFound) {
          this.accounts.loadUserProfile().subscribe(
            (response) => {
              if (response.status == 'success') {
                this.backpackerProfile = response.profile;
                this.profileService.changeProfile(this.backpackerProfile);
                this.isLoadingProfile = false;

                if (this.backpackerProfile.account_tier != 'standard') {
                  this.paidProfile = true;
                }

                switch (this.backpackerProfile.account_tier) {
                  case 'standard':
                    this.currentPlanText = 'Free Plan';
                    break;
                  case 'certified':
                    this.currentPlanText = 'Certified Backpacker';
                    break;
                  case 'pro':
                    this.currentPlanText = 'Backpacker Pro';
                    break;
                }
              }
            });
        } else {
          this.router.navigate(['/create-profile'])
        }
      });

  }

  toggleMenu() {
    this.menuTrigger.openMenu();
  }

  protected readonly open = open;

  buildResume() {
    this.accounts.generateResume().subscribe((response) => {
      const url = window.URL.createObjectURL(response);
      const anchor = document.createElement('a');
      anchor.href = url;
      anchor.download = 'resume.pdf';
      anchor.click();
      window.URL.revokeObjectURL(url);
    });
  }

  copyPublicProfileToClipboard() {
    const base = `${location.protocol}//${location.host}`;
    const fullPath = `${base}/backpacker-profile/${this.backpackerProfile?.account_id}`;
    navigator.clipboard.writeText(fullPath);

    this.shared.triggerSnackbar('Profile link copied to clipboard');
  }

  changeAccountPublicStatus() {
    this.backpackerProfile.is_public_profile = !this.backpackerProfile.is_public_profile
    this.accounts.changeAccountPublicStatus(this.backpackerProfile.is_public_profile).subscribe((response) => {
    });
  }
}
