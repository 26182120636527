import {Component, Inject, OnInit} from '@angular/core';
import {FormControl, UntypedFormBuilder, UntypedFormGroup} from "@angular/forms";
import {SharedService} from "../../services/shared.service";
import {JobsService} from "../../services/jobs.service";
import {Job} from "../../interfaces/job";
import {Settings} from "../../globals/settings.model"
import {Router, ActivatedRoute} from '@angular/router';
import {MetaService} from "../../services/meta.service";

@Component({
  selector: 'app-jobs-main',
  templateUrl: './jobs-main.component.html',
  styleUrls: ['./jobs-main.component.scss', '../latest-jobs/list-jobs.component.scss']
})
export class JobsMainComponent implements OnInit {
  jobSearchForm: UntypedFormGroup;
  public loading = true;
  public waitingOnRouteParams = false;
  public currentPage: number = 1;
  public totalPages: number;
  public jobs: Job[];

  public hasFilters = true;
  public hasTerm = true;
  public locationTitle: string = "";
  public catTitle: string = "";

  public metaKeywords: string[] = ['hire', 'find jobs', 'search', 'search jobs', 'backpacking job board', 'backpacker job board', 'Australia', 'backpackers', 'rural jobs', 'farm work', 'fruit picking', 'working holiday visa', 'WHV jobs', 'job applications', 'Backpacker Jobs Now'];

  public metaTitle = 'Search Backpacker, Rural & WHV Jobs in Australia | Backpacker Jobs Now - Australia\'s Official Backpacker Job Board';
  public metaDescription = "Search and apply for backpacking jobs across Australia on Backpacker Jobs Now. From rural jobs to farm work and fruit picking, find the perfect job to complete your working holiday visa.";

  constructor(private jobsService: JobsService,
              private formBuilder: UntypedFormBuilder,
              public globals: Settings,
              private route: ActivatedRoute,
              private router: Router,
              private meta: MetaService,
  ) {
  }

  ngOnInit() {
    this.hasFilters = true;
    this.hasTerm = true;
    this.initializeJobSearchForm();
    this.subscribeToRouteParams();
    this.subscribeToQueryParamMap();

    if (!this.hasTerm && !this.hasFilters) {
      this.setupDefaultMetaTags();
    }

  }

  initializeJobSearchForm() {
    this.jobSearchForm = this.formBuilder.group({
      searchInput: [''],
      workTypeFilter: [''],
      workLocationFilter: ['']
    });
  }

  subscribeToRouteParams() {
    this.route.paramMap.subscribe(params => {
      const searchTerm = params.get('term');
      if (searchTerm) {
        // Generate meta tags before API search
        let metaTitle = '';
        let metaDescription = '';


        switch (searchTerm) {

          // JOB LOCATIONS
          case 'new-south-wales':
            this.metaKeywords.push('new south wales', 'nsw');
            metaTitle = 'Backpacker Jobs in New South Wales | Backpacker Jobs Now - Australia\'s Official Backpacker Job Board';
            metaDescription = 'Find backpacker jobs in New South Wales. Explore WHV, rural jobs, and farm work, along with hospitality and casual roles. Complete your working holiday visa now.';
            this.locationTitle = "New South Wales";
            break;
          case 'queensland':
            this.metaKeywords.push('queensland', 'qld');
            metaTitle = 'Backpacker Jobs in Queensland | Backpacker Jobs Now - Australia\'s Official Backpacker Job Board';
            metaDescription = 'Find backpacker jobs in Queensland. Explore WHV, rural jobs, and farm work, along with hospitality and casual roles. Complete your working holiday visa now.';
            this.locationTitle = "Queensland";
            break;
          case 'victoria':
            this.metaKeywords.push('victoria', 'vic');
            metaTitle = 'Backpacker Jobs in Victoria | Backpacker Jobs Now - Australia\'s Official Backpacker Job Board';
            metaDescription = 'Find backpacker jobs in Victoria. Discover WHV, rural opportunities, and farm work, as well as jobs in hospitality and casual sectors. Secure your working holiday visa today.';
            this.locationTitle = "Victoria";
            break;
          case 'western-australia':
            this.metaKeywords.push('western australia', 'wa');
            metaTitle = 'Backpacker Jobs in Western Australia | Backpacker Jobs Now - Australia\'s Official Backpacker Job Board';
            metaDescription = 'Find backpacker jobs in Western Australia. Browse WHV, rural jobs, and farm work, in addition to hospitality and casual roles. Achieve your working holiday visa goals.';
            this.locationTitle = "Western Australia";
            break;
          case 'south-australia':
            this.metaKeywords.push('south australia', 'sa');
            metaTitle = 'Backpacker Jobs in South Australia | Backpacker Jobs Now - Australia\'s Official Backpacker Job Board';
            metaDescription = 'Find backpacker jobs in South Australia. Uncover WHV, rural, and farm work, plus hospitality and casual job openings. Fulfill your working holiday visa requirements.';
            this.locationTitle = "South Australia";
            break;
          case 'northern-territory':
            this.metaKeywords.push('northern territory', 'nt');
            metaTitle = 'Backpacker Jobs in Northern Territory | Backpacker Jobs Now - Australia\'s Official Backpacker Job Board';
            metaDescription = 'Find backpacker jobs in the Northern Territory. Search for WHV, rural, and farm work, along with hospitality and casual job opportunities. Start your working holiday visa journey.';
            this.locationTitle = "Northern Territory";
            break;
          case 'tasmania':
            this.metaKeywords.push('tasmania', 'tas');
            metaTitle = 'Backpacker Jobs in Tasmania | Backpacker Jobs Now - Australia\'s Official Backpacker Job Board';
            metaDescription = 'Find backpacker jobs in Tasmania. Look for WHV, rural, and farm work, as well as hospitality and casual job listings. Make the most of your working holiday visa.';
            this.locationTitle = "Tasmania";
            break;
          case 'australian-capital-territory':
            this.metaKeywords.push('australian capital territory', 'act');
            metaTitle = 'Backpacker Jobs in Australian Capital Territory | Backpacker Jobs Now - Australia\'s Official Backpacker Job Board';
            metaDescription = 'Find backpacker jobs in the Australian Capital Territory. Choose from WHV, rural, and farm work, along with hospitality and casual roles. Complete your working holiday visa with ease.';
            this.locationTitle = "Australian Capital Territory";
            break;

          // JOB TYPES/CATEGORIES

          case 'fruit-picking-jobs':
            this.metaKeywords.push('fruit picking', 'rural work', 'working holiday visa');
            metaTitle = 'Fruit Picking Jobs in Australia | Backpacker Jobs Now - Australia\'s Official Backpacker Job Board';
            metaDescription = 'Find fruit picking jobs across Australia. Ideal for backpackers looking for rural work to complete their working holiday visa requirements.';
            this.catTitle = "Fruit Picking Jobs";
            break;

          case 'agriculture-jobs':
            this.metaKeywords.push('agriculture work', 'farm work', 'cattle ranching');
            metaTitle = 'Agriculture Jobs in Australia | Backpacker Jobs Now - Australia\'s Official Backpacker Job Board';
            metaDescription = 'Search for agriculture work in Australia. From farm work to cattle ranching, find opportunities that suit your working holiday visa needs.';
            this.catTitle = "Agriculture Jobs";
            break;

          case 'au-pair-jobs':
            this.metaKeywords.push('au pair', 'childcare', 'working holiday visa');
            metaTitle = 'Au Pair Jobs in Australia | Backpacker Jobs Now - Australia\'s Official Backpacker Job Board';
            metaDescription = 'Find Au Pair jobs in Australia. A perfect fit for backpackers looking for a home away from home while completing their working holiday visa.';
            this.catTitle = "Au Pair Jobs";
            break;

          case 'conservation-jobs':
            this.metaKeywords.push('conservation', 'environment', 'wildlife');
            metaTitle = 'Conservation Jobs in Australia | Backpacker Jobs Now - Australia\'s Official Backpacker Job Board';
            metaDescription = 'Find conservation jobs in Australia. Ideal for backpackers looking to make a difference while completing their working holiday visa.';
            this.catTitle = "Conservation Jobs";
            break;

          case 'events-jobs':
            this.metaKeywords.push('events', 'promotions', 'working holiday visa');
            metaTitle = 'Events and Promotions Jobs in Australia | Backpacker Jobs Now - Australia\'s Official Backpacker Job Board';
            metaDescription = 'Find events and promotions jobs in Australia. Perfect for backpackers looking for flexible work while completing their working holiday visa.';
            this.catTitle = "Events and Promotions Jobs";
            break;

          case 'fishing-jobs':
            this.metaKeywords.push('fishing', 'aquaculture', 'working holiday visa');
            metaTitle = 'Fishing and Aquaculture Jobs in Australia | Backpacker Jobs Now - Australia\'s Official Backpacker Job Board';
            metaDescription = 'Search for fishing and aquaculture jobs in Australia. Ideal for backpackers looking for unique work experiences while completing their working holiday visa.';
            this.catTitle = "Fishing and Aquaculture Jobs";
            break;

          case 'hospitality-jobs':
            this.metaKeywords.push('hospitality', 'bartending', 'hotel work');
            metaTitle = 'Hospitality Jobs in Australia | Backpacker Jobs Now - Australia\'s Official Backpacker Job Board';
            metaDescription = 'Browse hospitality jobs in Australia. From bartending to hotel work, find casual jobs that fit your backpacking lifestyle.';
            this.catTitle = "Hospitality Jobs";
            break;

          case 'housekeeping-cleaning-jobs':
            this.metaKeywords.push('housekeeping', 'cleaning', 'working holiday visa');
            metaTitle = 'Housekeeping and Cleaning Jobs in Australia | Backpacker Jobs Now - Australia\'s Official Backpacker Job Board';
            metaDescription = 'Find housekeeping and cleaning jobs in Australia. Perfect for backpackers looking for flexible work while completing their working holiday visa.';
            this.catTitle = "Housekeeping and Cleaning Jobs";
            break;

          case 'landscaping-jobs':
            this.metaKeywords.push('landscaping', 'gardening', 'working holiday visa');
            metaTitle = 'Landscaping and Gardening Jobs in Australia | Backpacker Jobs Now - Australia\'s Official Backpacker Job Board';
            metaDescription = 'Search for landscaping and gardening jobs in Australia. Ideal for backpackers looking for outdoor work while completing their working holiday visa.';
            this.catTitle = "Landscaping and Gardening Jobs";
            break;

          case 'photography-videography-jobs':
            this.metaKeywords.push('photography', 'videography', 'working holiday visa');
            metaTitle = 'Photography and Videography Jobs in Australia | Backpacker Jobs Now - Australia\'s Official Backpacker Job Board';
            metaDescription = 'Find photography and videography jobs in Australia. Ideal for backpackers with specialized skills looking to complete their working holiday visa.';
            this.catTitle = "Photography and Videography Jobs";
            break;

          case 'sports-fitness-jobs':
            this.metaKeywords.push('sports', 'fitness', 'personal training');
            metaTitle = 'Sports and Fitness Jobs in Australia | Backpacker Jobs Now - Australia\'s Official Backpacker Job Board';
            metaDescription = 'Browse sports and fitness jobs in Australia. Perfect for backpackers looking for active work while completing their working holiday visa.';
            this.catTitle = "Sports and Fitness Jobs";
            break;

          case 'transport-logistics-jobs':
            this.metaKeywords.push('transport', 'logistics', 'delivery');
            metaTitle = 'Transport and Logistics Jobs in Australia | Backpacker Jobs Now - Australia\'s Official Backpacker Job Board';
            metaDescription = 'Search for transport and logistics jobs in Australia. Ideal for backpackers looking for driving and delivery work while completing their working holiday visa.';
            this.catTitle = "Transport and Logistics Jobs";
            break;

          case 'disaster-relief-jobs':
            this.metaKeywords.push('disaster relief', 'volunteer', 'working holiday visa');
            metaTitle = 'Disaster Relief Jobs in Australia | Backpacker Jobs Now - Australia\'s Official Backpacker Job Board';
            metaDescription = 'Search for disaster relief jobs in Australia. Contribute to meaningful work while fulfilling your working holiday visa requirements.';
            this.catTitle = "Disaster Relief Jobs";
            break;

          case 'education-teaching-jobs':
            this.metaKeywords.push('teaching', 'education', 'working holiday visa');
            metaTitle = 'Education and Teaching Jobs in Australia | Backpacker Jobs Now - Australia\'s Official Backpacker Job Board';
            metaDescription = 'Find education and teaching jobs in Australia. Ideal for backpackers looking to make a difference while completing their working holiday visa.';
            this.catTitle = "Education and Teaching Jobs";
            break;

          case 'cleaning-jobs':
            this.metaKeywords.push('cleaning', 'flexible hours', 'working holiday visa');
            metaTitle = 'Cleaning Jobs in Australia | Backpacker Jobs Now - Australia\'s Official Backpacker Job Board';
            metaDescription = 'Browse cleaning jobs in Australia. Flexible hours make it a great choice for backpackers on a working holiday visa.';
            this.catTitle = "Cleaning Jobs";
            break;

          case 'technology-jobs':
            this.metaKeywords.push('technology', 'IT', 'specialized skills');
            metaTitle = 'Tech & IT Jobs in Australia | Backpacker Jobs Now - Australia\'s Official Backpacker Job Board';
            metaDescription = 'Search for technology and IT jobs in Australia. Ideal for backpackers with specialized skills looking to complete their working holiday visa.';
            this.catTitle = "Tech & IT Jobs";
            break;

          case 'retail-sales-jobs':
            this.metaKeywords.push('retail', 'flexible shifts', 'working holiday visa');
            metaTitle = 'Retail and Sales Jobs in Australia | Backpacker Jobs Now - Australia\'s Official Backpacker Job Board';
            metaDescription = 'Find retail and sales jobs in Australia. Flexible shifts and diverse opportunities make it a go-to for backpackers on a working holiday visa.';
            this.catTitle = "Retail and Sales Jobs";
            break;

          case 'travel-tourism-jobs':
            this.metaKeywords.push('travel', 'tourism', 'exploring');
            metaTitle = 'Travel and Tourism Jobs in Australia | Backpacker Jobs Now - Australia\'s Official Backpacker Job Board';
            metaDescription = 'Browse travel and tourism jobs in Australia. Perfect for backpackers looking to explore while fulfilling their working holiday visa requirements.';
            this.catTitle = "Travel and Tourism Jobs";
            break;

          case 'volunteering-jobs':
            this.metaKeywords.push('volunteering', 'community service', 'working holiday visa');
            metaTitle = 'Volunteering Jobs in Australia | Backpacker Jobs Now - Australia\'s Official Backpacker Job Board';
            metaDescription = 'Find volunteering jobs in Australia. Contribute to meaningful work while fulfilling your working holiday visa requirements.';
            this.catTitle = "Volunteering Jobs";
            break;

          case 'construction-jobs':
            this.metaKeywords.push('construction', 'labouring', 'working holiday visa');
            metaTitle = 'Construction and Labouring Jobs in Australia | Backpacker Jobs Now - Australia\'s Official Backpacker Job Board';
            metaDescription = 'Find construction and labouring jobs in Australia. Ideal for backpackers looking for physical work while completing their working holiday visa.';
            this.catTitle = "Construction and Labouring Jobs";
            break;

          case 'trade-jobs':
            this.metaKeywords.push('trades', 'services', 'tradies', 'hire tradies', 'working holiday visa');
            metaTitle = 'Trades and Services Jobs in Australia | Backpacker Jobs Now - Australia\'s Official Backpacker Job Board';
            metaDescription = "Find trades and services jobs in Australia on Backpacker Jobs Now. Whether you're a tradie, sparky, electrician, or skilled worker, we have a variety of jobs ideal for backpackers looking to complete their working holiday visa.";
            this.catTitle = "Trades and Services Jobs";
            break;

          case 'mining-jobs':
            this.metaKeywords.push('mining', 'resources', 'working holiday visa');
            metaTitle = 'Mining and Resources Jobs in Australia | Backpacker Jobs Now - Australia\'s Official Backpacker Job Board';
            metaDescription = "Find mining and resources jobs in Australia on Backpacker Jobs Now. Whether you're a miner, driller, or skilled worker, we have a variety of jobs ideal for backpackers looking to complete their working holiday visa.";
            this.catTitle = "Mining and Resources Jobs";
            break;

          case 'energy-jobs':
            this.metaKeywords.push('energy', 'utilities', 'working holiday visa');
            metaTitle = 'Energy and Utilities Jobs in Australia | Backpacker Jobs Now - Australia\'s Official Backpacker Job Board';
            metaDescription = "Explore a wide range of energy and utilities jobs in Australia on Backpacker Jobs Now. From solar power to renewable energy projects, find the perfect job to complete your working holiday visa.";
            this.catTitle = "Energy and Utilities Jobs";
            break;
        }


        this.meta.setupPageTitle(metaTitle);

        this.meta.setupMetaTags(
          metaTitle,
          metaDescription,
          this.metaKeywords.join(', '),
          this.router.url
        );


        this.waitingOnRouteParams = true;
        this.performSearchBasedOnTerm(searchTerm);
      } else {
        this.hasTerm = false;
      }
    });
  }

  subscribeToQueryParamMap() {
    this.route.queryParamMap.subscribe(params => {
      const searchInput = params.get('search');
      let jobTypeParams = params.getAll('jobType');
      let locationParams = params.getAll('location');

      // Update form values based on query parameters
      this.updateFormValues(searchInput, jobTypeParams, locationParams);

      // Map job type URL slugs back to names
      jobTypeParams = this.mapUrlsToNames(jobTypeParams, this.globals.jobCategories);
      locationParams = this.mapUrlsToNames(locationParams, this.globals.states);

      if (searchInput || jobTypeParams.length > 0 || locationParams.length > 0) {
        const jobTypeString = jobTypeParams.join(',');
        const locationString = locationParams.join(',');

        this.metaKeywords.push(searchInput, jobTypeString, locationString);


        // Generate meta tags before API search
        let metaTitle = '';
        let metaDescription = '';

        // If there's a search input, start the title with it
        if (searchInput) {
          metaTitle += `'${searchInput}' jobs`;
          metaDescription += `Search '${searchInput}' jobs`;
        }
        // If there's no search input but there is a job type, use that
        else if (jobTypeString) {
          metaTitle += `${jobTypeString} jobs`;
          metaDescription += `Search ${jobTypeString} jobs`;
        }
        // If both search input and job type are empty, default to 'jobs'
        else {
          metaTitle += 'Jobs';
        }

        // If a specific location is provided, add it to the title
        if (locationString) {
          metaTitle += ` in ${locationString} Australia`;
          metaDescription += ` in ${locationString} Australia`;
        }
        // If no specific location is provided, default to "in Australia"
        else {
          metaTitle += ' in Australia';
          metaDescription += ' in Australia';
        }

        // If both search input and job type are provided, add job type at the end
        if (jobTypeString && searchInput) {
          metaTitle += ` - ${jobTypeString}`;
          metaDescription += ` - ${jobTypeString}`;
        }

        // Append site name to the end of the title
        metaTitle += ' | Backpacker Jobs Now - Australia\'s Official Backpacker Job Board';
        metaDescription += '. From rural jobs to farm work and fruit picking, find the perfect job on Backpacker Jobs Now to complete your working holiday visa.';

        // Set the generated meta title
        this.meta.setupPageTitle(metaTitle);

        // Add comments to remember the logic
        // If searchInput is empty and jobTypeString is empty, it defaults to 'jobs'
        // If location is empty, it defaults to 'in Australia'

        this.meta.setupMetaTags(
          metaTitle,
          metaDescription,
          this.metaKeywords.join(', '),
          this.router.url
        );


        this.fetchJobs(this.jobSearchForm.value.searchInput, jobTypeString, locationString, 1);
      } else {
        this.hasFilters = false;
        // at least route params have been found, in this case it will handle loading in that func
        if (!this.waitingOnRouteParams) {
          this.setupDefaultMetaTags();
          this.loading = false;
          this.jobs = [];
        }
      }
    });
  }


  performSearchBasedOnTerm(searchTerm: string) {

    let matchingSlug;

    for (const item of [...this.globals.jobCategories, ...this.globals.states]) {
      if (item.urlSlug === searchTerm || (item['seoUrls'] && item['seoUrls'].includes(searchTerm))) {
        matchingSlug = item;
        break;
      }
    }

    if (matchingSlug) {
      this.updateFormAndPerformSearch(matchingSlug, searchTerm);
    } else {
      // If it's not a slug, treat it as a search input
      this.jobSearchForm.controls['searchInput'].setValue(searchTerm);
      this.fetchJobs(this.jobSearchForm.value.searchInput, '', '', 1);
    }
  }


  updateFormAndPerformSearch(matchingSlug: any, searchTerm: string) {
    if (this.globals.jobCategories.some(cat => cat.urlSlug === matchingSlug.urlSlug || (cat['seoUrls'] && cat['seoUrls'].includes(searchTerm)))) {
      this.jobSearchForm.controls['workTypeFilter'].setValue([matchingSlug.urlSlug]);
      this.fetchJobs('', matchingSlug.name, '', 1);
    } else {
      this.jobSearchForm.controls['workLocationFilter'].setValue([matchingSlug.urlSlug]);
      this.fetchJobs('', '', matchingSlug.name, 1);
    }
  }

  updateFormValues(searchInput: string, jobTypeParams: string[], locationParams: string[]) {
    if (searchInput) {
      this.jobSearchForm.controls['searchInput'].setValue(searchInput);
    }

    if (jobTypeParams.length > 0) {
      this.jobSearchForm.controls['workTypeFilter'].setValue(jobTypeParams);
    }

    if (locationParams.length > 0) {
      this.jobSearchForm.controls['workLocationFilter'].setValue(locationParams);
    }
  }


  searchJobs() {
    const formValue = this.jobSearchForm.value;

    if (formValue.searchInput != '' || formValue.workTypeFilter.length > 0 || formValue.workLocationFilter.length > 0) {

      let queryParams: any = {};

      // Generate a string of search inputs
      if (formValue.searchInput != '') {
        queryParams.search = formValue.searchInput;
      }

      // Generate a string of work type filters
      if (formValue.workTypeFilter.length > 0) {
        queryParams.jobType = [];
        for (let i = 0; i < formValue.workTypeFilter.length; i++) {
          queryParams.jobType.push(formValue.workTypeFilter[i]);
        }
      }

      // Generate a string of work location filters
      if (formValue.workLocationFilter.length > 0) {
        queryParams.location = [];
        for (let i = 0; i < formValue.workLocationFilter.length; i++) {
          queryParams.location.push(formValue.workLocationFilter[i]);
        }
      }

      // Navigate to the URL with the generated parameters
      this.router.navigate(['/search-jobs'], {queryParams: queryParams});
    } else {
      // If no input or filters are selected, just navigate to the '/jobs' URL without any parameters
      this.router.navigate(['/search-jobs']);
    }
  }

  // Fetch jobs from the service
  fetchJobs(searchInput, jobType, location, page: number) {

    this.jobsService.getJobsBySearch(searchInput, jobType, location, page).subscribe(
      (response) => {
        this.jobs = response.data;
        this.totalPages = response.totalPages;
        this.loading = false;

      },
      (error) => {
        this.loading = false;
      }
    );
  }

  private mapUrlsToNames(slugs: string[], collection: any[]): string[] {
    return slugs.map(slug => {
      const item = collection.find(c => c.urlSlug === slug);
      return item ? item.name : undefined;
    }).filter(value => value);
  }

  onPageChange(page: number) {
    let queryParams: any = {};
    const formValue = this.jobSearchForm.value;

    // Generate a string of search inputs
    if (formValue.searchInput != '') {
      queryParams.search = formValue.searchInput;
    }

    // Generate a string of work type filters
    if (formValue.workTypeFilter.length > 0) {
      queryParams.jobType = [];
      for (let i = 0; i < formValue.workTypeFilter.length; i++) {
        queryParams.jobType.push(formValue.workTypeFilter[i]);
      }
    }

    // Generate a string of work location filters
    if (formValue.workLocationFilter.length > 0) {
      queryParams.location = [];
      for (let i = 0; i < formValue.workLocationFilter.length; i++) {
        queryParams.location.push(formValue.workLocationFilter[i]);
      }
    }


    this.currentPage = page;
    this.fetchJobs(queryParams.search, queryParams.jobType, queryParams.location, page);
  }

  setupDefaultMetaTags() {
    this.meta.setupPageTitle(this.metaTitle);

    this.meta.setupMetaTags(
      this.metaTitle,
      this.metaDescription,
      this.metaKeywords.join(', '),
      this.router.url
    );
  }

}
