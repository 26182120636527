import {AfterViewInit, Component, ContentChild, ElementRef, OnInit, ViewChild} from '@angular/core';
import {SharedService} from "../../services/shared.service";
import {Accounts} from "../../services/accounts.service";
import {BackpackerProfile} from "../../interfaces/backpacker-profile";
import {ActivatedRoute, Router} from "@angular/router";
import {UntypedFormBuilder, UntypedFormGroup} from "@angular/forms";
import {Settings} from "../../globals/settings.model";
import {SwiperOptions} from "swiper/types";
import {SwiperContainer} from "swiper/swiper-element";
import {EmployerProfile} from "../../interfaces/employer-profile";
import {MetaService} from "../../services/meta.service";

@Component({
  selector: 'app-search-backpackers',
  templateUrl: './backpacker-search.component.html',
  styleUrls: ['./backpacker-search.component.scss', '../latest-jobs/list-jobs.component.scss']
})
export class BackpackerSearchComponent implements OnInit, AfterViewInit {
  @ViewChild('swiper', {static: false}) swiperElement!: ElementRef<SwiperContainer>;
  isLoading = true;
  isLoadingSwiper = true;
  public totalPages: number = 1;
  public currentPage: number = 1;
  backpackerSearchForm: UntypedFormGroup;
  recommendedBackpackers: BackpackerProfile[];
  backpackerProfiles: BackpackerProfile[];
  loggedInBackpackerUserDetails: BackpackerProfile;
  employerUserDetails: EmployerProfile;
  viewerType = 'public'
  index = 0;

  public waitingOnRouteParams = false;
  public hasFilters = true;
  public hasTerm = true;
  public locationTitle: string = "";
  public catTitle: string = "";
  public metaKeywords: string[] = ["hire", "search backpackers", "how to hire backpackers", "hire backpackers", "backpacking job board", "backpacker job board", "Australia", "backpackers", "rural jobs", "farm work", "fruit picking", "working holiday visa", "WHV jobs", "job applications", "Backpacker Jobs Now"];
  public metaTitle = 'Search & Hire Experienced Backpackers | Backpacker Jobs Now - Australia\'s Official Backpacker Job Board';
  public metaDescription = "Search and hire experienced backpackers for free with Backpacker Jobs Now, Australia's leading job board for backpacking work. Find skilled backpackers for farm work, fruit picking, and more, or explore opportunities on a working holiday visa. Start your search today to connect with backpackers ready for rural and agricultural jobs without even needing to post an advert.";

  constructor(
    private meta: MetaService,
    public globals: Settings,
    public sharedService: SharedService,
    public accounts: Accounts,
    private formBuilder: UntypedFormBuilder,
    private router: Router,
    private route: ActivatedRoute,
  ) {

  }

  ngOnInit(): void {
    this.hasFilters = true;
    this.hasTerm = true;
    this.initializebackpackerSearchForm();
    this.subscribeToRouteParams();
    this.subscribeToQueryParamMap();
    if (this.sharedService.employeeLogin || this.sharedService.employerLogin) {
      if (this.sharedService.employeeLogin) {
        this.accounts.checkProfileCreated().subscribe(
          (profileCreated) => {
            if (profileCreated.profileFound) {
              this.fetchUserDetailsForBackpackerSearchPage();
            }
          });
      } else if (this.sharedService.employerLogin) {
        this.fetchUserDetailsForBackpackerSearchPage();
      }
    }
    if (this.sharedService.employerLogin) {
      this.fetchRecommendedBackpackersForEmployer();
    }

  }

  ngAfterViewInit() {
    this.initializeSwiper();
  }


  initializebackpackerSearchForm() {
    this.backpackerSearchForm = this.formBuilder.group({
      workTypeFilter: [[]],
      workLocationFilter: [[]],
      showExperiencedOnly: [false] // New field for the checkbox

    });
  }

  fetchAllFeaturedBackpackerProfiles(page: number = 1) {
    this.accounts.fetchBackpackerFeaturedProfiles(page).subscribe((response: any) => {
      if (response.status == 'success') {
        this.backpackerProfiles = response.data
        if(this.backpackerProfiles && this.backpackerProfiles.length > 0) {
          for (let i = 0; i < this.backpackerProfiles.length; i++) {
            if (this.backpackerProfiles[i].work_location) {
              this.backpackerProfiles[i].work_location = this.sharedService.mapStatesToAbbreviations(this.backpackerProfiles[i].work_location)
            }
          }
        }
        this.totalPages = response.totalPages;
      }
      this.isLoading = false;
    })
  }

  searchBackpackers(page: number = 1) {
    const formValue = this.backpackerSearchForm.value;

    if (formValue.workTypeFilter.length > 0 || formValue.workLocationFilter.length > 0) {

      let queryParams: any = {};

      // Generate a string of work type filters
      if (formValue.workTypeFilter.length > 0) {
        queryParams.jobType = [];
        for (let i = 0; i < formValue.workTypeFilter.length; i++) {
          queryParams.jobType.push(formValue.workTypeFilter[i].urlSlug);
        }
      }

      // Generate a string of work location filters
      if (formValue.workLocationFilter.length > 0) {
        queryParams.location = [];
        for (let i = 0; i < formValue.workLocationFilter.length; i++) {
          queryParams.location.push(formValue.workLocationFilter[i].urlSlug);
        }
      }
      // Navigate to the URL with the generated parameters
      this.router.navigate(['/search-backpackers'], {queryParams: queryParams});
    } else {
      // If no input or filters are selected, just navigate to the '/jobs' URL without any parameters
      this.router.navigate(['/search-backpackers']);

    }
  }

  showLastOnlineStatus(backpackerProfile: BackpackerProfile): boolean {
    if (backpackerProfile.last_online) {
      const lastOnlineDate = new Date(backpackerProfile.last_online);
      const currentDate = new Date();
      const twoDaysAgo = new Date(currentDate.getTime() - 2 * 24 * 60 * 60 * 1000); // 2 days ago

      // Check if the last_online date is within the last 2 days
      return lastOnlineDate >= twoDaysAgo;
    }
    return false;
  }

  fetchRecommendedBackpackersForEmployer() {
    this.accounts.fetchRecommendedBackpackersForEmployer().subscribe((response: any) => {
      if (response.status == 'success') {
        this.recommendedBackpackers = response.data
        for (let i = 0; i < this.recommendedBackpackers.length; i++) {
          if (this.recommendedBackpackers[i].work_location) {
            this.recommendedBackpackers[i].work_location = this.sharedService.mapStatesToAbbreviations(this.recommendedBackpackers[i].work_location)
          }
        }
      }
    })
  }

  initializeSwiper(): void {
    setTimeout(() => {
      const swiperEl = this.swiperElement?.nativeElement;
      if (swiperEl) {
        const shadowRoot = swiperEl.shadowRoot;
        if (shadowRoot) {
          const styleElement = document.createElement('style');
          styleElement.textContent = `
            .swiper-button-next, .swiper-button-prev {
              background-size: contain !important;
              background-repeat: no-repeat !important;
              background-position: center !important;
              width: 50px;
              height: 50px;
              color: transparent !important;

            }
            .swiper-button-next {
              background-image: url('assets/arrow-next.svg') !important;
              width: 80px !important;
              right: -13px !important;
            }
            .swiper-button-prev {
              background-image: url('assets/arrow-prev.svg') !important;
              width: 80px !important;
              left: -13px !important;
            }
          `;
          shadowRoot.appendChild(styleElement);
          // Additional Swiper configurations
          const mainSwiperOptions: SwiperOptions = {
            spaceBetween: 10,
            loop: true,
            navigation: true,
            breakpoints: {
              320: {
                slidesPerView: 1,
              },
              640: {
                slidesPerView: 2,
              },
              768: {
                slidesPerView: 3,
              },
              1024: {
                slidesPerView: 4,
              }
            }


          };
          Object.assign(swiperEl, mainSwiperOptions);
          swiperEl.initialize();
        }
      }
      this.isLoadingSwiper = false;

    }, 300);

  }

  fetchUserDetailsForBackpackerSearchPage() {
    this.accounts.fetchUserDetailsForBackpackerSearchPage().subscribe((response: any) => {
      if (response.status == 'success') {
        if (response.accountType == 'employer') {
          this.employerUserDetails = response.data
        }
        if (response.accountType == 'employee') {
          this.loggedInBackpackerUserDetails = response.data
          this.loggedInBackpackerUserDetails.work_location = this.sharedService.mapStatesToAbbreviations(this.loggedInBackpackerUserDetails.work_location)

        }
      }
    })
  }

  onPageChange(page: number) {
    let queryParams: any = {};
    const formValue = this.backpackerSearchForm.value;

    // Generate a string of work type filters
    if (formValue.workTypeFilter.length > 0) {
      queryParams.jobType = [];
      for (let i = 0; i < formValue.workTypeFilter.length; i++) {
        queryParams.jobType.push(formValue.workTypeFilter[i]);
      }
    }

    // Generate a string of work location filters
    if (formValue.workLocationFilter.length > 0) {
      queryParams.location = [];
      for (let i = 0; i < formValue.workLocationFilter.length; i++) {
        queryParams.location.push(formValue.workLocationFilter[i]);
      }
    }


    this.currentPage = page;
    if (formValue.workTypeFilter.length > 0 || formValue.workLocationFilter.length > 0) {
      this.searchBackpackers(page);
    } else {
      this.fetchAllFeaturedBackpackerProfiles(page);
    }
  }


  private subscribeToRouteParams() {
    this.route.paramMap.subscribe(params => {
      const searchTerm = params.get('term');
      if (searchTerm) {
        // Generate meta tags before API search
        let metaTitle = '';
        let metaDescription = '';


        switch (searchTerm) {

          // JOB LOCATIONS
          case 'new-south-wales':
            this.metaKeywords.push('new south wales', 'nsw', 'backpackers', 'experienced', 'hire', 'search', 'candidates', 'hire for free', 'rural work', 'farm work', 'fruit picking');
            metaTitle = 'Hire Experienced Backpackers in New South Wales | Backpacker Jobs Now';
            metaDescription = 'Seeking to hire backpackers in New South Wales? Discover experienced candidates for various jobs, such as rural work, farm work, and fruit picking. Contact & hire backpackers for free on Australia\'s official backpacker job board.';
            this.locationTitle = "New South Wales";
            break;
          case 'queensland':
            this.metaKeywords.push('queensland', 'qld', 'backpackers', 'experienced', 'hire', 'search', 'candidates', 'hire for free', 'rural work', 'farm work', 'fruit picking');
            metaTitle = 'Hire Experienced Backpackers in Queensland | Backpacker Jobs Now';
            metaDescription = 'Find and hire skilled backpackers in Queensland for diverse employment opportunities, including roles like rural work or fruit picking. Contact & hire backpackers for free on Australia\'s official backpacker job board.';
            this.locationTitle = "Queensland";
            break;
          case 'victoria':
            this.metaKeywords.push('victoria', 'vic', 'backpackers', 'experienced', 'hire', 'search', 'candidates', 'hire for free', 'rural work', 'farm work', 'fruit picking');
            metaTitle = 'Hire Experienced Backpackers in Victoria | Backpacker Jobs Now';
            metaDescription = 'Hire experienced backpackers in Victoria. Connect with candidates for a variety of positions, from farm work to other sectors. Contact & hire backpackers for free on Australia\'s official backpacker job board.';
            this.locationTitle = "Victoria";
            break;
          case 'western-australia':
            this.metaKeywords.push('western australia', 'wa', 'backpackers', 'experienced', 'hire', 'search', 'candidates', 'hire for free', 'rural work', 'farm work', 'fruit picking');
            metaTitle = 'Hire Experienced Backpackers in Western Australia | Backpacker Jobs Now';
            metaDescription = 'Explore experienced backpackers in Western Australia for all types of jobs, such as rural and farm work. Contact & hire backpackers for free on Australia\'s official backpacker job board.';
            this.locationTitle = "Western Australia";
            break;
          case 'south-australia':
            this.metaKeywords.push('south australia', 'sa', 'backpackers', 'experienced', 'hire', 'search', 'candidates', 'hire for free', 'rural work', 'farm work', 'fruit picking');
            metaTitle = 'Hire Experienced Backpackers in South Australia | Backpacker Jobs Now';
            metaDescription = 'Engage with experienced backpackers in South Australia. Find suitable candidates for a range of jobs, including unique roles like fruit picking. Contact & hire backpackers for free on Australia\'s official backpacker job board.';
            this.locationTitle = "South Australia";
            break;
          case 'northern-territory':
            this.metaKeywords.push('northern territory', 'nt', 'backpackers', 'experienced', 'hire', 'search', 'candidates', 'hire for free', 'rural work', 'farm work', 'fruit picking');
            metaTitle = 'Hire Experienced Backpackers in Northern Territory | Backpacker Jobs Now';
            metaDescription = 'Connect with experienced backpackers ready to work in the Northern Territory. From farm work to other fields, find the best candidates. Contact & hire backpackers for free on Australia\'s official backpacker job board.';
            this.locationTitle = "Northern Territory";
            break;
          case 'tasmania':
            this.metaKeywords.push('tasmania', 'tas', 'backpackers', 'experienced', 'hire', 'search', 'candidates', 'hire for free', 'rural work', 'farm work', 'fruit picking');
            metaTitle = 'Hire Experienced Backpackers in Tasmania | Backpacker Jobs Now';
            metaDescription = 'Need to hire backpackers in Tasmania? Access a pool of experienced candidates for diverse roles, including specialty areas like rural work. Contact & hire backpackers for free on Australia\'s official backpacker job board.';
            this.locationTitle = "Tasmania";
            break;
          case 'australian-capital-territory':
            this.metaKeywords.push('australian capital territory', 'act', 'backpackers', 'experienced', 'hire', 'search', 'candidates', 'hire for free', 'rural work', 'farm work', 'fruit picking');
            metaTitle = 'Hire Experienced Backpackers in Australian Capital Territory | Backpacker Jobs Now';
            metaDescription = 'Hire capable backpackers in the Australian Capital Territory for various job opportunities. Contact & hire backpackers for free on Australia\'s official backpacker job board.';
            this.locationTitle = "Australian Capital Territory";
            break;


          // JOB TYPES/CATEGORIES

          case 'fruit-picking-jobs':
            this.metaKeywords.push('hire fruit pickers', 'rural work', 'backpacker hiring');
            metaTitle = 'Hire Fruit Pickers in Australia | Backpacker Jobs Now';
            metaDescription = 'Hire experienced backpackers for fruit picking jobs across Australia. Ideal for employers looking for reliable workers to meet rural work demands. Contact & hire backpackers for free on Australia\'s official backpacker job board.';
            // HIRE EXPERIENCED BACKPACKERS FOR X In Australia
            this.catTitle = "Fruit Picking Jobs";
            break;

          case 'agriculture-jobs':
            this.metaKeywords.push('hire for agriculture', 'farm work', 'cattle ranching');
            metaTitle = 'Hire for Agriculture Jobs in Australia | Backpacker Jobs Now';
            metaDescription = 'Connect with experienced backpackers for agriculture roles, from farm work to cattle ranching, across Australia. Contact & hire backpackers for free on Australia\'s official backpacker job board.';
            this.catTitle = "Farm Work Jobs";
            break;

          case 'au-pair-jobs':
            this.metaKeywords.push('hire au pairs', 'childcare', 'backpacker au pairs');
            metaTitle = 'Hire Au Pairs in Australia | Backpacker Jobs Now';
            metaDescription = 'Find dependable and experienced backpackers for au pair positions in Australia, perfect for families needing quality childcare. Contact & hire backpackers for free on Australia\'s official backpacker job board.';
            this.catTitle = "Au Pair Roles";
            break;

          case 'conservation-jobs':
            this.metaKeywords.push('conservation work hire', 'environmental jobs', 'wildlife conservation');
            metaTitle = 'Hire for Conservation Jobs in Australia | Backpacker Jobs Now';
            metaDescription = 'Hire passionate & experienced backpackers for conservation projects in Australia, helping protect wildlife and the environment. Contact & hire backpackers for free on Australia\'s official backpacker job board.';
            this.catTitle = "Conservation Jobs";
            break;

          case 'events-jobs':
            this.metaKeywords.push('hire event staff', 'promotions', 'event jobs');
            metaTitle = 'Hire for Events and Promotions in Australia | Backpacker Jobs Now';
            metaDescription = 'Recruit enthusiastic backpackers for events and promotions roles in Australia. Ideal for dynamic and flexible staffing needs. Contact & hire backpackers for free on Australia\'s official backpacker job board.';
            this.catTitle = "Events & Promotions";
            break;

          case 'fishing-jobs':
            this.metaKeywords.push('hire for fishing jobs', 'aquaculture employment', 'seafood harvesting');
            metaTitle = 'Hire for Fishing and Aquaculture Jobs in Australia | Backpacker Jobs Now';
            metaDescription = 'Discover experienced backpackers for fishing and aquaculture roles, perfect for the unique challenges of seafood harvesting. Contact & hire backpackers for free on Australia\'s official backpacker job board.';
            this.catTitle = "Fishing & Aquaculture";
            break;

          case 'hospitality-jobs':
            this.metaKeywords.push('hospitality staff hiring', 'bartending', 'hotel staff');
            metaTitle = 'Hire Hospitality Staff in Australia | Backpacker Jobs Now';
            metaDescription = 'Find skilled backpackers for hospitality jobs from bartending to hotel management in Australia. Contact & hire backpackers for free on Australia\'s official backpacker job board.';
            this.catTitle = "Hospitality Jobs";
            break;

          case 'housekeeping-cleaning-jobs':
            this.metaKeywords.push('hire housekeepers', 'cleaning staff', 'domestic help');
            metaTitle = 'Hire Housekeeping and Cleaning Staff in Australia | Backpacker Jobs Now';
            metaDescription = 'Employ reliable backpackers for housekeeping and cleaning jobs, ensuring high standards in cleanliness and care. Contact & hire backpackers for free on Australia\'s official backpacker job board.';
            this.catTitle = "Housekeeping & Cleaning";
            break;

          case 'landscaping-jobs':
            this.metaKeywords.push('hire landscapers', 'gardening jobs', 'outdoor work');
            metaTitle = 'Hire for Landscaping and Gardening Jobs in Australia | Backpacker Jobs Now';
            metaDescription = 'Hire skilled backpackers for landscaping and gardening roles in Australia. Ideal for maintaining beautiful outdoor spaces. Contact & hire backpackers for free on Australia\'s official backpacker job board.';
            this.catTitle = "Landscaping & Gardening";
            break;

          case 'photography-videography-jobs':
            this.metaKeywords.push('hire photographers', 'videography jobs', 'creative positions');
            metaTitle = 'Hire Photographers and Videographers in Australia | Backpacker Jobs Now';
            metaDescription = 'Connect with talented backpackers skilled in photography and videography, ready to capture stunning visuals in Australia. Contact & hire backpackers for free on Australia\'s official backpacker job board.';
            this.catTitle = "Photography & Videography";
            break;

          case 'sports-fitness-jobs':
            this.metaKeywords.push('hire fitness instructors', 'sports jobs', 'personal trainers');
            metaTitle = 'Hire for Sports and Fitness Jobs in Australia | Backpacker Jobs Now';
            metaDescription = 'Find & hire energetic backpackers for sports and fitness roles, including personal trainers and fitness instructors in Australia. Contact & hire backpackers for free on Australia\'s official backpacker job board.';
            this.catTitle = "Sports & Fitness Jobs";
            break;

          case 'transport-logistics-jobs':
            this.metaKeywords.push('hire logistics staff', 'transport jobs', 'delivery positions');
            metaTitle = 'Hire for Transport and Logistics Jobs in Australia | Backpacker Jobs Now';
            metaDescription = 'Recruit reliable backpackers for transport and logistics roles, ideal for companies needing dependable delivery and coordination. Contact & hire backpackers for free on Australia\'s official backpacker job board.';
            this.catTitle = "Transport & Logistics Jobs";
            break;

          case 'disaster-relief-jobs':
            this.metaKeywords.push('hire disaster relief workers', 'emergency response', 'volunteer roles');
            metaTitle = 'Hire for Disaster Relief Jobs in Australia | Backpacker Jobs Now';
            metaDescription = 'Hire & contact backpackers for disaster relief roles, supporting emergency response efforts across Australia. Contact & hire backpackers for free on Australia\'s official backpacker job board.';
            this.catTitle = "Disaster Relief Jobs";
            break;

          case 'education-teaching-jobs':
            this.metaKeywords.push('hire teachers', 'education roles', 'tutoring jobs');
            metaTitle = 'Hire for Education and Teaching Jobs in Australia | Backpacker Jobs Now';
            metaDescription = 'Hire qualified backpackers for education and teaching positions, from classroom teaching to private tutoring in Australia. Contact & hire backpackers for free on Australia\'s official backpacker job board.';
            this.catTitle = "Education and Teaching Jobs";
            break;

          case 'cleaning-jobs':
            this.metaKeywords.push('hire cleaners', 'flexible cleaning jobs', 'janitorial work');
            metaTitle = 'Hire for Cleaning Jobs in Australia | Backpacker Jobs Now';
            metaDescription = 'Employ diligent backpackers for cleaning roles, providing flexible hours and reliable service across various locations in Australia. Contact & hire backpackers for free on Australia\'s official backpacker job board.';
            this.catTitle = "Cleaning Jobs";
            break;

          case 'technology-jobs':
            this.metaKeywords.push('hire tech talent', 'IT jobs', 'technology roles');
            metaTitle = 'Hire for Technology and IT Jobs in Australia | Backpacker Jobs Now';
            metaDescription = 'Recruit & contact skilled backpackers with technical expertise for IT and technology roles, enhancing your tech team in Australia. Contact & hire backpackers for free on Australia\'s official backpacker job board.';
            this.catTitle = "Tech & IT Jobs";
            break;

          case 'retail-sales-jobs':
            this.metaKeywords.push('hire retail staff', 'sales positions', 'customer service jobs');
            metaTitle = 'Hire for Retail and Sales Jobs in Australia | Backpacker Jobs Now';
            metaDescription = 'Discover experienced backpackers for retail and sales positions, capable of exceptional customer service and sales performance. Contact & hire backpackers for free on Australia\'s official backpacker job board.';
            this.catTitle = "Retail & Sales Jobs";
            break;

          case 'travel-tourism-jobs':
            this.metaKeywords.push('hire travel agents', 'tourism staff', 'adventure guides');
            metaTitle = 'Hire for Travel and Tourism Jobs in Australia | Backpacker Jobs Now';
            metaDescription = 'Employ adventurous backpackers for travel and tourism roles, from tour guides to travel agents, ready to enhance visitor experiences. Contact & hire backpackers for free on Australia\'s official backpacker job board.';
            this.catTitle = "Travel & Tourism Jobs"; // Adds clarity
            break;

          case 'volunteering-jobs':
            this.metaKeywords.push('hire volunteers', 'community service', 'non-profit support');
            metaTitle = 'Hire for Volunteering Jobs in Australia | Backpacker Jobs Now';
            metaDescription = 'Recruit compassionate backpackers for volunteering roles, contributing to community service and non-profit initiatives in Australia. Contact & hire backpackers for free on Australia\'s official backpacker job board.';
            this.catTitle = "Volunteering Jobs";
            break;

          case 'construction-jobs':
            this.metaKeywords.push('hire construction workers', 'labour jobs', 'building projects');
            metaTitle = 'Hire for Construction and Labouring Jobs in Australia | Backpacker Jobs Now';
            metaDescription = 'Find hardworking & experienced backpackers for construction and labouring roles, ready to take on building projects and physical work in Australia. Contact & hire backpackers for free on Australia\'s official backpacker job board.';
            this.catTitle = "Construction Jobs";
            break;

          case 'trade-jobs':
            this.metaKeywords.push('hire tradespeople', 'skilled trades', 'services jobs');
            metaTitle = 'Hire for Trades and Services Jobs in Australia | Backpacker Jobs Now';
            metaDescription = 'Recruit skilled backpackers in trades and services, from electricians to plumbers, essential for maintaining and enhancing infrastructure. Contact & hire backpackers for free on Australia\'s official backpacker job board.';
            this.catTitle = "Trades & Services Jobs";
            break;

          case 'mining-jobs':
            this.metaKeywords.push('hire miners', 'resource extraction', 'mining industry');
            metaTitle = 'Hire for Mining and Resources Jobs in Australia | Backpacker Jobs Now';
            metaDescription = 'Employ experienced & hardworking backpackers in the mining industry, skilled in resource extraction and operational roles in Australia. Contact & hire backpackers for free on Australia\'s official backpacker job board.';
            this.catTitle = "Mining & Resources Jobs";
            break;

          case 'energy-jobs':
            this.metaKeywords.push('hire energy workers', 'utilities jobs', 'sustainable energy roles');
            metaTitle = 'Hire for Energy and Utilities Jobs in Australia | Backpacker Jobs Now';
            metaDescription = 'Recruit backpackers for energy and utilities jobs, ideal for roles in sustainable and conventional energy sectors across Australia. Contact & hire backpackers for free on Australia\'s official backpacker job board.';
            this.catTitle = "Energy & Utilities Jobs";
            break;
        }


        this.meta.setupPageTitle(metaTitle);

        this.meta.setupMetaTags(
          metaTitle,
          metaDescription,
          this.metaKeywords.join(', '),
          this.router.url
        );

        this.waitingOnRouteParams = true;
        this.performSearchBasedOnTerm(searchTerm);
      } else {
        this.hasTerm = false;
        if (!this.waitingOnRouteParams) {
          // this.fetchAllFeaturedBackpackerProfiles(1)
          this.setupDefaultMetaTags();
        }
      }
    });
  }

  subscribeToQueryParamMap() {
    this.route.queryParamMap.subscribe(params => {
      let jobTypeParams = params.getAll('jobType');
      let locationParams = params.getAll('location');

      // Update form values based on query parameters
      this.updateFormValues(jobTypeParams, locationParams);

      // Map job type URL slugs back to names
      jobTypeParams = this.mapUrlsToNames(jobTypeParams, this.globals.jobCategories);
      locationParams = this.mapUrlsToNames(locationParams, this.globals.states);

      if (jobTypeParams.length > 0 || locationParams.length > 0) {
        const jobTypeString = jobTypeParams.join(',');
        const locationString = locationParams.join(',');

        this.metaKeywords.push(jobTypeString, locationString);


        // Generate meta tags before API search
        let metaTitle = '';
        let metaDescription = '';

        if (jobTypeString) {
          metaTitle += `${jobTypeString} jobs`;
          metaDescription += `Search ${jobTypeString} jobs`;
        } else {
          metaTitle += 'Jobs';
        }

        // If a specific location is provided, add it to the title
        if (locationString) {
          metaTitle += ` in ${locationString} Australia`;
          metaDescription += ` in ${locationString} Australia`;
        }
        // If no specific location is provided, default to "in Australia"
        else {
          metaTitle += ' in Australia';
          metaDescription += ' in Australia';
        }

        // If both search input and job type are provided, add job type at the end
        if (jobTypeString) {
          metaTitle += ` - ${jobTypeString}`;
          metaDescription += ` - ${jobTypeString}`;
        }

        // Append site name to the end of the title
        metaTitle += ' | Backpacker Jobs Now - Australia\'s Official Backpacker Job Board';
        metaDescription += '. From rural jobs to farm work and fruit picking, find the perfect job on Backpacker Jobs Now to complete your working holiday visa.';

        // Set the generated meta title
        this.meta.setupPageTitle(metaTitle);

        // Add comments to remember the logic
        // If searchInput is empty and jobTypeString is empty, it defaults to 'jobs'
        // If location is empty, it defaults to 'in Australia'

        this.meta.setupMetaTags(
          metaTitle,
          metaDescription,
          this.metaKeywords.join(', '),
          this.router.url
        );

        this.fetchBackpackersWithSearch();
      } else {
        this.hasFilters = false;
        if (!this.waitingOnRouteParams) {
          this.fetchAllFeaturedBackpackerProfiles(1);
        }
      }
    });
  }

  performSearchBasedOnTerm(searchTerm: string) {

    let matchingSlug;

    for (const item of [...this.globals.jobCategories, ...this.globals.states]) {
      if (item.urlSlug === searchTerm || (item['seoUrls'] && item['seoUrls'].includes(searchTerm))) {
        matchingSlug = item;
        break;
      }
    }

    if (matchingSlug) {
      this.updateFormAndPerformSearch(matchingSlug, searchTerm);
    }
  }

  updateFormAndPerformSearch(matchingSlug: any, searchTerm: string) {
    if (this.globals.jobCategories.some(cat => cat.urlSlug === matchingSlug.urlSlug || (cat['seoUrls'] && cat['seoUrls'].includes(searchTerm)))) {
      this.backpackerSearchForm.controls['workTypeFilter'].setValue([matchingSlug]);
    } else {
      this.backpackerSearchForm.controls['workLocationFilter'].setValue([matchingSlug]);
    }
    this.fetchBackpackersWithSearch();
  }

  private setupDefaultMetaTags() {
    this.meta.setupPageTitle(this.metaTitle);
    this.meta.setupMetaTags(
      this.metaTitle,
      this.metaDescription,
      this.metaKeywords.join(', '),
      this.router.url
    );
  }

  private mapUrlsToNames(slugs: string[], collection: any[]): string[] {
    return slugs.map(slug => {
      const item = collection.find(c => c.urlSlug === slug);
      return item ? item.name : undefined;
    }).filter(value => value);
  }

  mapUrlsToObjects(urlParams: string[], data: any[]): any[] {
    return urlParams
      .map(slug => data.find(item => item.urlSlug === slug))
      .filter(item => item !== undefined); // Filter out any undefined results in case of no match
  }


  updateFormValues(jobTypeParams: string[], locationParams: string[]) {
    // Map job type and location URL slugs to their corresponding objects
    const jobTypeObjects = this.mapUrlsToObjects(jobTypeParams, this.globals.jobCategories);
    const locationObjects = this.mapUrlsToObjects(locationParams, this.globals.states);

    // Set form values with the mapped objects if there are any
    if (jobTypeObjects.length > 0) {
      this.backpackerSearchForm.controls['workTypeFilter'].setValue(jobTypeObjects);
    }

    if (locationObjects.length > 0) {
      this.backpackerSearchForm.controls['workLocationFilter'].setValue(locationObjects);
    }
  }


  fetchBackpackersWithSearch() {
    // Extract 'name' from workTypeFilter objects
    const workTypeNames = this.backpackerSearchForm.value.workTypeFilter.map((item: any) => item.name);
    // Extract 'name' from workLocationFilter objects
    const workLocationNames = this.backpackerSearchForm.value.workLocationFilter.map((item: any) => item.name);

    this.accounts.fetchBackpackerFeaturedProfilesWithSearch(workTypeNames, workLocationNames, this.backpackerSearchForm.value.showExperiencedOnly, 1).subscribe((response: any) => {
      if (response.status == 'success') {
        this.backpackerProfiles = response.data
        this.totalPages = response.totalPages;
        this.isLoading = false;
        if (this.backpackerProfiles && this.backpackerProfiles.length > 0) {
          for (let i = 0; i < this.backpackerProfiles.length; i++) {
            if (this.backpackerProfiles[i].work_location) {
              this.backpackerProfiles[i].work_location = this.sharedService.mapStatesToAbbreviations(this.backpackerProfiles[i].work_location)
            }
          }
        }
      }
    })
  }

}


