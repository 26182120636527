<div
  [ngStyle]="data.error ? {'border-left': '5px solid red'} : {'border-left': '5px solid green'}"
  class="error-dialog flex flex-row items-center">
  <mat-icon *ngIf="data.error">highlight_off</mat-icon>
  <mat-icon *ngIf="!data.error">task_alt</mat-icon>
  <div class="flex flex-col mx-4">
    <h1>{{data.title}}</h1>
    <div class="p-0">
      <p>{{data.message}}</p>
    </div>
  </div>
<!--  <div mat-dialog-actions>-->
<!--    <button (click)="onNoClick()" mat-button>Close</button>-->
<!--  </div>-->
</div>
