export const WHAT_WE_DO = {
  title: 'What We Do Differently',
  copy: `
   <p>
   <strong class="text-primary  ">G’day Backpackers!</strong>
     We're BackpackerJobsNow, and we're revolutionizing the way backpackers find jobs in Australia.
     Whether you're looking to complete your 88 days, find hospitality jobs, or explore residency options, we've got you covered.
   </p>
   <mat-icon>send</mat-icon>
   <h3 class="mt-8 text-lg font-semibold">Verified Employers and Fair Opportunities</h3>
   <p>
     We carefully vet employers to make sure they align with our commitment to fairness and integrity.
     No more stress over what's meant to be an exciting chapter in your life; we're here to ensure your hard work is rewarded.
    </p>

    <h3 class="mt-8 text-lg font-semibold">Built by Backpackers, for Backpackers</h3>
    <p>
      We've picked the fruit, mustered the sheep, and been in your dusty boots.
      We know exactly what you need because we've lived the backpacker life ourselves.
      Our site is handmade by us, for you.
    </p>

    <h3 class="mt-8 text-lg font-semibold">No More Scouring Facebook Groups</h3>
    <p>
      Isn't it odd that there's been no dedicated platform for backpacker jobs, forcing you to sift through social media groups for opportunities?
      We've changed that, making it easy to find the right job without the social media maze.
    </p>

    <h3 class="mt-8 text-lg font-semibold">Find Backpacker Jobs Today</h3>

    <p>
      Finding the right backpacking job in Australia shouldn't be as hard as wrestling a crocodile, and with BackpackerJobsNow, it isn't.
      Our site is completely free. No hidden fees, no wild goose chases, just a straightforward path to the opportunities you want.
      Dive into our job listings and make your Australian adventure a reality.
    </p>



  `,
};
