<div class="flex flex-row justify-end m-2">
  <button (click)="close()" mat-icon-button>
    <mat-icon class="scale-150">close</mat-icon>
  </button>
</div>
<section class="container-space">
  <form [formGroup]='employmentDetails' class='flex flex-col'>
    <h2 class="mb-3">Edit Portfolio Documents</h2>
    <p class="mb-6">This information will be shared when you apply for jobs, you may optionally leave out any
      information you would
      not like to be shared.</p>
    <div class="fileUploadContainer">
      <ngx-dropzone (change)="onSelectResume($event)" [maxFileSize]="3145728" [multiple]="false" class="resume-dropzone"
                    accept="application/pdf, application/msword, application/vnd.openxmlformats-officedocument.wordprocessingml.document">
        <ngx-dropzone-label>Upload New Resume</ngx-dropzone-label>
        <div class="success-overlay" *ngIf="resumeFile">
          <mat-icon>check_circle</mat-icon>
          <div>Resume uploaded successfully</div>
          <button mat-flat-button (click)="onRemoveResume(resumeFileUploaded)">Remove</button>
        </div>
      </ngx-dropzone>
    </div>

    <h3 class="mt-4">A Little About Yourself</h3>
    <p>This is a <strong>cover letter</strong> for your profile - generic for all employers. You can edit this later
      on the application forms to make it unique to the employer.</p>

    <editor [init]="tinymceConfig" apiKey="52gvcmwqj90cb0ungtd1bw4anx2a37qoixz8qjnd7v8b3atl"
            formControlName="cover_letter"></editor>

    <h3 class="mt-4">Your Work Experience </h3>
    <p>This work experience is for your profile - so write about all experience that could be relevant. When
      applying to individual jobs you can change this to be more specific.</p>

    <editor [init]="tinymceConfig2" apiKey="52gvcmwqj90cb0ungtd1bw4anx2a37qoixz8qjnd7v8b3atl"
            formControlName="work_experience"></editor>


    <app-public-profile-work-fields (jobFieldsChange)="workFieldSelectionChange($event)"
                                    [joinTodayLink]=true
                                    [accountTier]="accountTier"
                                    [fullName]="fullName"
                                    [profilePictureUrl]="profileData.profile_picture"
                                    [workLocations]="profileData.work_location"
                                    [hasResume]="employmentDetails.get('resume_link').value"
                                    [hasWorkExperience]="employmentDetails.get('work_experience').value"
                                    [existingWorkFields]="employmentDetails.get('work_fields').value">
    </app-public-profile-work-fields>

    <div class="flex justify-between mt-5">
      <button class="w-full sm:w-1/3 lightBtn generalBtn" (click)="close()" mat-stroked-button>Cancel</button>
      <button class="w-full sm:w-1/3 generalBtn" color="primary" (click)="update()" mat-raised-button>Update Profile
      </button>
    </div>
  </form>
</section>
