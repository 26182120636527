import { Injectable, Inject, PLATFORM_ID } from '@angular/core';
import { HttpInterceptor, HttpRequest, HttpHandler, HttpEvent, HttpResponse } from '@angular/common/http';
import {TransferState, makeStateKey } from '@angular/platform-browser';
import { isPlatformServer, isPlatformBrowser } from '@angular/common';
import {Observable, of, throwError} from 'rxjs';
import { tap, catchError } from 'rxjs/operators';

@Injectable()
export class UniversalInterceptor implements HttpInterceptor {

  constructor(
    @Inject(PLATFORM_ID) private platformId: any,
    private transferState: TransferState
  ) {}

  intercept(request: HttpRequest<any>, next: HttpHandler): Observable<HttpEvent<any>> {
    const isBrowser = isPlatformBrowser(this.platformId);
    const isServer = isPlatformServer(this.platformId);
    const cachedResponseKey = makeStateKey<HttpResponse<any>>(request.url);

    if (isBrowser) {
      const cachedResponse = this.transferState.get(cachedResponseKey, null);
      if (cachedResponse) {
        this.transferState.remove(cachedResponseKey);  // Optional: remove the key if you don't need it anymore
        return of(new HttpResponse(cachedResponse));
      }
    }

    return next.handle(request).pipe(
      tap(event => {
        if (event instanceof HttpResponse) {
          if (isServer) {
            this.transferState.set(cachedResponseKey, event.clone());
          }
        }
      }),
      catchError(error => {
        // Handle errors if needed
        return throwError(error);
      })
    );
  }
}
