<ng-container *ngIf="jobsFound; else noJobsFound">

  <div class="latest-jobs-container flex flex-col items-center">
    <h2 class="font-bold text-2xl text-primary text-center p-2">Showing {{jobs?.length}} Backpacker Jobs</h2>
    <ng-container *ngFor="let job of jobs">
      <a class="w-full" href="/job/{{job.reference}}/{{job.urlSlug}}" target="_blank">
        <button [ngClass]="{'featured': job.adType != 'standard'}" class="job-card" mat-stroked-button>
          <div class="flex flex-row items-center justify-between">
            <img *ngIf="job.jobLogo" alt="job image" class="w-full h-full" src="{{job.jobLogo}}"/>
            <div class="flex flex-col items-start text-left mr-1">
              <h3 class="font-bold text-base sm:text-xl">{{job.jobTitle}}</h3>
              <h4 class="text-sm mt-2 sm:text-base">{{job.companyDisplayName}}</h4>
            </div>
            <div class="flex flex-col items-center ml-auto self-start">
              <button class="applyBtn " mat-stroked-button>Apply Now</button>
            <!--- Featured Job -->
              <div *ngIf="job.adType === 'featured' || job.adType === 'sponsored'" class="featuredBadge">
                Featured
              </div>
            </div>
          </div>
          <p class="text-left">{{sharedService.truncateString(job.jobBriefDesc, 15, 50)}}</p>
          <div class="flex flex-row flex-wrap mt-1">
            <span *ngIf="job.hasVisa" class="highlight">WHV Eligible</span>
            <span *ngIf="job.hasHourly" class="highlight">Hourly Wages</span>
            <span *ngIf="job.hasResidency" class="highlight">Residency Option</span>
          </div>
        </button>
      </a>
    </ng-container>


    <button *ngIf="pageRef =='home'" class="!rounded-3xl w-full mt-4 max-w-lg text-lg !py-6 " color="primary"
            mat-raised-button routerLink="search-jobs">
      See All Backpacker Jobs
      <mat-icon iconPositionEnd>double_arrow</mat-icon>
    </button>
  </div>

  <div *ngIf="!searchedJobs" class="flex justify-center mt-4">
    <button (click)="onPageChange(currentPage - 1)" *ngIf="currentPage > 1" class="controlBtns rotate-180 leading-3">
      <mat-icon>navigate_next</mat-icon>
    </button>

    <ng-container *ngFor="let page of [].constructor(totalPages); let i = index">
      <button (click)="onPageChange(i + 1)" [class.active]="i + 1 === currentPage" class="pageBtn">{{i + 1}}</button>
    </ng-container>

    <button (click)="onPageChange(currentPage + 1)" *ngIf="currentPage < totalPages" class="controlBtns leading-3">
      <mat-icon>navigate_next</mat-icon>
    </button>
  </div>


</ng-container>

<ng-template #noJobsFound>
  <h2 class="font-bold text-lg text-primary text-center p-2">No Jobs Found</h2>
</ng-template>
