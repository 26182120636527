<app-loading-screen *ngIf="isLoadingProfile"></app-loading-screen>


<section *ngIf="!isLoadingProfile"  class="container-space mt-8 md:mt-20">
  <div class="flex md:flex-row flex-col ">

    <mat-card class="flex flex-col p-8 w-full md:w-1/3 mb-4 md:mb-0 min-h-[850px]">
      <div class="pp-container relative">
        <ngx-skeleton-loader [theme]="{height: '10rem', width: '10rem'}" *ngIf="isLoadingProfile; else profilePicture"
                             appearance="circle"></ngx-skeleton-loader>
        <ng-template #profilePicture>

          <img *ngIf="!isEditingProfilePicture && employerProfile?.company_logo == '' " alt="profile picture"
               class="prof-img"
               src="./assets/profile-picture-placeholder.jpg">

          <img *ngIf="!isEditingProfilePicture && employerProfile?.company_logo != ''" alt="profile picture"
               class="prof-img"
               src="{{employerProfile?.company_logo}}">

        </ng-template>

        <div (click)="editProfilePicture()" *ngIf="!isEditingProfilePicture"
             class="cursor-pointer bg-primary iconCircle-lg absolute top-3 right-2">
          <fa-icon [icon]="faPen"></fa-icon>
        </div>

        <div *ngIf="isEditingProfilePicture" class="fileUploadContainer ">

          <ngx-dropzone (change)="onSelectProfilePicture($event)" [maxFileSize]="3145728" [multiple]="false"
                        class="profile-picture-dropzone prof-img"
                        accept="image/png, image/jpeg, image/jpg, image/gif"
          >
            <ngx-dropzone-label>Upload Company Logo</ngx-dropzone-label>
            <ngx-dropzone-image-preview (removed)="onRemove(profilePictureFile)" *ngIf="profilePictureFile"
                                        [file]="profilePictureFile"
                                        [removable]="true" ngProjectAs="ngx-dropzone-preview">
            </ngx-dropzone-image-preview>

          </ngx-dropzone>

          <div class="flex flex-row w-full justify-center gap-5 mt-3">
            <ng-container *ngIf="profilePictureUploaded">
              <button (click)="uploadProfilePicture()" class="" mat-stroked-button>Save & Upload</button>
            </ng-container>
            <ng-container *ngIf="isEditingProfilePicture">
              <button (click)="closeUploader()" class="" mat-stroked-button>Close Uploader</button>
            </ng-container>
          </div>
        </div>

        <h2 *ngIf="isMobile" class="text-center flex-grow mt-2">{{ employerProfile?.company_name }}</h2>


      </div>

      <ng-container *ngIf="shared.is_verified; else unverified">
        <div class="flex justify-center">
          <div class="text-md text-primary verified mt-4 text-center">
            <h4>Account Verified</h4>
            <mat-icon> verified</mat-icon>
          </div>
        </div>
      </ng-container>

      <ng-template #unverified>
        <div class="text-md text-primary px-5 py-1 unverified">
          <h4>Account Unverified</h4>
          <mat-icon> info</mat-icon>
        </div>
      </ng-template>

      <h3 class="font-semibold text-xl mb-3 mt-8">Basic Information</h3>
      <div class="info-item">
        <h3 class="backpacker-info">Company Name:</h3>
        <h4>{{employerProfile?.company_name}}</h4>
      </div>

      <div class="info-item">
        <h3 class="backpacker-info">Email Address:</h3>
        <h4>{{employerProfile?.email}}</h4>
      </div>

      <div *ngIf="isMobile" class="mt-14">
        <h3 class="mb-5">Recent Job Listings</h3>
        <app-employer-job-list [smallWidth]="true"></app-employer-job-list>
      </div>

    </mat-card>


    <mat-card *ngIf="!isMobile" class="flex flex-col p-8 w-full md:w-2/3 md:ml-4">

      <h2 class="text-center ">{{ employerProfile?.company_name }}</h2>

      <div class="mt-14">
        <h3 class="mb-5">Recent Job Listings</h3>
        <app-employer-job-list [smallWidth]="true"></app-employer-job-list>
      </div>
    </mat-card>
  </div>
</section>

