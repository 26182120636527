<div class="shadow-sm sticky w-full top-0 bg-white z-10">
  <div class="navigation-container-space">

    <nav class="flex flex-row items-center p-5 w-full">
      <a href="/" class="websiteLogo mr-4 md:mr-7">
        BackpackerJobs
        <div>now</div>
      </a>


      <div *ngIf="!isMobile" class="flex items-center flex-grow">
        <ng-container *ngFor="let navItem of navItems">
          <ng-container
            *ngIf="(sharedService.employerLogin || sharedService.employeeLogin) && ((sharedService.employerLogin && navItem.displayEmployer) || (sharedService.employeeLogin && navItem.displayEmployee)) || (!sharedService.employerLogin && !sharedService.employeeLogin && navItem.displayDefault)">
            <div>
              <a *ngIf="!navItem.mobOnly" class="navItem nav-pad mx-4 sm:mx-5 lg:mx-7 text-primary font-semibold text-sm"
                 href="{{navItem.route}}">{{navItem.name}}
                <span class="newTag" *ngIf="navItem.newTag">NEW</span>
              </a>
            </div>
          </ng-container>
        </ng-container>

        <ng-container *ngIf="!sharedService.employeeLogin && !sharedService.employerLogin">
          <a class="ml-auto" href="login">
            <button class="loginBtn text-sm lg:!py-4 lg:!px-6 md:text-sm md:!py-3 md:!px-6" color="primary" mat-raised-button>
              Login<span class="hidden xl:inline">/Sign up</span>
              <mat-icon iconPositionEnd>login</mat-icon>
            </button>
            <!--        <button class="!py-10 text-lg" mat-stroked-button>-->
            <!--          <span class="text-primary">Login/Sign up</span>-->
            <!--        </button>-->
          </a>
        </ng-container>

        <!--    <div class="nav-section" *ngIf="(sharedService.employerLogin || sharedService.employeeLogin)">-->
        <!--      <ng-container *ngFor="let loggedInItem of loginNavItems">-->
        <!--        <ng-container-->
        <!--          *ngIf="(sharedService.employerLogin || sharedService.employeeLogin) && ((sharedService.employerLogin && loggedInItem.displayEmployer) || (sharedService.employeeLogin && loggedInItem.displayEmployee))">-->
        <!--          <a class="navItem nav-pad mx-10 text-primary font-semibold" href="{{loggedInItem.route}}">{{loggedInItem.name}}</a>-->
        <!--        </ng-container>-->
        <!--      </ng-container>-->
        <!--    </div>-->


        <div *ngIf="(sharedService.employerLogin || sharedService.employeeLogin)" class="profileDropdown">
          <button (click)="toggleDropdown()" class="flex items-center nav-pad ml-3 sm:ml-4 text-primary font-semibold text-sm">
            My Profile
            <mat-icon
              class="transform scale-125 ml-2">{{ isDropdownVisible ? 'expand_less' : 'expand_more' }}</mat-icon>
          </button>

          <div *ngIf="isDropdownVisible" class="dropdown-content">
            <div class="subNavGroup">
              <p class="nav-label m-0">WELCOME BACK</p>
              <p class="text-primary mt-3 font-medium">{{sharedService.username}}</p>
            </div>
            <div class="subNavGroup">
              <ng-container *ngFor="let loggedInItem of loginNavItems">
                <ng-container
                  *ngIf="(sharedService.employerLogin || sharedService.employeeLogin) && ((sharedService.employerLogin && loggedInItem.displayEmployer) || (sharedService.employeeLogin && loggedInItem.displayEmployee))">
                  <a *ngIf="!loggedInItem.mobOnly" class="subNavItem navItem mx-10 text-primary font-semibold"
                     href="{{loggedInItem.route}}">{{loggedInItem.name}}</a>
                </ng-container>
              </ng-container>
              <a *ngIf="sharedService.employerLogin" class="subNavItem navItem mx-10 text-primary font-semibold"
                 href="/post-job">Post Job</a>
            </div>
            <a (click)="logout()" class="cursor-pointer w-fit mt-4 text-primary text-sm font-medium hover:opacity-60">Logout</a>
          </div>
        </div>

      </div>


      <div *ngIf="isMobile" class="flex items-center ml-auto">
        <div class="nav-partials pr-3">

          <!--Employer Navigation Buttons-->
          <ng-container *ngIf="sharedService.employerLogin">
            <a href="post-job">
              <button class="nav-mob-button" mat-stroked-button>
                <span class="text-primary">Post New Job</span>
              </button>
            </a>
<!--            <a href="employer-profile">-->
<!--              <button class="nav-mob-button" mat-stroked-button>-->
<!--                <span class="text-primary">View Profile</span>-->
<!--              </button>-->
<!--            </a>-->
          </ng-container>

          <!--        Employee Navigation Buttons-->
          <ng-container *ngIf="sharedService.employeeLogin">
            <a href="jobs">
              <button class="nav-mob-button" mat-stroked-button>
                <span class="text-primary ">Search Jobs</span>
              </button>
            </a>
<!--            <a href="profile">-->
<!--              <button class="nav-mob-button" mat-stroked-button>-->
<!--                <span class="text-primary">My Profile</span>-->
<!--              </button>-->
<!--            </a>-->

          </ng-container>

          <!--Default Navigation Buttons-->
          <ng-container *ngIf="!sharedService.employeeLogin && !sharedService.employerLogin && isMobile">
            <a href="search-jobs">
              <button class="mr-5" mat-stroked-button>
                <span class="text-primary">Find a Job</span>
              </button>
            </a>
<!--            <a href="login">-->
<!--              <button class="mr-5" mat-stroked-button>-->
<!--                <span class="text-primary">Login</span>-->
<!--              </button>-->
<!--            </a>-->
          </ng-container>
        </div>

        <div (click)="toggleMobNav()" *ngIf="isMobile" class="mob-nav-btn-container ">
          <div (click)="isMobNavToggled" id="navigation-icon">
            <span></span>
            <span></span>
            <span></span>
            <span></span>
          </div>

          <!--      <mat-icon *ngIf="!isMobNavToggled" aria-hidden="false" aria-label="mobile navigation menu" class="text-primary"-->
          <!--                fontIcon="menu"></mat-icon>-->
          <!--      <mat-icon *ngIf="isMobNavToggled" aria-hidden="false" aria-label="mobile navigation menu" class="text-primary"-->
          <!--                fontIcon="close"></mat-icon>-->
        </div>
      </div>
    </nav>
  </div>
</div>
<app-mobile-side-nav [mobNavToggle]="isMobNavToggled"></app-mobile-side-nav>
