<div [@slideInOut]="mobNavToggle" class="navContainer">
  <div class="nav-section" *ngIf="(sharedService.employerLogin || sharedService.employeeLogin)">
    <p class="nav-pad mt-5 nav-label">WELCOME BACK</p>
    <p class="text-primary mt-3 font-medium nav-pad nav-profile-name">{{sharedService.username}}</p>
  </div>
  <div class="nav-section">
    <p class="nav-pad mt-5 nav-label">NAVIGATION</p>
    <ng-container *ngFor="let navItem of navItems">
      <ng-container
        *ngIf="(sharedService.employerLogin || sharedService.employeeLogin) && ((sharedService.employerLogin && navItem.displayEmployer) || (sharedService.employeeLogin && navItem.displayEmployee)) || (!sharedService.employerLogin && !sharedService.employeeLogin && navItem.displayDefault)">
        <a *ngIf="!navItem.desktopOnly" class="nav-pad mb-5 text-primary font-medium" href="{{navItem.route}}">{{navItem.name}}</a>
      </ng-container>
    </ng-container>
  </div>
  <div class="nav-section" *ngIf="(sharedService.employerLogin || sharedService.employeeLogin)">
    <p class="nav-pad mt-5 nav-label">PROFILE</p>
    <ng-container *ngFor="let loggedInItem of loginNavItems">
      <ng-container
        *ngIf="(sharedService.employerLogin || sharedService.employeeLogin) && ((sharedService.employerLogin && loggedInItem.displayEmployer) || (sharedService.employeeLogin && loggedInItem.displayEmployee))">
        <a *ngIf="!loggedInItem.desktopOnly" class="nav-pad mb-5 text-primary font-medium" href="{{loggedInItem.route}}">{{loggedInItem.name}}</a>
      </ng-container>
    </ng-container>
  </div>
  <div class="flex flex-col justify-end h-full ">
    <a *ngIf="(sharedService.employerLogin || sharedService.employeeLogin)" class="nav-pad cursor-pointer mb-5 text-primary font-medium" (click)="logout()">Logout</a>
  </div>
</div>

