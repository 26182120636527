import {Component, Input} from '@angular/core';
import {SharedService} from "../../services/shared.service";
import {Accounts} from "../../services/accounts.service";

@Component({
  selector: 'app-verification-message',
  templateUrl: './verification-message.component.html',
  styleUrls: ['./verification-message.component.scss']
})
export class VerificationMessageComponent {
  @Input() public accountType: string;
  public verificationLinkText = 'Resend Verification Link';
  public resentLink = false;

  constructor(
    private accounts: Accounts,
    public shared: SharedService) {
  }


  sendEmailVerification() {
    if (!this.resentLink) {
      this.verificationLinkText = 'Sending Link...';
      this.accounts.sendEmailVerification(this.shared.account_email).subscribe(
        (response: any) => {
          if (response.status === 'success') {
            this.resentLink = true;
            this.verificationLinkText = 'Verification Link Sent';
          }
        });
    }
  }
}

