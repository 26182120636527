import {Component, Inject, OnInit, PLATFORM_ID, ViewEncapsulation} from '@angular/core';
import {SharedService} from "../../services/shared.service";
import {ActivatedRoute, Router} from "@angular/router";
import {JobsService} from "../../services/jobs.service";
import {Job} from '../../interfaces/job';
import {ApplyNowComponent} from "../apply-now/apply-now.component";
import {MatDialog, MatDialogConfig} from "@angular/material/dialog";
import {Clipboard} from '@angular/cdk/clipboard';
import {Settings} from '../../globals/settings.model';
import {Accounts} from "../../services/accounts.service";
import {MetaService} from "../../services/meta.service";
import {isPlatformBrowser} from "@angular/common";
import {BreadcrumbService} from "xng-breadcrumb";
import {LeaveReviewComponent} from "../reusables/leave-review/leave-review.component";

@Component({
  encapsulation: ViewEncapsulation.None,
  selector: 'app-job-view',
  templateUrl: './job-view.component.html',
  styleUrls: ['./job-view.component.scss', '../latest-jobs/list-jobs.component.scss']
})
export class JobViewComponent implements OnInit {
  reference: string;
  URLSlug: string;
  jobData: Job = null;
  hasLoaded = false;
  public jobHeroImage = '';
  public accountType = '';
  public applicationSent = false;
  public submittedReview = false;

  constructor(
    @Inject(PLATFORM_ID) private platformId: any,
    private meta: MetaService,
    private accounts: Accounts,
    public globals: Settings,
    public clipboard: Clipboard,
    public sharedService: SharedService,
    private route: ActivatedRoute,
    private router: Router,
    private jobsService: JobsService,
    private breadcrumbService: BreadcrumbService,
    public dialog: MatDialog) {
  }

  ngOnInit() {
    this.route.paramMap.subscribe(params => {
      this.reference = params.get('reference');
      this.URLSlug = params.get('URLSlug');
      this.getJobByReference();
    });
  }

  favouriteClicked() {
    if (this.sharedService.employeeLogin) {
      this.jobData.isSaved = !this.jobData.isSaved;
      this.jobsService.saveJobToFavourites(this.jobData.id, this.jobData.isSaved).subscribe((result: any) => {
          return;
        },
        (error: any) => {
          if (error.error) {
            this.sharedService.openErrorDialog({
              error: true,
              title: 'Error',
              message: error.error.msg || 'This is an error message',
            });
          }
        });
    } else {
      this.router.navigate(['/login']);
    }
  }

  openApplication(): void {

    if (this.sharedService.employeeLogin) {

      this.accounts.checkProfileCreated().subscribe(
        (profileCreated) => {
          if (profileCreated.profileFound) {
            const dialogConfig = new MatDialogConfig();
            dialogConfig.data = {
              jobTitle: this.jobData.jobTitle,
              jobId: this.jobData.id,
            };

            dialogConfig.panelClass = 'apply-now-dialog';  // Add this line

            const dialogRef = this.dialog.open(ApplyNowComponent, dialogConfig);

            dialogRef.afterClosed().subscribe((result) => {
              // Handle the result when the dialog is closed
              if (result) {
                this.applicationSent = true;
                if(!this.submittedReview) {
                  this.triggerReviewWindow();
                }
              }
            });
          } else {
            this.router.navigate(['/create-profile']);
          }
        });
    } else {
      this.router.navigate(['/login']);
    }
  }

  getJobByReference() {
    this.jobsService.getJobByReference(this.reference).subscribe((result: any) => {
      this.jobData = result.data;
      this.breadcrumbService.set('@JobChild', this.jobData.jobTitle);
      this.breadcrumbService.set('@SearchJobs', 'Search Jobs');

      this.accountType = result.accountType;
      this.submittedReview = result.submittedReview;
      const category = this.globals.jobCategories.find(cat => cat.name === this.jobData.jobCategory);

      // check if a matching category was found
      if (category) {
        this.jobHeroImage = category.categoryImage;
      }

      this.meta.setupPageTitle(this.jobData.jobTitle + " | Backpacker Jobs Now - Australia\'s Official Backpacker Job Board ");
      this.meta.setupMetaTags(
        this.jobData.jobTitle,
        this.jobData.jobBriefDesc,
        'job listing, backpacker jobs, Australia, working holiday visa, job search, employment, career, rural work, farm work, casual jobs',
        this.router.url
      );

      this.hasLoaded = true;
    });
  }

  public shareJobClick() {
    if (isPlatformBrowser(this.platformId)) {
      this.clipboard.copy(window.location.href);
    }
  }

  public editListing() {
    this.router.navigate(['/edit-listing', this.jobData.reference, this.jobData.urlSlug]);
  }

  public deleteListing() {
    if (!confirm('Are you sure you want to delete this listing?\nThis action cannot be undone. ')) {
      return;
    } else {
      this.jobsService.deleteJobByReference(this.jobData.reference).subscribe((result: any) => {
        this.router.navigate(['/employer-profile']);
      });
    }
  }

  triggerReviewWindow() {
    const dialogConfig = new MatDialogConfig();
    dialogConfig.width = '500px';
    dialogConfig.minHeight = '400px';

    const dialogRef = this.dialog.open(LeaveReviewComponent, dialogConfig);

    dialogRef.afterClosed().subscribe((result) => {
      // Handle the result when the dialog is closed
      if (result) {
        this.applicationSent = true;
      }
    });

  }

}
