import {Component, Inject, OnInit} from '@angular/core';
import {MatDialogRef, MAT_DIALOG_DATA} from '@angular/material/dialog';
import {FormBuilder, FormGroup, UntypedFormBuilder, Validators} from '@angular/forms';
import {ReviewsService} from "../../../services/reviews.service";

@Component({
  selector: 'app-leave-review',
  templateUrl: './leave-review.component.html',
  styleUrls: ['./leave-review.component.scss']
})
export class LeaveReviewComponent implements OnInit {
  reviewForm: FormGroup;
  stars: boolean[] = Array(5).fill(false);
  rating: number = 5;

  constructor(
    public dialogRef: MatDialogRef<LeaveReviewComponent>,
    @Inject(MAT_DIALOG_DATA) public data: any,
    private formBuilder: UntypedFormBuilder,
    private reviews: ReviewsService
  ) {

  }

  ngOnInit() {
    this.reviewForm = this.formBuilder.group({
      feedback: ['']
    });
  }

  rate(starIndex: number) {
    this.rating = starIndex;
  }

  onSubmit() {
    if (this.reviewForm.valid) {
      const reviewData = {
        rating: this.rating,
        feedback: this.reviewForm.get('feedback').value
      };

      this.reviews.submitReview(reviewData).subscribe((response: any) => {
        this.dialogRef.close(response);
      });

      console.log(reviewData)
      this.dialogRef.close(reviewData);
    }
  }

  onClose() {
    this.dialogRef.close();
  }
}
