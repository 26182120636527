<app-loading-screen *ngIf="loading"></app-loading-screen>

<section class="container-space">
  <div class="mt-12" *ngIf="!loading">
    <div class="mb-6">
      <xng-breadcrumb [separator]="iconTemplate"></xng-breadcrumb>
      <ng-template #iconTemplate>
        <mat-icon>chevron_right</mat-icon>
      </ng-template>
    </div>

    <h1><span class="underline-dec">Backpacker  Information</span></h1>
    <section class="flex flex-wrap">
      <div class="articleContainer" *ngFor="let post of posts; let first = first">
        <a href="backpacker-information/article/{{post.slug.current}}">
          <img class="rounded-lg object-cover w-full h-[240px]" alt="hero image for blog post - {{ post.title }}" src="{{post.imageUrl}}">
          <div *ngIf="first" class="newTag">Latest</div>

          <div class="flex flex-col">
            <h2 class="text-xl mt-2">{{ post.title }}</h2>
            <h3 class="blogPostDate">Updated: {{post.publishedAt | date: 'longDate'}}</h3>
            <p class="text-sm">{{ post.briefDesc }}</p>
            <button mat-flat-button class="lightBtn self-start capitalize text-sm !py-4 !px-3">Read More
              <mat-icon class="!ml-1" iconPositionEnd>chevron_right</mat-icon>
            </button>
          </div>
        </a>
      </div>
    </section>
  </div>
</section>
