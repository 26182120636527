<section class="container-space">
  <a (click)="sharedService.triggerBackButton()" class="flex items-center text-xs font-semibold mt-4 mb-5"> <mat-icon class="scale-75 mr-1">chevron_left</mat-icon> Back To Candidates</a>
  <section class="flex flex-col items-center justify-center prof-top">
    <div class="pp-container relative">

      <div class="profileViewImageContainer">
        <img *ngIf="candidate.profile_picture" alt="profile picture" src="{{candidate.profile_picture}}">
        <img *ngIf="!candidate.profile_picture" alt="profile picture" src="assets/profile-picture-placeholder.jpg">

      </div>
    </div>
    <h2 class="mt-2 mb-8">{{ candidate.full_name }}</h2>
    <div class="flex flex-row w-full justify-center">
      <button (click)="contactNow()" class="generalBtn w-40 mr-4 sm:mr-10" color="primary" mat-raised-button>
        Contact Now
      </button>

      <button *ngIf="!candidate.resume_link" (click)="openPDFViewer(candidate.resume_link)"
              [disabled]="!candidate.resume_link"
              class="lightBtn generalBtn w-40 opacity-25" mat-flat-button>No Resume
      </button>

      <button *ngIf="candidate.resume_link" (click)="openPDFViewer(candidate.resume_link)"
              class="lightBtn generalBtn w-40" mat-flat-button>View Resume
      </button>

    </div>
  </section>

  <section>
    <h3 class="font-semibold text-2xl mb-5">Cover Letter</h3>
    <div *ngIf="candidate.cover_letter" class="mb-5">
      <div *ngIf="!showMoreCoverLetter"
           [innerHTML]="sharedService.truncateString(candidate.cover_letter, readMoreLength, readMoreLength)"></div>
      <div *ngIf="showMoreCoverLetter" [innerHTML]="candidate.cover_letter"></div>
      <div *ngIf="candidate.cover_letter.split(' ').length > readMoreLength"
           (click)="showMoreCoverLetter = !showMoreCoverLetter"
           class="cursor-pointer flex flex-row align-center font-semibold mt-2 opacity-60">
        {{ showMoreCoverLetter ? 'Show Less' : 'Show More' }}
        <mat-icon [ngClass]="{'spin-icon': showMoreCoverLetter}">expand_more</mat-icon>
      </div>
    </div>

    <h3 *ngIf="candidate.is_experienced" class="font-semibold text-2xl mb-5">Work Experience</h3>
    <div *ngIf="candidate.is_experienced" class="mb-5">
      <div *ngIf="!showMoreExperience"
           [innerHTML]="sharedService.truncateString(candidate.work_experience, readMoreLength, readMoreLength)"></div>
      <div *ngIf="showMoreExperience" [innerHTML]="candidate.work_experience"></div>
      <div *ngIf="candidate.work_experience.split(' ').length > readMoreLength"
           (click)="showMoreExperience = !showMoreExperience"
           class="cursor-pointer flex flex-row align-center font-semibold mt-2 opacity-60">
        {{ showMoreExperience ? 'Show Less' : 'Show More' }}
        <mat-icon [ngClass]="{'spin-icon': showMoreExperience}">expand_more</mat-icon>
      </div>
    </div>


  </section>


  <section>
    <h3 class="font-semibold text-2xl mb-5">Basic Information</h3>

    <div class="flex flex-wrap flex-row">
      <div class="headerTextLabel">
        <h4>Full Name</h4>
        <p>{{ candidate.full_name }}</p>
      </div>

      <div class="headerTextLabel">
        <h4>Country of Residence</h4>
        <p>{{ candidate.nationality }}</p>
      </div>

      <div class="headerTextLabel">
        <h4>Worker Type</h4>
        <p>{{ candidate.job_category }}</p>
      </div>


    </div>
  </section>

  <section *ngIf="candidate.website || candidate.linked_in">
    <h3 class="font-semibold text-2xl mb-5">Additional Information</h3>

    <div class="flex flex-wrap flex-row">

      <div *ngIf="candidate.website" class="headerTextLabel">
        <h4>Portfolio/Website</h4>
        <p>{{ candidate.website }}</p>
      </div>

      <div *ngIf="candidate.linked_in" class="headerTextLabel">
        <h4>LinkedIn Page</h4>
        <p>{{ candidate.linked_in }}</p>
      </div>

    </div>
  </section>

</section>


<div class="hireNowMob flex fixed sm:hidden">
  <button (click)="hireNow()" *ngIf="!candidate.is_approved" class="generalBtn w-40 mr-4 sm:mr-10" color="primary"
          mat-raised-button>
    Hire Now
  </button>
  <button (click)="hireNow()" *ngIf="candidate.is_approved" class="generalBtn w-40 mr-4 sm:mr-10" color="primary"
          mat-raised-button>
    Contact Now
  </button>

  <button *ngIf="!candidate.resume_link" (click)="openPDFViewer(candidate.resume_link)"
          [disabled]="!candidate.resume_link"
          class="lightBtn generalBtn w-40 opacity-25" mat-flat-button>No Resume
  </button>

  <button *ngIf="candidate.resume_link" (click)="openPDFViewer(candidate.resume_link)"
          class="lightBtn generalBtn w-40" mat-flat-button>View Resume
  </button>

</div>
