<app-loading-screen *ngIf="loading"></app-loading-screen>


<section *ngIf="!loading" class="container-space ">
  <div class="mt-16">
    <h2 class="text-center mb-3"><span class="underline-dec">Get Hired</span> Without Applying To Jobs</h2>
    <p class="text-center">Take Control & Join The Revolutionary Way To Get Hired!</p>
    <p class="text-center">Feature Your Backpacker Profile On The Front Page & <strong>Let Top Employers Come to
      You!</strong></p>

    <div class="pricingModels"
         [ngClass]="{'two-tiers': existingMembership === 'pro' || existingMembership === 'certified'}">
      <mat-card class="priceCardContainer hvr-float"
                *ngIf="existingMembership != 'pro' && existingMembership != 'certified' ">
        <h2>Standard Account</h2>
        <h3 class="paymentPrice my-4">$0 AUD</h3>
        <p>Find and apply to jobs manually</p>
        <button (click)="selectTier('standard')"
                [ngClass]="{'selected': accountTier === 'standard', 'inverseManageBtn': accountTier === 'standard'}"
                class="manageBtn w-full !text-base mb-2">
          {{ buttonTexts['standard'] }}
        </button>
        <mat-list>
          <mat-list-item class="icon-list">
            <mat-icon matListItemIcon>check</mat-icon>
            <div matListItemTitle>Our Standard Account Type</div>
          </mat-list-item>

          <!--          <mat-list-item class="icon-list">-->
          <!--            <mat-icon matListItemIcon>check</mat-icon>-->
          <!--            <div matListItemTitle>Apply To 3 Jobs Per Day</div>-->
          <!--          </mat-list-item>-->

          <mat-list-item class="icon-list">
            <mat-icon matListItemIcon style="color: red">close</mat-icon>
            <div matListItemTitle>Hidden Profile (Not Listed)</div>
          </mat-list-item>

          <mat-list-item class="icon-list">
            <mat-icon matListItemIcon style="color: red">close</mat-icon>
            <div matListItemTitle>No Access To Profile Share</div>
          </mat-list-item>
        </mat-list>
      </mat-card>

      <mat-card
        [ngClass]="{'recommendedCard': existingMembership != 'pro' && existingMembership != 'certified'}"
        class="priceCardContainer  hvr-float">
        <div class="recommendedBanner" *ngIf="existingMembership != 'pro' && existingMembership != 'certified' ">
          Recommended Choice
        </div>
        <h2>Certified Backpacker</h2>
        <h3 class="paymentPrice mt-4 mb-2">$6.99 AUD <span class="opacity-40 text-xs">Monthly</span></h3>
        <div class="smallHandwriting">The Price of a Small Coffee!</div>
        <p>Instantly advertise your backpacker profile to all Australian employers. Let Employers Find You!</p>
        <button (click)="selectTier('certified')"
                [ngClass]="{'selected': accountTier === 'certified', 'inverseManageBtn': accountTier === 'certified', 'disableBtn': existingMembership === 'certified'}"
                class="manageBtn w-full !text-base">
          {{ buttonTexts['certified'] }}
        </button>
        <mat-list>
          <mat-list-item class="icon-list">
            <mat-icon matListItemIcon>check</mat-icon>
            <div matListItemTitle>Most Popular Choice</div>
          </mat-list-item>

          <mat-list-item class="icon-list">
            <mat-icon matListItemIcon>check</mat-icon>
            <div matListItemTitle>Pinned To Top Of Applications
              <mat-icon class="tooltip-icon"
                        matTooltipClass="tooltip-md"
                        matTooltip="Your profile will appear above the other backpackers on the free plan, giving you more of a chance to stand out."
                        matTooltipPosition="below">info
              </mat-icon>
            </div>
          </mat-list-item>

          <mat-list-item class="icon-list">
            <mat-icon matListItemIcon>check</mat-icon>
            <div matListItemTitle>Unlimited Job Applications</div>
          </mat-list-item>

          <mat-list-item class="icon-list">
            <mat-icon matListItemIcon>check</mat-icon>l
            <div matListItemTitle>Unlimited Job Applications</div>
          </mat-list-item>

          <mat-list-item class="icon-list">
            <mat-icon matListItemIcon>check</mat-icon>
            <div matListItemTitle>New Job Notifications
              <mat-icon class="tooltip-icon"
                        matTooltipClass="tooltip-md"
                        matTooltip="Receive email notifications about newly listed jobs, increasing your chances of getting to the top of the list and finding a job quickly."
                        matTooltipPosition="below">info
              </mat-icon>
            </div>
          </mat-list-item>

          <mat-list-item class="icon-list">
            <mat-icon matListItemIcon>check</mat-icon>
            <div matListItemTitle>Profile Featured To Employers
              <mat-icon class="tooltip-icon"
                        matTooltipClass="tooltip-md"
                        matTooltip="Your profile will be listed on our backpacker search page, allowing employers to find and directly contact you without even applying to jobs!"
                        matTooltipPosition="below">info
              </mat-icon>
            </div>
          </mat-list-item>

          <mat-list-item class="icon-list">
            <mat-icon matListItemIcon>check</mat-icon>
            <div matListItemTitle>Social Media Profile Share Tool
              <mat-icon class="tooltip-icon"
                        matTooltipClass="tooltip-md"
                        matTooltip="Create an engaging link to your backpacker profile that is perfect for sharing on social media platforms and messaging apps."
                        matTooltipPosition="below">info
              </mat-icon>
            </div>
          </mat-list-item>

          <mat-list-item class="icon-list">
            <mat-icon matListItemIcon>check</mat-icon>
            <div matListItemTitle class="!font-semibold">6x More Likely To Find Work</div>
          </mat-list-item>
        </mat-list>

      </mat-card>

      <mat-card class="priceCardContainer hvr-float">
        <h2>Backpacker Pro</h2>
        <h3 class="paymentPrice mt-4 mb-2">$9.99 AUD <span class="opacity-40 text-xs">Monthly</span></h3>
        <div class="smallHandwriting">The Price of a Slightly Larger Coffee!</div>
        <p>All benefits of the others, but get your backpacker profile pinned to the front page for as long as it
          takes you to find a job!</p>
        <button (click)="selectTier('pro')"
                [ngClass]="{
                  'selected': accountTier === 'pro',
                  'inverseManageBtn': accountTier === 'pro',
                  'disableBtn': existingMembership === 'pro'
                }"
                class="manageBtn w-full !text-base">
          {{ buttonTexts['pro'] }}
        </button>
        <mat-list>
          <mat-list-item class="icon-list">
            <mat-icon matListItemIcon>check</mat-icon>
            <div matListItemTitle>Pinned To Top Of Profile Listings
              <mat-icon class="tooltip-icon"
                        matTooltipClass="tooltip-md"
                        matTooltip="Perfect when you need a job asap. Your profile will remain pinned to the top of the listings whilst your membership exists."
                        matTooltipPosition="below">info
              </mat-icon>
            </div>
          </mat-list-item>

          <mat-list-item class="icon-list">
            <mat-icon matListItemIcon>check</mat-icon>
            <div matListItemTitle>Pinned To Top Of Applications
              <mat-icon class="tooltip-icon"
                        matTooltipClass="tooltip-md"
                        matTooltip="Your profile will appear above the other backpackers on the free plan, giving you more of a chance to stand out."
                        matTooltipPosition="below">info
              </mat-icon>
            </div>
          </mat-list-item>

          <mat-list-item class="icon-list">
            <mat-icon matListItemIcon>check</mat-icon>
            <div matListItemTitle><span class="font-semibold">Top Hire </span> Ribbon Attached To Listing</div>
          </mat-list-item>

          <mat-list-item class="icon-list">
            <mat-icon matListItemIcon>check</mat-icon>
            <div matListItemTitle>Unlimited Job Applications</div>
          </mat-list-item>

          <mat-list-item class="icon-list">
            <mat-icon matListItemIcon>check</mat-icon>
            <div matListItemTitle>New Job Notifications
              <mat-icon class="tooltip-icon"
                        matTooltipClass="tooltip-md"
                        matTooltip="Receive email notifications about newly listed jobs, increasing your chances of getting to the top of the list and finding a job quickly."
                        matTooltipPosition="below">info
              </mat-icon>
            </div>
          </mat-list-item>

          <mat-list-item class="icon-list">
            <mat-icon matListItemIcon>check</mat-icon>
            <div matListItemTitle>Profile Featured To Employers
              <mat-icon class="tooltip-icon"
                        matTooltipClass="tooltip-md"
                        matTooltip="Your profile will be listed on our backpacker search page, allowing employers to find and directly contact you without even applying to jobs!"
                        matTooltipPosition="below">info
              </mat-icon>
            </div>
          </mat-list-item>

          <mat-list-item class="icon-list">
            <mat-icon matListItemIcon>check</mat-icon>
            <div matListItemTitle>Social Media Profile Share Tool
              <mat-icon class="tooltip-icon"
                        matTooltipClass="tooltip-md"
                        matTooltip="Create an engaging link to your backpacker profile that is perfect for sharing on social media platforms and messaging apps."
                        matTooltipPosition="below">info
              </mat-icon>
            </div>
          </mat-list-item>

          <mat-list-item class="icon-list">
            <mat-icon matListItemIcon>check</mat-icon>
            <div matListItemTitle class="!font-semibold">10x More Likely To Find Work</div>
          </mat-list-item>

        </mat-list>


      </mat-card>
    </div>


    <app-public-profile-work-fields *ngIf="loaded"
                                    (jobFieldsChange)="workFieldSelectionChange($event)"
                                    [joinTodayLink]=false
                                    [accountTier]="accountTier"
                                    [existingWorkFields]="selectedJobs"
                                    [profilePictureUrl]="profileData.profile_picture"
                                    [workLocations]="profileData.work_location"
                                    [hasResume]="profileData.has_resume"
                                    [hasWorkExperience]="profileData.has_work_experience"
                                    [fullName]="sharedService.username">

    </app-public-profile-work-fields>


    <div class="flex flex-row justify-between flex-wrap mt-8">
      <button routerLink="/profile" class="lightBtn generalBtn !h-10 w-full sm:w-4/12" mat-flat-button>
        Cancel
      </button>


      <button (click)="upgradeAccount()" class="generalBtn !h-10 w-full sm:w-6/12" color="primary"
              mat-raised-button>
        Update Account
      </button>

    </div>
    <div class="mt-4 flex justify-end">
      <a
        *ngIf="profileData?.stripe_customer_id && (profileData?.stripe_subscription_id && profileData?.stripe_subscription_id != 'unsubscribed') && profileData.account_tier != 'standard'"
        class="text-xs underline" [routerLink]="['/cancel-membership']">Cancel My Membership</a>
    </div>
  </div>
</section>
