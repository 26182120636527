import {Component, OnInit} from '@angular/core';
import {UntypedFormBuilder, UntypedFormGroup, Validators} from '@angular/forms';
import {Accounts} from "../../../services/accounts.service";
import {Router} from "@angular/router";
import {SharedService} from "../../../services/shared.service";
import {MetaService} from "../../../services/meta.service";

@Component({
  selector: 'app-signup-modal',
  templateUrl: './employee-signup-window.component.html',
  styleUrls: ['./employee-signup-window.component.scss', '../../login-page/login-page.component.scss']
})
export class EmployeeSignupWindow implements OnInit {
  signupForm: UntypedFormGroup;
  errorMsg: string;
  public metaTitle: string = 'Join For Free & Get Hired Today | Backpacker Jobs Now - Australia\'s Official Backpacker Job Board';
  public metaDescription: string = 'Join Backpacker Jobs Now to find, save, and apply for jobs across Australia. From farm work to fruit picking, your ideal job is just a click away. Sign up and get your working holiday visa today.';
  public metaKeywords: string = 'join,free,get hired,backpacker jobs,find jobs,save jobs,apply jobs,Australia,farm work,fruit picking,working holiday visa';


  constructor(
      private meta: MetaService,
      private router: Router,
      private formBuilder: UntypedFormBuilder,
      private apiService: Accounts,
      private route: Router,
      private sharedService: SharedService) {
  }

  ngOnInit(): void {
    this.meta.setupPageTitle(this.metaTitle);
    this.meta.setupMetaTags(
        this.metaTitle,
        this.metaDescription,
        this.metaKeywords,

        this.router.url
    );

    this.signupForm = this.formBuilder.group({
      full_name: ['', Validators.required],
      email: ['', [Validators.email, Validators.required]],
      password: ['', [Validators.required, Validators.minLength(5)]],
      confirm_password: ['', Validators.required],
      terms: [false, Validators.requiredTrue]
    });
  }

  submitSignup() {
    const formData = {
      full_name: this.signupForm.value.full_name,
      email: this.signupForm.value.email,
      password: this.signupForm.value.password,
      confirm_password: this.signupForm.value.confirm_password,
      terms: this.signupForm.value.terms
    };
    this.apiService.createBackpackerAccount(formData).subscribe(
      (response) => {
        // Save what is needed to storage so we can use it in the create profile component

        localStorage.setItem('account_type', 'employee')
        this.sharedService.employeeLogin = true;
        this.sharedService.employerLogin = false;

        // save username into local storage for use in navbar
        this.sharedService.username = response.data.full_name;
        localStorage.setItem('username', response.data.full_name);

        // save email into local storage for use in the verification messages etc.
        this.sharedService.account_email = response.data.email;
        localStorage.setItem('account_email', response.data.email);
        localStorage.setItem('is_verified', 'true');
        // this.apiService.sendEmailVerification(response.data.email).subscribe();

        this.route.navigate(['/create-profile']);
      },
      (error) => {
        if (error.error.errorType == 'email') {
          this.signupForm.controls['email'].setErrors({'incorrect': true});
        } else {
          this.signupForm.controls['password'].setErrors({'incorrect': true});
        }
        this.errorMsg = error.error.returnMsg;
      }
    );
  }

}
