import {AfterViewInit, Component, Inject, OnInit, PLATFORM_ID} from '@angular/core';
import {ActivatedRoute, Router} from "@angular/router";
import {BlogService} from "../../../services/blog.service";
import {JobsService} from "../../../services/jobs.service";
import {Job} from '../../../interfaces/job';
import {DomSanitizer, SafeHtml} from '@angular/platform-browser';
import {BreadcrumbService} from "xng-breadcrumb";
import {MetaService} from "../../../services/meta.service";
import {DOCUMENT, isPlatformBrowser} from "@angular/common";

@Component({
  selector: 'app-article-view',
  templateUrl: './article-view.component.html',
  styleUrls: ['./article-view.component.scss']
})
export class ArticleViewComponent implements OnInit, AfterViewInit {
  private urlSlug: string;
  public article: any[] = [];
  public faqs: any[] = [];
  public jobSideAds: Job[]
  public htmlContent = ""
  public loading = true;
  public readTime = '';

  constructor(private router: Router, private meta: MetaService, private route: ActivatedRoute, private blog: BlogService, private jobsService: JobsService, private sanitizer: DomSanitizer, private breadcrumbService: BreadcrumbService, @Inject(DOCUMENT) private document: Document, @Inject(PLATFORM_ID) private platformId: Object
  ) {
    this.route.params.subscribe(params => {
      this.urlSlug = params['articleSlug'];

    });
  }

  ngOnInit() {
    this.getJobsForSideAds();
  }

  ngAfterViewInit() {
    this.getArticleBySlug(this.urlSlug);
  }

  getArticleBySlug(slug: string) {
    this.blog.getArticleBySlug(slug).then(data => {
      this.article = data;
      this.breadcrumbService.set('@Child1', this.article['title']);

      this.meta.setupPageTitle(this.article['title'] + " | Backpacker Jobs Now - Australia\'s Official Backpacker Job Board ");
      this.meta.setupMetaTags(
        this.article['title'],
        this.article['briefDesc'],
        'article, backpacker article, Australia, working holiday visa, backpacker jobs info, info, backpacker visa info, rural work, farm work, casual jobs',
        this.router.url
      );


      if (this.article && this.article['body']) {
        this.loading = false;
        this.htmlContent = this.article['body'];
        this.calculateReadTime(this.article['body']);
        this.faqs = this.article['faqs'];
      }
    });
  }

  private getJobsForSideAds() {
    this.jobsService.fetchAllJobs(1).subscribe((result: any) => {
      this.jobSideAds = result.data;
    });
  }

  public toggleFaq(faq: any): void {
    faq.open = !faq.open;
  }

  public calculateReadTime(textContent: any) {
    // Step 1: Create a temporary div element to parse the HTML
    const tempDiv = document.createElement('div');
    tempDiv.innerHTML = textContent;

    // Step 2: Extract the text content without HTML tags
    const plainText = tempDiv.textContent || '';

    // Step 3: Count the number of words (you can define your own word separator)
    const words = plainText.split(/\s+/).filter(word => word.trim() !== '');

    // Step 4: Estimate read time based on average reading speed (e.g., 200 words per minute)
    const wordsPerMinute = 238; // Adjust this value as needed
    const wordCount = words.length;
    const estimatedReadTime = Math.ceil(wordCount / wordsPerMinute);

    // Format the read time as a string
    if (estimatedReadTime < 1) {
      this.readTime = 'Less than 1 minute';
    } else {
      this.readTime = `${estimatedReadTime} Minute${estimatedReadTime > 1 ? 's' : ''}`;
    }
  }

  scrollToElement(id: string): void {
    if (isPlatformBrowser(this.platformId)) {
      const element = this.document.querySelector(`#${id}`);
      if (element) {
        element.scrollIntoView({behavior: 'smooth', block: 'start'});
      }
    }
  }


}
