import {Inject, Injectable, OnInit, PLATFORM_ID} from '@angular/core';
import {ErrorDialogComponent} from "../components/error-dialog/error-dialog.component";
import {MatDialog} from "@angular/material/dialog";
import {ErrorData} from "../components/error-dialog/error";
import {isPlatformBrowser} from "@angular/common";
import {SnackbarComponent} from "../components/snackbar/snackbar.component";
import {MatSnackBar} from "@angular/material/snack-bar";
import {Settings} from "../globals/settings.model";
import {Location} from "@angular/common";

@Injectable({
  providedIn: 'root'
})
export class SharedService {
  isMobile = false;
  public employerLogin = false;
  public employeeLogin = false;
  public is_verified = true;
  public account_email = null;
  public username = null;
  public apiURL = 'https://backpackerjobsnow.com/api/';
  // public apiURL = 'http://localhost:8080/';


  constructor(private dialog: MatDialog, @Inject(PLATFORM_ID) private platformId: any, private snack: MatSnackBar, private globals: Settings, private location: Location) {
    let accountType = localStorage.getItem('account_type');
    let username = localStorage.getItem('username');
    let is_verified = localStorage.getItem('is_verified');
    let account_email = localStorage.getItem('account_email');

    this.employeeLogin = (accountType === 'employee');
    this.employerLogin = (accountType === 'employer');
    this.username = username;
    this.account_email = account_email;
    this.is_verified = is_verified === 'true';

    if (isPlatformBrowser(this.platformId)) {
      if (window.innerWidth < 768) {
        this.isMobile = true;
      }
    }

  }

  public generateRandomString(): string {
    return Math.random().toString(36).substr(2, 8);
  }

  public generateURLSlug(inputString) {
    const stopWords = ["and", "or", "the", "a", "an", "of"];
    const sanitizedString = inputString.toLowerCase().replace(/[^\w\s-]/g, '').replace(/\s+/g, '-');
    const words = sanitizedString.split('-').filter(word => !stopWords.includes(word));
    const url = words.join('-');
    return url;
  }

  public truncateString(input: string, mobLimit: number, desktopLimit: number): string {
    if (input) {
      let limit = desktopLimit;
      if (isPlatformBrowser(this.platformId)) {
        if (window.innerWidth < 768) {
          limit = mobLimit;
        }
      }

      let words = input.split(' ');
      if (words.length > limit) {
        words = words.slice(0, limit);
        return words.join(' ') + '...';
      } else {
        return input;
      }
    }
  }

  public truncateStringStrict(input: string, mobLimit: number, desktopLimit: number): string {
    // Determine the limit based on the device type (mobile or desktop)
    let limit = desktopLimit;
    if (isPlatformBrowser(this.platformId)) {
      if (window.innerWidth < 768) {
        limit = mobLimit;
      }
    }

    // Truncate the string to the specified limit
    if (input.length > limit) {
      return input.substring(0, limit) + '...';
    } else {
      return input; // Return the original string if it's within the limit
    }
  }

  public unsetLocalStorage() {
    localStorage.removeItem('account_id');
    localStorage.removeItem('account_type');
    localStorage.removeItem('backpackerProfileData');
    localStorage.removeItem('username');
    localStorage.removeItem('account_email');
    localStorage.removeItem('is_verified');

  }

  public openErrorDialog(data: ErrorData): void {
    this.dialog.open(ErrorDialogComponent, {
      width: '100%',
      height: 'fit-content',
      maxWidth: '100vw',
      position: {
        top: '80px',
        left: '0px'
      },
      data: data,
    });
  }

  public triggerSnackbar(message: string) {
    this.snack.openFromComponent(SnackbarComponent, {
      data: {
        message: message,
        icon: 'info'
      },
      panelClass: "notificationSnack",
      duration: 5000,
      verticalPosition: this.isMobile ? 'bottom' : 'top',
      horizontalPosition: 'right',
    });

  }

  getFirstNamePlusInitials(fullName: string): string {
    const parts = fullName.trim().split(/\s+/);

    if (!parts.length) {
      return '';
    }

    const firstName = parts[0];
    const lastNameInitial = parts.length > 1 ? parts[parts.length - 1][0].toUpperCase() + '.' : '';

    return `${firstName} ${lastNameInitial}`.trim();
  }

  mapStatesToAbbreviations(longFormStates: string): string {
    if (longFormStates == 'Anywhere In Australia') {
      return longFormStates;
    }

    // Split the input string into an array of state names
    const namesArray = longFormStates.split(/,\s*/).map(name => name.trim());

    // Map each state name to its abbreviation
    const abbreviationsArray = namesArray.map(name => {
      const state = this.globals.states.find(state => state.name.toLowerCase() === name.toLowerCase());
      return state ? state.abbreviation : null;
    });

    // Check if any abbreviation is null
    if (abbreviationsArray.includes(null)) {
      return longFormStates;
    }

    // Join the abbreviations into a string
    return abbreviationsArray.join(', ');
  }


  triggerBackButton() {
    this.location.back();
  }


}
