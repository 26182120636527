<div class="infoMsg mb-6">
  <h4>Struggling To Find Work?</h4>
  <p class="font-bold">Become a member today <span *ngIf="!existingCustomer"> for free </span> and increase the chances of finding a job
    by 10x</p>
  <mat-list class="self-start">
    <mat-list-item class="icon-list">
      <mat-icon matListItemIcon>check</mat-icon>
      <div matListItemTitle>Passively Let Employers Find You</div>
    </mat-list-item>

    <mat-list-item class="icon-list">
      <mat-icon matListItemIcon>check</mat-icon>
      <div matListItemTitle>Get Your Profile Listed On The Front Page</div>
    </mat-list-item>

    <mat-list-item class="icon-list">
      <mat-icon matListItemIcon>check</mat-icon>
      <div matListItemTitle>Access Our Profile Share Feature</div>
    </mat-list-item>

    <mat-list-item class="icon-list">
      <mat-icon matListItemIcon>check</mat-icon>
      <div matListItemTitle>Apply To Unlimited Jobs</div>
    </mat-list-item>
  </mat-list>

  <button *ngIf="!existingCustomer" routerLink="/upgrade-account" class="lightBtn text-xs mt-2 mb-1 self-start !py-3 "  mat-flat-button>Upgrade For Free</button>
  <button *ngIf="existingCustomer" routerLink="/upgrade-account" class="lightBtn text-xs mt-2 mb-1 self-start !py-3 "  mat-flat-button>Renew Membership</button>
</div>
