<section class="cancellation-stepper container-space">
  <mat-horizontal-stepper linear #stepper>
    <mat-step>
      <section class="mb-10">
        <div class="flex items-center flex-col ">
          <div class="max-w-[700px]">
            <h1 class="leading-relaxed uppercase text-center">Update Your Membership</h1>

            <div class="border-b-lightGrey pb-3 border-b items-center flex justify-between">
              <div class="flex flex-col">
                <h2 class="text-base mt-6">{{ productText }} </h2>
                <p class="my-1 text-sm">What you'll pay monthly effective:</p>
                <p class="my-1 text-sm"> {{ renewalDate | date: 'd MMMM y' }} </p>
              </div>
              <div class="flex">
                {{ priceText }}
              </div>
            </div>

            <div class="items-start pt-3 flex justify-between">
              <div class="flex flex-col">
                <p class="text-base m-0">Amount due today</p>
                <a href="{{portalURL}}" target="_blank" class="my-1 text-xs text-grey underline"> Charged on default
                  payment
                  method </a>
              </div>
              <div class="flex-col flex">
                <p class="m-0 font-semibold"> {{ priceText }} </p>
              </div>
            </div>
            <!--            <p>You will be charged <strong>$9.99</strong> on your default payment method.</p>-->
            <!--          <div class="flex items-center justify-center">-->
            <!--            <h3 class="text-base mt-2"> Visa **** 4242 </h3>-->
            <!--            <button routerLink="/upgrade-account" mat-flat-button class="lightBtn ml-3 !h-7">Edit</button>-->
            <!--          </div>-->
          </div>
          <!--          <h3 class="text-base mt-7">Your membership will be cancelled immediately effective {{ Date() | date: 'dd/MM/yyyy' }}</h3>-->
        </div>
      </section>

      <div class="flex justify-center w-full">
        <div class="flex flex-col max-w-[700px]">
          <button [routerLink]="['/upgrade-account']" mat-flat-button class="lightBtn generalBtn mb-4 !h-10">
            Cancel
          </button>
          <button (click)="updateMembership()" mat-raised-button class="generalBtn !h-12" color="primary">Confirm
            Update
          </button>
          <p class="text-center text-sm mt-4 font-semibold">To configure membership, change card details or edit billing
            information
            please see:</p>
          <a href="{{portalURL}}" target="_blank" class="self-center">
            <button mat-flat-button class="lightBtn w-fit ">Edit Your Billing Details</button>
          </a>
        </div>
      </div>
    </mat-step>

    <mat-step>
      <ng-container *ngIf="updateResponse">
        <ng-container *ngIf="updateSuccess; else updateError">
          <section class="mb-6">
            <div class="flex justify-center text-center flex-col">
              <h1 class="leading-relaxed uppercase">Membership Updated Successfully</h1>
              <h3 class="text-base mt-7">Thank you so much for supporting Backpacker Jobs Now. <br> <span
                class="text-sm mt-2">Kindest Regards, Josh <3 </span>
              </h3>
            </div>
          </section>

          <div class="flex justify-center w-full">
            <div class="flex flex-col max-w-[700px]">
              <button [routerLink]="['/profile']" mat-raised-button class="generalBtn !h-12" color="primary">
                Take Me To My Profile
              </button>
            </div>
          </div>
        </ng-container>

        <ng-template #updateError>
          <section class="mb-6">
            <div class="flex justify-center text-center flex-col">
              <h1 class="leading-relaxed uppercase">An Error Has Occurred</h1>
              <h2 class="text-base mt-6">Well.. This Is Awkward.
              </h2>
              <h3 class="text-base mt-7">Please reach out directly to me at <a class="font-semibold"
                                                                               href="mailto:Josh@backpackerjobsnow.com">Josh@backpackerjobsnow.com</a>
                and I can assist you directly.</h3>
            </div>
          </section>

          <div class="flex justify-center w-full">
            <div class="flex flex-col max-w-[700px]">
              <button [routerLink]="['/upgrade-account']" mat-raised-button class="generalBtn !h-12" color="primary">Take Me Back
              </button>
            </div>
          </div>
        </ng-template>
      </ng-container>
    </mat-step>
  </mat-horizontal-stepper>
</section>
