<div class="mt-10">

  <mat-checkbox class="mb-2" (change)="showExperiencedOnlyToggle()"><span class="font-semibold"> Only Show Backpackers With Experience</span>
  </mat-checkbox>

  <ng-container *ngIf="objectKeys(candidates).length > 0; else empty">
    <ng-container *ngFor="let jobTitle of objectKeys(candidates)">
      <h3 class="font-semibold mb-3 mt-6">{{ jobTitle }}</h3>
      <ng-container *ngFor="let candidate of candidates[jobTitle]">
        <div class="applicantContainer px-1 py-2 sm:px-4 sm:py-4 sm:!justify-start"
             *ngIf="!showExperiencedOnly || candidate.is_experienced">
          <div class="flex flex-col sm:flex-row sm:items-start items-center ">
            <div class="sm:flex sm:flex-row items-start sm:mr-4 profileListImageContainer">
              <img *ngIf="candidate.profile_picture" class="mb-3 sm:mb-0" src="{{candidate.profile_picture}}">
              <img *ngIf="candidate?.profile_picture == ''" class="mb-3 sm:mb-0" alt="profile picture"
                   src="assets/profile-picture-placeholder.jpg">
            </div>
            <div class="sm:text-left flex-grow">
              <div class="flex flex-row items-center justify-center sm:justify-normal mb-2">
                <p class="text-primary font-semibold text-lg text-center  sm:text-left">{{candidate.full_name}}</p>
              </div>

              <p class="text-xs text-center sm:text-left font-semibold opacity-70">Available
                Starting: {{candidate.work_date | date:'mediumDate'}}</p>
              <!--                  <p-->
              <!--                    class="overflow-ellipsis text-xs text-left font-light mt-1">{{sharedService.truncateString(candidate.cover_letter, 20, 40)}}</p>-->
              <!--                  -->
              <div [innerHTML]="sharedService.truncateString(candidate.cover_letter, 30, 50)"
                   class="leading-3 	overflow-ellipsis text-sm text-center sm:text-left font-light mt-1"></div>
              <div class="flex flex-row justify-center sm:justify-normal my-3">
                <div *ngIf="candidate.is_experienced" class=" candidateHighlight">Experienced</div>
                <div *ngIf="candidate.has_resume" class=" candidateHighlight"> Resume Attached</div>
              </div>

            </div>
            <div
              class="sm:ml-6 w-full sm:w-auto flex flex-wrap items-start justify-around sm:flex-col flex-none self-center">
              <a (click)="loadCandidateProfile(candidate.job_reference, candidate.candidate_id)"
                 class="text-xs sm:text-sm text-primary mr-2 mb-4 flex items-center hover:opacity-50">
                <mat-icon class="mr-1 sm:mr-3">person</mat-icon>
                View Candidate
              </a>


              <a (click)="shortlistCandidate(candidate.job_id, candidate.candidate_id)"
                 class="text-xs sm:text-sm text-red mr-2 mb-4  flex items-center hover:opacity-50">
                <mat-icon *ngIf="candidate.is_shortlisted" class="mr-1 sm:mr-3">favorite</mat-icon>
                <mat-icon *ngIf="!candidate.is_shortlisted" class="mr-1 sm:mr-3">favorite_border</mat-icon>
                Shortlist
              </a>

              <a (click)="rejectCandidate(candidate.job_id, candidate.candidate_id)"
                 class="text-xs sm:text-sm text-label flex items-center hover:opacity-50 ">
                <mat-icon class="mr-1 sm:mr-3">thumb_down</mat-icon>
                Reject
              </a>
            </div>
          </div>

        </div>
      </ng-container>
    </ng-container>
  </ng-container>

  <ng-template #empty>
    <p>No candidates found.</p>
  </ng-template>

</div>
