import {Component, OnInit} from '@angular/core';
import {Router} from "@angular/router";
import {MetaService} from "../../services/meta.service";
import {SharedService} from "../../services/shared.service";

@Component({
    selector: 'app-job-listings',
    templateUrl: './job-listings.component.html',
    styleUrls: ['./job-listings.component.scss']
})
export class JobListingsComponent implements OnInit {
    public metaTitle: string = 'Manage Job Listings | Backpacker Jobs Now - Australia\'s Official Backpacker Job Board';
    public metaDescription: string = 'Take full control of your job listings. View who\'s saved or applied to your jobs, edit, delete, or pause listings, and manage all aspects of your hiring process. Find the perfect backpacker candidate effortlessly with Backpacker Jobs Now.';
    public metaKeywords: string = 'manage,job listings,full control,favorited,applied,edit,delete,pause,manage,hiring process,perfect,backpacker candidate,effortlessly,Backpacker Jobs Now';

    constructor(
        public shared: SharedService,
        private router: Router,
        private meta: MetaService
    ) {
    }

    ngOnInit() {
        this.meta.setupPageTitle(this.metaTitle);
        this.meta.setupMetaTags(
            this.metaTitle,
            this.metaDescription,
            this.metaKeywords,

            this.router.url
        );
    }

}
