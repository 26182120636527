<section class="container-space article-container" *ngIf="!loading">
  <div class="mt-16">
    <div class="mb-6">
      <xng-breadcrumb [separator]="iconTemplate"></xng-breadcrumb>
      <ng-template #iconTemplate>
        <mat-icon>chevron_right</mat-icon>
      </ng-template>
    </div>
    <div class="flex flex-row flex-wrap justify-between">
      <div class="w-full lg:w-[72%] flex flex-col">
        <h1 class="!text-left">{{article['title']}}</h1>

        <div class="articleData">
          <div class="flex flex-row items-center">
            <p class="publishDate flex items-center">
              <mat-icon class="mr-2">today</mat-icon>
              {{article['publishedAt'] | date: 'longDate'  }}</p>
            <p class="ml-4 readTime flex items-center">
              <mat-icon class="mr-2">schedule</mat-icon>
              {{readTime}} Read
            </p>
          </div>
        </div>

        <img class="mt-6" alt="{{article['title']}} hero image" src="{{article['imageUrl']}}">

        <div class="articleContent">


          <nav class="tableOfContents" *ngIf="article.tableOfContents?.length">
            <h2>Table of Contents</h2>
            <ul>
              <li *ngFor="let item of article.tableOfContents">
                <a (click)="scrollToElement(item.id)">{{ item.text }}</a>
              </li>
            </ul>
          </nav>

          <div *ngIf="htmlContent" [innerHTML]="htmlContent | safeHtml"></div>

          <div class="faqSection" *ngIf="faqs && faqs.length > 0">
            <h2>Frequently Asked Questions</h2>
            <div *ngFor="let faq of faqs">
              <details class="faqContainer" (toggle)="toggleFaq(faq)">
                <summary>
                  <mat-icon>{{ faq.open ? 'remove' : 'add' }}</mat-icon>
                  {{ faq.question }}
                </summary>
                <div class="faqAnswer" [innerHTML]="faq.answer"></div>
                <!-- This will render the HTML content of the answer -->
              </details>
            </div>
          </div>


          <div class="flex justify-center mt-8">
            <button routerLink="/employee-signup" class="font-bold text-lg !py-5 !px-8" mat-raised-button
                    color="primary">Sign Up & Find a Job Today
            </button>
          </div>
        </div>

      </div>
      <div class="mt-10 md:mt-0 w-full lg:w-[24%] flex flex-col items-center">
        <h2 class="text-lg text-center mb-6 underline-dec w-fit">Latest Backpacker Jobs</h2>
        <div class="jobSideAd mb-5 w-full" *ngFor="let job of jobSideAds">
          <a href="job/{{job.reference}}/{{job.urlSlug}}" target="_blank">
            <h3 class="text-base">{{job.jobTitle}}</h3>
            <h4 class="text-sm mt-2">{{job.companyDisplayName}}</h4>
          </a>
        </div>
      </div>
    </div>


  </div>
</section>
