import {Component, HostListener, Inject, OnDestroy, OnInit, PLATFORM_ID} from '@angular/core';
import {Meta, Title} from "@angular/platform-browser";
import {DOCUMENT, isPlatformBrowser} from "@angular/common";
import {Router} from "@angular/router";
import {Accounts} from "./services/accounts.service";
import {SharedService} from "./services/shared.service";

@Component({
  selector: 'app-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.scss'],

})
export class AppComponent implements OnInit {

  title = 'Australia\'s Official Backpacker Job Board - Find Rural, Fruit Picking & WHV Jobs in Australia';

  constructor(
    private accounts: Accounts,
    private metaService: Meta,
    private titleService: Title,
    public router: Router,
    private sharedService: SharedService,
    @Inject(PLATFORM_ID) private platformId: object,
    @Inject(DOCUMENT) private document: Document,
  ) {

  }


  ngOnInit(): void {
    this.setupMetaTags();
    if (isPlatformBrowser(this.platformId) && this.sharedService.employeeLogin) {
      this.checkAndSetOnlineStatus();
    }
  }

  setupMetaTags() {
    this.titleService.setTitle("Australia\'s Official Backpacker Job Board - Find Rural, Fruit Picking & WHV Jobs in Australia");
    this.metaService.addTags([
      {
        name: 'keywords',
        content:
          "backpacking job board, backpacker job board, Australia, backpackers, rural jobs, farm work, fruit picking, working holiday visa, WHV jobs, job applications, Backpacker Jobs Now",
      },
      {
        name: 'description',
        content: "Australia's number 1 backpacker job board built exclusively for backpackers. Find rural, farm work, fruit picking and working holiday visa jobs with ease. Get your working holiday visa completed today with Backpacker Jobs Now.",
      },
      {name: 'robots', content: 'index, follow'},


      {name: 'og:type', content: 'website'},
      {name: 'og:url', content: 'https://backpackerjobsnow.com'},
      {
        name: 'og:title',
        content:
          'Australia\'s Official Backpacker Job Board - Find Rural, Fruit Picking & WHV Jobs in Australia',
      },
      {
        name: 'og:site_name',
        content:
          'Backpacker Jobs Now',
      },
      {
        name: 'og:description',
        content:
          "Australia's number 1 backpacker job board built exclusively for backpackers. Find rural, farm work, fruit picking and working holiday visa jobs with ease. Get your working holiday visa completed today with Backpacker Jobs Now.",
      },
      {
        name: 'og:image',
        content: '/assets/backpacker-jobs-now-cover.jpg',
      },


      {name: 'twitter:url', content: 'https://backpackerjobsnow.com'},
      {
        name: 'twitter:title',
        content:
          'Australia\'s Official Backpacker Job Board - Find Rural, Fruit Picking & WHV Jobs in Australia',
      },
      {
        name: 'twitter:description',
        content:
          "Australia's number 1 backpacking job board built exclusively for backpackers. Find rural, farm work, fruit picking and working holiday visa jobs with ease. Get your working holiday visa completed today with Backpacker Jobs Now.",
      },
      {
        name: 'twitter:image',
        content:
          '/assets/backpacker-jobs-now-cover.jpg',
      },
    ]);
  }

  checkAndSetOnlineStatus() {
    const onlineStatus = sessionStorage.getItem('online_status');
    if (!onlineStatus || onlineStatus !== 'true') {
      this.accounts.setLastSeenStatus().subscribe((response: any) => {
        if (response.status == 'success') {
          sessionStorage.setItem('online_status', 'true');
        } else {
          console.error('Failed to set online status');
        }
      });
    } else {
    }
  }
}
