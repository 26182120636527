import {Injectable} from '@angular/core';
import {HttpClient} from '@angular/common/http';
import {Observable} from 'rxjs';
import {SharedService} from "./shared.service";
import {Router} from "@angular/router";

@Injectable({
  providedIn: 'root'
})
export class Accounts {
  constructor(private http: HttpClient, private settings: SharedService, private router: Router) {
    // if username is not found, logout user or we cannot display it in the navbar
    if ((this.settings.employerLogin || this.settings.employeeLogin) && !this.settings.username) {
      this.logout().subscribe((result: any) => {
      });

    }

  }

  verifyToken(token: string): Observable<any> {
    return this.http.post<any>(this.settings.apiURL + 'accounts/VerifyPasswordResetToken', {token: token});
  }

  VerifyVerificationEmailToken(token: string): Observable<any> {
    return this.http.post<any>(this.settings.apiURL + 'accounts/VerifyVerificationEmailToken', {token: token});
  }

  forgotPassword(email: string): Observable<any> {
    return this.http.post<any>(this.settings.apiURL + 'accounts/ForgottenPassword', {email: email});
  }

  sendEmailVerification(email: string): Observable<any> {
    return this.http.post<any>(this.settings.apiURL + 'accounts/SendVerificationEmail', {email: email}, {withCredentials: true});
  }

  checkProfileCreated(): Observable<any> {
    return this.http.get<any>(this.settings.apiURL + 'accounts/checkProfileCreated', {withCredentials: true});
  }

  createEmployerAccount(formData: any): Observable<any> {
    return this.http.post<any>(this.settings.apiURL + 'accounts/SaveEmployerAccount', formData, {withCredentials: true});
  }

  createBackpackerAccount(formData: any): Observable<any> {
    return this.http.post<any>(this.settings.apiURL + 'accounts/SaveBackpackerAccount', formData, {withCredentials: true});
  }

  saveJobPost(formData: any): Observable<any> {
    return this.http.post<any>(this.settings.apiURL + 'jobs/SaveJobPost', formData, {withCredentials: true});
  }

  updateJobPost(formData: any): Observable<any> {
    return this.http.post<any>(this.settings.apiURL + 'jobs/UpdateJobPost', formData, {withCredentials: true});
  }

  login(formData: any): Observable<any> {
    return this.http.post<any>(this.settings.apiURL + 'accounts/Login', formData, {withCredentials: true});
  }

  logout(): Observable<any> {
    this.settings.employerLogin = false;
    this.settings.employeeLogin = false;
    this.settings.username = '';
    this.settings.unsetLocalStorage();
    this.router.navigate(['/']);
    return this.http.post<any>(this.settings.apiURL + 'accounts/Logout', null, {withCredentials: true});

  }

  saveBackpackerProfile(formData: any): Observable<any> {
    return this.http.post<any>(this.settings.apiURL + 'accounts/SaveBackpackerProfile', formData, {withCredentials: true});
  }

  updateBackpackerWorkFields(formData: any): Observable<any> {
    return this.http.post<any>(this.settings.apiURL + 'accounts/UpdateBackpackerWorkFields', formData, {withCredentials: true});
  }

  updateBasicBackpackerProfile(formData: any): Observable<any> {
    return this.http.post<any>(this.settings.apiURL + 'accounts/UpdateBasicBackpackerProfile', formData, {withCredentials: true});

  }

  updateAdditionalBackpackerProfile(formData: any): Observable<any> {
    return this.http.post<any>(this.settings.apiURL + 'accounts/UpdateAdditionalBackpackerProfile', formData, {withCredentials: true});
  }

  updateDocumentsBackpackerProfile(formData: any): Observable<any> {
    return this.http.post<any>(this.settings.apiURL + 'accounts/updateDocumentsBackpackerProfile', formData, {withCredentials: true});
  }

  UpdateBackpackerProfilePicture(formData: any): Observable<any> {
    return this.http.post<any>(this.settings.apiURL + 'accounts/UpdateBackpackerProfilePicture', formData, {withCredentials: true});
  }

  updateEmployerInfo(formData: any): Observable<any> {
    return this.http.post<any>(this.settings.apiURL + 'accounts/UpdateEmployerInfo', formData, {withCredentials: true});
  }

  loadUserProfile(): Observable<any> {
    return this.http.get<any>(this.settings.apiURL + 'accounts/LoadUserProfile', {withCredentials: true});
  }

  getApplicantDetails(job_ref, backpacker_id): Observable<any> {
    const formData = {job_ref: job_ref, backpacker_id: backpacker_id}
    return this.http.post<any>(this.settings.apiURL + 'accounts/GetApplicantDetails', formData, {withCredentials: true});
  }

  getAllJobApplications() {
    return this.http.get<any>(this.settings.apiURL + 'accounts/FetchJobApplications', {withCredentials: true});
  }

  passwordReset(formData: any): Observable<any> {
    return this.http.post<any>(this.settings.apiURL + 'accounts/ResetPassword', formData);
  }

  backpackerProfileCheckout(profileType: string): Observable<any> {
    const formData = {profileType: profileType}
    return this.http.post(this.settings.apiURL + 'payments/checkout', formData, {withCredentials: true});
  }

  backpackerMembershipUpdate(profileType: string): Observable<any> {
    const formData = {profileType: profileType}
    return this.http.post(this.settings.apiURL + 'payments/UpdateSubscription', formData, {withCredentials: true});
  }

  backpackerCheckoutCancelled(customerID: string): Observable<any> {
    const formData = {customerID: customerID}
    return this.http.post<any>(this.settings.apiURL + 'payments/BackpackerCheckoutCancelled', formData, {withCredentials: true});
  }

  fetchBackpackerFeaturedProfiles(page): Observable<any> {
    const formData = {
      page_number: page
    }
    return this.http.post<any>(this.settings.apiURL + 'accounts/GetFeaturedBackpackerAccounts', formData);
  }

  fetchBackpackerFeaturedProfilesWithSearch(workTypeFilter: Array<string>, workLocationFilter: Array<string>, isExperiencedOnly, page) {
    const formData = {
      workLocationFilter: workLocationFilter,
      workTypeFilter: workTypeFilter,
      isExperiencedOnly: isExperiencedOnly,
      page_number: page
    }
    return this.http.post<any>(this.settings.apiURL + 'accounts/GetFeaturedBackpackerAccountsWithSearch', formData);
  }

  getPublicBackpackerProfileByID(backpackerID: string): Observable<any> {
    const formData = {account_id: +backpackerID}

    if (this.settings.employerLogin) {
      return this.http.post<any>(this.settings.apiURL + 'accounts/GetPublicBackpackerProfileByIDForEmployer', formData, {withCredentials: true});
    } else {
      return this.http.post<any>(this.settings.apiURL + 'accounts/GetPublicBackpackerProfileByIDForPublic', formData);
    }

  }

  setHiddenBackpackerFields(hidden_field: string, value?: number): Observable<any> {
    const formData = {hidden_field: hidden_field, value: value}
    return this.http.post<any>(this.settings.apiURL + 'accounts/ToggleHiddenField', formData, {withCredentials: true});
  }

  getManageMembershipData() {
    return this.http.get<any>(this.settings.apiURL + 'accounts/GetManageMembershipData', {withCredentials: true});
  }

  setLastSeenStatus() {
    return this.http.get<any>(this.settings.apiURL + 'accounts/RecordLastOnlineTime', {withCredentials: true});
  }

  generateResume(): Observable<any> {
    return this.http.get(this.settings.apiURL + 'accounts/GenerateResume', {
      responseType: 'blob',
      withCredentials: true
    });
  }

  checkTrialEnded(): Observable<any> {
    return this.http.get<any>(this.settings.apiURL + 'accounts/CheckTrialEnded', {withCredentials: true});
  }

  fetchRecommendedBackpackersForEmployer(): Observable<any> {
    return this.http.get<any>(this.settings.apiURL + 'accounts/GetRecommendedBackpackersForEmployer', {withCredentials: true});
  }

  fetchUserDetailsForBackpackerSearchPage(): Observable<any> {
    return this.http.get<any>(this.settings.apiURL + 'accounts/fetchUserDetailsForBackpackerSearchPage', {withCredentials: true});
  }

  changeAccountPublicStatus(publicProfile: boolean): Observable<any> {
    const formData = {is_public: publicProfile}
    return this.http.post<any>(this.settings.apiURL + 'accounts/ChangeAccountPublicStatus', formData, {withCredentials: true});
  }

  cancelMembership(): Observable<any> {
    return this.http.get<any>(this.settings.apiURL + 'payments/CancelMembership', {withCredentials: true});
  }

  checkForExistingMembership(): Observable<any> {
    return this.http.get<any>(this.settings.apiURL + 'payments/CheckForExistingMembership', {withCredentials: true});
  }

  getUpdateMembershipData(): Observable<any> {
    return this.http.get<any>(this.settings.apiURL + 'payments/GetUpdateMembershipData', {withCredentials: true});
  }

}
