<app-loading-screen *ngIf="loading"></app-loading-screen>

<section *ngIf="!loading" class="container-space mt-8">
  <section class="banner">
    <div class="flex justify-center text-center flex-col">
      <ng-container *ngIf="catTitle != '' && locationTitle != ''">
        <h1 class="uppercase">Search {{catTitle | uppercase}} IN {{locationTitle | uppercase}} Designed For <span
          class="underline-dec">Backpackers</span></h1>
      </ng-container>

      <ng-container *ngIf="catTitle != '' && locationTitle == ''">
        <h1 class="uppercase">Search {{catTitle | uppercase}} In Australia Designed For <span class="underline-dec">Backpackers</span>
        </h1>
      </ng-container>

      <ng-container *ngIf="catTitle == '' && locationTitle != ''">
        <h1 class="uppercase">Search All Jobs In {{locationTitle | uppercase}} Designed For <span class="underline-dec">Backpackers</span>
        </h1>
      </ng-container>


      <ng-container *ngIf="catTitle == '' && locationTitle == ''">
        <h1 class="uppercase">Search All Jobs Designed For <span class="underline-dec">Backpackers</span></h1>
      </ng-container>

      <p>Search <span class="font-semibold"> Farm Work</span>, <span class="font-semibold">Fruit Picking </span> and
        <span class="font-semibold">Working Holiday Visa </span> Jobs now. Australia's official
        backpacker job board allows you to explore a variety of backpacker jobs near you, with filtering by location and category.</p>

    </div>
  </section>
  <section class="job-search-container flex flex-col mt-20">
    <form [formGroup]="jobSearchForm" class="job-search-form">
      <mat-form-field appearance="outline" class="w-full searchInput">
        <mat-label>Search Backpacker Jobs</mat-label>
        <input formControlName="searchInput" matInput placeholder="Fruit Picking">
        <mat-icon matSuffix>search</mat-icon>
      </mat-form-field>

      <div class="flex flex-col sm:flex-row">
        <mat-form-field appearance="outline" class="mt-5 sm:mt-0 md:mr-3 w-full">
          <mat-label>All Types of Work</mat-label>
          <mat-select formControlName="workTypeFilter" multiple>
            <mat-option *ngFor="let category of globals.jobCategories"
                        [value]="category.urlSlug">{{category.name}}</mat-option>
          </mat-select>
        </mat-form-field>

        <mat-form-field appearance="outline" class="w-full">
          <mat-label>All Locations</mat-label>
          <mat-select formControlName="workLocationFilter" multiple>
            <mat-option *ngFor="let state of globals.states" [value]="state.urlSlug">{{state.name}}</mat-option>
          </mat-select>
        </mat-form-field>
      </div>

      <div class="w-full flex justify-center">
        <button (click)="searchJobs()" class="mt-10 !rounded-xl !py-6 w-full max-w-xl text-lg" color="primary"
                mat-flat-button>
          SEARCH JOBS
        </button>
      </div>
    </form>

  </section>
  <section class="job-list mt-20">
    <app-list-jobs *ngIf="!loading" [jobs]="jobs"></app-list-jobs>
  </section>

  <div *ngIf="jobs?.length > 0" class="flex justify-center mt-4">
    <button (click)="onPageChange(currentPage - 1)" *ngIf="currentPage > 1" class="controlBtns rotate-180 leading-3">
      <mat-icon>navigate_next</mat-icon>
    </button>

    <ng-container *ngFor="let page of [].constructor(totalPages); let i = index">
      <button (click)="onPageChange(i + 1)" [class.active]="i + 1 === currentPage" class="pageBtn">{{i + 1}}</button>
    </ng-container>

    <button (click)="onPageChange(currentPage + 1)" *ngIf="currentPage < totalPages" class="controlBtns leading-3">
      <mat-icon>navigate_next</mat-icon>
    </button>
  </div>


</section>
