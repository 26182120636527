<div class="unverifiedErrorMsg">
  <div class="flex flex-col justify-center items-center">
    <div class="flex flex-col text-center items-center">
      <mat-icon>info</mat-icon>

      <ng-container *ngIf="accountType == 'employer'; else backpackerMsg">
        <strong class="mt-2">Already verified? Sorry about that, simply log out, then log back in!</strong>
        <p class="text-base">To make your job posts visible, please verify your account by clicking on the
          confirmation link sent
          to {{shared.account_email}}</p>
      </ng-container>

      <ng-template #backpackerMsg>
        <strong class="mt-2">Already verified? Sorry about that! Simply log out, then log back in!</strong>
        <p class="text-sm">To apply for jobs, please verify your account by clicking on the
          confirmation link sent
          to {{shared.account_email}}</p>
      </ng-template>
    </div>


    <button *ngIf="!resentLink" mat-stroked-button (click)="sendEmailVerification()"
            class="text-sm">{{verificationLinkText}}
    </button>

    <ng-container *ngIf="resentLink">
      <button class="flex items-center text-sm" color="primary"
              mat-flat-button>Verification Link Sent
        <mat-icon iconPositionEnd>check_circle</mat-icon>
      </button>

      <p class="my-2">(If our email's playing hard to get, try the spam folder!)</p>
    </ng-container>
  </div>
</div>
