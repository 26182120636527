<section class="container-space ">
  <h1 class="mb-2 my-16 " mat-dialog-title>Create an account in seconds...</h1>
  <mat-card class="rounded-2xl mt-12 px-3 md:px-10  py-5">

    <div class="flex flex-col">
      <h2 class="text-sm">Already have an account? <a routerLink="/login">Login Here</a></h2>
      <form [formGroup]="signupForm" class="mt-10 text-center">
        <mat-form-field appearance="fill" class="w-full">
          <mat-label>Full Name</mat-label>
          <input formControlName="full_name" matInput placeholder="John Smith">
          <mat-icon matSuffix>groups</mat-icon>
          <mat-error *ngIf="signupForm.get('full_name').hasError('required')">
            Full Name is required
          </mat-error>
        </mat-form-field>
        <mat-form-field appearance="fill" class="w-full">
          <mat-label>Email</mat-label>
          <input formControlName="email" matInput placeholder="john.smith@gmail.com">
          <mat-icon matSuffix>email</mat-icon>
          <mat-error *ngIf="signupForm.get('email').hasError('required')">
            Email is required
          </mat-error>
          <mat-error *ngIf="signupForm.get('email').hasError('email')">
            Please enter a valid email address
          </mat-error>
        </mat-form-field>

        <mat-form-field appearance="fill" class="w-full">
          <mat-label>Create Password</mat-label>
          <input formControlName="password" matInput type="password">
          <mat-icon matSuffix>vpn_key</mat-icon>

          <mat-error *ngIf="signupForm.get('password').hasError('required')">
            Password is required
          </mat-error>
          <mat-error *ngIf="signupForm.get('password').hasError('minlength')">
            Password must be at least 5 characters
          </mat-error>

        </mat-form-field>

        <mat-form-field appearance="fill" class="w-full">
          <mat-label>Repeat Password</mat-label>
          <input formControlName="confirm_password" matInput type="password">
          <mat-icon matSuffix>vpn_key</mat-icon>
          <mat-error *ngIf="signupForm.get('confirm_password').hasError('required')">
            Password is required
          </mat-error>
          <mat-error *ngIf="signupForm.get('confirm_password').hasError('minlength')">
            Password must be at least 5 characters
          </mat-error>
        </mat-form-field>

        <div *ngIf="errorMsg" class="errorMsg">
          <mat-icon>error_outline</mat-icon>
          <p>{{this.errorMsg}}</p>
        </div>

        <mat-checkbox class="text-sm" formControlName="terms">
          I agree to the <a href="terms-and-conditions" target="_blank">Terms and Conditions</a>
        </mat-checkbox>

        <div>

          <button (click)="submitSignup()" [disabled]="!signupForm.valid" class="lightBtn w-full sm:max-w-xs generalBtn mt-5"
                  mat-flat-button>Sign Up
          </button>
        </div>
      </form>
    </div>
  </mat-card>
</section>
