<app-loading-screen *ngIf="isLoadingProfile"></app-loading-screen>

<section *ngIf="!isLoadingProfile" class="container-space mt-8 md:mt-20">

  <div class="flex justify-between">
    <div *ngIf="paidProfile" (click)="copyPublicProfileToClipboard()"
         class="hover:underline cursor-pointer flex items-center mb-3 mt-3 text-sm">
      <fa-icon class="mr-2" [icon]="faShare"></fa-icon>
      Share Your Profile
    </div>

    <a *ngIf="paidProfile" href="backpacker-profile/{{backpackerProfile?.account_id}}"
       target="_blank" class="flex items-center mb-3 mt-3 text-sm">
      <mat-icon class="mr-2">visibility</mat-icon>
      <span class="hover:underline">View As Public</span> </a>
  </div>
  <div class="flex md:flex-row flex-col ">
    <!-- Backpacker Info Card (right) work rights, visa etc. -->

    <mat-card class="flex flex-col p-8 w-full md:w-1/3 mb-4 md:mb-0">
      <div class="pp-container relative">
        <ngx-skeleton-loader [theme]="{height: '10rem', width: '10rem'}" *ngIf="isLoadingProfile; else profilePicture"
                             appearance="circle"></ngx-skeleton-loader>
        <ng-template #profilePicture>

          <img *ngIf="!isEditingProfilePicture && backpackerProfile?.profile_picture == '' " alt="profile picture"
               class="prof-img"
               src="./assets/profile-picture-placeholder.jpg">

          <img *ngIf="!isEditingProfilePicture && backpackerProfile?.profile_picture != ''" alt="profile picture"
               class="prof-img"
               src="{{backpackerProfile?.profile_picture}}">

        </ng-template>

        <div (click)="editProfilePicture()" *ngIf="!isEditingProfilePicture"
             class="cursor-pointer bg-primary iconCircle-lg absolute top-3 right-2">
          <fa-icon [icon]="faPen"></fa-icon>
        </div>

        <div *ngIf="isEditingProfilePicture" class="fileUploadContainer ">

          <ngx-dropzone (change)="onSelectProfilePicture($event)" [maxFileSize]="3145728" [multiple]="false"
                        class="profile-picture-dropzone prof-img"
                        accept="image/png, image/jpeg, image/jpg, image/gif"
          >
            <ngx-dropzone-label>Upload Profile Picture</ngx-dropzone-label>
            <ngx-dropzone-image-preview (removed)="onRemove(profilePictureFile)" *ngIf="profilePictureFile"
                                        [file]="profilePictureFile"
                                        [removable]="true" ngProjectAs="ngx-dropzone-preview">
            </ngx-dropzone-image-preview>

          </ngx-dropzone>

          <div class="flex flex-row w-full justify-center gap-5 mt-3">
            <ng-container *ngIf="profilePictureUploaded">
              <button (click)="uploadProfilePicture()" class="" mat-stroked-button>Save & Upload</button>
            </ng-container>
            <ng-container *ngIf="isEditingProfilePicture">
              <button (click)="closeUploader()" class="" mat-stroked-button>Close Uploader</button>
            </ng-container>
          </div>
        </div>

        <h2 *ngIf="isMobile" class="text-center flex-grow mt-2">{{ backpackerProfile?.full_name }}</h2>


      </div>

      <button (click)="openEditor('upload-documents')" class="generalBtn lightBtn mt-6" color="primary" mat-flat-button>
        Edit Resume
      </button>

      <!--      <button (click)="buildResume()" class="generalBtn mt-2" color="primary" mat-flat-button>-->
      <!--        Generate Sharable PDF-->
      <!--      </button>-->

      <h3 class="font-semibold text-xl mb-3 mt-5">Account Information</h3>
      <div class="info-item">
        <h3 class="backpacker-info">Membership Plan:</h3>
        <div class="flex flex-row items-center">
          <h4>{{ currentPlanText }}</h4>
          <button *ngIf="backpackerProfile?.account_tier != 'pro'" routerLink="/upgrade-account" mat-flat-button class="lightBtn ml-3 !h-7">Upgrade</button>
        </div>
      </div>

      <div class="mb-4">
        <div class="flex flex-row items-center mt-3">
          <mat-slide-toggle [checked]="backpackerProfile?.is_public_profile == 1 && backpackerProfile?.account_tier != 'standard'" (change)="changeAccountPublicStatus()" [disabled]="backpackerProfile?.account_tier == 'standard'"></mat-slide-toggle>
          <h4 class="ml-2">Public Profile</h4>
        </div>
        <div class="hint" *ngIf="backpackerProfile?.account_tier == 'standard'">Free accounts cannot be made public</div>
        <div class="hint" *ngIf="backpackerProfile?.account_tier != 'standard' && backpackerProfile?.is_public_profile == 1">Account can be discovered by employers</div>
        <div class="hint" *ngIf="backpackerProfile?.account_tier != 'standard' && backpackerProfile?.is_public_profile == 0">Account is private and cannot be discovered</div>
      </div>



      <div class="flex flex-row justify-between items-center">
        <h3 class="font-semibold text-xl mb-3 mt-5">Basic Information</h3>
        <div (click)="openEditor('basic')" class="bg-primary cursor-pointer iconCircle-sm">
          <fa-icon [icon]="faPen"></fa-icon>
        </div>
      </div>
      <div class="info-item">
        <h3 class="backpacker-info">Nationality:</h3>
        <h4>{{ backpackerProfile?.nationality }}</h4>
      </div>

      <div class="info-item">
        <h3 class="backpacker-info">Looking For Work In:</h3>
        <h4>{{ backpackerProfile?.work_location }}</h4>
      </div>

      <div class="info-item">
        <h3 class="backpacker-info">Worker Type:</h3>
        <h4>{{ backpackerProfile?.job_category }}</h4>
      </div>

      <div class="info-item">
        <h3 class="backpacker-info">Contact Number:</h3>
        <h4>{{ backpackerProfile?.contact_number }}</h4>
      </div>

      <div class="info-item">
        <h3 class="backpacker-info">Email Address:</h3>
        <h4>{{ backpackerProfile?.email }}</h4>
      </div>


      <div class="flex flex-row justify-between items-center">
        <h3 class="font-semibold text-xl mb-3 mt-5">Additional Information</h3>
        <div (click)="openEditor('additional')" class="bg-primary cursor-pointer iconCircle-sm">
          <fa-icon [icon]="faPen"></fa-icon>
        </div>
      </div>

      <div class="info-item">
        <h3 class="backpacker-info">Available From:</h3>
        <h4>{{ backpackerProfile?.work_date | date: 'mediumDate' }}</h4>
      </div>

      <div class="info-item">
        <h3 class="backpacker-info">Website/Profile Link:</h3>
        <a *ngIf="backpackerProfile?.website else noWebsite">{{ backpackerProfile?.website }}</a>
        <ng-template #noWebsite>
          <a>-</a>
        </ng-template>
      </div>


      <div class="info-item">
        <h3 class="backpacker-info">LinkedIn Page</h3>
        <a *ngIf="backpackerProfile?.linked_in else noLinkedIn">{{ backpackerProfile?.linked_in }}</a>
        <ng-template #noLinkedIn>
          <a>-</a>
        </ng-template>
      </div>




    </mat-card>


    <!-- Backpacker Details Card (right) descriptions etc. -->
    <mat-card class="flex flex-col p-8 w-full md:w-2/3 md:ml-4">

      <app-upsell-backpacker *ngIf="!paidProfile"></app-upsell-backpacker>

      <div *ngIf="!isMobile" class="flex flex-row items-center">
        <h2 class="text-center flex-grow">{{ backpackerProfile?.full_name }}</h2>
        <div (click)="openEditor('upload-documents')" class="bg-primary cursor-pointer iconCircle-sm">
          <fa-icon [icon]="faPen"></fa-icon>
        </div>
      </div>


      <ng-container *ngIf="paidProfile">
        <h3 class="mt-6">Looking For Work In...</h3>
        <div class="highlights w-full text-center">
        <span *ngFor="let workField of backpackerProfile?.work_fields" class="highlight">
          {{ workField }}
        </span>
        </div>
      </ng-container>

      <div class="mt-6">
        <div class="flex flex-row items-center">
          <h3 class="flex-grow">A Little About Myself...</h3>
          <div *ngIf="isMobile" (click)="openEditor('upload-documents')"
               class="bg-primary cursor-pointer iconCircle-sm">
            <fa-icon [icon]="faPen"></fa-icon>
          </div>
        </div>

        <div *ngIf="!showMoreCoverLetter"
             [innerHTML]="shared.truncateString(backpackerProfile?.cover_letter, readMoreLength, readMoreLength)">

        </div>
        <div *ngIf="showMoreCoverLetter" [innerHTML]="backpackerProfile?.cover_letter"></div>
        <div *ngIf="backpackerProfile?.cover_letter.split(' ').length > readMoreLength"
             (click)="showMoreCoverLetter = !showMoreCoverLetter"
             class="cursor-pointer flex flex-row align-center font-semibold mt-2 opacity-60">
          {{ showMoreCoverLetter ? 'Show Less' : 'Show More' }}
          <mat-icon [ngClass]="{'spin-icon': showMoreCoverLetter}">expand_more</mat-icon>
        </div>
      </div>

      <div class="mt-6">
        <h3>Work Experience</h3>
        <div *ngIf="!showMoreWorkExperience"
             [innerHTML]="shared.truncateString(backpackerProfile?.work_experience, readMoreLength, readMoreLength)">

        </div>

        <div *ngIf="showMoreWorkExperience" [innerHTML]="backpackerProfile?.work_experience"></div>
        <div *ngIf="backpackerProfile?.work_experience.split(' ').length > readMoreLength"
             (click)="showMoreWorkExperience = !showMoreWorkExperience"
             class="cursor-pointer flex flex-row align-center font-semibold mt-2 opacity-60">
          {{ showMoreWorkExperience ? 'Show Less' : 'Show More' }}
          <mat-icon [ngClass]="{'spin-icon': showMoreWorkExperience}">expand_more</mat-icon>
        </div>

        <div *ngIf="!backpackerProfile?.work_experience">
          -
        </div>

      </div>


      <!--  Self Advertise Information    -->
      <ng-container *ngIf="false">


        <h3 class="mt-4 mb-4">Key Information</h3>
        <div class="check-item">
          <mat-icon>check_box</mat-icon>
          <h4>Owns Car</h4>
        </div>
        <div class="check-item">
          <mat-icon>check_box</mat-icon>
          <h4>Drives License</h4>
        </div>
        <div class="check-item">
          <mat-icon>check_box</mat-icon>
          <h4>Blue Card</h4>
        </div>
        <div class="check-item">
          <mat-icon>check_box</mat-icon>
          <h4>White Card</h4>
        </div>
      </ng-container>
    </mat-card>
  </div>
</section>
