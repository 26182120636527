import {AfterViewInit, ChangeDetectorRef, Component, OnInit, ViewChild, ViewEncapsulation} from '@angular/core';
import {UntypedFormBuilder, UntypedFormGroup, Validators} from "@angular/forms";
import {Accounts} from "../../services/accounts.service";
import {JobsService} from "../../services/jobs.service";
import {SharedService} from "../../services/shared.service";
import {UploadFileService} from "../../services/upload-file.service";
import {Settings} from "../../globals/settings.model"
import {ActivatedRoute, Router} from "@angular/router";
import {Stripe} from "@stripe/stripe-js";
import {MetaService} from "../../services/meta.service";
import {MatStepper} from "@angular/material/stepper";
import {environment} from "../../../environments/environment.prod";

declare var Stripe: any;

@Component({
  selector: 'app-job-post',
  templateUrl: './job-post.component.html',
  styleUrls: ['./job-post.component.scss', '../latest-jobs/list-jobs.component.scss'],
})
export class JobPostComponent implements OnInit, AfterViewInit {
  @ViewChild('stepper') stepper: MatStepper;

  public metaTitle: string = 'Create Job Listing | Backpacker Jobs Now - Australia\'s Official Backpacker Job Board';
  public metaDescription: string = 'Post your job in under 5 minutes to instantly connect with 1000s of backpackers in Australia. Find the ideal candidates for roles in agriculture, hospitality, and more. Start your hiring journey today and find the perfect fit for your job openings in Australia with Backpacker Jobs Now';
  public metaKeywords: string = 'create,job listing,post job,5 minutes,instantly connect,1000s,backpackers,Australia,ideal candidates,roles,agriculture,hospitality,more,start,hiring journey,today,perfect fit,job openings,Backpacker Jobs Now';

  public metaEditTitle: string = 'Edit Job Listing | Backpacker Jobs Now - Australia\'s Official Backpacker Job Board';
  public metaEditDescription: string = 'Edit your job listing to attract the right backpacker candidates. Edit details, update requirements, and optimize your listing for better visibility. Make your job stand out and find your ideal backpacker quickly with Backpacker Jobs Now.';
  public metaEditKeywords: string = 'edit,job listing,update,minutes,reach,backpackers,Australia,fine-tune,requirements,roles,agriculture,hospitality,more,perfect match,Backpacker Jobs Now';

  public errorMsg: string;

  private stripe: Stripe;
  public adType: string = 'standard';
  public origAdType: string = '';

  imagePreview?: string;
  generatedReference: string;
  jobPostFormData: any = {};
  isEditing: boolean = false;
  editingUrlSlug: string;


  testCounter = 0;

  public tinymceConfig = {
    height: 400,
    menubar: 'edit ',
    plugins: 'lists',
    placeholder: "Enter the full job description here",
    toolbar: 'undo redo | styles | bullist numlist | bold italic underline | alignleft aligncenter alignright alignjustify | outdent indent',
    apiKey: '52gvcmwqj90cb0ungtd1bw4anx2a37qoixz8qjnd7v8b3atl',
    branding: false
  };

  jobLogo: File | null = null;
  jobLogoUploaded: boolean = false;

  jobPostFormStep1: UntypedFormGroup;
  jobPostFormStep2: UntypedFormGroup;
  jobPostFormStep3: UntypedFormGroup;
  isLinear = true;
  jobTypes = ["Full Time", "Part Time", "Casual", "Contract", "Temporary", "Seasonal"];


  salaryTypeSelected = "Per Hour";


  constructor(
    private cdr: ChangeDetectorRef,
    private formBuilder: UntypedFormBuilder,
    private apiService: Accounts,
    public sharedService: SharedService,
    public jobsService: JobsService,
    private uploadService: UploadFileService,
    public globals: Settings,
    private route: ActivatedRoute,
    private router: Router,
    private meta: MetaService) {

  }

  ngAfterViewInit() {
    this.cdr.detectChanges();
  }

  ngOnInit(): void {
    this.stripe = Stripe(environment.stripeProdKey);

    this.route.params.subscribe(params => {

      const reference = params['reference'];
      const urlSlug = params['urlSlug'];
      if (reference && urlSlug) {
        this.meta.setupPageTitle(this.metaEditTitle);
        this.meta.setupMetaTags(
          this.metaEditTitle,
          this.metaEditDescription,
          this.metaEditKeywords,

          this.router.url
        );


        this.generatedReference = reference;
        this.editingUrlSlug = urlSlug;
        this.isEditing = true; // Set the editing flag to true
        this.prefillJobDetails(reference); // Fetch the job details
      } else {
        this.meta.setupPageTitle(this.metaTitle);
        this.meta.setupMetaTags(
          this.metaTitle,
          this.metaDescription,
          this.metaKeywords,

          this.router.url
        );

      }
    });

    if (!this.isEditing) {
      this.generatedReference = this.sharedService.generateRandomString();
    }
    this.jobPostFormStep1 = this.formBuilder.group({
      jobTitle: ['', Validators.required],
      jobType: ['', Validators.required],
      jobCategory: ['', Validators.required],
      jobCountry: ['Australia', Validators.required],
      jobState: ['', Validators.required],
      jobSuburb: ['', Validators.required],
      jobPostcode: ['', Validators.required],
    });
    this.jobPostFormStep2 = this.formBuilder.group({
      jobBriefDesc: ['', Validators.required],
      salaryMin: [],
      salaryMax: [],
      salaryType: ['hour', Validators.required],
      jobFullDesc: ['', Validators.required],
    });
    this.jobPostFormStep3 = this.formBuilder.group({
      companyWebsite: ['',],
      hasVisa: [],
      hasResidency: [],
      hasHourly: [],
    });

    this.jobPostFormStep1.get('jobCountry').disable();

  }

  onSelect(event) {
    this.jobLogo = event.addedFiles[0]; // Only accept the first file
    this.jobLogoUploaded = true;
  }

  onRemove(event) {
    this.jobLogo = null;
  }

  async uploadCompanyLogo(): Promise<string> {
    let jobLogoUrl = "";

    if (!this.jobLogo) {
      return Promise.resolve("");
    }

    let filePath = 'company_job_logos/' + Math.random() * 10000000000 + "_" + this.jobLogo.name;

    try {
      const response = await this.uploadService.uploadFile(this.jobLogo, filePath).toPromise();
      if (response.status === "success" && response['fileURL']) {
        jobLogoUrl = response['fileURL'];
      }
    } catch (error) {
      console.error("An error occurred during upload:", error);
      // Handle error appropriately
    }

    return jobLogoUrl;
  }


  reviewJobPost() {
    this.jobPostFormStep2.value.salaryMin = parseFloat(this.jobPostFormStep2.value.salaryMin);
    this.jobPostFormStep2.value.salaryMax = parseFloat(this.jobPostFormStep2.value.salaryMax);

    this.jobPostFormData = Object.assign({}, this.jobPostFormStep1.value, this.jobPostFormStep2.value, this.jobPostFormStep3.value);

    //add reference and jobUrl
    this.jobPostFormData.reference = this.generatedReference;

    if (!this.isEditing) {
      this.jobPostFormData.urlSlug = this.sharedService.generateURLSlug(this.jobPostFormStep1.value.jobTitle);
    } else {
      this.jobPostFormData.urlSlug = this.editingUrlSlug;
    }


  }

  async submitJobPost() {
    try {

      const uploadedImageUrl = await this.uploadCompanyLogo();

      this.jobPostFormData.jobLogo = uploadedImageUrl;
      if (!this.isEditing) {
        this.apiService.saveJobPost(this.jobPostFormData).subscribe(
          response => {
            if (this.adType != 'standard') {
              this.onCheckout();
            } else {
              this.router.navigate(['/job/' + response.data.reference + '/' + response.data.urlSlug])
            }
          },
          error => console.error(error)
        );
      } else {
        this.apiService.updateJobPost(this.jobPostFormData).subscribe(
          response => {
            if ((this.adType != this.origAdType) && (this.adType != 'standard')) {
              this.onCheckout();
            } else {
              this.router.navigate(['/job/' + response.data.reference + '/' + response.data.urlSlug])
            }
          },
          error => console.error(error)
        );
      }
    } catch (error) {
      console.error('Image upload failed:', error);
    }
  }

  onCheckout() {

    this.jobsService.createStripeSession(this.adType, this.generatedReference).subscribe((session: any) => {
      this.stripe.redirectToCheckout({
        sessionId: session.sessionId
      }).then((result) => {
        // Handle result.error here if the redirect fails
      });
    });
  }


  prefillJobDetails(reference: string) {
    this.jobsService.editJobByReference(reference).subscribe(
      response => {
        let jobData = response.data;
        this.jobPostFormStep1.patchValue({
          jobTitle: jobData.jobTitle,
          jobType: jobData.jobType,
          jobCategory: jobData.jobCategory,
          jobCountry: jobData.jobCountry,
          jobState: jobData.jobState,
          jobSuburb: jobData.jobSuburb,
          jobPostcode: jobData.jobPostcode,
        });
        this.jobPostFormStep2.patchValue({
          jobBriefDesc: jobData.jobBriefDesc,
          salaryMin: jobData.salaryMinimum,
          salaryMax: jobData.salaryMaximum,
          salaryType: jobData.salaryType,
          jobFullDesc: jobData.jobFullDesc,
        });
        this.jobPostFormStep3.patchValue({
          companyWebsite: jobData.companyWebsite,
          hasHourly: jobData.hasHourly,
          hasVisa: jobData.hasVisa,
          hasResidency: jobData.hasResidency,
        });


        this.adType = this.origAdType = jobData.adType;
      },
      error => this.router.navigate(['/'])
    );
  }

  selectTier(tierSelection) {
    this.adType = tierSelection;
  }

  shouldDisableTier(tier: string): boolean {
    if (!this.isEditing) return false;
    if (this.origAdType === 'sponsored') return tier !== 'sponsored';
    if (this.origAdType === 'featured') return tier === 'standard';
    return false;
  }

  getButtonTextTier(tier: string): string {
    if (this.adType === tier) {
      return 'Selected';
    }
    if (this.isEditing && !this.shouldDisableTier(tier) && tier !== this.origAdType) {
      return 'Upgrade';
    }
    if (this.isEditing && tier === this.origAdType && this.adType !== this.origAdType) {
      return 'Select';
    }
    return 'Select';
  }

  testMode() {
    this.testCounter += 1

    if (this.testCounter > 10) {
      this.adType = 'test'
    }

  }

  selectionChange($event: any) {
    if ($event.selectedIndex == 3) {
      this.reviewJobPost();
    }
  }

  formValidation(formStep: number) {
    this.errorMsg = ""; // Clear previous error messages

    if (formStep === 1) {
      if (!this.jobPostFormStep1.value.jobType) {
        this.errorMsg = "Please select a work type.";
        return false; // Stop form progression
      }
    }

    if (formStep === 2) {
      const salaryMin = this.jobPostFormStep2.value.salaryMin;
      const salaryMax = this.jobPostFormStep2.value.salaryMax;

      // Validate only if salaryMin or salaryMax is not empty
      if (salaryMin || salaryMax) {
        const numSalaryMin = parseFloat(salaryMin);
        const numSalaryMax = parseFloat(salaryMax);

        // Check if numbers are valid
        if (isNaN(numSalaryMin) || isNaN(numSalaryMax)) {
          this.errorMsg = "Please enter valid numeric values for salary.";
          return false; // Stop form progression
        }

        // Ensure max is greater than or equal to min if both are provided
        if (numSalaryMin > numSalaryMax) {
          this.errorMsg = "Maximum salary should be greater than or equal to the minimum salary.";
          return false; // Stop form progression
        }
      }

      if (!this.jobPostFormStep2.value.jobFullDesc || this.jobPostFormStep2.value.jobFullDesc.trim() === "") {
        this.errorMsg = "Please enter job description.";
        return false; // Stop form progression
      }
    }

    return true;
  }

  checkValidationThenNextStep(formStep: number) {
    const isValid = this.formValidation(formStep);
    if (isValid) {
      this.stepper.next(); // Assuming `stepper` is a ViewChild of MatStepper
    } else {
      // Handle the error case, maybe show a message or shake the form
      console.error(this.errorMsg);
    }
  }



}
