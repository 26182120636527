<ng-container *ngIf="hasLoaded">
  <div class="w-full h-40 sm:h-60 overflow-y-hidden">
    <img alt="placeholder" class="img-responsive object-cover object-center w-full h-full"
         src="{{jobHeroImage}}">
  </div>
  <div *ngIf="jobData.jobLogo" class="flex flex-row justify-center -mt-10">
    <div class="logoContainer">
      <img alt="placeholder" class="img-responsive" src="{{jobData.jobLogo}}">
    </div>
  </div>




  <section class="container-space !mt-0">
    <a href="/search-jobs" class="flex items-center text-xs font-semibold mt-4 mb-5"> <mat-icon class="scale-75 mr-1">chevron_left</mat-icon> Back To Search</a>
    <h1 class="text-center mt-5 text-3xl">{{jobData.jobTitle}}</h1>
    <h2 class="text-center mt-3 text-xl">{{jobData.companyDisplayName}}</h2>
    <section class="section-divider !mt-0">

      <div id="badgesContainer">
        <div class="highlights justify-center sm:justify-normal text-center">
          <span *ngIf="jobData.hasVisa" class="highlight">WHV Eligible</span>
          <span *ngIf="jobData.hasHourly" class="highlight">Hourly Wages</span>
          <span *ngIf="jobData.hasResidency" class="highlight">Residency Option</span>
        </div>
      </div>

      <!--  Show verification if not verified-->
      <div class="my-6" *ngIf="!sharedService.is_verified && sharedService.employeeLogin">
        <app-verification-message [accountType]="'backpacker'"></app-verification-message>
      </div>

      <div class="flex flex-row flex-wrap justify-center sm:justify-start mt-8">
        <!-- Employers will be the only ones to have isOwner = true  -->
        <ng-container *ngIf="accountType == 'employer' else defaultJobView">
          <ng-container *ngIf="jobData.isOwner">
            <a href="candidates?ref={{jobData.reference}}">
              <button class="generalBtn mr-4 mt-2" color="primary" mat-flat-button>Candidates</button>
            </a>
            <button (click)="editListing()" class="lightBtn generalBtn mt-2" mat-flat-button>Edit Post</button>
            <button (click)="deleteListing()" color="warn" class="ml-auto !min-w-0 mt-2" mat-flat-button>
              <div class="flex flex-col items-center">
                <mat-icon>delete</mat-icon>
              </div>
            </button>
          </ng-container>
        </ng-container>

        <ng-template #defaultJobView>
          <button [disabled]="!sharedService.is_verified && sharedService.employeeLogin" (click)="openApplication()"
                  *ngIf="!jobData.isApplied && !this.applicationSent "
                  class="generalBtn mr-4"
                  color="primary" mat-raised-button>
            Apply Now
          </button>
          <button *ngIf="jobData.isApplied || this.applicationSent"
                  class="generalBtn opacity-70 mr-4" disabled
                  mat-flat-button>Submitted
          </button>

          <button (click)="favouriteClicked()" *ngIf="!jobData.isSaved" class="lightBtn generalBtn" mat-flat-button>Save
            Job
          </button>
          <button (click)="favouriteClicked()" *ngIf="jobData.isSaved" class="lightBtn generalBtn" mat-flat-button>
            Unsave Job
          </button>
        </ng-template>
      </div>


    </section>


    <div #jobDetailsContainer class="jobDetailsContainer">

      <section class="section-divider !mt-0">
        <h2 class="mb-3">Overview</h2>
        <div class="overview flex flex-row flex-wrap">
          <h4>
            <div class="opacity-50 text-sm">Posted</div>
            <div class="mt-2">{{jobData?.postTime ? (jobData.postTime | date: 'mediumDate') : '-'}}</div>
          </h4>

          <h4>
            <div class="opacity-50 text-sm">Category</div>
            <div class="mt-2" placeholder="-">{{jobData?.jobCategory || '-'}}</div>
          </h4>
          <h4>
            <div class="opacity-50 text-sm">Job Type</div>
            <div class="mt-2" placeholder="-">{{jobData?.jobType || '-'}}</div>
          </h4>

          <ng-container
            *ngIf="jobData?.salaryMinimum && jobData?.salaryMaximum && jobData?.salaryType">
            <h4>
              <div class="opacity-50 text-sm">Salary</div>
              <div class="mt-2">${{jobData.salaryMinimum | number:'1.2-2' }} - ${{jobData.salaryMaximum | number:'1.2-2' }} per {{jobData.salaryType}}</div>
            </h4>
          </ng-container>
<!--          <ng-template #salaryPlaceholder>-->
<!--            <h4>-->
<!--              <div class="opacity-50 text-sm">Salary</div>-->
<!--              <div class="mt-2" placeholder="-">-</div>-->
<!--            </h4>-->
<!--          </ng-template>-->
          <h4>
            <div class="opacity-50 text-sm">Location</div>
            <ng-container *ngIf="!(jobData?.jobState || jobData?.jobSuburb || jobData?.jobPostcode); else showLocation">
              <div class="mt-2"> -</div>
            </ng-container>
            <ng-template #showLocation>
              <div class="mt-2"> {{ jobData?.jobState }}, {{ jobData?.jobSuburb }}, {{ jobData?.jobPostcode }}</div>
            </ng-template>
          </h4>
          <h4>
            <div class="opacity-50 text-sm">Website</div>
            <a class="mt-2" target="_blank" [href]="jobData?.companyWebsite ? (jobData.companyWebsite.startsWith('http') ? jobData.companyWebsite : 'https://' + jobData.companyWebsite) : '#'">
              {{ jobData?.companyWebsite || '-' }}
            </a>

          </h4>

        </div>


      </section>
      <section class="section-divider !mt-0">
        <h2 class="mb-3">Brief Description</h2>
        <div [innerHTML]="jobData.jobBriefDesc" class="jobDescription">
        </div>
      </section>
      <section class="section-divider !mt-0">
        <h2 class="mb-3">Job Description</h2>
        <div [innerHTML]="jobData.jobFullDesc" class="jobDescription">
        </div>
      </section>
    </div>

  </section>


  <ng-container *ngIf="accountType == 'employer' && jobData.isOwner; else defaultView">


    <div class="applyNowMob fixed flex sm:hidden">
      <mat-icon (click)="shareJobClick()" class="cursor-pointer scale-125 text-primary">share</mat-icon>
      <a href="candidates?ref={{jobData.reference}}">
        <button class="generalBtn " color="primary" mat-flat-button>Candidates</button>
      </a>
      <button (click)="editListing()" class="generalBtn lightBtn rounded-2xl text-lg !py-4 " mat-flat-button>Edit Post
      </button>
    </div>

  </ng-container>

  <ng-template #defaultView>
    <div class="applyNowMob fixed flex sm:hidden">
      <button [disabled]="!sharedService.is_verified && sharedService.employeeLogin" (click)="openApplication()"
              *ngIf="!jobData.isApplied && !this.applicationSent " class="rounded-2xl text-lg w-1/2 !py-4"
              color="primary" mat-raised-button>
        Apply Now
      </button>


      <button *ngIf="jobData.isApplied || this.applicationSent"
              class="opacity-70 rounded-2xl text-lg w-1/2 !py-4" disabled
              mat-flat-button>Submitted
      </button>


      <mat-icon (click)="favouriteClicked()" *ngIf="!jobData.isSaved"
                class="cursor-pointer scale-125 fav-btn text-primary">
        favorite_border
      </mat-icon>
      <mat-icon (click)="favouriteClicked()" *ngIf="jobData.isSaved"
                class="cursor-pointer scale-125 fav-btn text-primary">
        favorite
      </mat-icon>
    </div>
  </ng-template>
</ng-container>
