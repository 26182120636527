import {Component, Input, OnChanges, OnInit, SimpleChanges} from '@angular/core';
import {Job} from '../../interfaces/job';
import {JobsService} from "../../services/jobs.service";
import {SharedService} from "../../services/shared.service";

@Component({
  selector: 'app-list-jobs',
  templateUrl: './list-jobs.component.html',
  styleUrls: ['./list-jobs.component.scss']
})
export class ListJobsComponent implements OnInit, OnChanges {
  @Input() jobs: Job[];
  @Input() pageRef: string;
  public jobsFound = true;
  public searchedJobs = true;
  public currentPage: number = 1;
  public totalPages: number = 1;


  constructor(public jobsService: JobsService, public sharedService: SharedService) {
  }

  ngOnInit(): void {
    if (!this.jobs) {
      this.getRecentJobs(1);
    }
  }

  ngOnChanges(changes: SimpleChanges) {
    if (changes.jobs) {
      if (changes.jobs.currentValue == -1) {
        this.jobsFound = false;
      } else {
        this.jobsFound = true;
        if (this.jobs.length < 1) {
          this.searchedJobs = false;
          this.getRecentJobs(1);
        }
      }
    }
  }

  private getRecentJobs(page: number) {
    this.jobsService.fetchAllJobs(page).subscribe((result: any) => {
      this.jobs = result.data;
      this.totalPages = result.totalPages;
    });
  }

  onPageChange(page: number) {
    this.currentPage = page;
    this.getRecentJobs(page);
  }

}
