<section class="container-space">
  <div class="mt-16">
    <h1>Applications</h1>
    <section *ngIf="jobApplication; else empty" class="flex flex-col jobApplicationsContainer">
      <app-upsell-backpacker></app-upsell-backpacker>

      <ng-container *ngFor="let job of jobApplication">
        <div class="applicationCard flex flex-row">
          <img *ngIf="job.jobLogo" alt="Logo for {{job.companyDisplayName}}" class="companyLogo" src="{{job.jobLogo}}">
          <div class="w-full ml-4 flex flex-col">
            <div class="flex flex-row items-center">
              <h2 class="text-lg">{{job.jobTitle}}</h2>
              <div *ngIf="!job.isApproved && !job.isShortlisted" class="currentStatus text-sm">Received</div>
              <div *ngIf="job.isApproved" class="currentStatus text-sm">Approved</div>
              <div *ngIf="!job.isApproved && job.isShortlisted" class="currentStatus text-sm">Shortlisted</div>

            </div>

            <p *ngIf="job.isApproved" class="text-sm font-bold text-success">The employer has approved your application! They have the
              contact details listed on your profile and will be in contact shortly.</p>

            <div class="flex flex-row items-center mb-2">
              <p class="text-sm text-primary font-bold mr-2 mb-0">{{job.companyDisplayName}}</p>
              <!--            <div *ngIf="job.salaryMinimum && job.salaryMaximum && job.salaryType">-->
              <!--              <p class="salary-text">${{job.salaryMinimum}} - ${{job.salaryMaximum}} per {{job.salaryType}}</p>-->
              <!--            </div>-->
            </div>
            <div class="flex flex-row items-center mb-2">
              <p class="text-sm m-0">{{job.jobType}}</p>
              <mat-icon class="dot-spacer">fiber_manual_record</mat-icon>
              <p class="text-sm m-0">{{job.jobCountry}}, {{job.jobState}}</p>
            </div>
            <p class="text-sm ">{{job.jobBriefDesc}}</p>
            <div class="flex flex-row items-center justify-between mt-4 flex-wrap">
              <a class="text-sm font-medium underline" href="/job/{{job.reference}}/{{job.urlSlug}}" target="_blank">Job
                Post</a>
              <a (click)="openCoverLetter(job)" class="text-sm font-medium underline">Cover Letter</a>
              <p class="appliedText text-right">Application Submitted: {{job.application_date | date:'mediumDate'}}</p>
            </div>
          </div>
        </div>
      </ng-container>
    </section>
    <ng-template #empty>
      <p class="mt-3">You have not applied to any jobs.</p>
    </ng-template>
  </div>
</section>
