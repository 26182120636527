import { Injectable } from '@angular/core';
import { BehaviorSubject } from 'rxjs';
import {EmployerProfile} from "../../../interfaces/employer-profile";

@Injectable({
  providedIn: 'root'
})
export class EmployerProfileService {
  private profileSource = new BehaviorSubject<EmployerProfile | null>(null);
  currentProfile = this.profileSource.asObservable();

  constructor() { }

  changeProfile(profile: EmployerProfile) {
    this.profileSource.next(profile);
  }
}
