<div class="flex flex-row justify-end m-2">
  <button (click)="update()" mat-icon-button>
    <mat-icon class="scale-150">close</mat-icon>
  </button>
</div>
<section class="container-space">
  <form [formGroup]='profileInfo' class='flex flex-col'>
    <h2 class="mb-3">Edit Information</h2>

    <mat-form-field appearance="outline">
      <mat-label>Company Name</mat-label>
      <input formControlName="company_name" matInput>
    </mat-form-field>


    <mat-form-field appearance="outline">
      <mat-label>Email</mat-label>
      <input formControlName="email" matInput>
    </mat-form-field>

    <button (click)="update()" color="primary" mat-stroked-button>Update Profile</button>

  </form>
</section>
