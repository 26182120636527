<div class="flex flex-row justify-end m-2">
  <button (click)="update()" mat-icon-button>
    <mat-icon class="scale-150">close</mat-icon>
  </button>
</div>
<section class="container-space">
  <form [formGroup]='additionalInfo' class='flex flex-col'>
    <h2 class="mb-3">Edit Additional Information</h2>
    <p class="mb-6">This information will be shared when you apply for jobs, you may optionally leave out any
      information you would
      not like to be shared.</p>

    <mat-form-field appearance="outline">
      <mat-label>When Are You Available To Work?</mat-label>
      <input [matDatepicker]="picker" formControlName="work_date" matInput>
      <mat-hint>DD/MM/YYYY</mat-hint>
      <mat-datepicker-toggle [for]="picker" matIconSuffix></mat-datepicker-toggle>
      <mat-datepicker #picker></mat-datepicker>
    </mat-form-field>

    <mat-form-field appearance="outline">
      <mat-label>Portfolio/Website</mat-label>
      <input formControlName="website" matInput>
    </mat-form-field>


    <mat-form-field appearance="outline">
      <mat-label>LinkedIn Profile</mat-label>
      <input formControlName="linked_in" matInput>
    </mat-form-field>

    <button (click)="update()" color="primary" mat-stroked-button>Update Profile</button>

  </form>
</section>
