import {Component, EventEmitter, Input, OnInit, Output} from '@angular/core';
import {MatChipSelectionChange} from "@angular/material/chips";
import {Settings} from "../../../globals/settings.model";
import {SharedService} from "../../../services/shared.service";
import {BackpackerProfile} from "../../../interfaces/backpacker-profile";

@Component({
  selector: 'app-public-profile-work-fields',
  templateUrl: './public-profile-work-fields.component.html',
  styleUrls: ['./public-profile-work-fields.component.scss', '../../latest-jobs/list-jobs.component.scss', '../../login-page/login-page.component.scss', '../../search-backpackers/backpacker-search.component.scss']
})
export class PublicProfileWorkFieldsComponent implements OnInit {
  // @Input() profileData: BackpackerProfile;


  @Input() unuploadedProfilePicture: File | null = null;
  @Input() profilePictureUrl: string;
  @Input() workLocations: string;
  @Input() hasResume: boolean;
  @Input() hasWorkExperience: boolean;
  @Input() fullName: string;




  @Input() accountTier: string;
  @Input() joinTodayLink: boolean = false;
  @Input() existingWorkFields: string[];
  @Input() fromCreateProfile: boolean = false;
  @Output() jobFieldsChange = new EventEmitter<string[]>();

  selectedJobFields = [];
  isInitialized = false; // Flag to check if the component has initialized

  constructor(public globals: Settings, private sharedService: SharedService) {

  }

  ngOnInit() {
    if (this.existingWorkFields) {
      this.selectedJobFields = [...this.existingWorkFields];
    }

    setTimeout(() => {
      this.isInitialized = true; // Set the initialization complete after a tick
    });

    if (this.fullName) {
     this.fullName = this.sharedService.getFirstNamePlusInitials(this.fullName)
    }

    if(this.workLocations){
      this.workLocations = this.sharedService.mapStatesToAbbreviations(this.workLocations)
    }

  }

  isSelected(name: string): boolean {
    return this.selectedJobFields.includes(name);
  }

  onSelectionChange(event: MatChipSelectionChange): void {
    if (!this.isInitialized) {
      return;
    }
    const jobName = event.source.value;

    if (event.selected) {
      if (this.selectedJobFields.length < 6) {
        this.selectedJobFields.push(jobName);
      } else {
        this.sharedService.triggerSnackbar("You can only select a maximum of 6 job fields");
        event.source.deselect();
      }
    } else {
      const index = this.selectedJobFields.indexOf(jobName);
      if (index !== -1) {
        this.selectedJobFields.splice(index, 1);
      }
    }

    this.jobFieldsChange.emit(this.selectedJobFields);
  }

  getProfilePictureUrl(): string {
    return this.unuploadedProfilePicture ? URL.createObjectURL(this.unuploadedProfilePicture) : 'assets/profile-picture-placeholder.jpg';
  }


}
