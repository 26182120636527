import {Component, Inject, OnInit, PLATFORM_ID} from '@angular/core';
import {Accounts} from "../../../services/accounts.service";
import {ActivatedRoute, Router} from "@angular/router";
import {CandidateFullProfile} from "../../../interfaces/candidate";
import {ApplyNowComponent} from "../../apply-now/apply-now.component";
import {ContactNowComponent} from "./hire-now/contact-now.component";
import {MatDialog, MatDialogConfig} from "@angular/material/dialog";
import {Job} from "../../../interfaces/job";
import {SharedService} from "../../../services/shared.service";
import {MetaService} from "../../../services/meta.service";
import {isPlatformBrowser} from "@angular/common";
import {JobsService} from "../../../services/jobs.service";

@Component({
  selector: 'app-candidates-profile',
  templateUrl: './candidates-profile.component.html',
  styleUrls: ['./candidates-profile.component.scss']
})
export class CandidatesProfileComponent implements OnInit {
  public metaTitle: string = 'Candidate Profile | Backpacker Jobs Now - Australia\'s Official Backpacker Job Board\n';
  public metaDescription: string = 'Read more about this backpacker, view their cover letter, experience, CV and resume. Contact and hire this backpacker today at Backpacker Jobs Now with 1 simple click.';
  public metaKeywords: string = 'candidate profile,read,cover letter,experience,CV,resume,contact,hire,backpacker,simple click,Backpacker Jobs Now';


  public contacted = false;
  showMoreCoverLetter = false;
  showMoreExperience = false;
  hiddenEmail = '';
  public candidate: CandidateFullProfile;
  public jobData: Job
  public jobRef: string = '';
  public backpacker_id = 0;
  public readMoreLength = 100;

  constructor(
    @Inject(PLATFORM_ID) private platformId: any,
    private jobService: JobsService,
    private router: Router,
    private meta: MetaService,
    private dialog: MatDialog,
    private accounts: Accounts,
    private route: ActivatedRoute,
    public sharedService: SharedService) {

  }

  ngOnInit() {
    this.meta.setupPageTitle(this.metaTitle);
    this.meta.setupMetaTags(
      this.metaTitle,
      this.metaDescription,
      this.metaKeywords,

      this.router.url
    );
    if (isPlatformBrowser(this.platformId)) {
      if (window.innerWidth < 768) {
        this.readMoreLength = 40;
      }
    }
    // get parameters from router url
    this.jobRef = this.route.snapshot.queryParamMap.get('ref');
    this.backpacker_id = +this.route.snapshot.queryParamMap.get('id');

    this.getApplicantDetails(this.jobRef, this.backpacker_id);
    this.hireApplicant();
  }

  hireApplicant() {
    // this.accounts.hireApplicant().subscribe(
    //   (response) => {
    //
    //   });
  }

  getApplicantDetails(jobRef, backpacker_id) {
    this.accounts.getApplicantDetails(jobRef, backpacker_id).subscribe(
      (response) => {
        this.candidate = response.profileData;
        this.jobData = response.jobData;
        this.hideContactDetails(this.candidate.email)
      });
  }

  hideContactDetails(email) {
    this.hiddenEmail = email.split('@')[1];
  }


  openPDFViewer(pdfLink) {
    if (isPlatformBrowser(this.platformId)) {
      window.open(pdfLink, '_blank');
    }
  }

  contactNow() {
    this.jobService.setApplicationContacted(this.candidate.application_id, this.backpacker_id).subscribe((response) => {
      if (response.status == 'success') {

        const dialogConfig = new MatDialogConfig();
        dialogConfig.width = '640px';
        dialogConfig.data = {
          candidateData: this.candidate,
          jobData: this.jobData,
          jobRef: this.jobRef,
          backpacker_id: this.backpacker_id,
          approvalSent: this.contacted
        };

        const dialogRef = this.dialog.open(ContactNowComponent, dialogConfig);

        dialogRef.afterClosed().subscribe((result) => {
          if (result) {
            this.contacted = result.isApproved;
          }
        });
      } else{
        this.sharedService.triggerSnackbar("Error: This Candidate Has No Contact Information.")
      }

    });
  }


}
