import {Injectable} from '@angular/core';
import {DomSanitizer, Meta, SafeHtml, Title} from "@angular/platform-browser";

@Injectable({
  providedIn: 'root'
})
export class MetaService {

  constructor(private metaService: Meta,
              private titleService: Title) {
  }

  setupPageTitle(title: string) {
    this.titleService.setTitle(title);
  }

  setupMetaTags(title: string, description: string, keywords: string, url: string) {
    const metaDesc = description;

    if (title != "") {

      /* SEO TITLE */
      this.metaService.updateTag({
        name: 'og:title',
        content: title,
      });

      this.metaService.updateTag({
        name: 'twitter:title',
        content: title,
      });

    }

    if (description != "") {

      /* SEO DESCRIPTION */
      this.metaService.updateTag(
        {
          content: description,
        },
        'name="description"',
      );

      this.metaService.updateTag({
        name: 'og:description',
        content: description,
      });

      this.metaService.updateTag({
        name: 'twitter:description',
        content: description,
      });
    }

    if (keywords != "") {
      /* SEO KEYWORDS */
      this.metaService.updateTag({
        name: 'keywords',
        content: keywords,
      });
    }


    if (url != "") {
      /* SEO URL */
      this.metaService.updateTag({name: 'og:url', content: url});
      this.metaService.updateTag({name: 'twitter:url', content: url});
    }

  }

}
