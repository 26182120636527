import {Component, OnInit} from '@angular/core';
import {FormBuilder, FormGroup, Validators} from '@angular/forms';
import {Accounts} from "../../../services/accounts.service";
import {SharedService} from "../../../services/shared.service";
import {Router} from "@angular/router";
import {MetaService} from "../../../services/meta.service";
@Component({
  selector: 'app-signup-page',
  templateUrl: './employer-signup-page.component.html',
  styleUrls: ['./employer-signup-page.component.scss', '../../login-page/login-page.component.scss']
})
export class EmployerSignupPageComponent implements OnInit{
  errorMsg: string;
  signupForm: FormGroup;
  public metaTitle: string = 'Join For Free & Find Backpackers | Backpacker Jobs Now - Australia\'s Official Backpacker Job Board';
  public metaDescription: string = 'Sign up in 20 seconds - completely for FREE! Connect with 1000s of backpackers eager to work. Post jobs, hire talent, and manage your listings effortlessly with Backpacker Jobs Now';
  public metaKeywords: string = 'join,free,find backpackers,employers,sign up,20 seconds,connect,backpackers,eager to work,post jobs,hire talent,manage listings,Backpacker Jobs Now';


  constructor(
    private meta: MetaService,
    private fb: FormBuilder,
    private accounts: Accounts,
    private sharedService: SharedService,
    private route: Router
  ) {
    this.signupForm = this.fb.group({
      company_name: ['', Validators.required],
      email: ['', [Validators.required, Validators.email]],
      password: ['', [Validators.required, Validators.minLength(5)]],
      confirm_password: ['', [Validators.required, Validators.minLength(5)]],
      terms: [false, Validators.requiredTrue]
    });
  }

  ngOnInit() {
    this.meta.setupPageTitle(this.metaTitle);
    this.meta.setupMetaTags(
        this.metaTitle,
        this.metaDescription,
        this.metaKeywords,

        this.route.url
    );
  }

  submitSignup() {
    const formData = {
      company_name: this.signupForm.value.company_name,
      email: this.signupForm.value.email,
      password: this.signupForm.value.password,
      confirm_password: this.signupForm.value.confirm_password
    };
    this.accounts.createEmployerAccount(formData).subscribe(
      (response) => {
        localStorage.setItem('account_type', 'employer')
        this.sharedService.employeeLogin = false;
        this.sharedService.employerLogin = true;

        // save username into local storage for use in navbar
        this.sharedService.username = response.company_name;
        localStorage.setItem('username', response.company_name);

        // save email into local storage for use in the verification messages etc.
        this.sharedService.account_email = response.account_email;
        localStorage.setItem('account_email', response.account_email);

        this.accounts.sendEmailVerification(response.account_email).subscribe();

        this.route.navigate(['/post-job']);

      },
      (error) => {
        if (error.error.errorType == 'company_name') {
          this.signupForm.controls['company_name'].setErrors({'incorrect': true});
        } else if (error.error.errorType == 'email') {
          this.signupForm.controls['email'].setErrors({'incorrect': true});
        } else {
          this.signupForm.controls['confirm_password'].setErrors({'incorrect': true});

        }
        this.errorMsg = error.error.returnMsg;
      }
    );
  }



}
