<section *ngIf="resetPasswordForm" class="container-space">
  <mat-card class="rounded-2xl my-16 px-3 md:px-10 py-5">
    <h1 class="text-center mb-2 text-2xl" mat-dialog-title>Reset Your Password</h1>
    <h2 class="text-center mb-2 text-sm">Enter your new password below</h2>
    <div class="flex flex-col">
      <form [formGroup]="resetPasswordForm" class="mt-10 text-center">

        <mat-form-field appearance="fill" class="w-full">
          <mat-label>Create New Password</mat-label>
          <input formControlName="password" matInput type="password">
          <mat-icon matSuffix>vpn_key</mat-icon>

          <mat-error *ngIf="resetPasswordForm.get('password').hasError('required')">
            Password is required
          </mat-error>
          <mat-error *ngIf="resetPasswordForm.get('password').hasError('minlength')">
            Password must be at least 5 characters
          </mat-error>
        </mat-form-field>

        <mat-form-field appearance="fill" class="w-full">
          <mat-label>Repeat Password</mat-label>
          <input formControlName="confirm_password" matInput type="password">
          <mat-icon matSuffix>vpn_key</mat-icon>
          <mat-error *ngIf="resetPasswordForm.get('confirm_password').hasError('required')">
            Password is required
          </mat-error>
          <mat-error *ngIf="resetPasswordForm.get('confirm_password').hasError('minlength')">
            Password must be at least 5 characters
          </mat-error>
        </mat-form-field>

        <div *ngIf="errorMsg" class="errorMsg">
          <mat-icon>error_outline</mat-icon>
          <p>{{this.errorMsg}}</p>
        </div>

        <button (click)="submitPasswordReset()" class="mt-6 rounded-3xl text-lg w-full sm:max-w-xs !py-5" color="primary" mat-raised-button>
          Reset Password
        </button>

      </form>
    </div>
  </mat-card>
</section>
