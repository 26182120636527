import {Injectable} from '@angular/core';
import {HttpClient} from "@angular/common/http";
import {SharedService} from "./shared.service";
import {Observable} from "rxjs";

@Injectable({
  providedIn: 'root'
})
export class JobsService {

  constructor(private http: HttpClient, private settings: SharedService) {
  }

  fetchAllJobs(page_number: number) {
    const formData = {page_number: page_number}
    return this.http.post<any>(this.settings.apiURL + 'jobs/GetAllJobs', formData);

  }

  getJobByReference(reference: string) {
    const formData = {reference: reference}
    return this.http.post<any>(this.settings.apiURL + 'jobs/GetJobByReference', formData, { withCredentials: true });
  }

  editJobByReference(reference: string) {
    const formData = {reference: reference}
    return this.http.post<any>(this.settings.apiURL + 'jobs/EditJobByReference', formData, { withCredentials: true });
  }

  applyForJob(formData: any): Observable<any> {
    return this.http.post<any>(this.settings.apiURL + 'jobs/ApplyForJob', formData, { withCredentials: true });
  }


  getJobsBySearch(searchInput, workTypeFilter, workLocationFilter, page ) {
    const formData = {searchInput: searchInput, workLocationFilter: workLocationFilter, workTypeFilter: workTypeFilter, page_number: page}
    return this.http.post<any>(this.settings.apiURL + 'jobs/GetJobsBySearch', formData);
  }

  saveJobToFavourites(jobID: any, isSaved: any) {
    const formData = {jobID: jobID, isSaved: isSaved}
    return this.http.post<any>(this.settings.apiURL + 'jobs/saveJobToFavourites', formData,{ withCredentials: true });
  }

  getAllSavedJobsForUser() {
    return this.http.get<any>(this.settings.apiURL + 'jobs/GetSavedJobsByAccountID',{ withCredentials: true });
  }

  getEmployerJobListings() {
    // const account_id = +localStorage.getItem('account_id');
    // const account_type = localStorage.getItem('account_type');
    // const formData = {account_id: account_id, account_type: account_type}
    return this.http.get<any>(this.settings.apiURL + 'jobs/GetEmployerJobListings', { withCredentials: true });
  }

  getCandidates(reference?: string) {
    if (!reference) {
      reference = '';
    }
    // const account_id = +localStorage.getItem('account_id');
    // const account_type = localStorage.getItem('account_type');
    const formData = {reference: reference}
    return this.http.post<any>(this.settings.apiURL + 'jobs/GetCandidates', formData, { withCredentials: true });
  }

  setApplicationApproved(application_id, backpacker_id){
    const formData = {application_id: application_id, backpacker_id: backpacker_id}
    return this.http.post<any>(this.settings.apiURL + 'jobs/setApplicationApproved', formData, { withCredentials: true });
  }

  setApplicationContacted(application_id: number, backpacker_id: number) {
    const formData = {application_id: application_id, backpacker_id: backpacker_id}
    return this.http.post<any>(this.settings.apiURL + 'jobs/setApplicationContacted', formData, { withCredentials: true });
  }

  setApplicationShortlisted(job_id: number, candidate_id: string) {
    const formData = {job_id: job_id, candidate_id: candidate_id}
    return this.http.post<any>(this.settings.apiURL + 'jobs/SetApplicationShortlisted', formData, { withCredentials: true });
  }

  setApplicationRejected(job_id: number, candidate_id: string) {
    const formData = {job_id: job_id, candidate_id: candidate_id}
    return this.http.post<any>(this.settings.apiURL + 'jobs/setApplicationRejected', formData, { withCredentials: true });
  }


  deleteJobByReference(reference: string) {
    const formData = {reference: reference}
    return this.http.post<any>(this.settings.apiURL + 'jobs/DeleteJobByReference', formData, { withCredentials: true });
  }

  pauseJobListing(job_id: number, pause_status: boolean) {
    const formData = {job_id: job_id, pause_status: pause_status}
    return this.http.post<any>(this.settings.apiURL + 'jobs/PauseJobListing', formData, { withCredentials: true });
  }

  // proceedToCheckout(adType: string, reference: string) {
  //   const formData = {adType: adType, reference: reference}
  //   return this.http.post<any>(this.settings.apiURL + 'jobs/ProceedToCheckout', formData, { withCredentials: true });
  // }

  createStripeSession(adType: string, jobRef: string): Observable<any> {
    const formData = {adType: adType, jobRef: jobRef}
    return this.http.post(this.settings.apiURL + 'payments/checkout', formData, { withCredentials: true });
  }


}
