<section class="container-space">
  <div class="mt-16">

    <h1 class="mb-3">Saved Jobs</h1>
    <ng-container *ngIf="savedJobs; else empty">
      <app-list-jobs [jobs]="savedJobs"></app-list-jobs>
    </ng-container>
    <ng-template #empty>
      <p class="mt-3">You have no saved jobs yet.</p>
    </ng-template>
  </div>
</section>
