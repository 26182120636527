import {Component, OnInit} from '@angular/core';
import {MatDialog, MatDialogRef} from '@angular/material/dialog';
import {Router} from '@angular/router';
import {MetaService} from "../../services/meta.service";

@Component({
    selector: 'app-employer-signup',
    templateUrl: './employer-landing-page-signup.component.html',
    styleUrls: ['./employer-landing-page-signup.component.scss']
})
export class EmployerLandingPageSignupComponent implements OnInit {
    public metaTitle: string = 'Post a Job & Hire Backpackers For Free | Backpacker Jobs Now - Australia\'s Official Backpacker Job Board';
    public metaDescription: string = 'Post job listings for free and find the perfect candidates for roles ranging from farm work and fruit picking to hospitality and casual jobs. Start hiring with Backpacker Jobs Now.';
    public metaKeywords: string = 'post job,free job listing,hire backpackers,Australia,working holiday visa,farm work,fruit picking,hospitality,casual jobs,employer';

    constructor(
        public meta: MetaService,
        public dialog: MatDialog,
        public router: Router) {
    }

    ngOnInit() {
        this.meta.setupPageTitle(this.metaTitle);
        this.meta.setupMetaTags(
            this.metaTitle,
            this.metaDescription,
            this.metaKeywords,

            this.router.url
        );
    }
}
