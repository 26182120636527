import {Directive, HostListener, Inject, PLATFORM_ID} from '@angular/core';
import {MatStepper} from '@angular/material/stepper';
import {StepperSelectionEvent} from "@angular/cdk/stepper";
import {isPlatformBrowser} from '@angular/common';

@Directive({
  selector: 'mat-stepper',
})
export class MatStepperAutoScrollDirective {
  constructor(private stepper: MatStepper, @Inject(PLATFORM_ID) private platformId: Object) {
  }

  @HostListener('animationDone', ['$event'])
  animationDone(event: AnimationEvent) {
    if (isPlatformBrowser(this.platformId)) {

      const stepContentElements = document.querySelectorAll('.mat-horizontal-stepper-content, .mat-vertical-stepper-content');
      const activeStepIndex = this.stepper.selectedIndex;
      const stepContentElement = stepContentElements[activeStepIndex] as HTMLElement;

      if (stepContentElement) {
        const topOffset = -100; // Adjust this value to fit your progress bar height
        const elementPosition = stepContentElement.getBoundingClientRect().top + window.pageYOffset;
        const offsetPosition = elementPosition + topOffset;

        window.scrollTo({
          top: offsetPosition,
        });
      }
    }
  }
}
