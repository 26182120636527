import {Component, Inject} from '@angular/core';
import {MAT_DIALOG_DATA, MatDialogRef} from "@angular/material/dialog";

@Component({
  selector: 'app-view-cover-letter-dialog',
  templateUrl: './view-cover-letter-dialog.component.html',
  styleUrls: ['./view-cover-letter-dialog.component.scss']
})
export class ViewCoverLetterDialogComponent {
  constructor(
    public dialogRef: MatDialogRef<ViewCoverLetterDialogComponent>,
    @Inject(MAT_DIALOG_DATA) public data: any,
  ) {
  }
}
