import {Component, Inject, OnInit} from '@angular/core';
import {UntypedFormBuilder, UntypedFormGroup} from "@angular/forms";
import {MAT_DIALOG_DATA, MatDialogRef} from "@angular/material/dialog";
import {SharedService} from "../../../../services/shared.service";
import {JobsService} from "../../../../services/jobs.service";

@Component({
  selector: 'app-hire-now',
  templateUrl: './contact-now.component.html',
  styleUrls: ['./contact-now.component.scss']
})
export class ContactNowComponent implements OnInit{
  public isApproved = false;
  hireNowForm: UntypedFormGroup;

  constructor(
    private jobService: JobsService,
    private formBuilder: UntypedFormBuilder,
    public sharedService: SharedService,
    public dialogRef: MatDialogRef<ContactNowComponent>,
    @Inject(MAT_DIALOG_DATA) public data: any) {
    dialogRef.backdropClick().subscribe(() => {
      this.closeDialog();
    });

  }

  ngOnInit() {
    this.isApproved = this.data.approvalSent;
    this.hireNowForm = this.formBuilder.group({
      message: ['']
    });
  }

  markAsApproved() {
    this.jobService.setApplicationApproved(this.data.candidateData.application_id, this.data.backpacker_id).subscribe(
      (response) => {
        this.isApproved = true;
      });
  }

  closeDialog() {
    this.dialogRef.close({isApproved: this.isApproved})
  }

}
