import { Injectable } from '@angular/core';
import {BackpackerProfile} from "../../../interfaces/backpacker-profile";
import {BehaviorSubject} from "rxjs";

@Injectable({
  providedIn: 'root'
})
export class EmployeeProfileService {
  private profileSource = new BehaviorSubject<BackpackerProfile | null>(null);
  currentProfile = this.profileSource.asObservable();

  constructor() {
    const profile = localStorage.getItem('backpackerProfileData');
    if (profile) {
      this.profileSource.next(JSON.parse(profile));
    }
  }

  changeProfile(profile: BackpackerProfile) {
    localStorage.setItem('backpackerProfileData', JSON.stringify(profile));
    this.profileSource.next(profile);
  }
}
