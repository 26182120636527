export const navItems = [
  {route: '/post-job', name: 'Post Job', displayEmployer: true, displayEmployee: false, displayDefault: false},
  {route: '/search-jobs', name: 'Search Jobs', displayEmployer: true, displayEmployee: true, displayDefault: true},
  {route: '/search-backpackers', name: 'Search Backpackers', displayEmployer: true, displayEmployee: true, displayDefault: true, newTag: "new"},
  {route: '/employer-join', name: 'Post Job', displayEmployer: false, displayEmployee: false, displayDefault: true},
  {route: '/login', name: 'Login/Signup', displayEmployer: false, displayEmployee: false, displayDefault: true, mobOnly: true},
  // {route: '/how-it-works', name: 'How It Works', displayEmployer: true, displayEmployee: true, displayDefault: true},
  {route: '/backpacker-information', name: 'Backpacker Info', displayEmployer: true, displayEmployee: true, displayDefault: true},
  // {route: '/referral-scheme', name: "We'll Hire You", displayEmployer: false, displayEmployee: true, displayDefault: false},
];

export const loginNavItems = [
  {route: '/employer-profile', name: 'My Profile', displayEmployer: true, displayEmployee: false, mobOnly: true},
  {route: '/profile', name: 'My Profile', displayEmployer: false, displayEmployee: true, mobOnly: true},

  // I wanted to change the navigation title for desktop hence the duplicates here
  {route: '/employer-profile', name: 'View Profile', displayEmployer: true, displayEmployee: false, desktopOnly:true},
  {route: '/profile', name: 'View Profile', displayEmployer: false, displayEmployee: true, desktopOnly:true},

  {route: '/job-listings', name: 'Job Listings', displayEmployer: true, displayEmployee: false},
  {route: '/job-applications', name: 'Job Applications', displayEmployer: false, displayEmployee: true},
  {route: '/saved-jobs', name: 'Saved Jobs', displayEmployer: false, displayEmployee: true},
  {route: '/candidates', name: 'Candidates', displayEmployer: true, displayEmployee: false},
  {route: '/upgrade-account', name: 'Manage Membership', displayEmployer: false, displayEmployee: true},
  // {route: '/messages', name: 'Messages', displayEmployer: true, displayEmployee: true},
]

