<div class="relative sm:mt-8 mt-4">

  <div *ngIf="accountTier == 'standard'" class="locked-content">
    <mat-icon>lock</mat-icon>
    <div class="flex">
      <a *ngIf="joinTodayLink" href="/upgrade-account" target="_blank"> Members Only: <span class="underline-dec">Join Today</span>
        & Let Employers
        Discover You!</a>
      <div *ngIf="!joinTodayLink"> Members Only: Join Today & Let Employers Discover
        You!
      </div>
    </div>
  </div>

  <div [ngStyle]="accountTier == 'standard' ? {'opacity':'0.4', 'pointer-events':'none'} : ''">

    <div class="flex sm:flex-row flex-col-reverse mt-6">
      <div class="backpacker-profile-container sm:w-1/3 w-full sm:mt-0 mt-6">
        <div class="profile-text-top">Here's How Your Profile Will Look!</div>

        <div *ngIf="accountTier == 'pro'" class="ribbon ribbon-top-right"><span>Top Hire</span></div>

        <ng-container *ngIf="!fromCreateProfile else tempProfilePic">
          <div class="backpacker-profile-image">
            <img *ngIf="profilePictureUrl != ''" alt="profile picture"
                 src="{{profilePictureUrl}}">
            <img *ngIf="profilePictureUrl == ''" alt="profile picture"
                 src="assets/profile-picture-placeholder.jpg">
          </div>
        </ng-container>

        <ng-template #tempProfilePic>
          <div class="backpacker-profile-image">
            <img [src]="getProfilePictureUrl()" alt="profile picture">
          </div>
        </ng-template>


        <!--      Privacy so last name initial-->
        <div class="backpacker-profile-name">
          {{ fullName }}
        </div>

        <div class="flex justify-center w-full">
          <div class="work-location text-icon-wrapper">
            <mat-icon>location_on</mat-icon>
            <div
              *ngIf="workLocations != 'Anywhere In Australia'">Looking In {{ workLocations }}</div>
            <div
              *ngIf="workLocations == 'Anywhere In Australia'">Looking {{ workLocations }}</div>
          </div>
        </div>

        <div class="flex flex-row mt-3 pinned-highlights">
          <div class="highlight" *ngIf="hasResume">
            <mat-icon>attach_file</mat-icon>
            <div>Resume Attached</div>
          </div>
          <div class="highlight" *ngIf="hasWorkExperience">
            <mat-icon>star</mat-icon>
            <div>Experienced</div>
          </div>
        </div>

        <div class="jobCategories mt-3">
          <h4 class="profile-view-title">Looking To Work In:</h4>
          <div class="flex flex-wrap justify-center mt-3">
            <ng-container *ngIf="selectedJobFields.length == 0">
              <div class="highlight jobFieldHighlight">Agriculture & Farm Work</div>
              <div class="highlight jobFieldHighlight">Choose More Jobs +</div>

            </ng-container>
            <div *ngFor="let field of selectedJobFields" class="highlight jobFieldHighlight">
              {{ field }}
            </div>
          </div>
        </div>

        <button class="generalBtn" color="primary" mat-raised-button
                matTooltipClass="tooltip-sm"
                matTooltip="On your listing, this button will open your profile so employers can see your resume, cover letter, experience and all other details you entered in this form"
                matTooltipPosition="below"
        >
          View Profile

        </button>

      </div>

      <div class="customise-profile sm:ml-8">


        <h4 class="mb-4 text-center">What Backpacker Jobs Are You Looking For? (Pick Up To 6)</h4>
        <p class="text-sm text-center">Choose your preferred jobs to enhance your profile visibility to employers
          seeking the right match.</p>
        <mat-chip-listbox #chipList multiple>
          <mat-chip-option *ngFor="let job of globals.jobCategories"
                           [value]="job.name"
                           [selected]="isSelected(job.name)"
                           (selectionChange)="onSelectionChange($event)">
            {{ job.name }}
          </mat-chip-option>
        </mat-chip-listbox>

      </div>


    </div>
  </div>
</div>
