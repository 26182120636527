<section class="container-space">
  <mat-card class="mt-16">
    <mat-progress-bar [value]="(stepper.selectedIndex / (stepper.steps.length - 1)) * 100"></mat-progress-bar>
    <mat-stepper #stepper [linear]="isLinear" class="mt-8" (selectionChange)="selectionChange($event)">
      <mat-step [stepControl]="jobPostFormStep1">
        <form [formGroup]='jobPostFormStep1' class='flex flex-col'>
          <h2 class="mb-3">Basic Job Information</h2>


          <mat-form-field appearance='outline' class='form-field mb-2'>
            <mat-label>Job Title</mat-label>
            <input formControlName='jobTitle' matInput required='true'/>
            <mat-error *ngIf="jobPostFormStep1.controls['jobTitle'].hasError('required')">
              Please enter a job title
            </mat-error>
          </mat-form-field>

          <mat-form-field appearance="outline" class='form-field mb-2'>
            <mat-label>What Category Best Describes The Job</mat-label>
            <mat-select formControlName="jobCategory">
              <mat-option *ngFor="let jobCategory of globals.jobCategories"
                          [value]="jobCategory.name">{{jobCategory.name}}</mat-option>
            </mat-select>
            <mat-error *ngIf="jobPostFormStep1.controls['jobCategory'].hasError('required')">
              Please select a job category
            </mat-error>
          </mat-form-field>

          <mat-form-field appearance="outline" class='form-field mb-2'>
            <mat-label>Country</mat-label>
            <input disabled formControlName="jobCountry" matInput value="Australia">
            <mat-hint>We are currently only operating in Australia.</mat-hint>
          </mat-form-field>


          <mat-form-field appearance="outline" class='form-field mb-2'>
            <mat-label>State/Territory</mat-label>
            <mat-select formControlName="jobState">
              <mat-option *ngFor="let state of globals.states" [value]="state.name">{{state.name}}</mat-option>
            </mat-select>
          </mat-form-field>


          <mat-form-field appearance='outline' class='form-field mb-2'>
            <mat-label>Suburb/City</mat-label>
            <input formControlName='jobSuburb' matInput required='true'/>
            <mat-error *ngIf="jobPostFormStep1.controls['jobSuburb'].hasError('required')">
              Please enter a city or suburb
            </mat-error>
          </mat-form-field>

          <mat-form-field appearance='outline' class='form-field mb-2'>
            <mat-label>Postcode</mat-label>
            <input formControlName='jobPostcode' matInput required='true'/>
            <mat-error *ngIf="jobPostFormStep1.controls['jobPostcode'].hasError('required')">
              Please enter a postcode
            </mat-error>
          </mat-form-field>

          <mat-label class="mb-2 text-label">Employee Work Type</mat-label>
          <mat-chip-listbox #jobChips class="chip-container" class='mb-2' formControlName="jobType">
            <mat-chip-option *ngFor="let jobType of jobTypes" [value]="jobType">{{ jobType }}</mat-chip-option>
          </mat-chip-listbox>



          <div *ngIf="errorMsg" class="errorMsg">
            <mat-icon>error_outline</mat-icon>
            <p>{{this.errorMsg}}</p>
          </div>



          <button (click)="checkValidationThenNextStep(1)" class="lightBtn text-xl !p-6 mt-5 self-end w-full sm:w-1/3" mat-flat-button >Continue
          </button>
        </form>

      </mat-step>

      <mat-step [stepControl]="jobPostFormStep2">
        <form [formGroup]='jobPostFormStep2' class='flex flex-col'>
          <h2 class="mb-3">Customise Your Job Post</h2>
          <mat-form-field
            appearance='outline'
            class='form-field'
          >
            <mat-label>Brief Job Description</mat-label>
            <textarea #jobBriefDesc [maxLength]="250" [rows]="5" formControlName='jobBriefDesc' matInput
                      required='true'> </textarea>
            <mat-hint align="end">{{ jobBriefDesc.value ? jobBriefDesc.value.length : 0 }} / 250</mat-hint>

            <mat-error
              *ngIf="jobPostFormStep2.controls['jobBriefDesc'].hasError('required')"
            >
              Please enter a brief description
            </mat-error>

          </mat-form-field>

          <mat-label class="mb-2 text-label">Display Salary (optional)</mat-label>
          <div class="flex flex-row items-baseline">
            <mat-form-field
              appearance='outline'
              class='form-field flex-1 mr-1'
            >
              <input #jobBrief formControlName='salaryMin' matInput/>
              <span matTextPrefix>$&nbsp;</span>
              <mat-label>Minimum</mat-label>
            </mat-form-field>
            to
            <mat-form-field
              appearance='outline'
              class='form-field flex-1 ml-1'
            >
              <input #jobBrief formControlName='salaryMax' matInput/>
              <span matTextPrefix>$&nbsp;</span>
              <mat-label>Maximum</mat-label>

            </mat-form-field>
            <mat-form-field appearance="outline" class="w-1/4">
              <mat-select formControlName="salaryType">
                <mat-option value="hour">Per Hour</mat-option>
                <mat-option value="day">Per Day</mat-option>
                <mat-option value="week">Per Week</mat-option>
                <mat-option value="month">Per Month</mat-option>
                <mat-option value="year">Per Year</mat-option>
              </mat-select>
            </mat-form-field>
          </div>

          <div class="my-4">
            <editor [init]="tinymceConfig" apiKey="52gvcmwqj90cb0ungtd1bw4anx2a37qoixz8qjnd7v8b3atl"
                    formControlName="jobFullDesc"></editor>
          </div>


          <div *ngIf="errorMsg" class="errorMsg">
            <mat-icon>error_outline</mat-icon>
            <p>{{this.errorMsg}}</p>
          </div>


          <div class="flex flex-row justify-between flex-wrap">
            <button class="lightBtn text-xl !p-6 mt-5 self-end w-full sm:w-1/3" mat-flat-button matStepperPrevious>
              Previous
            </button>
            <button (click)="checkValidationThenNextStep(2)" class="lightBtn text-xl !p-6 mt-5 self-end w-full sm:w-1/3" mat-flat-button>
              Continue
            </button>
          </div>
        </form>
      </mat-step>

      <mat-step [stepControl]="jobPostFormStep3">
        <form [formGroup]='jobPostFormStep3' class='flex flex-col'>
          <h2 class="mb-3">Polish it off</h2>
          <p>Optional fields to steal the spotlight!</p>
          <ngx-dropzone (change)="onSelect($event)" [maxFileSize]="3145728" [multiple]="false" class="my-3" accept="image/png, image/jpeg, image/jpg, image/gif">
            <ngx-dropzone-label>Upload Company Logo (Optional)</ngx-dropzone-label>

            <ngx-dropzone-image-preview (removed)="onRemove(jobLogo)" *ngIf="jobLogo"
                                        [file]="jobLogo"
                                        [removable]="true" ngProjectAs="ngx-dropzone-preview">
            </ngx-dropzone-image-preview>
          </ngx-dropzone>



          <mat-form-field
            appearance='outline'
            class='form-field'
          >
            <mat-label>Company Website (Optional)</mat-label>
            <input formControlName='companyWebsite' matInput/>

          </mat-form-field>


          <p>Does this job offer an hourly/annual salary?
            <span
              class="material-icons info-icon"
              matTooltip="Please click no if the job is piece rate"
              matTooltipPosition="above"
              tabindex="0"
            >
          info_outline
        </span>
          </p>
          <mat-chip-listbox #badgeList class="chipControls" formControlName="hasHourly">
            <mat-chip-option [value]="1">Yes</mat-chip-option>
            <mat-chip-option [value]="0">No</mat-chip-option>
          </mat-chip-listbox>

          <p>Is a 2nd or 3rd visa extension (88 days) available for your job vacancy?
            <span
              class="material-icons info-icon"
              matTooltip="Is the job available for 88 days and is it in regional Australia?"
              matTooltipPosition="above"
              tabindex="0"
            >
          info_outline
        </span>
          </p>
          <mat-chip-listbox #badgeList class="chipControls" formControlName="hasVisa">
            <mat-chip-option [value]="1">Yes</mat-chip-option>
            <mat-chip-option [value]="0">No</mat-chip-option>
          </mat-chip-listbox>

          <p>Is there a chance for residency for the right candidate?
            <span
              class="material-icons info-icon"
              matTooltip="If the employee does exceptional work would you consider sponsoring them for residency?"
              matTooltipPosition="above"
              tabindex="0"
            >
          info_outline
        </span>
          </p>
          <mat-chip-listbox #badgeList class="chipControls" formControlName="hasResidency">
            <mat-chip-option [value]="1">Yes</mat-chip-option>
            <mat-chip-option [value]="0">No</mat-chip-option>
          </mat-chip-listbox>

          <div class="flex flex-row justify-between flex-wrap">
            <button class="lightBtn text-xl !p-6 mt-5 self-end w-full sm:w-1/3" mat-flat-button matStepperPrevious>
              Previous
            </button>
            <button (click)="reviewJobPost()"
                    [disabled]="!jobPostFormStep1.valid || !jobPostFormStep2.valid"
                    [ngStyle]="(!jobPostFormStep1.valid || !jobPostFormStep2.valid) ? {'opacity':'0.4'} : ''"
                    class="lightBtn text-xl !p-6 mt-5 self-end w-full sm:w-1/3" mat-flat-button
                    matStepperNext>Finish & Preview
            </button>
          </div>
        </form>

      </mat-step>
      <mat-step>
        <h2>Preview Your Listing</h2>

        <div class="pricingModels">
          <mat-card [class.disabledCard]="shouldDisableTier('standard')" class="priceCardContainer hvr-float">
            <div *ngIf="isEditing && origAdType === 'standard'" class="currentAdTypeBanner">
              Current Selection
            </div>
            <h2>Standard Ad</h2>
            <h3 class="paymentPrice my-4">$0 AUD</h3>
            <p>Find a candidate with our basic ad</p>
            <button (click)="selectTier('standard')" [disabled]="shouldDisableTier('standard')"
                    [ngClass]="{'selected': adType === 'standard', 'inverseManageBtn': adType === 'standard'}"
                    class="manageBtn w-full !text-base">
              {{ getButtonTextTier('standard') }}
            </button>
            <ul>
              <li>Our standard Ad type</li>
            </ul>
          </mat-card>

          <mat-card [class.disabledCard]="shouldDisableTier('featured')"
                    class="priceCardContainer recommendedCard hvr-float">
            <div *ngIf="!isEditing" class="recommendedBanner">
              Recommended Choice
            </div>
            <div *ngIf="isEditing && origAdType === 'featured'" class="currentAdTypeBanner">
              Current Selection
            </div>
            <h2>Featured Ad</h2>
            <h3 class="paymentPrice my-4">$25.99 AUD</h3>
            <p>Get your listing to stand out on the job list and attract more candidates.</p>
            <button (click)="selectTier('featured')" [disabled]="shouldDisableTier('featured')"
                    [ngClass]="{'selected': adType === 'featured', 'inverseManageBtn': adType === 'featured'}"
                    class="manageBtn w-full !text-base">
              {{ getButtonTextTier('featured') }}
            </button>
            <ul>
              <li>Featured Badge on your Ad</li>
              <li>Highlighted border to stand out</li>
            </ul>
          </mat-card>

          <mat-card [class.disabledCard]="shouldDisableTier('sponsored')" class="priceCardContainer hvr-float">
            <div *ngIf="isEditing && origAdType === 'sponsored'" class="currentAdTypeBanner">
              Current Selection
            </div>
            <h2>Sponsored Ad</h2>
            <h3 class="paymentPrice my-4">$49.99 AUD</h3>
            <p>Get your listing pinned to the top of the job list until you find your ideal candidate.</p>
            <button (click)="selectTier('sponsored')" [disabled]="shouldDisableTier('sponsored')"
                    [ngClass]="{'selected': adType === 'sponsored', 'inverseManageBtn': adType === 'sponsored'}"
                    class="manageBtn w-full !text-base">
              {{ getButtonTextTier('sponsored') }}
            </button>
            <ul>
              <li>Pinned to the top of the list</li>
              <li>Featured Badge on your Ad</li>
              <li>Highlighted border to stand out</li>
            </ul>
          </mat-card>
        </div>


        <h3 class="my-4">This is what your advert will look like</h3>
        <button [ngClass]="{'featured': adType === 'featured' || adType === 'sponsored'}" class="job-card"
                mat-stroked-button>
          <div class="flex flex-row items-center">
            <img *ngIf="imagePreview" alt="company logo preview" class="w-full h-full" src="{{imagePreview}}"/>
            <div class="w-9/12 flex flex-col items-start text-left">
              <h3 class="font-bold">{{jobPostFormStep1.get('jobTitle').value}}</h3>
              <h4>{{this.sharedService.username}}</h4>
              <p
                class="font-light ">{{sharedService.truncateString(jobPostFormStep2.get('jobBriefDesc').value, 15, 40)}}</p>
            </div>
            <div class="flex flex-col items-center ml-auto self-start">
              <button class="applyBtn " mat-stroked-button>Apply Now</button>
              <div *ngIf="adType === 'featured' || adType === 'sponsored'" class="featuredBadge">
                Featured
              </div>
            </div>
          </div>
          <!--    <div class="badgesContainer">-->
          <!--      <span class="eeDays">88</span>-->
          <!--      <mat-icon class="verifiedTick">verified</mat-icon>-->
          <!--    </div>-->
          <div class="highlights text-left">
            <span *ngIf="jobPostFormStep3.get('hasVisa').value" class="highlight">WHV Eligible</span>
            <span *ngIf="jobPostFormStep3.get('hasHourly').value" class="highlight">Hourly Wages</span>
            <span *ngIf="jobPostFormStep3.get('hasResidency').value" class="highlight">Residency Option</span>
          </div>
        </button>

        <div class="flex flex-row justify-between flex-wrap">

          <button class="lightBtn text-xl !p-6 mt-5 self-end w-full sm:w-1/3" mat-flat-button matStepperPrevious>
            Previous
          </button>
          <button (click)="submitJobPost()" class="lightBtn text-xl !p-6 mt-5 self-end w-full sm:w-1/3" mat-flat-button
                  matStepperNext>Post Job
          </button>

          <div (click)="testMode()" class="testBtn absolute right-0 bottom-0 w-2 h-2">
          </div>

        </div>
      </mat-step>
    </mat-stepper>
  </mat-card>
</section>
