<section class="container-space ">
  <div class="mt-16">
    <h1>Job Listings</h1>
    <!--  Show verification if not verified-->
    <div class="my-6" *ngIf="!shared.is_verified">
      <app-verification-message [accountType]="'employer'"></app-verification-message>
    </div>

    <section>
      <app-employer-job-list></app-employer-job-list>
    </section>
  </div>
</section>
