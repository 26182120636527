import {Injectable} from '@angular/core';
import {ActivatedRouteSnapshot, Router, RouterStateSnapshot, UrlTree} from '@angular/router';
import {Observable} from 'rxjs';
import {SharedService} from "../services/shared.service";

@Injectable({
  providedIn: 'root'
})
export class EmployerAuthGuard {
  constructor(private router: Router, private settings: SharedService) {
  }

  canActivate(
    route: ActivatedRouteSnapshot,
    state: RouterStateSnapshot): Observable<boolean | UrlTree> | Promise<boolean | UrlTree> | boolean | UrlTree {


    if (!this.settings.employerLogin || !this.settings.username) {
      this.router.navigate(['/login']);
      return false;
    }
    // Check if this specific route requires email verification
    if (route.data.requiresVerification) {
      if (!this.settings.is_verified) {
        this.router.navigate(['verify-email']);
        return false;
      }
    }

    return true;

  }

}
